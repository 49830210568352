<div class="special-offer-upgrades" *ngIf="specialOfferStoreService.filterSpecialOfferUpgradeList(specialOfferStoreService.specialOfferUpgrades).length">
    <div class="header">Upgrade your booking?</div>
    <div *ngFor="let upgrade of specialOfferStoreService.filterSpecialOfferUpgradeList(specialOfferStoreService.specialOfferUpgrades)" class="upgrade">
        <div class="upgrade-info">
            <div class="upgrade-description">

                <p>Upgrade your <strong>{{upgrade.slotType?.shortDescription}}</strong> to <strong>{{upgrade.specialOffer?.name}}</strong>.</p>
                <div class="save" *ngIf="upgrade.specialOffer.discountedPrice.type == 3">
                    Save £{{(upgrade.specialOffer.fullPrice.value - upgrade.specialOffer.discountedPrice.value) | number:"1.2-2"}}
                </div>
                <div class="save" *ngIf="upgrade.specialOffer.discountedPrice.type != 3">
                    Save at least £{{(upgrade.specialOffer.fullPrice.value - upgrade.specialOffer.discountedPrice.value) | number:"1.2-2"}} when you upgrade.
                </div>
            </div>

            <div class="upgrade-price">
                <div class="price-preamble strikethrough" *ngIf="upgrade.specialOffer.discountedPrice.type == 3">£{{upgrade.specialOffer.fullPrice.value | number:"1.2-2"}}</div>
                <div class="price-preamble" *ngIf="specialOfferStoreService.getPricePreamble(upgrade.specialOffer) != ''">{{specialOfferStoreService.getPricePreamble(upgrade.specialOffer)}}</div>
                {{"£" + (upgrade.specialOffer.discountedPrice.value | number:"1.2-2")}}
            </div>
        </div>
        
        <button class="btn btn-primary" (click)="basketStoreService.upgradeSlotTypeToSpecialOffer(upgrade.slotType, upgrade.specialOffer)">{{config.buttonText}}</button>
    </div>
</div>
