import { Injectable } from '@angular/core';
import { IBooking } from 'src/app/interfaces/booking.interface';
import {
	IGooglePurchaseItem,
	IGooglePurchase,
} from 'src/app/interfaces/google-analytics.interface';
import { BasketStoreService } from '../../basket/basket-store.service';
import { LoggerStoreService } from '../../logger/logger-store.service';

declare var gtag: Function;

@Injectable({
	providedIn: 'root',
})
export class GoogleAnalyticsStoreService {
	enabled = false;
	googleAnalyticsID = '';

	constructor(
		private loggerStoreService: LoggerStoreService,
		private basketStoreService: BasketStoreService
	) {}

	configureGoogleAnalytics(googleAnalyticsID: string) {
		try {
			this.loggerStoreService.log(
				'Google Analytics Store: Initialising google analytics'
			);
			const script1 = document.createElement('script');
			script1.async = true;
			script1.src =
				'https://www.googletagmanager.com/gtag/js?id=' +
				googleAnalyticsID;
			document.head.appendChild(script1);

			const script2 = document.createElement('script');
			script2.innerHTML =
				`
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', '` +
				googleAnalyticsID +
				`', {'send_page_view': false});`;
			document.head.appendChild(script2);
			this.googleAnalyticsID = googleAnalyticsID;
			this.enabled = true;
		} catch (ex) {
			console.error('Error appending google analytics');
			console.error(ex);
		}
	}

	pageView(pageUrl: string) {
		if (this.enabled) {
			gtag('event', 'page_view', {
				page_path: pageUrl,
			});
		}
	}

	trackBooking(booking: IBooking) {
		if (this.enabled) {
			const items: IGooglePurchaseItem[] = [];

			booking.onlineShoppingBasket.tyres.forEach((tyre) => {
				items.push({
					id: tyre.id,
					name: tyre.treadPattern,
					brand: tyre.manufacturerName,
					category: 'Tyres',
					quantity: tyre.quantity,
					price: tyre.quantity * tyre.fullPrice.value,
				});
			});

			booking.onlineShoppingBasket.slotTypes.forEach((slotType) => {
				items.push({
					id: slotType.id,
					name: slotType.shortDescription,
					category: 'Slot',
					quantity: 1,
					price: slotType.fullPrice.value,
				});
			});

			booking.onlineShoppingBasket.specialOffers.forEach(
				(specialOffer) => {
					items.push({
						id: specialOffer.id,
						name: specialOffer.name,
						category: 'Special offer',
						quantity: 1,
						price: specialOffer.discountedPrice.value,
					});
				}
			);

			booking.onlineShoppingBasket.extras.forEach((extra) => {
				items.push({
					id: extra.id,
					name: extra.name,
					category: 'Extra',
					quantity: 1,
					price: extra.fullPrice.value,
				});
			});

			let date = new Date();

			const googlePurchase: IGooglePurchase = {
				transaction_id: date.getTime(),
				affiliation: booking.branch.branchName,
				value: this.basketStoreService.getBasketTotal(),
				currency: 'GBP',
				items: items,
			};

			gtag('event', 'purchase', googlePurchase);
		}
	}
}
