import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IAuthorizationData } from 'src/app/interfaces/authorization-data.interface';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private http: HttpClient) { }

    getToken(grantType: string, branchGuid: string, clientId: string) {

        const data = 'grant_type=' + grantType + '&client_id=' + clientId + '&branch_guid=' + branchGuid;
        const headers = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }};
        return this.http.post<IAuthorizationData>(`${environment.apiUrl}/token`, data, headers);
    }
}
