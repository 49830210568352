import { Injectable } from '@angular/core';
import { IBooking } from 'src/app/interfaces/booking.interface';
import { LoggerStoreService } from '../../logger/logger-store.service';
import {
	IFacebookPixelPurchaseItem,
	IFacebookPixelPurchase,
} from '../../../interfaces/facebook-pixel.interface';
import { BasketStoreService } from '../../basket/basket-store.service';

declare var fbq: Function;

@Injectable({
	providedIn: 'root',
})
export class FacebookPixelStoreService {
	enabled = false;
	facebookPixelID = '';

	constructor(
		private basketStoreService: BasketStoreService,
		private loggerStoreService: LoggerStoreService
	) {}

	configureFacebookPixel(pixelID: string) {
		try {
			this.loggerStoreService.log(
				'Facebook Pixel Store: Initialising facebook pixel'
			);

			const script = document.createElement('script');
			script.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${pixelID}');
        fbq('track', 'PageView')`;
			document.head.appendChild(script);

			const pixelImg = document.createElement('noscript');
			pixelImg.innerHTML = `
      <img height="1" width="1" style="display:none" 
       src="https://www.facebook.com/tr?id=${pixelID}&ev=PageView&noscript=1"/>
      `;
			document.body.appendChild(pixelImg);

			this.facebookPixelID = pixelID;
			this.enabled = true;
		} catch (ex) {
			console.error('Error appending facebook pixel');
			console.error(ex);
		}
	}

	pageView(pageUrl: string) {
		if (this.enabled) {
			fbq('track', `PageView`, pageUrl);
		}
	}

	trackBooking(booking: IBooking) {
		if (this.enabled) {
			const contents: IFacebookPixelPurchaseItem[] = [];

			booking.onlineShoppingBasket.tyres.forEach((tyre) => {
				contents.push({
					id: tyre.id,
					name: tyre.treadPattern,
					brand: tyre.manufacturerName,
					category: 'Tyres',
					quantity: tyre.quantity,
					price: tyre.quantity * tyre.fullPrice.value,
				});
			});

			booking.onlineShoppingBasket.slotTypes.forEach((slotType) => {
				contents.push({
					id: slotType.id,
					name: slotType.shortDescription,
					category: 'Slot',
					quantity: 1,
					price: slotType.fullPrice.value,
				});
			});

			booking.onlineShoppingBasket.specialOffers.forEach(
				(specialOffer) => {
					contents.push({
						id: specialOffer.id,
						name: specialOffer.name,
						category: 'Special offer',
						quantity: 1,
						price: specialOffer.discountedPrice.value,
					});
				}
			);

			booking.onlineShoppingBasket.extras.forEach((extra) => {
				contents.push({
					id: extra.id,
					name: extra.name,
					category: 'Extra',
					quantity: 1,
					price: extra.fullPrice.value,
				});
			});

			const facebookPixelPurchase: IFacebookPixelPurchase = {
				// content_ids: [...contents.map((c) => c.id.toString())],
				// content_name: 'booking',
				content_type: 'product',
				contents: contents,
				currency: 'GBP',
				// num_items: contents.length,
				value: this.basketStoreService.getBasketTotal(),
			};

			fbq('track', 'Purchase', facebookPixelPurchase);
		}
	}
}
