<div class="widget_mot-reminder" [ngClass]="{'widget_narrow': !wide}">
    <div class="widget_loading-panel" *ngIf="loading"></div>
    <div class="widget_reminder-created" *ngIf="completeReminderForm">MOT reminder created!</div>
    <div class="widget_check-mot">
        <div class="widget_title">{{ config?.title }}</div>
        <div class="widget_description">{{ config?.description }}</div>
        <div class="widget_step widget_step-1" *ngIf="!motChecked">1 - Registration number
        </div>

        <div class="widget_vrm-form-wrapper" *ngIf="!motChecked">
            <form class="widget_vrm-form" [formGroup]="regForm" (ngSubmit)="doVrm();">
                <div class="widget_vrm-input-wrapper">
                    <input class="vrm-input" placeholder="Vehicle registration" formControlName="vehicleRegFormControl" />
                    <button class="widget_button widget_vrm-button" (click)="doVrm()" type="submit" [disabled]="disableVrm()">
                         Check
                    </button>
                </div>
                <mat-error *ngIf="regForm.get('vehicleRegFormControl').hasError('invalidRegistration')">
                    Invalid Registration
                </mat-error>
            </form>
           
        </div>

        <div class="widget_mot-info" *ngIf="motChecked">
            <div class="widget_vehicle-details">
                {{ vehicle.make }} {{ vehicle.model }}
                <a class="widget_secondary-button" (click)="startAgain()">Change</a>
            </div>
            <div class="widget_title">
                <div *ngIf="motValid">
                    Your MOT is due on {{ getMOTDueDate() | moment:'dddd, Do MMMM yyyy' }}
                </div>
                <div *ngIf="!motValid">
                    No valid MOT
                </div>
                <div *ngIf="motCloseToOrExpired" class="widget_book">
                    <a class="widget_button" href="javascript:void(0)" (click)="bookMOT()">Book now</a>
                </div>
            </div>
        </div>

    </div>
    <div class="widget_create-reminder-book">
        <div class="widget_user-action">
            <div class="widget_remind">
                <div class="widget_step">
                    <span class="widget_number-badge">2</span>
                    2 - Your details
                    <span class="widget_reminder-error" *ngIf="reminderFormSubmitError"> Error creating reminder, please
                        try again.</span>
                </div>
                <div class="widget_description">
                    Enter details for the reminders you wish to receive below.
                </div>
                <form class="widget_reminder-form-wrapper" [formGroup]="reminderForm" #formDirective="ngForm"
                    (ngSubmit)="reminderFormSubmit(reminderForm, formDirective)">
                    <div class="widget_reminder-select-wrapper"
                        *ngIf="companyStoreService?.company?.branches?.length > 1">
                        <app-company-select-branch [template]="'dropdown'" (branchSelected)="branchSelected($event)">
                        </app-company-select-branch>
                    </div>

                    <div class="widget_row widget_name-inputs">
                        <div class="widget_col">
                            <mat-form-field [appearance]="config?.formAppearance">
                                <mat-label>First name <span class="text-danger">*</span>
                                </mat-label>
                                <input matInput class="widget_reminder-input" formControlName="firstNameFormControl">
                                <mat-error *ngIf="reminderForm.get('firstNameFormControl').hasError('required')">
                                    First name is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="widget_col">
                            <mat-form-field [appearance]="config?.formAppearance">
                                <mat-label>Surname <span class="text-danger">*</span>
                                </mat-label>
                                <input matInput class="widget_reminder-input" formControlName="lastNameFormControl">
                                <mat-error *ngIf="reminderForm.get('lastNameFormControl').hasError('required')">
                                    Surname is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="widget_row widget_contact-inputs">
                        <div class="widget_col">
                            <mat-form-field [appearance]="config?.formAppearance">
                                <mat-label>Email</mat-label>
                                <input matInput class="widget_reminder-input" type="email" formControlName="emailFormControl">
                                <mat-error *ngIf="reminderForm.errors?.missingContact">
                                    Email or Mobile Number is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="widget_col">
                            <mat-form-field [appearance]="config?.formAppearance">
                                <mat-label>Mobile Number</mat-label>
                                <input matInput class="widget_reminder-input" type="tel" formControlName="numberFormControl">
                                <mat-error *ngIf="reminderForm.errors?.missingContact">
                                    Mobile Number or Email is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <button class="widget_button widget_remind-button" [disabled]="isReminderButtonDisabled()">Create reminder</button>
                    

                    
                </form>
            </div>
        </div>
    </div>
</div>