<div class="widget_vrm-lookup">
    <div class="widget_inner-wrapper">
        <div class="widget_loading-panel" *ngIf="loading"></div>
        <div class="widget_title">Book Online with <span>{{getCompanyBranchName()}}</span></div>
        <div *ngIf="companyStoreService?.company?.branches?.length > 1">
            <div class="widget_description">Select where you'd like to book</div>
            <app-company-select-branch [template]="'dropdown'" (branchSelected)="branchSelected($event)"></app-company-select-branch>
        </div>
        <div *ngIf="configStoreService?.config?.selectVehicleType">
            <div class="widget_description">Select your vehicle type</div>
            <app-vehicle-type [template]="'dropdown'" (vehicleTypeSelected)="vehicleTypeSelected($event)"></app-vehicle-type>
        </div>
        <div class="widget_description">Enter your vehicle registration to get a price & book online.</div>
        <div class="widget_vrm-form-wrapper">
            <div class="widget_vrm-input-wrapper">
                <div class="widget_gb">
                    <img src="{{env.assetsUrl}}/images/EU-section-with-GB.svg" />
                </div>
                <input class="widget_vrm-input" placeholder="Enter your registration" [(ngModel)]="vehicleRegistration" />
            </div>
            <a href="javascript:void(0)" class="widget_button" (click)="doVrm()" [ngClass]="{'widget_button-disabled': isButtonDisabled()}" [attr.disabled]="isButtonDisabled()">{{config?.buttonText}}
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" class="widget_chevron svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                </svg>
            </a>
        </div>
    </div>
</div>
