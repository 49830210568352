import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ISlotTypeGroup } from 'src/app/interfaces/slot-type-group.interface';
import { ISlotType } from 'src/app/interfaces/slot-type.interface';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { LoggerStoreService } from 'src/app/services/logger/logger-store.service';
import { DialogInfoComponent } from '../../dialogs/dialog-info/dialog-info.component';
import { environment } from '../../../../environments/environment.beta';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';

@Component({
    selector: 'app-slot-type-service-checklist',
    templateUrl: './slot-type-service-checklist.component.html',
    styleUrls: ['./slot-type-service-checklist.component.scss']
})
export class SlotTypeServiceChecklistComponent implements OnInit, AfterViewInit  {
    config: IComponentConfig;

    @ViewChild('serviceChecklist') serviceChecklist: ElementRef;

    @Input() template: string;
    @Input() slotTypeGroup: ISlotTypeGroup;
    @Input() readOnly: boolean = false;
    @Input() widget = false;
    
    totalItems = 0;
    spacerColCssClass = 'col-4';
    itemsWrapperCssClass = 'col-8';
    showLongDescription =  true;
    showLongDescriptionLink = false;
    longDescriptionMaxLength = 100;
    maxWidth = 0;
    minWidth = 600;
    wide;
    env;

    constructor(
        public basketStoreService: BasketStoreService,
        private configStoreService: ConfigStoreService,
        public companyStoreService: CompanyStoreService,
        private authStoreService: AuthStoreService,
        private modalService: NgbModal,
        private loggerStoreService: LoggerStoreService) { 
            this.env = environment;
        }
    

    ngOnInit(): void {
        this.config = this.configStoreService.getComponentConfig('SlotTypeServiceChecklistComponent');

        if(!this.slotTypeGroup) {return;}
        this.init();        
    }

    ngAfterViewInit(): void {
        this.calculateWidth();
    }

    @HostListener('window:resize', ['$event'])
	onResize(event) {
		this.calculateWidth();
	}

    calculateWidth() {
		const widgetWidth = this.serviceChecklist.nativeElement.offsetWidth;
		if (widgetWidth > this.minWidth) {
			this.wide = true;
		} else {
			this.wide = false;
            this.maxWidth = widgetWidth;
		}
	}

    init() { 
        this.totalItems = this.getTotalItems();

        switch(this.slotTypeGroup.slotTypes.length) {
            case 1:
                this.spacerColCssClass = 'col-9';
                this.itemsWrapperCssClass = 'col-3';
                this.minWidth = 500;
            break;
            case 2:
                this.spacerColCssClass = 'col-6';
                this.itemsWrapperCssClass = 'col-6';
                this.minWidth = 550;
            break;
            case 3:
                this.minWidth = 600;
            break;
            case 4:
                this.spacerColCssClass = 'col-3';
                this.itemsWrapperCssClass = 'col-9';
                this.minWidth = 800;
            break;
            default:
                this.spacerColCssClass = 'col-3';
                this.itemsWrapperCssClass = 'col-9';
                this.minWidth = 850;
            break;
        }

        this.showLongDescription = this.config.services.showSlotTypeLongDescription;

        if (this.showLongDescription) {
            let longestDescription = 0;
            this.slotTypeGroup.slotTypes.forEach(g => {
                longestDescription = g.longDescription.length > longestDescription ? g.longDescription.length : longestDescription;
            });

            if (longestDescription > this.longDescriptionMaxLength) {
                this.loggerStoreService.log(`Slot Type Service Checklist: Warning - One of the service long descriptions is longer than ${this.longDescriptionMaxLength} characters, so the descriptions have been replaced with a link.`)
                this.showLongDescription = false;
                this.showLongDescriptionLink = true;
            }
        }
    }

    showHideGroup(group) {
        if (this.readOnly) { return; }
        group.hidden =! group.hidden;
    }
    
    checkSlotTypesCompatibility(slotTypeID: number, slotTypesCompatibility: {[key: number]: boolean }): boolean {
        const compatibilityValue = Object.keys(slotTypesCompatibility)
            // find our key (ID)
            .filter(key => key === slotTypeID.toString())
            // if we find it, reduce the key/value to just its value
            // and assign that an object.
            .reduce((obj, key) => {
                obj = slotTypesCompatibility[key];
                return obj;
            }, {});

        // if we found something *and* its true return true.
        if (compatibilityValue) {
            return true;
        }
        // if we didn't find something *or* its false return false.
        return false;
    }

    getPricePreamble(slotType: ISlotType): string {
        switch(slotType.fullPrice.type) {
            case 1:
                return 'Price from';
            case 2:
                return 'Price to';
            case 4:
                return slotType.fullPrice.label;
            default:
                return ''
        }
    }

    getTotalItems() {
        let count = 0;
        this.slotTypeGroup.checklistTemplate.itemGroups.forEach(g => {
            count += g.items.length;
        });
        return count;
    }

    getSlotTypeCount(slotTypeID: number) {
        let count = 0;
        this.slotTypeGroup.checklistTemplate.itemGroups.forEach(g => {
            g.items.forEach(i => {
                count += i.slotTypesCompatibility[slotTypeID] == true ? 1 : 0;
            });
        });
        return count;
    }

    addRemoveSlotType(slotType: ISlotType) {
        if(this.widget) {
            this.addToBasketAndNavigateSlotType(slotType);
            return;
        }

        this.basketStoreService.addRemoveSlotType(slotType);        
    }

    
	addToBasketAndNavigateSlotType(slotType: ISlotType) {
		let url = window.location.search;
			url = url.replace("?", '');
		let queryString = `?startingUrl=${window.location.href}&slotTypeID=${slotType.id}`;

		if (this.companyStoreService.branch.guid) {
			queryString += `&branchGuid=${this.companyStoreService.branch.guid}`;
		}	
		
		queryString += `${url ? '&' + url: ''}`;
		
		if (window.innerWidth < this.config.mobileCutoff && this.config.mobileRedirect) {
			// Here, we redirect to a pure booking system url/route.
			window.location.href = this.env.baseUrl + this.authStoreService.clientId + '/' + this.configStoreService.configId + queryString;
		} else {
			window.location.href = this.config.bookingSystemUrl + queryString;
		}
	}

    openInfoModal(title: string, innerText: string) {
        const modalRef = this.modalService.open(DialogInfoComponent);
        modalRef.componentInstance.data = {
            title: title,
            innerHtml: innerText,
            html: false
        };

        this.loggerStoreService.log(innerText);

        modalRef.result.then((response) => {

        }, (reason) => { });
    }
}
