<div class="widget_booking-system-link">
    
    <div class="widget_inner-wrapper" >
        <div class="widget_loading-panel {{!loading ? 'hidden' : '' }}"></div>
        <div class="widget_title">{{title}}</div>
        
        <div class="widget_description">{{config?.description}}</div>
        <a href="javascript:void(0)" class="widget_button" (click)="navigate()">{{config?.buttonText}}
            
        </a>
    </div>
</div>

