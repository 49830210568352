<div class="tyre-results-wrapper row" [ngClass]="{'show-filters-mobile': showFilters}">
    <!-- Tyre Filters-->
    <div class="tyre-filters col-lg-3">
        <button class="btn btn-primary btn-lg btn-filters" (click)="showFilters =! showFilters">Hide filters</button>
        <button class="btn btn-light btn-reset btn-block" (click)="tyreStoreService.resetTyreFilters()">Clear filters</button>
        <div class="filter-wrapper manufacturers" [ngClass]="{'open': showManufacturers}">
            <div class="filter-title" (click)="showManufacturers =! showManufacturers">
                <span>Tyre brands</span>
                <i class="fas fa-chevron-up" [hidden]="!showManufacturers"></i>
                <i class="fas fa-chevron-down" [hidden]="showManufacturers"></i>
            </div>
            <div class="filter-options">
                <div *ngFor="let manufacturer of tyreStoreService.tyreManufacturers | keyvalue">
                    <mat-checkbox [(ngModel)]="manufacturer.value"
                        (click)="tyreStoreService.toggleTyreManufacturer(manufacturer)">{{manufacturer.key | titlecase}}
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <div class="filter-wrapper tyre-categories" [ngClass]="{'open': showTyreCategories}">
            <div class="filter-title" (click)="showTyreCategories =! showTyreCategories">
                <span>Categories</span>
                <i class="fas fa-chevron-up" [hidden]="!showTyreCategories"></i>
                <i class="fas fa-chevron-down" [hidden]="showTyreCategories"></i>
            </div>
            <div class="filter-options">
                <div>
                    <mat-checkbox value="category1" (click)="tyreStoreService.toggleTyreCategory()" [(ngModel)]="tyreStoreService.tyreCatgories[1]">
                        {{config.tyres.tyreCategories.category1}}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox value="category2" (click)="tyreStoreService.toggleTyreCategory()" [(ngModel)]="tyreStoreService.tyreCatgories[2]">
                        {{config.tyres.tyreCategories.category2}}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox value="category3" (click)="tyreStoreService.toggleTyreCategory()" [(ngModel)]="tyreStoreService.tyreCatgories[3]">
                        {{config.tyres.tyreCategories.category3}}
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <div class="filter-wrapper seasons" [ngClass]="{'open': showSeasons}">
            <div class="filter-title" (click)="showSeasons =! showSeasons">
                <span>Seasons</span>
                <i class="fas fa-chevron-up" [hidden]="!showSeasons"></i>
                <i class="fas fa-chevron-down" [hidden]="showSeasons"></i>
            </div>
            <div class="filter-options">
                <div>
                    <mat-checkbox [(ngModel)]="tyreStoreService.tyreSeasons.allSeasons" (click)="tyreStoreService.toggleTyreSeason()">All
                        seasons</mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [(ngModel)]="tyreStoreService.tyreSeasons.summer" (click)="tyreStoreService.toggleTyreSeason()">Summer tyre
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [(ngModel)]="tyreStoreService.tyreSeasons.winter" (click)="tyreStoreService.toggleTyreSeason()">Winter tyre
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <div class="filter-wrapper load-index" [ngClass]="{'open': showLoadIndex}">
            <div class="filter-title" (click)="showLoadIndex =! showLoadIndex">
                <span>Load index speed</span>
                <i class="fas fa-chevron-up" [hidden]="!showSeasons"></i>
                <i class="fas fa-chevron-down" [hidden]="showSeasons"></i>
            </div>
            <div class="filter-options">
                <div *ngFor="let rating of tyreStoreService.tyreLoadIndexSpeedRatings | keyvalue">
                    <mat-checkbox [(ngModel)]="rating.value"
                        (click)="tyreStoreService.toggleTyreLoadIndexSpeedRating(rating)">{{rating.key | uppercase}}
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <div class="filter-wrapper fuel-efficiencies" [ngClass]="{'open': showFuelEfficiency}">
            <div class="filter-title" (click)="showFuelEfficiency =! showFuelEfficiency">
                <span>Fuel efficiencies</span>
                <i class="fas fa-chevron-up" [hidden]="!showFuelEfficiency"></i>
                <i class="fas fa-chevron-down" [hidden]="showFuelEfficiency"></i>
            </div>
            <div class="filter-options">
                <div *ngFor="let fuel of tyreStoreService.tyreFuelEfficiencies | keyvalue">
                    <mat-checkbox [(ngModel)]="fuel.value" (click)="tyreStoreService.toggleTyreFuelEfficiency(fuel)">
                        {{fuel.key | uppercase}}</mat-checkbox>
                </div>
            </div>
        </div>

        <div class="filter-wrapper wet-grips" [ngClass]="{'open': showWetGrip}">
            <div class="filter-title" (click)="showWetGrip =! showWetGrip">
                <span>Wet grip grades</span>
                <i class="fas fa-chevron-up" [hidden]="!showWetGrip"></i>
                <i class="fas fa-chevron-down" [hidden]="showWetGrip"></i>
            </div>
            <div class="filter-options">
                <div *ngFor="let wetGripGrade of tyreStoreService.tyreWetGripGrades | keyvalue">
                    <mat-checkbox [(ngModel)]="wetGripGrade.value"
                        (click)="tyreStoreService.toggleTyreWetGripGrade(wetGripGrade)">{{wetGripGrade.key | uppercase}}
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <div class="filter-wrapper noise-ratings" [ngClass]="{'open': showNoiseRating}">
            <div class="filter-title" (click)="showNoiseRating =! showNoiseRating">
                <span>Noise ratings</span>
                <i class="fas fa-chevron-up" [hidden]="!showNoiseRating"></i>
                <i class="fas fa-chevron-down" [hidden]="showNoiseRating"></i>
            </div>
            <div class="filter-options">
                <div *ngFor="let noiseRating of tyreStoreService.tyreNoiseRatings | keyvalue">
                    <mat-checkbox [(ngModel)]="noiseRating.value"
                        (click)="tyreStoreService.toggleTyreNoiseRating(noiseRating)">{{noiseRating.key | uppercase}}db
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <div class="filter-wrapper specialist-type" [ngClass]="{'open': showSpecialistType}">
            <div class="filter-title" (click)="showSpecialistType =! showSpecialistType">
                <span>Specialist Type</span>
                <i class="fas fa-chevron-up" [hidden]="!showSpecialistType"></i>
                <i class="fas fa-chevron-down" [hidden]="showSpecialistType"></i>
            </div>
            <div class="filter-options">
                    <div>
                        <mat-checkbox [(ngModel)]="tyreStoreService.tyreSpecialistType.runFlat"
                            (click)="tyreStoreService.toggleTyreSpecialistType()">
                            Run Flat                        
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox [(ngModel)]="tyreStoreService.tyreSpecialistType.extraLoad"
                            (click)="tyreStoreService.toggleTyreSpecialistType()">
                            Extra Load                        
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox [(ngModel)]="tyreStoreService.tyreSpecialistType.vanTyres"
                            (click)="tyreStoreService.toggleTyreSpecialistType()">
                            Van                     
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox [(ngModel)]="tyreStoreService.tyreSpecialistType.suvTyres"
                            (click)="tyreStoreService.toggleTyreSpecialistType()">
                            SUV                     
                        </mat-checkbox>
                    </div>
            </div>
        </div>

    </div>
    <!-- End Tyre Filters-->
    <!-- Tyre Results -->
    <div class="tyre-results col-lg-9">

        <!-- This is currently hidden with CSS -->
        <div class="selected-tyres" *ngIf="config.tyres.enabled && !config.services.enabled">
            <h5 class="mobile-count">
                <span>Showing</span> {{productCountLabel()}}
            </h5>
            <div class="tyre-size-details">
                <div class="tyre-size"> 
                    {{vehicleStoreService.getFormattedTyreSize()}}
                </div>
                <a href="javascript:void(0)" (click)="searchAgain()">Search for a different tyre size</a>
            </div>
            <div class="selected-tyre-details">
                <div class="quantity">
                    {{selectedTyresLabel()}}
                </div>
                <a href="javascript:void(0)" (click)="basketStoreService.clearTyres()">Clear selection</a>
            </div>
            <button class="btn btn-primary btn-lg" [disabled]="!basketStoreService.tyres.length" (click)="proceed($event)">Continue <i class="fas fa-chevron-right"></i></button>
            
        </div>

        <div *ngIf="tyreStoreService.featuredTyres.length && !mobileDevice"> 
            <h5>Featured brand</h5>
            <div class="featured-tyres" *ngIf="!mobileDevice">
                <ng-template *ngFor="let tyre of tyreStoreService.featuredTyres | slice:0:1;" 
                    [ngTemplateOutlet]="TyreListItem" [ngTemplateOutletContext]="{tyre: tyre}"></ng-template>
            </div>
        </div>

            

        <div class="tyre-toolbar row">

            <h5 class="col-lg-5 d-none d-lg-block">
                <span>Showing</span> {{productCountLabel()}}
            </h5>

            <div class="d-lg-none col-6">
                <button (click)="showFilters =! showFilters" class="btn btn-primary btn-block btn-filters">Show filters</button>
            </div>

            <div class="col-lg-3 text-right d-none d-lg-block">
                <div class="btn-group">
                    <button class="btn"
                    [ngClass]="{'btn-primary': template=='card', 'btn-light': template!='card'}"
                    (click)="changeTemplate('card')">
                    <i class="fas fa-th-large"></i>
                </button>
                <button class="btn"
                    [ngClass]="{'btn-primary': template=='list', 'btn-light': template!='list'}"
                    (click)="changeTemplate('list')">
                    <i class="fas fa-grip-lines"></i>
                </button>
                <button class="btn"
                    [ngClass]="{'btn-primary': template=='table', 'btn-light': template!='table'}"
                    (click)="changeTemplate('table')">
                    <i class="fas fa-table"></i>
                </button>
                </div>
                
            </div>

            <div class="col-6 col-lg-4 text-right">
                <mat-form-field [appearance]="config.formAppearance" class="d-block">
                    <mat-label>Order by</mat-label>
                    <mat-select [(ngModel)]="tyreStoreService.sortSelection" name="sort"
                        (selectionChange)="tyreStoreService.sortTyres()">
                        <mat-option value="lowToHigh">Price low to high</mat-option>
                        <mat-option value="highToLow">Price high to low</mat-option>
                        <mat-option value="fuelEfficiency">Fuel efficiency</mat-option>
                        <mat-option value="wetGrip">Wet grip grade</mat-option>
                        <mat-option value="noiseDb">Noise db</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>

        <div *ngIf="tyreStoreService.featuredTyres.length && mobileDevice"> 
            <h5>Featured brand</h5>
            <div class="featured-tyres row">
                <ng-template *ngFor="let tyre of tyreStoreService.featuredTyres | slice:0:1;" [ngTemplateOutlet]="TyreCardItem"
                    [ngTemplateOutletContext]="{tyre: tyre}"></ng-template>
            </div>
        </div>  
        
        <h5 *ngIf="mobileDevice">
            <span>Showing</span> {{productCountLabel()}}
        </h5>

        <ng-container *ngIf="template == 'list'">
            <div *ngIf="!tyreStoreService.loading">
                <ng-template *ngFor="let tyre of tyreStoreService.splicedTyres" [ngTemplateOutlet]="TyreListItem"
                [ngTemplateOutletContext]="{tyre: tyre}"></ng-template>
            </div>
            <div *ngIf="tyreStoreService.loading">
                <ng-template *ngFor="let number of [0,1,2,3,4,5,6,7,8,9]" [ngTemplateOutlet]="TyreListItemPlaceholder" ></ng-template>
            </div>
        </ng-container>

        <ng-container *ngIf="template == 'card'">
            <div class="row" *ngIf="!tyreStoreService.loading">
                <ng-template *ngFor="let tyre of tyreStoreService.splicedTyres" [ngTemplateOutlet]="TyreCardItem"
                    [ngTemplateOutletContext]="{tyre: tyre}"></ng-template>
            </div>
            <div class="row" *ngIf="tyreStoreService.loading">
                <ng-template *ngFor="let number of [0,1,2,3,4,5,6,7,8]" [ngTemplateOutlet]="TyreCardPlaceHolder">
                </ng-template>
            </div>
        </ng-container>

        <ng-container *ngIf="template == 'table'">
            <div class="tyre-table">
                <div class="tyre-table-header">
                    <div class="tyre-images"></div>
                    <div class="tyre-description">
                        <div class="description">
                            Description
                        </div>
                        <div class="icon wet-grip">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179.92 151.53">
                                <defs>
                                    <style>
                                        .cls-1 {
                                            fill: #0092d5;
                                        }

                                        .cls-2 {
                                            fill: none;
                                            stroke: #0092d5;
                                            stroke-miterlimit: 10;
                                            stroke-width: 2.25px;
                                        }
                                    </style>
                                </defs>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path class="cls-1"
                                            d="M120.32,109.81a7.29,7.29,0,0,1,10.2-1.1,7.17,7.17,0,0,1,.9,10.2c-2.6,3.1-19.9,12.8-19.9,12.8s6.3-18.7,8.8-21.9" />
                                        <path class="cls-1"
                                            d="M119.32,133.71a7.2,7.2,0,1,1,6.2,13c-3.6,1.7-23.5,3.2-23.5,3.2s13.6-14.5,17.3-16.2" />
                                        <path class="cls-1"
                                            d="M12.82,109.81a7.29,7.29,0,0,0-10.2-1.1,7.17,7.17,0,0,0-.9,10.2c2.6,3.1,19.9,12.8,19.9,12.8s-6.2-18.7-8.8-21.9" />
                                        <path class="cls-1"
                                            d="M13.92,133.71a7.2,7.2,0,1,0-6.2,13c3.6,1.7,23.5,3.2,23.5,3.2s-13.7-14.5-17.3-16.2" />
                                        <path class="cls-1"
                                            d="M82.42,58.71c-5.2,7.7-7.5,28.3-5.2,46s8.4,25.9,13.6,18.2c3.7-5.5,6-17.8,6.1-30.8h-6.8V58C87.72,54.91,84.92,54.91,82.42,58.71Z" />
                                        <path class="cls-1"
                                            d="M101.42,92.11c-.1,16.5-2.9,32-7.7,39-6.5,9.6-14.2-.6-17.1-22.8s0-48.1,6.6-57.8c2.2-3.3,4.6-4.3,6.9-3.2V32.91L75.82,31a4.79,4.79,0,0,1,2.2-.6h-7.6a21.07,21.07,0,0,0-8.3,4.2c-16.1,13.5-23.7,49.8-17,81,4,18.9,11.3,31.9,19.4,35.9h.4c7.8.1,19.8-.1,26.7-2.1a13.9,13.9,0,0,0,6.1-3.8c8.8-9.5,14.1-30.9,14.3-53.4h-10.6Zm-28.3-60.6,14.7,1.8a10.07,10.07,0,0,0-6.5,1.8l-11.5-1.4A12.65,12.65,0,0,1,73.12,31.51Zm-11.3,13,11.3,1.1c-.9,1.6-1.7,3.4-2.5,5.2l-11.1-1.1A40.89,40.89,0,0,1,61.82,44.51Zm-4.2,10.3,11.3.8a54.31,54.31,0,0,0-1.8,6.1l-11.2-.8C56.42,58.81,57,56.71,57.62,54.81Zm-3.1,12.3,11.4.6c-.4,2.2-.8,4.4-1.1,6.7l-11.4-.6C53.72,71.51,54.12,69.31,54.52,67.11Zm-1.9,14.7,11.4.4c-.1,1.9-.3,3.8-.3,5.7l-11.3-.3C52.42,85.61,52.52,83.71,52.62,81.81Zm-.2,15.6h11.2c0,1.3.1,2.6.1,3.9H52.62C52.52,100.11,52.52,98.81,52.42,97.41Zm1.9,17.5c-.2-1-.3-2.1-.5-3.2l10.9-.9c.2,1.1.3,2.3.5,3.4l-10.8,1A.37.37,0,0,1,54.32,114.91Zm2.8,12.3a19.45,19.45,0,0,1-.5-2l10.4-1.1c.2.7.3,1.4.5,2.1Zm3.6,10.3a6.94,6.94,0,0,1-.5-1.3l10-1.3c.2.5.3.9.5,1.4Zm4.8,8.3a5.55,5.55,0,0,1-.6-.8l9.5-1.4.6.9Zm-1.2-105.7a46.27,46.27,0,0,1,3-4l11.6,1.2a10,10,0,0,0-1.1,1,33.57,33.57,0,0,0-2.4,2.9Z" />
                                        <path class="cls-1"
                                            d="M39.52,115.41c-6.6-31.2,1-67.4,17-81a21.86,21.86,0,0,1,7.4-4.3c-.7,0-1.4.1-2.1.1-3,.1-5.8.3-8.6.5-.8.1-1.7.1-2.5.2l-1,.2c-1.5.2-.8.1-2.1.3a2.54,2.54,0,0,0-.7.2c2.4-.2,10.2-.7,10.2-.7a28.07,28.07,0,0,0-5.5,2.7l-12,1.4a15.76,15.76,0,0,0-1.5,1.2l-1.2,1.2h.2l11.3-1.3a29.57,29.57,0,0,0-3.9,4l-10.9,1.2a1,1,0,0,1-.4.5,5.55,5.55,0,0,1-.6.8,26.75,26.75,0,0,0-1.8,2.8h.3l10.1-1c-1.1,1.6-2.1,3.4-3.1,5.2l-9.9,1h0c-.7,1.5-1.3,3.1-1.9,4.8h.1l9.3-.8c-.8,2-1.6,4-2.3,6.1l-9.2.8-1.5,6h.1l8.8-.6c-.5,2.2-1,4.4-1.4,6.7l-8.7.6c-.2,1.4-.3,2.8-.5,4.2-.1.6-.1,1.2-.2,1.8,0,.2-.1.5-.1.7h0v1l8.4-.3c-.2,1.9-.3,3.9-.4,5.8l-8.3.3h-.1v6.8h.1c0,.9.1,1.9.1,2.8h8.3c.1,1.3.1,2.6.2,3.9h-8.4a1.27,1.27,0,0,0,.1.6,14.92,14.92,0,0,1,.2,2.1c.1,1.1.2,2.3.4,3.4h.1c.1,1,.3,2,.4,3l8.6,1c.2,1.2.5,2.5.8,3.7l-8.7-.9v0a3,3,0,0,1,.2,1c.4,1.7.8,3.3,1.2,4.9h0c.4,1.3.8,2.5,1.2,3.8l8.8,1.1c.3.8.5,1.5.8,2.3l-8.9-1.1a4.88,4.88,0,0,1,.3.7c.4.9.7,1.8,1.1,2.7.2.5.4,1,.6,1.4h.1c.6,1.4,1.3,2.6,2,3.9l8.6,1.2a10.93,10.93,0,0,0,.8,1.5l-8.8-1.4.6.9c.7,1,1.3,2,2,2.9.1.2.2.3.3.5h.1c.8,1,1.6,1.8,2.4,2.7l8.2,1.4a13.07,13.07,0,0,0,1.1,1.2l-8.4-1.5c.2.2.4.3.5.4a24.72,24.72,0,0,0,2.7,2.1,2.18,2.18,0,0,1,.5.4c.1.1.3.1.4.2a18.07,18.07,0,0,0,5.2,2.2l1.6.3c1.9.1,6.3.7,10.1,1.1C50.42,146.91,43.42,133.91,39.52,115.41Z" />
                                        <path class="cls-2"
                                            d="M117,53a5.44,5.44,0,1,1-8.4-6.9c1.9-2.3,15-9.6,15-9.6s-4.7,14.2-6.6,16.5" />
                                        <path class="cls-2"
                                            d="M122.62,77.31a5.44,5.44,0,1,1-8.4-6.9c1.9-2.3,15-9.6,15-9.6s-4.6,14.2-6.6,16.5" />
                                        <path class="cls-2"
                                            d="M137.62,53a5.44,5.44,0,1,1-8.4-6.9c1.9-2.3,15-9.6,15-9.6s-4.7,14.2-6.6,16.5" />
                                        <path class="cls-2"
                                            d="M143.22,77.31a5.44,5.44,0,1,1-8.4-6.9c1.9-2.3,15-9.6,15-9.6s-4.7,14.2-6.6,16.5" />
                                        <path class="cls-2"
                                            d="M158.22,53a5.44,5.44,0,1,1-8.4-6.9c1.9-2.3,15-9.6,15-9.6s-4.7,14.2-6.6,16.5" />
                                        <path class="cls-1"
                                            d="M179.92,32.11s-.7-11.5-17.7-9.6c0,0,4.2-9.5-5.2-12.7,0,.1,1.7-9.2-9.3-9.7-8.1-.7-13.2,2.1-14.9,5.8,0,0-23-2.3-18.1,12.7,0,.1-20,.1-12.7,13.5Z" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="icon fuel-mini">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.8 151.52">
                                <defs>
                                    <style>
                                        .cls-1 {
                                            fill: #0092d5;
                                        }
                                    </style>
                                </defs>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path class="cls-1"
                                            d="M19.4,115.6c-6.6-31.2,1-67.4,17-81a21.86,21.86,0,0,1,7.4-4.3c-.7,0-1.4.1-2.1.1-3,.1-5.8.3-8.6.5-.8.1-1.7.1-2.5.2l-1,.2c-1.5.2-.8.1-2.1.3a2.54,2.54,0,0,0-.7.2c2.4-.2,10.2-.7,10.2-.7a28.07,28.07,0,0,0-5.5,2.7l-12,1.4A15.76,15.76,0,0,0,18,36.4l-1.2,1.2H17l11.3-1.3a29.57,29.57,0,0,0-3.9,4L13.5,41.5a1,1,0,0,1-.4.5,5.55,5.55,0,0,1-.6.8c-.6.9-1.3,1.8-1.9,2.8h.3l10.1-1c-1.1,1.6-2.1,3.4-3.1,5.2L8,50.8H8c-.7,1.5-1.3,3.1-1.9,4.8h.1l9.3-.8c-.8,2-1.6,4-2.3,6.1L4,61.7l-1.5,6h.1l8.8-.6c-.5,2.2-1,4.4-1.4,6.7l-8.7.6c-.2,1.4-.3,2.8-.5,4.2-.1.6-.1,1.2-.2,1.8,0,.2-.1.5-.1.7h0a3.4,3.4,0,0,1-.1,1l8.4-.3c-.2,1.9-.3,3.9-.4,5.8l-8.3.3H0v6.8H.1c0,.9.1,1.9.1,2.8H8.5c.1,1.3.1,2.6.2,3.9H.3a1.27,1.27,0,0,0,.1.6,14.92,14.92,0,0,1,.2,2.1c.1,1.1.2,2.3.4,3.4h.1c.1,1,.3,2,.4,3l8.6,1c.2,1.2.5,2.5.8,3.7l-8.7-.9v0a3,3,0,0,1,.2,1c.4,1.7.8,3.3,1.2,4.9h0c.4,1.3.8,2.5,1.2,3.8l8.8,1.1c.3.8.5,1.5.8,2.3l-8.9-1.1a4.88,4.88,0,0,1,.3.7c.4.9.7,1.8,1.1,2.7.2.5.4,1,.6,1.4h.1c.6,1.4,1.3,2.6,2,3.9l8.6,1.2a10.93,10.93,0,0,0,.8,1.5l-8.8-1.4.6.9c.7,1,1.3,2,2,2.9.1.2.2.3.3.5h.1c.8,1,1.6,1.8,2.4,2.7l8.2,1.4a13.07,13.07,0,0,0,1.1,1.2l-8.4-1.5c.2.2.4.3.5.4a24.72,24.72,0,0,0,2.7,2.1,2.18,2.18,0,0,1,.5.4c.1.1.3.1.4.2a18.07,18.07,0,0,0,5.2,2.2l1.6.3c1.9.1,6.3.7,10.1,1.1C30.3,147,23.3,134.1,19.4,115.6Z" />
                                        <path class="cls-1"
                                            d="M70,58.2c-2.4-3.1-5.2-3.1-7.7.7-5.2,7.7-7.5,28.3-5.2,46s8.4,25.9,13.6,18.2c4.3-6.4,6.7-21.9,6-37.1H70V58.2Z" />
                                        <path class="cls-1"
                                            d="M81.2,85.9c.7,18.7-2.2,37.4-7.6,45.3-6.5,9.6-14.2-.6-17.1-22.8s0-48.1,6.6-57.8c2.2-3.3,4.6-4.3,6.9-3.2V33L55.7,31.1a4.79,4.79,0,0,1,2.2-.6H50.3A21.07,21.07,0,0,0,42,34.7C25.9,48.2,18.3,84.5,25,115.6c4,18.9,11.3,31.9,19.4,35.9h.4c7.8.1,19.8-.1,26.7-2.1a13.9,13.9,0,0,0,6.1-3.8c9.6-10.4,15.1-35,14.2-59.8H81.2ZM53,31.7l14.7,1.8a10.07,10.07,0,0,0-6.5,1.8L49.7,33.9A10.56,10.56,0,0,1,53,31.7Zm-11.3,13L53,45.8c-.9,1.6-1.7,3.4-2.5,5.2L39.4,49.9A40.89,40.89,0,0,1,41.7,44.7ZM37.5,54.9l11.3.8A54.31,54.31,0,0,0,47,61.8L35.8,61C36.3,58.9,36.9,56.9,37.5,54.9ZM34.4,67.2l11.4.6c-.4,2.2-.8,4.4-1.1,6.7l-11.4-.6C33.6,71.7,34,69.5,34.4,67.2ZM32.5,81.9l11.4.4c-.1,1.9-.3,3.8-.3,5.7l-11.3-.3C32.3,85.8,32.4,83.9,32.5,81.9Zm-.2,15.7H43.5c0,1.3.1,2.6.1,3.9H32.4C32.4,100.3,32.4,98.9,32.3,97.6Zm1.9,17.5c-.2-1-.3-2.1-.5-3.2l10.9-.9c.2,1.1.3,2.3.5,3.4l-10.8,1A.37.37,0,0,1,34.2,115.1ZM37,127.4a19.45,19.45,0,0,1-.5-2l10.4-1.1c.2.7.3,1.4.5,2.1Zm3.6,10.3a6.94,6.94,0,0,1-.5-1.3l10-1.3c.2.5.3.9.5,1.4Zm4.8,8.3a5.55,5.55,0,0,1-.6-.8l9.5-1.4.6.9ZM44.2,40.3a46.27,46.27,0,0,1,3-4l11.6,1.2a10,10,0,0,0-1.1,1,33.57,33.57,0,0,0-2.4,2.9Z" />
                                        <path class="cls-1"
                                            d="M123.6,22.7h-26V4.9h26ZM151,18.6l2.5-2.5S148.4,5,132.9,0V5.2s8.8,3.5,13.3,17.3l.9,3c1.5,5.6,3.1,12.6,3,17.5a9.7,9.7,0,0,1-.1,1.7c-.8,8.4-3.1,9.5-4.9,9.6a3.68,3.68,0,0,1-3.3-1.9c-1-1.4-2-4.1-2-8.4V34.7a11.31,11.31,0,0,0-3.9-9c-1.9-1.6-5-2.5-7.6-2.8V5.3A5.34,5.34,0,0,0,123,0H98a5.34,5.34,0,0,0-5.3,5.3V59.5H90v5.9h41V59.5h-2.7V28.8a11,11,0,0,1,3.9,1.4,5.5,5.5,0,0,1,1.8,4.6v9.4c0,5.1,1.1,9,3,11.7a9.89,9.89,0,0,0,8,4.3h.1c5.5,0,10-5.2,10.6-14.9,0-.7.1-1.4.1-2.1,0-8.1-2.9-18.5-4.8-24.6" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="icon sound">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186.5 123.75">
                                <defs>
                                    <style>
                                        .cls-1 {
                                            fill: #0092d5;
                                        }
                                    </style>
                                </defs>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path class="cls-1"
                                            d="M106.8,93.55C100.2,87.65,93.6,81.75,87,76H72.9V47.75H87l19.8-17.6,8.2-7.3V101Z" />
                                        <path class="cls-1"
                                            d="M88.9,71.05l2.6,2.3a147.16,147.16,0,0,0,0-23l-2.6,2.3H81.1a161.6,161.6,0,0,1,.1,18.4Z" />
                                        <path class="cls-1"
                                            d="M75.1,42.85c-2.7-14.1-8.1-20-12.8-13.1-5.2,7.7-7.5,28.3-5.2,46s8.4,25.9,13.6,18.2c1.9-2.9,3.5-7.5,4.5-13.1H68v-38Z" />
                                        <path class="cls-1"
                                            d="M19.4,86.55c-6.6-31.2,1-67.4,17-81a21.86,21.86,0,0,1,7.4-4.3c-.7,0-1.4.1-2.1.1-3,.1-5.8.3-8.6.5-.8.1-1.7.1-2.5.2l-1,.2c-1.5.2-.8.1-2.1.3a2.54,2.54,0,0,0-.7.2c2.4-.2,10.2-.7,10.2-.7a28.07,28.07,0,0,0-5.5,2.7l-12,1.4A15.76,15.76,0,0,0,18,7.35l-1.2,1.2H17l11.3-1.3a29.57,29.57,0,0,0-3.9,4l-10.9,1.2a1,1,0,0,1-.4.5,5.55,5.55,0,0,1-.6.8c-.6.9-1.3,1.8-1.9,2.8h.3l10.1-1c-1.1,1.6-2.1,3.4-3.1,5.2l-9.9,1H8c-.7,1.5-1.3,3.1-1.9,4.8h.1l9.3-.8c-.8,2-1.6,4-2.3,6.1l-9.2.8c-.5,2-1.1,4-1.5,6h.1l8.8-.6c-.5,2.2-1,4.4-1.4,6.7l-8.7.6c-.2,1.4-.3,2.8-.5,4.2-.1.6-.1,1.2-.2,1.8,0,.2-.1.5-.1.7h0a3.4,3.4,0,0,1-.1,1l8.4-.3c-.2,1.9-.3,3.9-.4,5.8l-8.3.3H0v6.8H.1c0,.9.1,1.9.1,2.8H8.5c.1,1.3.1,2.6.2,3.9H.3a1.27,1.27,0,0,0,.1.6,14.92,14.92,0,0,1,.2,2.1c.1,1.1.2,2.3.4,3.4h.1c.1,1,.3,2,.4,3l8.6,1c.2,1.2.5,2.5.8,3.7l-8.7-.9v0a3,3,0,0,1,.2,1c.4,1.7.7,3.3,1.2,4.9h0c.4,1.3.8,2.5,1.2,3.8l8.8,1.1c.3.8.5,1.5.8,2.3l-8.9-1.1a4.88,4.88,0,0,1,.3.7c.4.9.7,1.8,1.1,2.7.2.5.4,1,.6,1.4h.1c.6,1.4,1.3,2.6,2,3.9l8.6,1.2a10.93,10.93,0,0,0,.8,1.5l-8.8-1.3.6.9c.7,1,1.3,2,2,2.9.1.2.2.3.3.5h.1c.8,1,1.6,1.8,2.4,2.7l8.2,1.4a13.07,13.07,0,0,0,1.1,1.2l-8.4-1.5c.2.2.4.3.5.4a24.72,24.72,0,0,0,2.7,2.1,2.18,2.18,0,0,1,.5.4c.1.1.3.1.4.2a18.07,18.07,0,0,0,5.2,2.2l1.6.3c1.9.1,6.3.7,10.1,1.1C30.3,118,23.4,105.05,19.4,86.55Z" />
                                        <path class="cls-1"
                                            d="M85.1,80.85h-5c-1.3,9.2-3.5,16.9-6.5,21.3-6.5,9.7-14.2-.6-17.1-22.8s0-48.1,6.6-57.8c6.4-9.4,13.8.1,16.9,21.3h5.1l4.8-4.3c-2.1-12-5.7-21-10-26.8-4.5-6-8-7.5-8.2-7.7h0l-16-2.2a4.79,4.79,0,0,1,2.2-.6H50.3A21.07,21.07,0,0,0,42,5.45c-16.1,13.5-23.7,49.8-17,81,4,18.9,11.3,31.9,19.4,35.9h.4c7.8.1,19.8-.1,26.7-2.1a13.9,13.9,0,0,0,6.1-3.8c5.8-6.3,10.1-17.8,12.4-31.4ZM53,2.55l14.7,1.8a10.07,10.07,0,0,0-6.5,1.8L49.7,4.75A18.89,18.89,0,0,1,53,2.55Zm-11.3,13L53,16.65c-.9,1.6-1.7,3.4-2.5,5.2l-11.1-1.1C40.1,19,40.9,17.25,41.7,15.55Zm-4.2,10.3,11.2.8c-.7,1.9-1.2,4-1.8,6.1L35.7,32C36.3,29.85,36.9,27.75,37.5,25.85Zm-3.1,12.3,11.4.6c-.4,2.2-.8,4.4-1.1,6.7l-11.4-.6C33.6,42.55,34,40.35,34.4,38.15Zm-1.9,14.7,11.4.4c-.1,1.9-.3,3.8-.3,5.7l-11.3-.3C32.3,56.75,32.4,54.75,32.5,52.85Zm-.2,15.7H43.5c0,1.3.1,2.6.1,3.9H32.4C32.4,71.15,32.4,69.85,32.3,68.55ZM34.2,86c-.2-1-.3-2.1-.5-3.2l10.9-.9c.2,1.1.3,2.3.5,3.4l-10.8,1A.37.37,0,0,1,34.2,86ZM37,98.35a19.45,19.45,0,0,1-.5-2l10.4-1.1c.2.7.3,1.4.5,2.1Zm3.6,10.2a6.94,6.94,0,0,1-.5-1.3l10-1.3c.2.5.3.9.5,1.4Zm4.8,8.4a5.55,5.55,0,0,1-.6-.8l9.5-1.4.6.9ZM44.3,11.15a46.27,46.27,0,0,1,3-4l11.6,1.2c-.4.3-.7.7-1.1,1a33.57,33.57,0,0,0-2.4,2.9Z" />
                                        <path
                                            d="M163.1,123.75a7.21,7.21,0,0,1-4-1.2,7.06,7.06,0,0,1-1.8-9.8c9.7-14,15.1-32,15.1-50.7s-5.4-36.9-15.2-51a7.13,7.13,0,0,1,1.7-9.8A7.13,7.13,0,0,1,168.7,3c11.4,16.4,17.8,37.3,17.8,59,0,21.5-6.2,42.4-17.6,58.7A7.13,7.13,0,0,1,163.1,123.75Z" />
                                        <path
                                            d="M145,108.45a7.29,7.29,0,0,1-4.2-1.4,7,7,0,0,1-1.4-9.8c7.1-9.5,11.1-22.1,11.1-35.3,0-13.4-4-26-11.3-35.6a7,7,0,0,1,11.1-8.5c9.1,12,14.1,27.6,14.1,44.1,0,16.2-4.9,31.8-13.9,43.7A6.56,6.56,0,0,1,145,108.45Z" />
                                        <path
                                            d="M127.7,93.35a7.27,7.27,0,0,1-4.6-1.7,7,7,0,0,1-.6-9.9c4.4-5,6.9-12.2,6.9-19.8,0-7.8-2.6-15.1-7.1-20.1a7,7,0,0,1,10.4-9.4c6.9,7.5,10.8,18.3,10.8,29.5s-3.7,21.4-10.4,29A7.3,7.3,0,0,1,127.7,93.35Z" />
                                    </g>
                                </g>
                            </svg>

                        </div>
                    </div>
                    <div class="tyre-pricing">Price</div>
                </div>

                <div *ngIf="!tyreStoreService.loading">
                    <ng-template *ngFor="let tyre of tyreStoreService.splicedTyres" [ngTemplateOutlet]="TyreTableItem"
                    [ngTemplateOutletContext]="{tyre: tyre}"></ng-template>
                </div>
                <div *ngIf="tyreStoreService.loading">
                    <ng-template *ngFor="let number of [0,1,2,3,4,5,6,7,8]" [ngTemplateOutlet]="TyreTableItemPlaceholder">
                    </ng-template>
                </div>
            </div>
        </ng-container>

        <mat-paginator (page)="tyreStoreService.pageChangeEvent($event)" [length]="tyreStoreService.pageLength"
            [pageSize]="tyreStoreService.pageSize" [pageSizeOptions]="tyreStoreService.pageSizeOptions">
        </mat-paginator>

    </div>
    <!-- End Tyre Filters -->
</div>
<ng-template #TyreListItemPlaceholder>
    <div class="tyre-list-item loading">

        <div class="tyre-image-wrapper">
            
        </div>

        <div class="tyre-details">
            <div class="manufacturer-logo-wrapper shimmer">
                loading
            </div>
            <div class="tread-pattern shimmer">
                loading
            </div>
            <div class="description shimmer">
                loading
            </div>
            <div class="label-wrapper shimmer">
                loading
            </div>
            <div class="tyre-icons-wrapper">
                <div class="icons shimmer">
                     loading
                </div>
            </div>
        </div>

        <div class="add-to-basket">
            <div class="spacer"></div>
            <div class="pricing-wrapper shimmer">
                <div class="pricing">
                    <div class="price">
                         loading
                    </div>
                    <div class="price-description">
                     </div>
                </div>
                <div class="quantity">
                     
                </div>
            </div>
            <div class="btn-wrapper shimmer">
                <button class="btn btn-block btn-lg  btn-add-to-basket">Loading</button>
                 
            </div>
        </div>

    </div>
</ng-template>
<ng-template #TyreListItem let-tyre="tyre">
    <div class="tyre-list-item">

        <div class="tyre-image-wrapper">
            <img src="https://bookingsystemapp.motasoftvgm.co.uk/{{tyre.image}}"
                onError="this.src='https://bookingsystemapp.motasoftvgm.co.uk//Images/TyreImages/default.jpg'" />
        </div>

        <div class="tyre-details">
            <div class="manufacturer-logo-wrapper">
                <img src="https://bookingsystemapp.motasoftvgm.co.uk/images/TyreManufacturerLogos/{{tyre.manufacturerImage}}"
                onError="this.src='https://bookingsystemapp.motasoftvgm.co.uk/images/TyreManufacturerLogos/budget-brand.jpg'"
                    class="manufacturer-logo" />
            </div>
            <div class="tread-pattern">
                {{tyre.treadPattern}}
            </div>
            <div class="description">
                {{tyre.description}}
            </div>
            <div class="label-wrapper">
                <app-tyre-label [wetGripGrade]="tyre.wetGripGrade" [fuelEfficiency]="tyre.fuelEfficiency"
                    [noiseDbBarRating]="tyre.noiseDbBarRating" [noiseDb]="tyre.noiseDb" [template]="'horizontal'">
                </app-tyre-label>
            </div>
            <div class="tyre-icons-wrapper">
                <div class="icons">
                    <app-tyre-icons [tyreCategory]="tyre.tyreCategory" [summerTyre]="tyre.summerTyre" [suvTyre]="tyre.suvTyre" [vanTyre]="tyre.vanTyre"
                        [winterTyre]="tyre.winterTyre" [extraLoad]="tyre.extraLoad" [runFlat]="tyre.runFlat">
                    </app-tyre-icons>
                </div>
            </div>
        </div>

        <div class="add-to-basket">
            <div class="spacer"></div>
            <div class="pricing-wrapper">
                <div class="pricing">
                    <div class="price">
                        £{{tyre.fullPrice.value | number:"1.2-2"}}
                    </div>
                    <div class="price-description">
                        {{config.tyres.tyrePriceDescription}}</div>
                </div>
                <div class="quantity">
                    <mat-form-field [appearance]="config.formAppearance">
                        <mat-label>Quantity</mat-label>
                        <mat-select [(ngModel)]="tyre.quantity" [disabled]="tyre.quantity > 0 && basketStoreService.tyreSelected(tyre)">
                            <mat-option [value]="1" *ngIf="tyre.totalQuantity >= 1">1</mat-option>
                            <mat-option [value]="2" *ngIf="tyre.totalQuantity >= 2">2</mat-option>
                            <mat-option [value]="3" *ngIf="tyre.totalQuantity >= 3">3</mat-option>
                            <mat-option [value]="4" *ngIf="tyre.totalQuantity >= 4">4</mat-option>
                            <mat-option [value]="5" *ngIf="tyre.totalQuantity >= 5">5</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="btn-wrapper">

                <button (click)="addRemoveTyre($event, tyre)" [disabled]="tyre.quantity < 1"
                    [hidden]="basketStoreService.tyreSelected(tyre)"
                    class="btn btn-block btn-lg btn-primary btn-add-to-basket">{{config.buttonText}}</button>

                <button (click)="addRemoveTyre($event, tyre)" [hidden]="!basketStoreService.tyreSelected(tyre)" class="btn btn-block btn-lg btn-light btn-add-to-basket">
                    <span>{{tyre.quantity}} added to booking</span>
                    <span class="remove-tyres"><i class="far fa-trash-alt"></i> Remove</span>
                </button>

            </div>
        </div>

    </div>
</ng-template>

<ng-template #TyreCardPlaceHolder>
    <div class="col-lg-4 col-md-6">
        <div class="tyre-card loading">
            <div class="manufacturer-logo-wrapper shimmer">

            </div>
            <div class="tread-pattern shimmer">
                loading
            </div>
            <div class="description shimmer">
                loading
            </div>
            <div class="tyre-details">
                <div class="tyre-image-wrapper shimmer">

                </div>
                <div class="label-wrapper">

                </div>
            </div>
            <div class="tyre-icons-wrapper">
                <div class="icons shimmer">

                </div>
            </div>
            <div class="add-to-basket shimmer">
                <div class="pricing">
                    <div class="price">

                    </div>
                    <div class="price-description">
                    </div>
                </div>
                <div class="quantity">
                    <mat-form-field [appearance]="config.formAppearance">
                        <mat-label>Quantity</mat-label>
                        <mat-select>
                            <mat-option>1</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="btn-wrapper shimmer">
                <a href="javascript:void(0)" class="btn btn-block btn-lg btn-primary btn-add-to-basket">loading</a>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #TyreCardItem let-tyre="tyre">
    <div class="col-lg-4 col-md-6">
        <div class="tyre-card">
            <div class="manufacturer-logo-wrapper">
                <img src="https://bookingsystemapp.motasoftvgm.co.uk/images/TyreManufacturerLogos/{{tyre.manufacturerImage}}"
                onError="this.src='https://bookingsystemapp.motasoftvgm.co.uk/images/TyreManufacturerLogos/budget-brand.jpg'"
                    class="manufacturer-logo" />
            </div>
            <div class="tread-pattern">
                {{tyre.treadPattern}}
            </div>
            <div class="description">
                {{tyre.description}}
            </div>
            <div class="tyre-details">
                <div class="tyre-image-wrapper">
                    <img src="https://bookingsystemapp.motasoftvgm.co.uk/{{tyre.image}}"
                        onError="this.src='https://bookingsystemapp.motasoftvgm.co.uk//Images/TyreImages/default.jpg'" />
                </div>
                <div class="label-wrapper">
                    <app-tyre-label [wetGripGrade]="tyre.wetGripGrade" [fuelEfficiency]="tyre.fuelEfficiency"
                        [noiseDbBarRating]="tyre.noiseDbBarRating" [noiseDb]="tyre.noiseDb"></app-tyre-label>
                </div>
            </div>
            <div class="tyre-icons-wrapper">
                <div class="icons">
                    <app-tyre-icons [tyreCategory]="tyre.tyreCategory" [summerTyre]="tyre.summerTyre" [suvTyre]="tyre.suvTyre" [vanTyre]="tyre.vanTyre"
                        [winterTyre]="tyre.winterTyre" [extraLoad]="tyre.extraLoad" [runFlat]="tyre.runFlat">
                    </app-tyre-icons>
                </div>
            </div>
            <div class="add-to-basket">
                <div class="pricing">
                    <div class="price">
                        £{{tyre.fullPrice.value | number:"1.2-2"}}
                    </div>
                    <div class="price-description">
                        {{config.tyres.tyrePriceDescription}}</div>
                </div>
                <div class="quantity">
                    <mat-form-field [appearance]="config.formAppearance">
                        <mat-label>Quantity</mat-label>
                        <mat-select [(ngModel)]="tyre.quantity" [disabled]="tyre.quantity > 0 && basketStoreService.tyreSelected(tyre)">
                            <mat-option [value]="1" *ngIf="tyre.totalQuantity >= 1">1</mat-option>
                            <mat-option [value]="2" *ngIf="tyre.totalQuantity >= 2">2</mat-option>
                            <mat-option [value]="3" *ngIf="tyre.totalQuantity >= 3">3</mat-option>
                            <mat-option [value]="4" *ngIf="tyre.totalQuantity >= 4">4</mat-option>
                            <mat-option [value]="5" *ngIf="tyre.totalQuantity >= 5">5</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="btn-wrapper">

                <button (click)="addRemoveTyre($event, tyre)" [disabled]="tyre.quantity < 1"
                    [hidden]="basketStoreService.tyreSelected(tyre)"
                    class="btn btn-block btn-lg btn-primary btn-add-to-basket">{{config.buttonText}}</button>

                <button (click)="addRemoveTyre($event, tyre)" [hidden]="!basketStoreService.tyreSelected(tyre)" class="btn btn-block btn-lg btn-light btn-add-to-basket">
                    <span>{{tyre.quantity}} added to booking</span>
                    <span class="remove-tyres"><i class="far fa-trash-alt"></i> Remove</span>
                </button>

            </div>
        </div>
    </div>
</ng-template>

<ng-template #TyreTableItem let-tyre="tyre">
    <div class="tyre-table-row">
        <div class="tyre-images">
            <div class="tyre-image-wrapper">
                <img src="https://bookingsystemapp.motasoftvgm.co.uk/{{tyre.image}}"
                    onError="this.src='https://bookingsystemapp.motasoftvgm.co.uk//Images/TyreImages/default.jpg'" />
            </div>
            <div class="manufacturer-logo-wrapper">
                <img src="https://bookingsystemapp.motasoftvgm.co.uk/images/TyreManufacturerLogos/{{tyre.manufacturerImage}}"
                onError="this.src='https://bookingsystemapp.motasoftvgm.co.uk/images/TyreManufacturerLogos/budget-brand.jpg'"
                    class="manufacturer-logo" />
            </div>
        </div>
        <div class="tyre-description">
            <div class="tyre-info">
                <div class="tread-pattern">
                    {{tyre.treadPattern}}
                </div>
                <div class="description">
                    {{tyre.description}}
                </div>
                <app-tyre-icons [tyreCategory]="tyre.tyreCategory" [summerTyre]="tyre.summerTyre" [suvTyre]="tyre.suvTyre" [vanTyre]="tyre.vanTyre"
                    [winterTyre]="tyre.winterTyre" [extraLoad]="tyre.extraLoad" [runFlat]="tyre.runFlat">
                </app-tyre-icons>
            </div>
            <div class="label-wrapper">
                <app-tyre-label [wetGripGrade]="tyre.wetGripGrade" [fuelEfficiency]="tyre.fuelEfficiency"
                    [noiseDbBarRating]="tyre.noiseDbBarRating" [noiseDb]="tyre.noiseDb" [template]="'tiny'">
                </app-tyre-label>
            </div>
        </div>
        <div class="tyre-pricing">

            <div class="pricing">
                <div class="price">
                    £{{tyre.fullPrice.value | number:"1.2-2"}}
                </div>
                <div class="price-description">
                    {{config.tyres.tyrePriceDescription}}</div>
            </div>
            <mat-form-field [appearance]="config.formAppearance">
                <mat-label>Qty</mat-label>
                <mat-select [(ngModel)]="tyre.quantity" [disabled]="tyre.quantity > 0 && basketStoreService.tyreSelected(tyre)">
                    <mat-option [value]="1" *ngIf="tyre.totalQuantity >= 1">1</mat-option>
                    <mat-option [value]="2" *ngIf="tyre.totalQuantity >= 2">2</mat-option>
                    <mat-option [value]="3" *ngIf="tyre.totalQuantity >= 3">3</mat-option>
                    <mat-option [value]="4" *ngIf="tyre.totalQuantity >= 4">4</mat-option>
                    <mat-option [value]="5" *ngIf="tyre.totalQuantity >= 5">5</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="btn-wrapper">

                <button (click)="addRemoveTyre($event, tyre)" [disabled]="tyre.quantity < 1"
                    [hidden]="basketStoreService.tyreSelected(tyre)"
                    class="btn btn-block btn-sm btn-primary btn-add-to-basket">Select</button>

                <button (click)="addRemoveTyre($event, tyre)" [hidden]="!basketStoreService.tyreSelected(tyre)" class="btn btn-block btn-sm btn-light btn-add-to-basket">
                    <span><i class="fas fa-check"></i></span>
                    <span class="remove-tyres"><i class="far fa-trash-alt"></i></span>
                </button>

            </div>
        </div>
    </div>
</ng-template>

<ng-template #TyreTableItemPlaceholder>
    <div class="tyre-table-row loading">
        <div class="tyre-images">
            <div class="tyre-image-wrapper shimmer">
                <img src="https://bookingsystemapp.motasoftvgm.co.uk/Images/TyreImages/default.jpg" />
            </div>
           
        </div>
        <div class="tyre-description">
            <div class="tyre-info">
                <div class="tread-pattern shimmer">
                    loading
                </div>
                <div class="description shimmer">
                    loading
                </div>
                <div class="shimmer app-tyre-icons">
                    <app-tyre-icons>
                    </app-tyre-icons>
                </div>
                
            </div>
            <div class="label-wrapper shimmer">
                loading
            </div>
        </div>
        <div class="tyre-pricing">

            <div class="pricing shimmer">
                <div class="price">
                    loading
                </div>
                <div class="price-description">
                    loading</div>
            </div>

        </div>
    </div>
</ng-template>