<div class="modal-header mst">
    <h4 class="modal-title">Select your address</h4>
    <button class="btn-close-dialog" (click)="modal.dismiss('Closed modal')">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body mst">
    <mat-form-field [appearance]="config.formAppearance">
        <mat-label>Addresses</mat-label>
        <mat-select [(ngModel)]="selectedAddress">
            <mat-option *ngFor="let address of addressStoreService.postcodeResults" [value]="address">
                {{address.displayAddress}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div class="modal-footer mst">
    <button class="btn btn-primary" (click)="modal.close(selectedAddress)" [disabled]="!selectedAddress">Select</button> 
    <button class="btn btn-default" (click)="modal.dismiss('Closed modal')">Cancel</button>
</div>