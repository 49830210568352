<form [formGroup]="form" class="change-password-form">

    <div class="form-section">
        <h4>Change your password</h4>
        <div>
            <mat-form-field [appearance]="config.formAppearance">
                <mat-label>Old password <span class="text-danger">*</span>
                </mat-label>
                <input matInput formControlName="customerOldPasswordFormControl" type="password">
                <mat-error *ngIf="form.get('customerOldPasswordFormControl').hasError('required')">
                    Password is <strong>required</strong>.
                </mat-error>
                
            </mat-form-field>
        </div>
        <div>
            <mat-form-field [appearance]="config.formAppearance">
                <mat-label>New password <span class="text-danger">*</span>
                </mat-label>
                <input matInput formControlName="customerPasswordFormControl" type="password">
                <mat-error *ngIf="form.get('customerPasswordFormControl').hasError('required')">
                    Password is <strong>required</strong>.
                </mat-error>
                <mat-error
                    *ngIf="!form.get('customerPasswordFormControl').hasError('required') && form.get('customerPasswordFormControl').hasError('strong')">
                    Password must contain a combination of <strong>letters and numbers</strong> and be at least 6
                    characters.
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field [appearance]="config.formAppearance">
                <mat-label>Repeat new password <span class="text-danger">*</span>
                </mat-label>
                <input matInput formControlName="customerRepeatPasswordFormControl" type="password">
                <mat-error *ngIf="form.get('customerRepeatPasswordFormControl').hasError('passwordMatch')">
                    Passwords <strong>don't match</strong>
                </mat-error>
                
            </mat-form-field>
        </div>
        <div *ngIf="incorrectPassword">
            <mat-error>
                Old password is incorrect. Please try again.
            </mat-error>
        </div>

        <div class="alert alert-success" *ngIf="passwordChanged">
            Your password has been updated.
        </div>
    </div>

    <div class="form-section update-password">
        <div class="spacer"></div>
        <button class="btn btn-lg btn-primary" (click)="updatePassword()" [disabled]="!form.valid || btnUpdatePasswordLoading"><img class="btn-loading-img" src="{{env.assetsUrl}}/images/loader.svg"
            *ngIf="btnUpdatePasswordLoading" /> Update
            password</button>
    </div>

</form>