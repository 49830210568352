import { Component, OnInit } from '@angular/core';
import { AddressStoreService } from 'src/app/services/address/address-store.service';
import { IPostcodeResult } from 'src/app/interfaces/postcode-result.interface';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-dialog-select-address',
	templateUrl: './dialog-select-address.component.html',
	styleUrls: ['./dialog-select-address.component.scss']
})
export class DialogSelectAddressComponent implements OnInit {
	
	selectedAddress: IPostcodeResult;
	config: IComponentConfig;
	
	constructor(
		public addressStoreService: AddressStoreService,
		private configStoreService: ConfigStoreService,
		public companyStoreService: CompanyStoreService,
		public modal: NgbActiveModal) { }

	ngOnInit(): void {
		this.config = this.configStoreService.getComponentConfig('DialogSelectAddressComponent');
	}

}
