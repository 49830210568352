import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ITyre } from 'src/app/interfaces/tyre.interface';
import { IBooking, IOnlineShoppingBasket } from 'src/app/interfaces/booking.interface';
import { IOnlineShoppingBasketValid } from 'src/app/interfaces/shopping-basket.interface';

@Injectable({
	providedIn: 'root'
})
export class BasketService {

	constructor(private http: HttpClient) { }

	public getAvailableSlotDates(branchGuid: string, dateFrom: string, dateTo: string, slotTypeIDs: string, tyres: ITyre[]) {

		let params: any = {};
        if (branchGuid) { params.branchGuid = branchGuid; }
        if (dateFrom) { params.dateFrom = dateFrom; }
        if (dateTo) { params.dateTo = dateTo; }
        if (slotTypeIDs) { params.slotTypeIDs = slotTypeIDs; }

        const queryString = Object.keys(params).map(function(key) {
            return key + '=' + params[key]
        }).join('&');

		return this.http.post<any>(`${environment.apiUrl}/Api/AvailableSlotDates/Get?${queryString}`, tyres);
	}

	public getAvailableSlotTimes(branchGuid: string, date: string, slotTypeIDs: string, tyres: any) {

		let params: any = {};
        if (branchGuid) { params.branchGuid = branchGuid; }
        if (date) { params.date = date; }
		if (slotTypeIDs) { params.slotTypeIDs = slotTypeIDs; }
		
		const queryString = Object.keys(params).map(function(key) {
            return key + '=' + params[key]
        }).join('&');

		return this.http.post<any>(`${environment.apiUrl}/Api/AvailableSlotTimes/Get?${queryString}`, tyres);
	}

	public getShoppingBasket(branchGuid: string, booking: IBooking) {
		return this.http.post<IOnlineShoppingBasketValid>(`${environment.apiUrl}/Api/ShoppingBasketItems/Get?branchGuid=${branchGuid}`,booking);
	}

	public confirmBooking(branchGuid: string, booking: IBooking) {
		return this.http.post<IOnlineShoppingBasket>(`${environment.apiUrl}/Api/SubmitBooking/Post?branchGuid=${branchGuid}`, booking);		
	}
	

	public confirmPOABooking(branchGuid: string, booking: IBooking) {
		return this.http.post<IOnlineShoppingBasket>(`${environment.apiUrl}/Api/SubmitBookingPOA/Post?branchGuid=${branchGuid}`, booking);		
	}

	public confirmBookingPayPal(branchGuid: string, booking: IBooking, payPalOrderID: string) {
		return this.http.post<IOnlineShoppingBasket>(`${environment.apiUrl}/Api/SubmitBookingPaypal/Post?branchGuid=${branchGuid}&paypalOrderID=${payPalOrderID}`, booking);
	}

    getTermsAndPrivacy (branchGuid: string) {
        return this.http.get(`${environment.apiUrl}/Api/TermsAndPrivacy/Get?branchGuid=` + branchGuid)
            
    }
}

// Add get dates & times from old slot service
