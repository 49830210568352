import { Component, OnInit, Input } from '@angular/core';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { SpecialOfferStoreService } from 'src/app/services/special-offer/special-offer-store.service';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { ISpecialOffer } from 'src/app/interfaces/special-offer.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';

@Component({
    selector: 'app-special-offer-list',
    templateUrl: './special-offer-list.component.html',
    styleUrls: ['./special-offer-list.component.scss']
})
export class SpecialOfferListComponent implements OnInit {

    config: IComponentConfig;
    hideSpecialOffers = false;
    @Input() template: string;

    constructor(public specialOfferStoreService: SpecialOfferStoreService,
        public basketStoreService: BasketStoreService,
        private configStoreService: ConfigStoreService,
        public companyStoreService: CompanyStoreService) { 
            this.config = this.configStoreService.getComponentConfig('SpecialOfferListComponent');
        // if a template is passed directly into the component, then use that, else, use the default template from config.
        this.template = this.template ? this.template : this.config.template;
    }

    ngOnInit() { }

    getPricePreamble(specialOffer: ISpecialOffer): string {
        switch(specialOffer.discountedPrice.type) {
            case 1:
                return 'Price from';
            case 2:
                return 'Price to';
            default:
                return ''
        }
    }

    addRemoveSpecialOffer(specialOffer: ISpecialOffer) {
        this.basketStoreService.addRemoveSpecialOffer(specialOffer);
    }

}
