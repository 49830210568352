import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { SlotTypeStoreService } from 'src/app/services/slot-type/slot-type-store.service';
import { TyreStoreService } from 'src/app/services/tyre/tyre-store.service';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';

@Component({
    selector: 'app-dialog-service-checklist',
    templateUrl: './dialog-service-checklist.component.html',
    styleUrls: ['./dialog-service-checklist.component.scss']
})
export class DialogServiceChecklistComponent implements OnInit {

    @Input() data;
    config: IComponentConfig;
    loading = true;

    constructor(
        public modal: NgbActiveModal,
        private configStoreService: ConfigStoreService,
        public tyreStoreService: TyreStoreService) { }

    ngOnInit(): void {
 
        this.config = this.configStoreService.getComponentConfig('DialogServiceChecklistComponent');
        setTimeout(() => {
            this.loading = false;
        }, this.config.loadingPanelDelay / 2);
    }

}
