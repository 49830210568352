<div class="loading-panel" [hidden]="!vehicleFeedStoreService.loading"></div>
<div class="footer-overlay" *ngIf="showFooterMoreInfo"></div>


<div class="vehicle-feed-wrapper">
    <div class="top-wrapper" #top>        
        <div class="layout-header" #header *ngIf="headerVisible()">
        </div>
    </div>

    <div class="vehicle-feed-body">
        <div class="vehicle-feed-main"  [ngClass]="{'show-filters-mobile' : vehicleFeedStoreService.showFiltersMobile}">
            <div #scrollable>
                <div class="content">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
