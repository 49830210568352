import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector, ApplicationRef, Injectable, FactoryProvider, InjectionToken, ErrorHandler, Inject } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpRequestInterceptor } from './interceptors/http-request-interceptor';
import { CompanyModule } from './components/company/company.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { SlotTypeModule } from './components/slot-type/slot-type.module';

import { CommonModule } from '@angular/common';
import { VehicleModule } from './components/vehicle/vehicle.module';
import { VehicleFeedModule } from './components/vehicle-feed/vehicle-feed.module';
import { DialogsModule } from './components/dialogs/dialogs.module';
import { FormsModule } from '@angular/forms';
import { SpecialOfferModule } from './components/special-offer/special-offer.module';
import { BasketModule } from './components/basket/basket.module';
import { TyresModule } from './components/tyres/tyres.module';
import { StepsModule } from './components/steps/steps.module';
import { ExtraModule } from './components/extra/extra.module';
import { PipesModule } from './pipes/pipes.module';
import { CheckoutModule } from './components/checkout/checkout.module';
import { AccountModule } from './components/account/account.module';
import { LayoutModule } from '@angular/cdk/layout';
import { WidgetVrmLookupComponent } from './components/widgets/widget-vrm-lookup/widget-vrm-lookup.component';
import { createCustomElement } from '@angular/elements';
import { WidgetTyreSizeComponent } from './components/widgets/widget-tyre-size/widget-tyre-size.component';
import { WidgetVehicleFeedComponent } from './components/widgets/widget-vehicle-feed/widget-vehicle-feed.component';
import { WidgetMOTReminderComponent } from './components/widgets/widget-mot-reminder/widget-mot-reminder.component';
import { NgbModalConfig, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

import { LoggerStoreService } from './services/logger/logger-store.service';
import { WidgetBookingSystemLinkComponent } from './components/widgets/widget-booking-system-link/widget-booking-system-link.component';
import { WidgetsModule } from './components/widgets/widgets.module';
import { WidgetServicesListComponent } from './components/widgets/widget-services-list/widget-services-list.component';
import { WidgetServicesComponent } from './components/widgets/widget-services/widget-services.component';

import * as Rollbar from 'rollbar';
import { WidgetServiceChecklistComponent } from './components/widgets/widget-service-checklist/widget-service-checklist.component';

const rollbarConfig: Rollbar.Configuration = {
	accessToken: '997c85f6a05c4cce8ed99562076ead78',
	captureUncaught: true,
	captureUnhandledRejections: true,
	environment: environment.name,
	hostSafeList: ['motasoftvgm.co.uk'],
	payload: {
		environment: environment.name,
		client: {
			javascript: {
				source_map_enabled: true,
				code_version: environment.versionNumber,
				guess_uncaught_frames: true,
			},
		},
	},
};

export const WINDOW = new InjectionToken<Window>('window');

const windowProvider: FactoryProvider = {
	provide: WINDOW,
	useFactory: () => window,
};

export const WINDOW_PROVIDERS = [windowProvider];

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
	constructor(private loggerService: LoggerStoreService) {}

	handleError(error: any): void {
		this.loggerService.logErrorToRollBar(error.originalError || error);
	}
}

export function rollbarFactory() {
	return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		CompanyModule,
		SlotTypeModule,
		DialogsModule,
		CommonModule,
		VehicleModule,
		VehicleFeedModule,
		FormsModule,
		BrowserAnimationsModule,
		VehicleModule,
		SpecialOfferModule,
		BasketModule,
		TyresModule,
		ExtraModule,
		PipesModule,
		CheckoutModule,
		StepsModule,
		AccountModule,
		LayoutModule,
		MaterialModule,
		NgbModalModule,
		WidgetsModule,
	],
	providers: [
		WINDOW_PROVIDERS,
		{ provide: ErrorHandler, useClass: RollbarErrorHandler },
		{ provide: RollbarService, useFactory: rollbarFactory },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
	],
})
export class AppModule {
	constructor(injector: Injector, configModal: NgbModalConfig) {
		configModal.windowClass = 'mst';
		configModal.backdropClass = 'mst';
		configModal.backdrop = 'static';
		configModal.scrollable = true;

		const el = createCustomElement(WidgetVrmLookupComponent, { injector: injector });
		customElements.define('vrm-lookup', el);

		const el2 = createCustomElement(WidgetTyreSizeComponent, { injector: injector });
		customElements.define('tyre-size-lookup', el2);

		const el3 = createCustomElement(WidgetVehicleFeedComponent, { injector: injector });
		customElements.define('vehicle-feed-lookup', el3);

		const el4 = createCustomElement(WidgetBookingSystemLinkComponent, { injector: injector });
		customElements.define('booking-system-link', el4);

		const el5 = createCustomElement(WidgetServicesListComponent, { injector: injector });
		customElements.define('services-list', el5);

		const el6 = createCustomElement(WidgetServicesComponent, { injector: injector });
		customElements.define('services-cards', el6);

		const el7 = createCustomElement(WidgetMOTReminderComponent, { injector: injector });
		customElements.define('mot-reminder', el7);

		const el8 = createCustomElement(WidgetServiceChecklistComponent, { injector: injector });
		customElements.define('service-checklist', el8);
	}

	ngDoBootstrap(app: ApplicationRef) {
		if (document.getElementsByTagName('app-root').length > 0) {
			app.bootstrap(AppComponent);
		}
	}
}
