import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { IAuthorizationData } from 'src/app/interfaces/authorization-data.interface';
import { throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { LoggerStoreService } from '../logger/logger-store.service';
import { LocalStorageStoreService } from '../local-storage/local-storage-store.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { LocalStorageKey } from 'src/app/global/enums';

@Injectable({
	providedIn: 'root',
})
export class AuthStoreService {
	clientId = '';
	authorizationData: IAuthorizationData;
	passwordResetKey = '';

	constructor(private authService: AuthService, private loggerStoreService: LoggerStoreService, private localStorageStoreService: LocalStorageStoreService) {}

	init() {
		const appVersion = environment.versionNumber;
		const localStorageAppVersion = this.localStorageStoreService.get(LocalStorageKey.AppVersion, this.clientId);

		this.localStorageStoreService.set(LocalStorageKey.AppVersion, this.clientId, appVersion);

		if (appVersion != localStorageAppVersion) {
			this.loggerStoreService.log('Auth Store Service: Difference in app version, clearing local storage');
			this.localStorageStoreService.clearLocalStorage(this.clientId, true);
			return;
		}

		// See if we have auth data, if so check its expiry
		// if all is valid then we can use this to log the customer in
		const localStorageAuthData = this.localStorageStoreService.get(LocalStorageKey.AuthorizationData, this.clientId);

		if (localStorageAuthData) {
			const authData = JSON.parse(localStorageAuthData);
			// the following will compare the expiration against now.
			var now = moment(new Date());
			var expires = moment(authData['.expires']);
			var duration = moment.duration(expires.diff(now));
			var minutes = duration.asMinutes();
			// If minutes is a negative number, the token has expired so we clear local data and start fresh.
			if (minutes < 0) {
				this.localStorageStoreService.clearLocalStorage(this.clientId, true);
			} else {
				this.authorizationData = authData;
			}
		}
	}

	getToken(): Promise<any> {
		const promise = this.authService
			.getToken('client_credentials', '', this.clientId)
			.toPromise()
			.then((response) => {
				this.loggerStoreService.log('Auth Store Service: Received token');
				if (!this.authorizationData) {
					this.authorizationData = response;
				}

				return response;
			});
		return promise;
	}

	refreshAccessToken() {
		// Here, we need to check if logged in etc..
		return this.authService.getToken('client_credentials', '', this.clientId).pipe(
			tap((result: any) => {
				this.authorizationData = result;
			}),
			catchError((error: any) => {
				if (error?.error?.error == 'invalid_grant') {
					this.logout();
					return throwError(error);
				}

				return throwError(error);
			})
		);
	}

	userLogin(username: string, password: string, branchGuid: string): Promise<any> {
		const promise = this.authService
			.getToken(`password&username=${username}&password=${password}`, branchGuid, this.clientId)
			.toPromise()
			.then((response) => {
				if (response.isAuth == 'true') {
					this.authorizationData = response;
					this.localStorageStoreService.set(LocalStorageKey.AuthorizationData, this.clientId, JSON.stringify(this.authorizationData));
					this.loggerStoreService.log('Auth Store Service: User logged in');
					return response;
				} else {
					this.loggerStoreService.log('Auth Store Service: User incorrect password');
					return response;
				}
			});
		return promise;
	}

	isLoggedIn() {
		if (this.authorizationData?.isAuth == 'true') {
			return true;
		} else {
			return false;
		}
	}

	async logout() {
		this.localStorageStoreService.remove(LocalStorageKey.AuthorizationData, this.clientId);
		this.authorizationData = null;
		await this.getToken();
	}
}
