<div class="select-date-time" [ngClass]="{'calendar-visible': config.template == 'calendar'}">
    <h4>Select a date & time</h4>
    <div class="dropdown-wrapper" *ngIf="config.template != 'calendar'">
        <app-checkout-select-date-time></app-checkout-select-date-time> 
    </div>
    <div class="calendar-wrapper" *ngIf="config.template == 'calendar'">
        <app-checkout-calendar></app-checkout-calendar>
    </div>
</div>


