import { Component, OnInit } from '@angular/core';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { SpecialOfferStoreService } from 'src/app/services/special-offer/special-offer-store.service';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';

@Component({
	selector: 'app-special-offer-upgrade-list',
	templateUrl: './special-offer-upgrade-list.component.html',
	styleUrls: ['./special-offer-upgrade-list.component.scss']
})
export class SpecialOfferUpgradeListComponent implements OnInit {

	config: IComponentConfig;

	constructor(
		public basketStoreService: BasketStoreService,
		public specialOfferStoreService: SpecialOfferStoreService,
		public configStoreService: ConfigStoreService) { }

	ngOnInit(): void {
		this.config = this.configStoreService.getComponentConfig('SpecialOfferUpgradeListComponent');
	}

}
