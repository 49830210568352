import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ISlotTypeGroup } from 'src/app/interfaces/slot-type-group.interface';
import { map, shareReplay } from 'rxjs/operators';
import { ConfigStoreService } from '../config/config-store.service';

@Injectable({
    providedIn: 'root'
})
export class SlotTypeService {

    constructor(
        private http: HttpClient,
        private configStoreService: ConfigStoreService) { }

    get(branchGuid: string, vehicleMake: string, fuelTypeID: number, engineCC: number) {
        let params: any = {};
        if (branchGuid) { params.branchGuid = branchGuid; }
        if (vehicleMake) { params.vehicleMake = vehicleMake; }
        if (fuelTypeID) { params.fuelTypeID = fuelTypeID; }
        if (engineCC) { params.engineCC = engineCC; }

        const queryString = Object.keys(params).map(function(key) {
            return key + '=' + params[key]
        }).join('&');

        return this.http.get<any>(`${environment.apiUrl}/Api/SlotTypes/Get?${queryString}`).pipe(shareReplay(1));
    }

    getSlotTypeGroups(branchGuid: string, vehicleMake: string, fuelTypeID: number, engineCC: number, getNextAvailableSlotDates:boolean) {
        let params: any = {};
        if (branchGuid) { params.branchGuid = branchGuid; }
        if (vehicleMake) { params.vehicleMake = vehicleMake; }
        if (fuelTypeID) { params.fuelTypeID = fuelTypeID; }
        if (engineCC) { params.engineCC = engineCC; }
        if (getNextAvailableSlotDates) { params.getNextAvailableSlotDates = getNextAvailableSlotDates; }

        const queryString = Object.keys(params).map(function(key) {
            return key + '=' + params[key]
        }).join('&');

        return this.http.get<Array<ISlotTypeGroup>>(`${environment.apiUrl}/Api/SlotTypeGroups/Get?${queryString}`).pipe(
            map((slotTypeGroups: ISlotTypeGroup[]) => {
                    slotTypeGroups.forEach(s => {
                        if (s.checklistTemplate) {
                            s.checklistTemplate.itemGroups.forEach(g => {
                                g.hidden = !this.configStoreService.config.serviceChecklistOpenByDefault
                            })
                        }
                    })
                return slotTypeGroups;
            }),
            shareReplay(1));
    }
}

