import { Injectable, Inject } from '@angular/core';
import { IConfig, IComponentConfig } from 'src/app/interfaces/config.interface';
import { ConfigModel } from 'src/app/models/config.model';
import { LoggerStoreService } from '../logger/logger-store.service';
import { ClientService } from '../client/client.service';

@Injectable({
	providedIn: 'root'
})
export class ConfigStoreService {

	config: IConfig;
	configId: number;
	
	// Starting values captures from query strings.
	startingBranchGuid: string;
	startingVehicleType: string;
	startingVehicleRegistration: string;
	
	startingTyreVehicleRegistration: string;
	startingTyreWidth: string;
	startingTyreProfile: string;
	startingTyreDiameter: string;
	startingTyreSpeed: string;
	startingSlotType: string;
    startingSpecialOffer: string;

	constructor(
		private loggerStoreService: LoggerStoreService,
        private clientService: ClientService
	) {
		this.config = new ConfigModel();			
	}

	getComponentConfig(componentName: string): IComponentConfig { 
		componentName = componentName.charAt(0).toLowerCase() + componentName.slice(1);
		const componentConfig: IComponentConfig = {
			buttonText: this.config.buttonTexts[componentName] || this.config.buttonTexts.default,
			template: this.config.templates[componentName] || this.config.templates.default,
			title: this.config.titles[componentName] || this.config.titles.default,
			description: this.config.descriptions[componentName] || this.config.descriptions.default,
			brandPrimary: this.config.brandPrimary,
			brandPrimaryHover: this.config.brandPrimaryHover,
			forms: this.config.forms,
			services: this.config.services,
			tyres: this.config.tyres,
			startWithVehicle: this.config.startWithVehicle,
			bookingMode: this.config.bookingMode,
			skipBasket: this.config.skipBasket,
            skipUpsell: this.config.skipUpsell,
			loadingPanelDelay: this.config.loadingPanelDelay,
			formAppearance: this.config.formAppearance,
			passwordRecoveryUrl: this.config.passwordRecoveryUrl,
			bookingSystemUrl: this.config.bookingSystemUrl,
			vehicleFeedUrl: this.config.vehicleFeedUrl,
			mobileCutoff: this.config.mobileCutoff,
			mobileRedirect: this.config.mobileRedirect,
			parentElementCutOff: this.config.parentElementCutOff,
			googleAnalyticsID: this.config.googleAnalyticsID,
			mobileFallbackComponent: this.config.mobileFallbackComponent,
			branches: this.config.branches,
			selectVehicleType: this.config.selectVehicleType,
            vehicleTypeCarEnabled: this.config.vehicleTypeCarEnabled,
            vehicleTypeVanEnabled: this.config.vehicleTypeVanEnabled,
            vehicleTypeBikeEnabled: this.config.vehicleTypeBikeEnabled,
			onlinePaymentsPaypal: this.config.onlinePaymentsPaypal,
			vehicleFeedCardImage: this.config.vehicleFeedCardImage,
			vehicleFeedDetailImage:  this.config.vehicleFeedDetailImage,
			confirmationReturnURL: this.config.confirmationReturnURL,
			slotTypeIDs: this.config.slotTypeIDs,
			slotTypeGroupIDs: this.config.slotTypeGroupIDs,
			specialOfferIDs: this.config.specialOfferIDs,
			vehicleTypeCarImage: this.config.vehicleTypeCarImage,
			vehicleTypeCarTitle: this.config.vehicleTypeCarTitle,
			vehicleTypeCarDescription: this.config.vehicleTypeCarDescription,
			vehicleTypeBikeImage: this.config.vehicleTypeBikeImage,
			vehicleTypeBikeTitle: this.config.vehicleTypeBikeTitle,
			vehicleTypeBikeDescription: this.config.vehicleTypeBikeDescription,
			vehicleTypeVanImage: this.config.vehicleTypeVanImage,
			vehicleTypeVanTitle: this.config.vehicleTypeVanTitle,
			vehicleTypeVanDescription: this.config.vehicleTypeVanDescription,
			unpricedItemsHeader: this.config.unpricedItemsHeader,
			unpricedItemsSubHeader: this.config.unpricedItemsSubHeader,
            serviceChecklistButtonText: this.config.serviceChecklistButtonText,
            serviceChecklistOpenByDefault: this.config.serviceChecklistOpenByDefault,
            serviceChecklistTemplate: this.config.serviceChecklistTemplate,
            preferredDateConfiguration: this.config.preferredDateConfiguration
		};
		return componentConfig;
	}

	updateConfig(config?: Partial<IConfig>) {
		if (config.buttonTexts) {
			config.buttonTexts = JSON.parse(config.buttonTexts);
		} else {
			delete config.buttonTexts;
		}
		if (config.templates) {
			config.templates = JSON.parse(config.templates);
		} else {
			delete config.templates;
		}
		if (config.titles) {
			config.titles = JSON.parse(config.titles);
		} else {
			delete config.titles;
		}
		if (config.descriptions) {
			config.descriptions = JSON.parse(config.descriptions);
		} else {
			delete config.descriptions;
		}
		this.config = new ConfigModel(config);
		this.loggerStoreService.log('Config Store Service: Updating config');
		this.loggerStoreService.table(config);

		this.clientService.setClientInfo(this.config.id);
	}

	updateThemeColours() {
		if (this.config.brandPrimary) {
			let root = document.documentElement;
			root.style.setProperty('--primary', this.config.brandPrimary);
			root.style.setProperty('--primary-hover', this.config.brandPrimaryHover);
		}
	}

	updateMaxHeight() {
		
	}

	get isFilteredItemConfig() {
		if (this.config.slotTypeIDs[0] != '' || this.config.slotTypeGroupIDs[0] != '' || this.config.specialOfferIDs[0] != '') { return true; }
		return false;
	}
}
