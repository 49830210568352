import { Component, OnInit, Inject, Input, Injector } from '@angular/core';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ISlotType } from 'src/app/interfaces/slot-type.interface';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { SlotTypeStoreService } from 'src/app/services/slot-type/slot-type-store.service';
import { ISpecialOffer } from 'src/app/interfaces/special-offer.interface';
import { SpecialOfferStoreService } from 'src/app/services/special-offer/special-offer-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-dialog-vehicle-entry',
	templateUrl: './dialog-vehicle-entry.component.html',
	styleUrls: ['./dialog-vehicle-entry.component.scss'],
})
export class DialogVehicleEntryComponent implements OnInit {
	@Input() data;
	loading = false;
	btnVrmLoading = false;
	vehicleRegistration = '';
	vrmError = false;
	vrmForm: UntypedFormGroup;
	vehicleForm: UntypedFormGroup;
	originalSlotType: ISlotType;
	updatedSlotType: ISlotType;
	originalSpecialOffer: ISpecialOffer;
	updatedSpecialOffer: ISpecialOffer;
	config: IComponentConfig;
	basketStoreService: BasketStoreService;

	constructor(
		public companyStoreService: CompanyStoreService,
		public vehicleStoreService: VehicleStoreService,
		public slotTypeStoreService: SlotTypeStoreService,
		private configStoreService: ConfigStoreService,
		public modal: NgbActiveModal,
		private specialOfferStoreService: SpecialOfferStoreService
	) {}

	ngOnInit(): void {
		this.originalSlotType = this.data.selectedSlotType;
		this.originalSpecialOffer = this.data.selectedSpecialOffer;
		this.basketStoreService = this.data.basketStoreService;
		this.config = this.configStoreService.getComponentConfig('DialogVehicleEntryComponent');
		this.vrmForm = new UntypedFormGroup({
			vrmFormControl: new UntypedFormControl(null, [Validators.required, Validators.minLength(3)]),
		});
		this.vehicleForm = new UntypedFormGroup({});
	}

	vrmComplete() {
		this.navigate('VehicleResult');
	}

	enterVehicleManually($event) {
		this.navigate('ManualVehicleEntry');
	}

	async saveVehicle() {
		this.loading = true;
		this.vehicleStoreService.saveVehicleForm(this.vehicleForm);
		await this.basketStoreService.refreshPrices();
		if (this.originalSlotType || this.originalSpecialOffer) {
			this.navigate('AddToBasket');
		} else {
			setTimeout(() => {
				this.modal.close(true);
				this.loading = false;
			}, this.config.loadingPanelDelay * 2);
		}
	}

	navigate(step: string, title?: string) {
		this.loading = true;
		switch (step) {
			case 'ManualVehicleEntry':
				setTimeout(() => {
					this.data.template = 'ManualVehicleEntry';
					this.data.title = title ? title : 'Please enter your vehicle';
					this.loading = false;
				}, this.config.loadingPanelDelay);
				break;
			case 'VrmLookup':
				setTimeout(() => {
					this.data.template = 'VrmLookup';
					this.data.title = title ? title : 'Find your vehicle';
					this.loading = false;
				}, this.config.loadingPanelDelay);
				break;
			case 'VehicleResult':
				setTimeout(() => {
					this.data.template = 'VehicleResult';
					this.data.title = title ? title : 'Please confirm your vehicle';
					this.loading = false;
				}, this.config.loadingPanelDelay);
				break;
			case 'AddToBasket':
				setTimeout(() => {
					let priceValid = true;
					if (this.originalSlotType) {
						this.updatedSlotType = this.slotTypeStoreService.getSlotTypeByID(this.originalSlotType.id);
						if (this.updatedSlotType.fullPrice.type !== 3) {
							priceValid = false;
						}
					}
					if (this.originalSpecialOffer) {
						this.updatedSpecialOffer = this.specialOfferStoreService.getSpecialOfferByID(this.originalSpecialOffer.id);
						if (this.updatedSpecialOffer.discountedPrice.type !== 3) {
							priceValid = false;
						}
					}
					if (priceValid) {
						setTimeout(() => {
							this.data.template = 'AddToBasket';
							this.data.title = title ? title : 'Confirm price and book';
							this.loading = false;
						}, this.config.loadingPanelDelay);
					} else {
						setTimeout(() => {
							this.data.template = 'NoPriceAvailable';
							this.data.title = title ? title : 'No price available for vehicle';
							this.loading = false;
						}, this.config.loadingPanelDelay);
					}
				}, 2000);

				break;
		}
	}
}
