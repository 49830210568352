import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { environment } from 'src/environments/environment';
import { IComponentConfig } from '../../../interfaces/config.interface';
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroupDirective } from '@angular/forms';
import { VehicleStoreService } from '../../../services/vehicle/vehicle-store.service';
import { IMOTVehicle } from '../../../interfaces/vehicle.interface';
import * as moment from 'moment';
import { CustomerStoreService } from '../../../services/customer/customer-store.service';
import { IBranch } from 'src/app/interfaces/branch.interface';

@Component({
	selector: 'app-widget-mot-reminder',
	templateUrl: './widget-mot-reminder.component.html',
	styleUrls: ['./widget-mot-reminder.component.scss'],
})
export class WidgetMOTReminderComponent implements OnInit {
	motChecked = false;
	loading = true;
	wide = false;
	vehicle: IMOTVehicle;
	reminderSet = false;
	motValid: boolean;
	motCloseToOrExpired: boolean;

	env: any;
	config: IComponentConfig;

	selectedBranch: IBranch;
	vehicleRegistration: string;

	// recaptcha vars
	afterFirstSubmitClick = false;
	innerWidth: any;

	@Input() clientId: string;
	@Input() configId: number;

	reminderForm = new UntypedFormGroup(
		{
			firstNameFormControl: new UntypedFormControl({ value: null, disabled: true }, Validators.required),
			lastNameFormControl: new UntypedFormControl({ value: null, disabled: true }, Validators.required),
			emailFormControl: new UntypedFormControl({ value: null, disabled: true }, [Validators.email]),
			numberFormControl: new UntypedFormControl({ value: null, disabled: true }, [Validators.pattern('[- +()0-9]{11,13}')]),
		},
		{ validators: this.contactValidator }
	);
	reminderFormSubmitError = false;
	completeReminderForm = false;

	regForm = new UntypedFormGroup({
		vehicleRegFormControl: new UntypedFormControl(null, Validators.required),
	});

	constructor(
		public companyStoreService: CompanyStoreService,
		public configStoreService: ConfigStoreService,
		private vehicleStoreService: VehicleStoreService,
		private customerStoreService: CustomerStoreService,
		private authStoreService: AuthStoreService,
		private el: ElementRef,
		private cd: ChangeDetectorRef
	) {
		this.env = environment;
	}

	async ngOnInit() {
		await this.getCompany();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.calculateWidth();
	}

	calculateWidth() {
		this.innerWidth = this.el.nativeElement.offsetWidth;
		const width = this.el.nativeElement.offsetWidth;
		if (width > 768) {
			this.wide = true;
		} else {
			this.wide = false;
		}
	}

	disableVrm() {
		return !this.regForm.valid;
	}

	async getCompany() {
		if (this.clientId && this.configId) {
			this.authStoreService.clientId = this.clientId;
			this.configStoreService.configId = +this.configId || 0;
			await this.authStoreService.getToken();
			await this.companyStoreService.getCompany();
			this.configStoreService.updateThemeColours();
		}

		if (this.companyStoreService?.company?.branches?.length == 1) {
			this.selectedBranch = this.companyStoreService.company.branches[0];
		}

		this.config = this.configStoreService.getComponentConfig('WidgetMOTReminderComponent');
		this.calculateWidth();
		setTimeout(() => {
			this.loading = false;
			this.cd.detectChanges();
		}, this.config.loadingPanelDelay);
	}

	async doVrm() {
		if (!this.regForm.valid) {
			return;
		}

		this.loading = true;
		this.vehicleRegistration = this.regForm.get('vehicleRegFormControl').value.replace(/\s+/g, '');
		this.vehicle = await this.vehicleStoreService.checkVehicleMOT(this.vehicleRegistration);

		console.log(this.vehicle.registrationNumber);

		if (!this.vehicle.registrationNumber || this.vehicle.registrationNumber == null || this.vehicle.registrationNumber == '') {
			this.regForm.controls.vehicleRegFormControl.setErrors({ invalidRegistration: true });
			this.motChecked = false;

			this.reminderForm.disable();
			this.loading = false;
		} else {
			this.motValid = this.isMOTValid();
			this.motCloseToOrExpired = this.isMOTCloseToOrExpired();
			this.motChecked = true;

			this.reminderForm.enable();
			this.loading = false;
		}
	}

	reminderFormSubmit(formData: any, formDirective: FormGroupDirective): void {
		this.createReminder(formData)
			.then(() => {
				this.completeReminderForm = true;
				formDirective.resetForm();
				this.reminderForm.reset();
				this.loading = false;
			})
			.catch((err) => {
				this.reminderFormSubmitError = true;
				this.loading = false;
				console.error(err);
			});
	}

	async createReminder(formData: any) {
		try {
			this.completeReminderForm = false;
			this.reminderFormSubmitError = false;

			if (!this.reminderForm.valid) {
				return;
			}
			if (!this.selectedBranch) {
				return;
			}
			this.reminderFormSubmitError = false;
			this.loading = true;

			return this.customerStoreService.createMOTReminder(
				this.selectedBranch.guid,
				this.vehicle,
				formData.controls.firstNameFormControl.value,
				formData.controls.lastNameFormControl.value,
				formData.controls.emailFormControl.value,
				formData.controls.numberFormControl.value
			);
		} catch (err) {
			return Promise.reject(err);
		}
	}

	bookMOT() {
		this.loading = true;
		let url = window.location.search;
		url = url.replace('?', '');
		let queryString = `?startingUrl=${window.location.href}&vehicleRegistration=${this.vehicleRegistration}`;
		queryString += `&bookMOT=true`;

		if (this.companyStoreService?.company?.branches?.length > 1) {
			queryString += `&branchGuid=${this.selectedBranch.guid}`;
		}

		queryString += `${url ? '&' + url : ''}`;

		if (window.innerWidth < this.config.mobileCutoff && this.config.mobileRedirect) {
			// Here, we redirect to a pure booking system url/route.
			window.location.href = this.env.baseUrl + this.authStoreService.clientId + '/' + this.configStoreService.configId + queryString;
		} else {
			window.location.href = this.config.bookingSystemUrl + queryString;
		}
	}

	isReminderButtonDisabled() {
		let disabled = false;

		if (!this.reminderForm.valid || this.reminderForm.disabled) {
			disabled = true;
		}
		if (!this.selectedBranch) {
			disabled = true;
		}

		return disabled;
	}

	branchSelected(branch: IBranch) {
		this.selectedBranch = branch;
		this.cd.detectChanges();
	}

	isMOTValid(): boolean {
		const today = moment.utc();
		const motDueDate = moment.utc(this.vehicle.motDueDate);
		const manufactureDate = moment.utc(this.vehicle.manufactureDate, 'DD/MM/YYYY hh:mm:ss');
		if (today.isSameOrAfter(motDueDate)) {
			if (today.isSameOrBefore(manufactureDate.add(3, 'years'))) {
				return true;
			}

			return false;
		}
		return true;
	}

	isMOTCloseToOrExpired(): boolean {
		const today = moment.utc();
		const motDueDate = moment.utc(this.vehicle.motDueDate);
		const closeExpiry = moment.utc(this.vehicle.motDueDate).subtract(1, 'months');
		if (today.isBetween(closeExpiry, motDueDate)) {
			return true;
		}
		if (today.isAfter(motDueDate)) {
			return true;
		}
		return false;
	}

	getMOTDueDate() {
		const dvsaDueDate = moment.utc(this.vehicle.motDueDate);

		if (dvsaDueDate.isSame(moment.utc('0001-01-01T00:00:00'))) {
			return moment.utc(this.vehicle.manufactureDate, 'DD/MM/YYYY hh:mm:ss').add(3, 'years');
		}
		return dvsaDueDate;
	}

	isDateValid(date: Date | string) {
		const motDate = moment.utc(date);
		if (!motDate.isValid()) {
			return false;
		}
		if (!motDate.isAfter(moment.utc('0001-01-01T00:00:00'))) {
			return false;
		}
		return true;
	}

	contactValidator(group: UntypedFormGroup): { [s: string]: boolean } {
		const emailCtrl = group.controls.emailFormControl;
		const phoneCtrl = group.controls.numberFormControl;

		if (emailCtrl != undefined && phoneCtrl != undefined) {
			if (!((emailCtrl.value && emailCtrl.value.length) || (phoneCtrl.value && phoneCtrl.value.length))) {
				return { missingContact: true };
			}
		}
	}

	startAgain() {
		this.loading = true;
		this.vehicleRegistration = '';
		this.regForm.controls['vehicleRegFormControl'].setValue(null);

		this.reminderForm.disable();

		this.motChecked = false;
		this.completeReminderForm = false;
		this.reminderFormSubmitError = false;
		this.loading = false;
	}
}
