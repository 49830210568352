import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasketComponent } from './basket/basket.component';
import { PipesModule } from 'src/app/pipes/pipes.module';



@NgModule({
    declarations: [BasketComponent],
    imports: [
        CommonModule,
        PipesModule
    ],
    exports: [
        BasketComponent
    ]
})
export class BasketModule { }
