import { Component, OnInit } from '@angular/core';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { UntypedFormGroup, Validators, UntypedFormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { PasswordValidator } from '../../checkout/checkout-enter-details-form/checkout-enter-details-form.component';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { CustomerStoreService } from 'src/app/services/customer/customer-store.service';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { NavigationStoreService } from 'src/app/services/navigation/navigation-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-account-password-reset',
	templateUrl: './account-password-reset.component.html',
	styleUrls: ['./account-password-reset.component.scss']
})
export class AccountPasswordResetComponent implements OnInit {

	btnLoading = false;
	postcode = '';
	passwordResetForm: UntypedFormGroup;
	passwordChanged = false;
	passwordResetError = '';
	config: IComponentConfig;
	startingPath;
	env: any;

	constructor(
		public companyStoreService:CompanyStoreService,
		private basketStoreService: BasketStoreService,
		public customerStoreService: CustomerStoreService,
		public navigationStoreService: NavigationStoreService,
		public authStoreService: AuthStoreService,
		public configStoreService: ConfigStoreService) {
			this.env = environment;
		}

	ngOnInit(): void {
		this.config = this.configStoreService.getComponentConfig('AccountPasswordResetComponent');
		this.passwordResetForm = new UntypedFormGroup({});
		this.passwordResetForm.addControl('customerPasswordFormControl', new UntypedFormControl(null, [PasswordValidator.strong, Validators.minLength(6), Validators.required]));
		this.passwordResetForm.addControl('customerRepeatPasswordFormControl', new UntypedFormControl(null, [this.validatePasswords()]));
	}

	validatePasswords(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const match = control.value == this.passwordResetForm.controls.customerPasswordFormControl.value;
            return match ? null : { 'passwordMatch': { value: control.value } };
        };
	}
	
	resetPassword() {
		if (this.btnLoading || !this.passwordResetForm.valid) { return; }
		this.btnLoading = true;
		this.customerStoreService.resetPassword(this.passwordResetForm.controls.customerPasswordFormControl.value, this.authStoreService.passwordResetKey).then(response => {
			this.btnLoading = false;
			this.passwordChanged = true;
		}).catch(error => {
			this.btnLoading = false;
			this.passwordResetError = "Token invalid. If you've requested multiple password resets, please ensure you're clicking the link in the most recent email.";
		});
	}

	// MOVE THIS TO BOOKING SERVICE
	hasValidBooking() {
		if (this.companyStoreService.branch?.guid && this.basketStoreService.getBasketNumberOfItems() > 0) {
			return true;
		}
		return false;
	}

}
