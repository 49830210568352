import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanySelectBranchComponent } from './company-select-branch/company-select-branch.component';
import { MaterialModule } from 'src/app/material.module';



@NgModule({
    declarations: [CompanySelectBranchComponent],
    imports: [
        CommonModule,
        MaterialModule
    ],
    exports: [
        CompanySelectBranchComponent
    ]
})
export class CompanyModule { }
