import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { Router } from '@angular/router';
import { ExtraStoreService } from 'src/app/services/extra/extra-store.service';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';

@Component({
    selector: 'app-step-add-extras',
    templateUrl: './step-add-extras.component.html',
    styleUrls: ['./step-add-extras.component.scss']
})
export class StepAddExtrasComponent implements OnInit {

    constructor(
        private location: Location,
        public basketStoreService: BasketStoreService,
        public configStoreService: ConfigStoreService,
        public authStoreService: AuthStoreService,
        public extraStoreService: ExtraStoreService,
        public router: Router) { }

    ngOnInit(): void {

    } 
}
