<div class="widget_service-checklist"
    [ngClass]="{'widget_narrow': widgetSize == 'narrow', 'widget_wide': widgetSize == 'wide'}" #serviceChecklists>
    <div class="widget_inner-wrapper">
        <div class="widget_loading-panel" *ngIf="loading"></div>
        <div *ngIf="template == 'link'"
            class="widget_narrow">
            <div equalHeight="checklist-equal-short">
                <div class="checklist-group" equalHeight="checklist-equal-long">
                    <mat-tab-group mat-align-tabs="start">    
                        <mat-tab *ngFor="let slotType of slotTypeGroup?.slotTypes; let i = index" [label]="slotType.shortDescription" >
                            <div class="widget_slot-types-wrapper widget_tab-wrapper">
                                <ng-template [ngTemplateOutlet]="SlotType" [ngTemplateOutletContext]="{slotType: slotType}">
                                </ng-template>                               
                            </div>    
                        </mat-tab>    
                    </mat-tab-group>
                </div>
            </div>
        </div>

        <app-slot-type-service-checklist         
            *ngIf="slotTypeGroup && template == 'inline'"
            [slotTypeGroup]="slotTypeGroup"
            [readOnly]="readOnly"
            widget="true">
        </app-slot-type-service-checklist>
    </div>
</div>

<ng-template #SlotType let-slotType="slotType">
    <div class="widget_slot-type">
        <h5 class="widget_short-description widget_checklist-equal-long">
            <span>{{slotType.shortDescription}}</span>
        </h5>

        <div class="widget_long-description widget_checklist-equal-short" [hidden]="!config.services.showSlotTypeLongDescription">
            {{slotType.longDescription}}
        </div>
        <div class="widget_items-checked">{{getSlotTypeCount(slotType.id)}}<span>/{{totalItems}} items</span></div>
        <div class="widget_price">
            <span class="widget_value">
                <span *ngIf="slotType.fullPrice.type != 4"
                    [ngClass]="{'widget_price-preamble': slotType.fullPrice.type != 4}">{{getPricePreambleSlotType(slotType)}}</span>
                <span *ngIf="slotType.fullPrice.type != 4">
                    {{"£" + (slotType.fullPrice.value | number:"1.2-2")}}<span class="widget_price-suffix">{{slotType.priceSuffix}}</span>
                </span>
                <span class="widget_please-call" *ngIf="slotType.fullPrice.type == 4">
                    Please call {{companyStoreService.branch.phoneNumber}}
                </span>
            </span>
           
            <a href="javascript:void(0)" 
                (click)="showSlotTypeServiceChecklist(slotTypeGroup)" 
                class="widget_compare-btn widget_btn-sm">
                {{config.serviceChecklistButtonText}}
            </a>
        </div>
    </div>
</ng-template>  
