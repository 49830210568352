import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { IBranch } from 'src/app/interfaces/branch.interface';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';

@Component({
	selector: 'app-company-select-branch',
	templateUrl: './company-select-branch.component.html',
	styleUrls: ['./company-select-branch.component.scss'],
})
export class CompanySelectBranchComponent implements OnInit {
	config: IComponentConfig;
	columns: string;
	@Input() template: string;
	@Input() selectedBranch: IBranch;
	@Output() branchSelected = new EventEmitter<IBranch>();

	constructor(public companyStoreService: CompanyStoreService, private configStoreService: ConfigStoreService) {}

	ngOnInit(): void {
		this.config = this.configStoreService.getComponentConfig('CompanySelectBranchComponent');
		// if a template is passed directly into the component, then use that, else, use the default template from config.
		this.template = this.template ? this.template : this.config.template;

		if (!this.selectedBranch) {
			return;
		}
		this.selectBranch(null, this.selectedBranch);
	}

	selectBranch($event, selectedBranch: IBranch) {
		this.companyStoreService.selectBranch(selectedBranch);
		this.branchSelected.emit(selectedBranch);
	}

	getColumnClass(): string {
		if (this.companyStoreService?.company?.branches?.length % 3 === 0) {
			return 'col-md-6 col-lg-4';
		} else {
			return 'col-md-6';
		}
	}
}
