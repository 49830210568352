import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { IAutoTraderStock } from 'src/app/interfaces/vehicle-feed.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { VehicleFeedStoreService } from 'src/app/services/vehicle-feed/vehicle-feed-store.service';

@Component({
  selector: 'app-vehicle-feed-detail-icons',
  templateUrl: './vehicle-feed-detail-icons.component.html',
  styleUrls: ['./vehicle-feed-detail-icons.component.scss']
})
export class VehicleFeedDetailIconsComponent implements OnInit {

  stock: IAutoTraderStock;
  
  config: IComponentConfig;
  
  constructor(
    private route: ActivatedRoute,
		private configStoreService: ConfigStoreService,
    public vehicleFeedStoreService: VehicleFeedStoreService
  ) {     
		this.config = this.configStoreService.getComponentConfig('VehicleFeedDetailIconsComponent');
  }

  ngOnInit(): void {
    if (
			this.vehicleFeedStoreService.vehicleFeedFull &&
			this.vehicleFeedStoreService.vehicleFeedFull.stock
		) {
			this.stock = this.vehicleFeedStoreService.vehicleFeedFull.stock.filter(
				(c) =>
					c.stockItemID ==
					this.route.snapshot.paramMap.get('stockItemID')
			)[0];
		} else {
			this.vehicleFeedStoreService.initialiseService().then(() => {
				this.stock = this.vehicleFeedStoreService.vehicleFeedFull.stock.filter(
					(c) =>
						c.stockItemID ==
						this.route.snapshot.paramMap.get('stockItemID')
				)[0];
			});
		}
  }

}
