import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StepSelectLocationComponent } from './step-select-location/step-select-location.component';
import { StepAddExtrasComponent } from './step-add-extras/step-add-extras.component';
import { StepSelectDateTimeComponent } from './step-select-date-time/step-select-date-time.component';
import { StepEnterDetailsComponent } from './step-enter-details/step-enter-details.component';
import { StepSelectServicesComponent } from './step-select-services/step-select-services.component';
import { StepSelectVehicleComponent } from './step-select-vehicle/step-select-vehicle.component';
import { CompanyModule } from '../company/company.module';
import { SlotTypeModule } from '../slot-type/slot-type.module';
import { SpecialOfferModule } from '../special-offer/special-offer.module';
import { ExtraModule } from '../extra/extra.module';
import { CheckoutModule } from '../checkout/checkout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { DialogsModule } from '../dialogs/dialogs.module';
import { VehicleModule } from '../vehicle/vehicle.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { TyresModule } from '../tyres/tyres.module';
import { BasketModule } from '../basket/basket.module';
import { StepBookingConfirmationComponent } from './step-booking-confirmation/step-booking-confirmation.component';
import { LayoutModule } from '../layout/layout.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { StepSelectVehicleTypeComponent } from './step-select-vehicle-type/step-select-vehicle-type.component';
import { StepBasketComponent } from './step-basket/step-basket.component';


@NgModule({
	declarations: [StepSelectLocationComponent, StepAddExtrasComponent, StepSelectDateTimeComponent, StepEnterDetailsComponent, StepSelectServicesComponent, StepSelectVehicleComponent, StepBookingConfirmationComponent, StepSelectVehicleTypeComponent, StepBasketComponent],
	imports: [
		CommonModule,
		AppRoutingModule,
		CompanyModule,
		SlotTypeModule,
		SpecialOfferModule,
		ExtraModule,
		CheckoutModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		DialogsModule,
		VehicleModule,
        PipesModule,
        TyresModule,
		BasketModule,
		SpecialOfferModule,
		LayoutModule
	],
	exports: [StepSelectLocationComponent, StepAddExtrasComponent, StepSelectDateTimeComponent, StepEnterDetailsComponent],

})
export class StepsModule { }
