import { Component, OnInit, Input, ChangeDetectorRef, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ICompany } from 'src/app/interfaces/company.interface';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { IBranch } from 'src/app/interfaces/branch.interface';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { environment } from 'src/environments/environment';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';
import { TyreStoreService } from 'src/app/services/tyre/tyre-store.service';
import { ITyreSizeWidth, ITyreSizeProfile, ITyreSizeRim, ITyreSizeSpeed, ITyreSize } from 'src/app/interfaces/tyre.interface';
import { VehicleType } from 'src/app/global/enums';

@Component({
	selector: 'app-widget-tyre-size',
	templateUrl: './widget-tyre-size.component.html',
	styleUrls: ['./widget-tyre-size.component.scss'],
})
export class WidgetTyreSizeComponent implements OnInit {
	config: IComponentConfig;
	loading = true;
	showLoadingPanel = false;
	vehicleRegistration = '';
	searchType = 'vrm';
	selectedBranch: IBranch;
	selectedVehicleType: VehicleType;
	eSelectedVehicleType = VehicleType;
	company: ICompany;
	env: any;
	selectedWidth: ITyreSizeWidth;
	selectedProfile: ITyreSizeProfile;
	selectedDiameter: ITyreSizeRim;
	selectedSpeed: ITyreSizeSpeed;
	wide = false;
	@Input() clientId: string;
	@Input() configId: number;
	@Input() fallback = false;
	@ViewChild('tyreSizeLookup') tyreSizeLookup: ElementRef;

	constructor(
		public companyStoreService: CompanyStoreService,
		public vehicleStoreService: VehicleStoreService,
		public tyreStoreService: TyreStoreService,
		private authStoreService: AuthStoreService,
		private cd: ChangeDetectorRef,
		public configStoreService: ConfigStoreService,
		private element: ElementRef
	) {
		this.env = environment;
	}

	ngOnInit() {
		this.getCompany();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.calculateWidth();
	}

	calculateWidth() {
		const width = this.tyreSizeLookup.nativeElement.offsetWidth;
		if (width > 768) {
			this.wide = true;
		} else {
			this.wide = false;
		}
	}

	branchSelected(branch: IBranch) {
		this.selectedBranch = branch;
		this.selectedWidth = null;
		this.selectedProfile = null;
		this.selectedDiameter = null;
		this.selectedSpeed = null;
		this.cd.detectChanges();
	}

	async getCompany() {
		if (this.clientId && this.configId) {
			this.authStoreService.clientId = this.clientId;
			this.configStoreService.configId = +this.configId || 0;
			await this.authStoreService.getToken();
			await this.companyStoreService.getCompany();
			this.configStoreService.updateThemeColours();
		}

		if (!this.vehicleStoreService.vrmAvailable()) {
			this.searchType = 'manual';
		}
		if (this.companyStoreService?.branch.guid) {
			await this.tyreStoreService.getTyreSizes(this.companyStoreService.branch.guid);
		} else {
			await this.tyreStoreService.getTyreSizes('');
		}

		this.tyreStoreService.tyreSizes.sort((c1, c2) => +c1.value - +c2.value);

		this.config = this.configStoreService.getComponentConfig('WidgetTyreSizeComponent');
		this.calculateWidth();
		setTimeout(() => {
			this.loading = false;
			this.cd.detectChanges();
		}, this.config.loadingPanelDelay);
	}

	selectWidth(selectedWidth: ITyreSizeWidth) {
		this.selectedProfile = null;
		this.selectedDiameter = null;
		this.selectedSpeed = null;
		selectedWidth.profiles.sort((c1, c2) => +c1.value - +c2.value);
	}

	selectProfile(selectedProfile: ITyreSizeProfile) {
		this.selectedDiameter = null;
		this.selectedSpeed = null;
		selectedProfile.rims.sort((c1, c2) => +c1.value - +c2.value);
	}

	selectRim(selectedRim: ITyreSizeRim) {
		this.selectedSpeed = this.selectedDiameter.speeds[0];
		selectedRim.speeds.sort((c1, c2) => {
			const speed1 = c1.value.toUpperCase(); // ignore case
			const speed2 = c2.value.toUpperCase();
			if (speed1 < speed2) {
				return -1;
			}
			if (speed1 > speed2) {
				return 1;
			}

			// value must be equal
			return 0;
		});
	}

	selectSpeed(selectedSpeed: ITyreSizeSpeed) {}

	searchByTyreSize() {
		if (!this.selectedWidth || !this.selectedProfile || !this.selectedDiameter || (this.companyStoreService?.company?.branches?.length > 1 && !this.selectedBranch)) {
			return;
		}
		this.showLoadingPanel = true;

		let url = window.location.search;
		url = url.replace('?', '');

		let queryString = `?startingUrl=${window.location.href}&tyreWidth=${this.selectedWidth?.value}&tyreProfile=${this.selectedProfile?.value}&tyreDiameter=${this.selectedDiameter?.value}`;
		if (this.selectedSpeed?.value && this.selectedSpeed.value != 'Any') {
			queryString += `&tyreSpeed=${this.selectedSpeed?.value}`;
		}

		if (this.selectedBranch?.guid) {
			queryString += `&branchGuid=${this.selectedBranch.guid}`;
		}

		if (this.selectedVehicleType) {
			const selectedVehicleTypeID = this.eSelectedVehicleType[this.selectedVehicleType.toString().replace('vehicleTypes.', '')];
			queryString += `&vehicleType=${selectedVehicleTypeID}`;
		}
		queryString += `${url ? '&' + url : ''}`;

		setTimeout(() => {
			if (this.fallback && (!this.configId || !this.clientId)) {
				// Here, we are a fallback widget AND we are loaded within the iframe, so we are specifically going to target
				// the parent window.
				window.top.location.href = this.env.baseUrl + this.authStoreService.clientId + '/' + this.configStoreService.configId + queryString;
			} else if (this.fallback && this.element.nativeElement.parentElement.getBoundingClientRect().width < this.config.parentElementCutOff) {
				// Here, we are a fallback widget and
				// the parent element is too small so redirect to pure booking system please
				window.location.href = this.env.baseUrl + this.authStoreService.clientId + '/' + this.configStoreService.configId + queryString;
			} else {
				if (window.innerWidth < this.config.mobileCutoff && this.config.mobileRedirect) {
					// Here, we redirect to a pure booking system url/route.
					window.location.href = this.env.baseUrl + this.authStoreService.clientId + '/' + this.configStoreService.configId + queryString;
				} else {
					window.location.href = this.config.bookingSystemUrl + queryString;
				}
			}
		}, this.config.loadingPanelDelay * 2);
	}

	vehicleTypeSelected(event) {
		console.log('vehicle type changed');
		this.selectedVehicleType = event.data.type;
		this.cd.detectChanges();
	}

	searchByTyreSizeDisabled() {
		let disabled = false;

		if (this.companyStoreService?.company?.branches?.length > 1 && !this.selectedBranch) {
			disabled = true;
		}

		if (this.configStoreService?.config?.selectVehicleType && !this.selectedVehicleType) {
			disabled = true;
		}

		if (!this.selectedDiameter) {
			disabled = true;
		}

		return disabled;
	}

	searchByRegistrationError = '';
	searchByRegistration() {
		if (!this.vehicleRegistration || (this.companyStoreService?.company?.branches?.length > 1 && !this.selectedBranch)) {
			if (this.companyStoreService?.company?.branches?.length > 1) {
				this.searchByRegistrationError = 'Please ensure you have selected a branch and entered a vehicle registration.';
			} else {
				this.searchByRegistrationError = 'Please ensure you have entered a vehicle registration.';
			}
			return;
		}
		this.showLoadingPanel = true;

		let url = window.location.search;
		url = url.replace('?', '');

		let queryString = `?startingUrl=${window.location.href}&tyreVehicleRegistration=${this.vehicleRegistration}`;
		if (this.selectedBranch?.guid) {
			queryString += `&branchGuid=${this.selectedBranch.guid}`;
		}

		if (this.selectedVehicleType) {
			queryString += `&vehicleType=${this.selectedVehicleType}`;
		}
		queryString += `${url ? '&' + url : ''}`;
		if (this.fallback && (!this.configId || !this.clientId)) {
			// Here, we are a fallback widget AND we are loaded within the iframe, so we are specifically going to target
			// the parent window.
			window.top.location.href = this.env.baseUrl + this.authStoreService.clientId + '/' + this.configStoreService.configId + queryString;
		}
		if (window.innerWidth < this.config.mobileCutoff && this.config.mobileRedirect) {
			// Here, we redirect to a pure booking system url/route.
			window.location.href = this.env.baseUrl + this.authStoreService.clientId + '/' + this.configStoreService.configId + queryString;
		} else {
			window.location.href = this.config.bookingSystemUrl + queryString;
		}

		setTimeout(() => {
			if (window.innerWidth < this.config.mobileCutoff && this.config.mobileRedirect) {
				// Here, we redirect to a pure booking system url/route.
				window.location.href = this.env.baseUrl + this.authStoreService.clientId + '/' + this.configStoreService.configId + queryString;
			} else {
				window.location.href = this.config.bookingSystemUrl + queryString;
			}
		}, this.config.loadingPanelDelay * 2);
	}

	searchByRegistrationDisabled() {
		let disabled = false;

		if (this.companyStoreService?.company?.branches?.length > 1 && !this.selectedBranch) {
			disabled = true;
		}

		if (this.configStoreService?.config?.selectVehicleType && !this.selectedVehicleType) {
			disabled = true;
		}

		if (!this.vehicleRegistration) {
			disabled = true;
		}

		return disabled;
	}
}
