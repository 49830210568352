import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { VehicleFeedStoreService } from 'src/app/services/vehicle-feed/vehicle-feed-store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';

@Component({
	selector: 'app-vehicle-feed-results',
	templateUrl: './vehicle-feed-results.component.html',
	styleUrls: ['./vehicle-feed-results.component.scss']
})
export class VehicleFeedResultsComponent implements OnInit, OnDestroy {

	@Input() template: string;

	config: IComponentConfig;
	mobileDevice = false;

	cardImages = {};

	subscriptions: any;

	constructor(
		public configStoreService: ConfigStoreService,
		private authStoreService: AuthStoreService,
		private router: Router,
		private route: ActivatedRoute,
		public vehicleFeedStoreService: VehicleFeedStoreService) {
		this.vehicleFeedStoreService.initialiseService();

		this.config = this.configStoreService.getComponentConfig('VehicleFeedResultsComponent');
		const windowWidth = window.innerWidth;
		// if a template is passed directly into the component, then use that, else, use the default template from config.

		if (windowWidth > 991) {
			// this.template = this.template ? this.template : this.config.template;

			this.changeTemplate('card');
		} else {
			this.mobileDevice = true;
			this.changeTemplate('card');
		}
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(c => { 
			this.vehicleFeedStoreService.filterFromUrlParams.push(c)			
		});
		
		this.vehicleFeedStoreService.checkFilterFromUrlParams();		
		this.subscriptions = this.vehicleFeedStoreService.pagedVehicleFeed$.subscribe(c => {
			c.forEach(stock => {
				this.setImageUrl(stock.id.toString(), stock.vehicle.images[0]?.toString(), 'https://m.atcdn.co.uk/a/media/no_image.png');
			});
		});
	}

	setImageUrl(id:string, imageUrl: string, fallbackUrl: string) {
		if(id) {
			if(!this.cardImages[id]) {
				if (imageUrl) {
					const img = new Image();
					img.onload = () => { this.cardImages[id] = imageUrl }; 
					img.onerror = () => { this.cardImages[id] = fallbackUrl };
					img.src = imageUrl;
				} else {
					this.cardImages[id] = fallbackUrl
				}		
			}
		}
	}	


	getImageUrl(id:string) {
		return this.cardImages[id] ?? 'https://m.atcdn.co.uk/a/media/no_image.png'; 
	}	

	changeTemplate(template: string) {

		this.vehicleFeedStoreService.loading = true;
		switch (template) {
			case 'card':
				this.template = 'card';
				this.vehicleFeedStoreService.pageSizeOptions = [18, 27, 36];
				this.vehicleFeedStoreService.pageSize = this.vehicleFeedStoreService.pageSizeOptions[0];
				this.vehicleFeedStoreService.sortVehicles();
				break;
		}
	}

	navigateToDetails(stockItemID: string): void {
		this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'vehicle', stockItemID]);
	}

	ngOnDestroy(): void {
		if (this.subscriptions)
			this.subscriptions.unsubscribe();
	}
}
