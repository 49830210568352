import { Component, OnInit } from '@angular/core';
import { IBranch } from 'src/app/interfaces/branch.interface';
import { Router } from '@angular/router';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { LoggerStoreService } from 'src/app/services/logger/logger-store.service';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { SlotTypeStoreService } from 'src/app/services/slot-type/slot-type-store.service';
import { SpecialOfferStoreService } from 'src/app/services/special-offer/special-offer-store.service';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { VehicleStoreService } from '../../../services/vehicle/vehicle-store.service';

@Component({
	selector: 'app-step-select-location',
	templateUrl: './step-select-location.component.html',
	styleUrls: ['./step-select-location.component.scss'],
})
export class StepSelectLocationComponent implements OnInit {
	config: IComponentConfig;

	constructor(
		private router: Router,
		private authStoreService: AuthStoreService,
		private loggerStoreService: LoggerStoreService,
		private basketStoreService: BasketStoreService,
		private configStoreService: ConfigStoreService,
		private slotTypeStoreService: SlotTypeStoreService,
		private specialOfferStoreService: SpecialOfferStoreService,
		private companyStoreService: CompanyStoreService,
		private vehicleStoreService: VehicleStoreService
	) {}

	ngOnInit(): void {
		this.config = this.configStoreService.getComponentConfig(
			'StepSelectLocationComponent'
		);
	}

	async branchSelected(branch: IBranch) {
		this.loggerStoreService.log(
			`Step: Select location: Branch ${branch.branchName} has been selected.`
		);
		// Are we going to vehicle select or services select?
		let path = [
			this.authStoreService.clientId,
			String(this.configStoreService.configId),
			'booking',
			'select-services',
		];

		// if they want to start with VRM lookup. Must have VRM lookup enabled.
		// we check that there are either company level credits available or branch level credits available.
		// However, if we have both services and tyres set to true we must start at the services step.
		if (
			this.config.startWithVehicle &&
			(this.companyStoreService.company.companyLevelVrmAvailable ||
				this.companyStoreService.branch.branchLevelVrmAvailable) &&
			this.configStoreService.config.tyres.enabled !=
				this.configStoreService.config.services.enabled &&
			!this.vehicleStoreService.hasValidVehicle()
		) {
			this.loggerStoreService.log(
				'Step: Select location: Start with vehicle true. VRM enabled. First step - select vehicle.'
			);
			path = [
				this.authStoreService.clientId,
				String(this.configStoreService.configId),
				'booking',
				'select-vehicle',
			];
		}

		// if we have services enabled but want to only show certain services based on vehicle type
		// then we should go to vehicle type first
		if(this.config.selectVehicleType && this.config.services.enabled) {
			this.loggerStoreService.log(
				'Step: Select location: Select vehicle type First step - select vehicle type.'
			);
			path = [
				this.authStoreService.clientId,
				String(this.configStoreService.configId),
				'booking',
				'select-vehicle-type',
			];
		}

		// if the booking system is purely tyres, we MUST start with vrm/tyre size search
		if (this.config.tyres.enabled && !this.config.services.enabled) {
			this.loggerStoreService.log(
				'Step: Select location: Tyres only. Must start with vrm/tyre size search.'
			);
			path = [
				this.authStoreService.clientId,
				String(this.configStoreService.configId),
				'booking',
				'select-vehicle',
			];

			if (this.configStoreService.startingTyreWidth) {
				// But wait a sec! We have a starting width and tyre size, so we don't need to start with the vrm lookup.
				// The layout component has already commited the tyre size to the service, so we delete it afterwards so subsequent calls to
				// go to first step are correct.
				this.configStoreService.startingTyreWidth = null;
				path = [
					this.authStoreService.clientId,
					String(this.configStoreService.configId),
					'booking',
					'select-services',
				];
			}
        }
        
		this.slotTypeStoreService.getSlotTypeGroups(
			this.companyStoreService.branch.guid
		);
		this.specialOfferStoreService.getSpecialOffers(
			this.companyStoreService.branch.guid
		);
		this.basketStoreService.clearBasket();

		this.router.navigate(path);
	}
}
