<div class="loading-panel" [hidden]="!loading"></div>
<div class="modal-header mst">
    <h4 class="modal-title">{{data.title}}</h4>
    <button class="btn-close-dialog" (click)="modal.dismiss('Closed modal')">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body mst">
    <ng-container *ngIf="data.template == 'TyreSizeSearch'">
        <ng-template [ngTemplateOutlet]="TyreSizeSearch"></ng-template>
    </ng-container>
    <ng-container *ngIf="data.template == 'TyreSizeSelection'">
        <ng-template [ngTemplateOutlet]="TyreSizeSelection"></ng-template>
    </ng-container>
    <ng-container *ngIf="data.template == 'TyreResults'">
        <ng-template [ngTemplateOutlet]="TyreResults"></ng-template>
    </ng-container>
</div>
<div class="modal-footer mst">
    <ng-container *ngIf="data.template == 'TyreSizeSelection'">
        <ng-template [ngTemplateOutlet]="TyreSizeSelectionActions"></ng-template>
    </ng-container>
</div>


<ng-template #TyreSizeSearch>
    <div class="{{searchType}}-search">
        <div class="tabbed-nav" *ngIf="vehicleStoreService.vrmAvailable()">
            <button (click)="searchType = 'vrm'" [ngClass]="{'active': searchType == 'vrm'}">
                Search by reg
            </button>
            <button (click)="searchType = 'manual'" [ngClass]="{'active': searchType == 'manual'}">
                Search by size
            </button>
        </div>

        <div class="tyre-search">
            <div class="vrm-lookup">
                <app-vehicle-vrm-lookup [checkTyres]="true" (vrmComplete)="vrmComplete()"></app-vehicle-vrm-lookup>
            </div>
            <div class="or">
                <span>Or</span>
            </div>
            <div class="manual-entry">
 
                <app-tyre-size-form (confirmTyreSize)="confirmTyreSize()"></app-tyre-size-form>
            </div>
        </div>
        <div class="tyre-image">
            <img src="{{config.tyres.tyreSizeDescriptionImage}}" />     
        </div>
    </div>
</ng-template>

<ng-template #TyreSizeSelection>
    <app-tyre-size-results (valueChange)="selectedTyreSizeValueChanged($event)" (confirmAccept)="confirmSelectedTyreSize($event)"></app-tyre-size-results>
</ng-template>

<ng-template #TyreSizeSelectionActions>
    <a *ngIf="!data.selectTyres" class="btn btn-light" (click)="navigate('TyreSizeSearch')">Enter tyre size manually</a>
    <button *ngIf="!data.selectTyres" class="btn btn-primary" [disabled]="!selectedTyreSizeConfirmed || !tyreStoreService.tyreSizeValid(selectedTyreSize)" (click)="saveTyreSize()">Continue</button>

    <button *ngIf="data.selectTyres" class="btn btn-light" (click)="navigate('TyreSizeSearch')">Enter tyre size manually</button>
    <button *ngIf="data.selectTyres" class="btn btn-primary" [disabled]="!selectedTyreSizeConfirmed || !tyreStoreService.tyreSizeValid(selectedTyreSize)" (click)="saveTyreSize()">Continue</button>
</ng-template>

<ng-template #TyreResults>
    <app-tyre-results (tyreSelected)="tyreSelected()"></app-tyre-results>
</ng-template>

