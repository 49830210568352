<div class="widget_vehicle-feed-lookup" [ngClass]="{'wide': wide}">
	<div class="widget_loading-panel" *ngIf="loading || showLoadingPanel"></div>

	<div class="widget_header">
		<h3>
			Search Vehicles
		</h3>
	</div>

	<div class="widget_inner-wrapper {{searchType}}-search" #vehicleFeedLookup>

		<div class="widget_vehicle-search" *ngIf="!loading">
			<div class="widget_filter-wrapper">
				<mat-form-field [appearance]="config?.formAppearance">
					<mat-label>Make</mat-label>
					<mat-select (selectionChange)="filterVehicleMake($event.value)"
						[value]="selectedVehicleMake">
						<mat-option value="Any">Any</mat-option>
						<mat-option *ngFor="let vehicleMake of  vehicleFeedStoreService.vehicleMakes"
							[value]="vehicleMake">
							{{vehicleMake | titlecase}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="widget_filter-wrapper">
				<mat-form-field [appearance]="config?.formAppearance">
					<mat-label>Model</mat-label>
					<mat-select [disabled]="disableModel()"
						[(ngModel)]="selectedVehicleModel">
						<mat-option value="Any">Any</mat-option>
						<mat-option *ngFor="let vehicleModel of vehicleFeedStoreService.vehicleModels"
							[value]="vehicleModel">
							{{vehicleModel | titlecase}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="widget_filter-wrapper">
				<mat-form-field [appearance]="config?.formAppearance">
					<mat-label>Price Min</mat-label>
					<mat-select [(ngModel)]="selectedPriceMin">
						<mat-option value="Any">Any</mat-option>
						<mat-option *ngFor="let vehiclePriceMin of vehicleFeedStoreService.vehiclePriceMins"
							[value]="vehiclePriceMin">
							£{{vehiclePriceMin}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="widget_filter-wrapper">
				<mat-form-field [appearance]="config?.formAppearance">
					<mat-label>Price Max</mat-label>
					<mat-select [(ngModel)]="selectedPriceMax">
						<mat-option value="Any">Any</mat-option>
						<mat-option *ngFor="let vehiclePriceMax of vehicleFeedStoreService.vehiclePriceMaxs"
							[value]="vehiclePriceMax">
							£{{vehiclePriceMax}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<a href="javascript:void(0)" class="widget_button" (click)="searchVehicleFeed()">
				Search Vehicles
			</a>
		</div>
	</div>
</div>
