import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';
import { ITyreSize } from 'src/app/interfaces/tyre.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
 
@Component({
    selector: 'app-tyre-size-results',
    templateUrl: './tyre-size-results.component.html',
    styleUrls: ['./tyre-size-results.component.scss']
})
export class TyreSizeResultsComponent implements OnInit {

    @Output() valueChange = new EventEmitter<ITyreSize>();
    @Output() confirmAccept = new EventEmitter<boolean>();

    config: IComponentConfig;
    visibleTyreSizes = 'Front';
    confirmTyreSizeCheck = false;
    selectedTyreSize: ITyreSize;

    constructor(
        private companyStoreService: CompanyStoreService,
        private configStoreService: ConfigStoreService,
        public vehicleStoreService: VehicleStoreService) { }

    ngOnInit(): void {
        this.config = this.configStoreService.getComponentConfig('TyreSizeResultsComponent');
    }

    selectTyreSize($event, tyreSize: ITyreSize) {
        $event.stopPropagation();
        this.selectedTyreSize = tyreSize;
        this.valueChange.emit(this.selectedTyreSize);
    }

    confirmTyreSizeCheckChange($event) {
 
        this.confirmAccept.emit(this.confirmTyreSizeCheck);
    }
}
