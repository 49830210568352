import { IBooking, IOnlineShoppingBasket } from '../interfaces/booking.interface';
import { IVehicle } from '../interfaces/vehicle.interface';
import { IBranch } from '../interfaces/branch.interface';
import { ICustomer, ICustomerConsent } from '../interfaces/customer.interface';
import { ISlotRange } from '../interfaces/slot.interface';
import { ICompany } from '../interfaces/company.interface';
import { IClientInfo } from '../interfaces/client-info.interface';

export class BookingModel implements IBooking {
    availableExtrasCount: number;
    bookingDate: any;
    branch: IBranch;
    customer: ICustomer;
    slotRange: ISlotRange;
    onlineShoppingBasket?: IOnlineShoppingBasket;
    confirmedShoppingBasket?: IOnlineShoppingBasket;
    notes: string;
    company: ICompany;
    preferredDate?: Date;
    preferredTime?: string;
    vehicle: IVehicle;
    consentLogItems: any[];
    payerEmail?: string;
    softDescriptor?: string;
    clientInfo?: IClientInfo;

    constructor(branch: IBranch, customer: ICustomer, slotRange: ISlotRange, notes: string, company: ICompany, vehicle: IVehicle, consentLogItems: any[], bookingDateTime: any) {
        this.branch = branch;
        this.bookingDate = bookingDateTime;
        this.customer = customer;
        this.slotRange = slotRange;
        this.notes = notes;
        this.company = company;
        this.vehicle = vehicle;
        this.consentLogItems = consentLogItems;
        this.onlineShoppingBasket = {};
        this.confirmedShoppingBasket = {};
    }
    
}
