import { Component, OnInit } from '@angular/core';
import { LoggerStoreService } from 'src/app/services/logger/logger-store.service';
import { CustomerStoreService } from 'src/app/services/customer/customer-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { Moment } from 'moment';
import * as moment from 'moment';
import { ICustomerBooking } from 'src/app/interfaces/customer-booking.interface';

@Component({
	selector: 'app-account-manage-bookings',
	templateUrl: './account-manage-bookings.component.html',
	styleUrls: ['./account-manage-bookings.component.scss']
})
export class AccountManageBookingsComponent implements OnInit {

	constructor(
		private loggerStoreService: LoggerStoreService,
		private companyStoreService: CompanyStoreService,
		public customerStoreService: CustomerStoreService) { }

	ngOnInit(): void {
		this.customerStoreService.getCustomerBookings(this.companyStoreService.branch.guid);
	}

	formatBookingDateTime(booking: ICustomerBooking) {
		return booking.bookingDate.slice(0, 11) + booking.bookingTime;
	}

	getBookingTotal(booking: ICustomerBooking) {
		let total = 0;
		booking.shoppingBasketItems.forEach(item => {
			if (item.discountNetPrice + item.discountVat > 0) {
				total += item.discountNetPrice + item.discountVat;
			} else {
				total += item.fullNetPrice + item.fullVat;
			}
		});
		return total;
	}

}
