import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
	name: 'moment',
})
export class MomentPipe implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		if (value == "0001-01-01T00:00:00") { return ''; }
		const [format] = args;
		return moment(value).format(format);
	}
}
 