import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SlotTypeType } from 'src/app/global/enums';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ISlotTypeGroup } from 'src/app/interfaces/slot-type-group.interface';
import { ISlotType } from 'src/app/interfaces/slot-type.interface';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { ModalStoreService } from 'src/app/services/modal/modal-store.service';
import { SlotTypeStoreService } from 'src/app/services/slot-type/slot-type-store.service';
import { environment } from 'src/environments/environment';
import { DialogServiceChecklistComponent } from '../../dialogs/dialog-service-checklist/dialog-service-checklist.component';

@Component({
	selector: 'app-widget-service-checklist',
	templateUrl: './widget-service-checklist.component.html',
	styleUrls: ['./widget-service-checklist.component.scss'],
})
export class WidgetServiceChecklistComponent implements OnInit {
	// Component properties
	config: IComponentConfig;
	loading = true;
	widgetAttributesErrors: string[] = [];
	env: any;
	widgetSize = '';
	template = '';
	totalItems = 0;

	// Component Data
	slotTypeGroup: ISlotTypeGroup = null;

	// Input Data
	@Input() clientId: string;
	@Input() configId: number;
	@Input() branchGuid: string;
	@Input() slotTypeGroupId: string;
	@Input() readOnly: boolean = false;

	@ViewChild('serviceChecklists') serviceChecklists: ElementRef;

	constructor(
		public companyStoreService: CompanyStoreService,
		private authStoreService: AuthStoreService,
		private slotTypeStoreService: SlotTypeStoreService,
		public configStoreService: ConfigStoreService,
		public modalStoreService: ModalStoreService,
		private modalService: NgbModal,
		private cd: ChangeDetectorRef
	) {
		this.env = environment;
	}

	async ngOnInit() {
		if (typeof this.readOnly == 'string') {
			// if it comes through as a string from the embeded site, fix it
			this.readOnly = (this.readOnly as any) === 'true';
		}

		try {
			await this.getCompany();
			this.validateWidgetInputs();
			this.calculateWidth();
			if (this.widgetAttributesErrors.length <= 0) {
				await this.getWidgetData();
				this.totalItems = this.getTotalItems();
				this.stopLoading();
			} else {
				this.widgetAttributesErrors.forEach((error) => {
					console.error(error);
				});
				this.stopLoading();
			}
		} catch {
			this.stopLoading();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.calculateWidth();
	}

	async getCompany() {
		if (this.clientId && this.configId) {
			// If we don't pass in a client ID and config ID, it's reasonable to assume this is being loaded within the app,
			// so the injected services will have the info we require.
			this.authStoreService.clientId = this.clientId;
			this.configStoreService.configId = +this.configId || 0;
			await this.authStoreService.getToken();
			await this.companyStoreService.getCompany();
			this.configStoreService.updateThemeColours();
		}
		this.config = this.configStoreService.getComponentConfig('WidgetServiceChecklistComponent');
	}

	async getWidgetData() {
		// this should only be 1, validation occurs earlier
		await this.slotTypeStoreService.getSlotTypeGroups(this.branchGuid);
		this.slotTypeGroup = this.slotTypeStoreService.slotTypeGroups.find((c) => c.id === +this.slotTypeGroupId);
	}

	getTotalItems() {
		let count = 0;
		this.slotTypeGroup.checklistTemplate.itemGroups.forEach((g) => {
			count += g.items.length;
		});
		return count;
	}

	getSlotTypeCount(slotTypeID: number) {
		let count = 0;
		this.slotTypeGroup.checklistTemplate.itemGroups.forEach((g) => {
			g.items.forEach((i) => {
				count += i.slotTypesCompatibility[slotTypeID] == true ? 1 : 0;
			});
		});
		return count;
	}

	showSlotTypeServiceChecklist(slotTypeGroup: ISlotTypeGroup) {
		const size = slotTypeGroup.slotTypes.length > 3 ? 'xl' : 'lg';
		const modalRef = this.modalService.open(DialogServiceChecklistComponent, { size: size, windowClass: 'mst full-width-modal' });
		modalRef.componentInstance.data = {
			title: slotTypeGroup.name,
			slotTypeGroup: slotTypeGroup,
			readOnly: false,
			widget: true,
		};

		modalRef.result.then(
			(response) => {},
			(reason) => {}
		);
	}

	validateWidgetInputs() {
		if (!this.branchGuid) {
			if (this.companyStoreService?.company?.branches?.length > 1) {
				this.widgetAttributesErrors.push('Missing branch Guid [Company has multiple branches]');
			} else {
				this.branchGuid = this.companyStoreService.company.branches[0].guid;
			}
		}

		if (!this.slotTypeGroupId) {
			this.widgetAttributesErrors.push('Missing slot type group ID');
		}
	}

	calculateWidth() {
		const width = this.serviceChecklists.nativeElement.offsetWidth;

		if (width <= 380) {
			this.widgetSize = 'narrow';
			this.template = 'link';
		} else if (width > 380) {
			this.widgetSize = 'wide';
			this.template = 'inline';
		} else {
			this.widgetSize = '';
			this.template = 'inline';
		}
	}

	getPricePreambleSlotType(slotType: ISlotType): string {
		switch (slotType.fullPrice.type) {
			case 1:
				return 'Price from';
			case 2:
				return 'Price to';
			case 4:
				return slotType.fullPrice.label;
			default:
				return '';
		}
	}

	isPOASlotType(slotType: ISlotType) {
		if (slotType.type == SlotTypeType.POA) {
			return true;
		}
		return false;
	}

	showSlotTypeInfoModal(slotType: ISlotType) {
		const slotTypeGroups = this.slotTypeStoreService.getSlotTypeGroupFromSlotType(slotType.id);
		// We just take the first one as theoretically, slot type could belong to multiple groups.
		if (slotTypeGroups.length == 1 && slotTypeGroups[0].checklistTemplate?.id > 0) {
			let content = `<div>${slotType.longDescription}</div><div class="widget_includes">The following items are checked:</div>`;
			slotTypeGroups[0].checklistTemplate.itemGroups.forEach((itemGroup) => {
				content += `<div class="widget_checklist-item-group-header">${itemGroup.name}</div>`;
				itemGroup.items.forEach((item) => {
					let compatible = this.checkSlotTypesCompatibility(slotType.id, item.slotTypesCompatibility);
					content += `<div class="widget_checklist-item">
                                        <div class="widget_checklist-item-name">${item.name}</div>
                                        <div class="widget_checklist-item-value">${
											compatible ? `<i class="fas fa-check text-success"></i>` : `<i class="fas fa-horizontal-rule text-muted"></i>`
										}</div>
                                    </div>`;
				});
			});

			this.modalStoreService.openModal(slotType.shortDescription, content);
		} else {
			this.modalStoreService.openModal(slotType.shortDescription, slotType.longDescription);
		}
	}

	checkSlotTypesCompatibility(slotTypeID: number, slotTypesCompatibility: { [key: number]: boolean }): boolean {
		const compatibilityValue = Object.keys(slotTypesCompatibility)
			// find our key (ID)
			.filter((key) => key === slotTypeID.toString())
			// if we find it, reduce the key/value to just its value
			// and assign that an object.
			.reduce((obj, key) => {
				obj = slotTypesCompatibility[key];
				return obj;
			}, {});

		// if we found something *and* its true return true.
		if (compatibilityValue) {
			return true;
		}
		// if we didn't find something *or* its false return false.
		return false;
	}

	slotTypeHasMoreInfo(slotType: ISlotType) {
		const slotTypeGroups = this.slotTypeStoreService.getSlotTypeGroupFromSlotType(slotType.id);
		const hasChecklist = slotTypeGroups[0].checklistTemplate?.id > 0;
		if (!slotType) {
			return false;
		}
		if (slotType.longDescription && slotType.shortDescription != slotType.longDescription) {
			return true;
		} else if (hasChecklist) {
			return true;
		} else {
			return false;
		}
	}

	stopLoading() {
		setTimeout(() => {
			this.loading = false;
			this.cd.detectChanges();
		}, this.config.loadingPanelDelay);
	}
}
