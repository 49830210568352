import { Injectable } from '@angular/core';
import { ExtraService } from './extra.service';
import { CompanyStoreService } from '../company/company-store.service';
import { LoggerStoreService } from '../logger/logger-store.service';
import { IExtra } from 'src/app/interfaces/extra.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ExtraStoreService {

    constructor(private extrasService: ExtraService,
        private companyStoreService: CompanyStoreService,
        private loggerStoreService: LoggerStoreService) {
        this.loggerStoreService.log(`Extras Store Service: Initialized`)
    }

    private readonly _extras = new BehaviorSubject<IExtra[]>([] as IExtra[]);
    readonly extras$ = this._extras.asObservable();

    get extras(): IExtra[] {
        return this._extras.getValue();
    }

    set extras(val: IExtra[]) {
        this._extras.next(val);
    }

    async getExtras(branchGuid: string, slotTypeIDs: string) {
        if (!branchGuid) {
            this.loggerStoreService.log(`Extra Store Service: No Branch Guid.`);
            return;
        }
        
        this.extras = await this.extrasService.get(branchGuid, slotTypeIDs).toPromise();
        this.loggerStoreService.log(`Extra Store Service: Received ${this.extras.length} extras.`);
        this.loggerStoreService.table(this.extras);
    }

}
