<div class="widget_service-cards" [ngClass]="{'widget_narrow': widgetSize == 'narrow', 'widget_wide': widgetSize == 'wide'}" #serviceCards>
    <div class="widget_inner-wrapper" equalHeight="widget_price">
        <div class="widget_loading-panel" *ngIf="loading"></div>
        <div class="widget_row" *ngIf="!widgetAttributesErrors.length" equalHeight="widget_information">
            <ng-template *ngFor="let specialOffer of specialOffers" 
                [ngTemplateOutlet]="SpecialOfferTile" [ngTemplateOutletContext]="{specialOffer: specialOffer}"></ng-template>
            <ng-template *ngFor="let slotType of slotTypes" 
                [ngTemplateOutlet]="SlotTypeTile" [ngTemplateOutletContext]="{slotType: slotType}"></ng-template>
            <!-- <ng-template *ngFor="let slotType of unpricedSlotTypes" 
                [ngTemplateOutlet]="POASlotTypeTile" [ngTemplateOutletContext]="{slotType: slotType}"></ng-template> -->
        </div>
        <div class="widget_error" *ngIf="widgetAttributesErrors.length">
            <div><strong>Error loading widget.</strong></div>
        </div>
    </div>
</div>

<ng-template #SlotTypeTile let-slotType="slotType">
    <div class="widget_col">
        <div class="widget_card widget_slot-type">
            <div class="widget_information">
                <div class="widget_description">
                    <div class="widget_short-description widget_has-more-info" *ngIf="slotTypeHasMoreInfo(slotType)" (click)="showSlotTypeInfoModal(slotType)">{{slotType.shortDescription}} <i class="fas fa-info-circle"></i></div>
                    <div class="widget_short-description" *ngIf="!slotTypeHasMoreInfo(slotType)">{{slotType.shortDescription}}</div>
                    <div class="widget_long-description" *ngIf="config.services.showSlotTypeLongDescription">
                        {{slotType.longDescription}}
                    </div>
                </div>
                <img *ngIf="config.services.showSlotTypeIcon" 
                    src="https://branchresources.motasoftvgm.co.uk/{{slotType.image}}" 
                    onError="this.src='https://branchresources.motasoftvgm.co.uk//branchresources/global/images/slottypes/icons/iconset1/blue/repairs.png'" 
                    class="widget_icon" 
                /> 
            </div>

            <div class="widget_price">
                <div class="widget_value">
                    <div *ngIf="getPricePreambleSlotType(slotType)" [ngClass]="{'widget_price-preamble': slotType.fullPrice.type != 4, 'widget_no-price':slotType.fullPrice.type == 4}">{{getPricePreambleSlotType(slotType)}}</div>
                    <div class="widget_price-value" *ngIf="slotType.fullPrice.type != 4 && !isPOASlotType(slotType)">
                        {{"£" + (slotType.fullPrice.value | number:"1.2-2")}}
                        <span class="widget_price-suffix small" *ngIf="slotType.priceSuffix">{{slotType.priceSuffix}}</span>
                    </div>
                    <div class="widget_please-call" *ngIf="slotType.fullPrice.type == 4 && !isPOASlotType(slotType)">Please call {{companyStoreService.branch.phoneNumber}}</div>
                    <span *ngIf="isPOASlotType(slotType)" class="widget_price-overwrite">
                        {{ slotType.priceOverwrite }}
                        <span class="widget_price-suffix small" *ngIf="slotType.priceSuffix">{{slotType.priceSuffix}}</span>
                    </span>
                </div>
                <a *ngIf="slotType.fullPrice.type != 4 || isPOASlotType(slotType)" href="javascript:void(0)"
                    class="widget_button" (click)="addToBasketAndNavigateSlotType(slotType)">
                    {{config.buttonText}}
                </a>
            </div>


        </div>
    </div>
</ng-template>

<ng-template #SpecialOfferTile let-specialOffer="specialOffer">
    <div class="widget_col">
        <div class="widget_card widget_special-offer">
            <div class="widget_information">
                   
                <div class="widget_description">
                    
                    <div class="widget_short-description widget_has-more-info" (click)="showSpecialOfferInfoModal(specialOffer)">
                        {{specialOffer.name}} <i class="fas fa-info-circle"></i>
                    </div>

                    <div class="widget_save" *ngIf="specialOffer.discountedPrice.type == 3">
                        Save £{{(specialOffer.fullPrice.value - specialOffer.discountedPrice.value) | number:"1.2-2"}}
                    </div>
                    <div class="widget_save" *ngIf="specialOffer.discountedPrice.type != 3">
                        Save at least £{{(specialOffer.fullPrice.value - specialOffer.discountedPrice.value) | number:"1.2-2"}}
                    </div>
                    <div *ngIf="config.services.showSpecialOfferLongDescription" class="widget_special-offer-items">
                        <!-- {{specialOffer.generalInformation}} -->
                        <ul>
                            <li *ngFor="let slotType of specialOffer.slotTypes"><i class="fas fa-check"></i> {{slotType.shortDescription}}</li>
                        </ul>
                    </div>
                </div>
                <img *ngIf="config.services.showSpecialOfferIcon" 
                    src="https://branchresources.motasoftvgm.co.uk/{{specialOffer.bookingSystemIcon}}" 
                    onError="this.src='https://branchresources.motasoftvgm.co.uk/branchresources/global/images/slottypes/icons/iconset1/blue/repairs.png'" 
                    class="widget_icon" />  
            </div>
            <div class="widget_price" *ngIf="specialOffer.discountedPrice.type != 4">
                <div class="widget_value">
                    <div class="widget_price-preamble" *ngIf="getPricePreambleSpecialOffer(specialOffer)">{{getPricePreambleSpecialOffer(specialOffer)}}</div>
                    <div class="widget_price-value widget_strikethrough" *ngIf="specialOffer.discountedPrice.type == 3">£{{specialOffer.fullPrice.value | number:"1.2-2"}}</div>
                    <div class="widget_price-value">{{"£" + (specialOffer.discountedPrice.value | number:"1.2-2")}}</div>
                </div>
                <a href="javascript:void(0)" 
                    class="widget_button" 
                    (click)="addToBasketAndNavigateSpecialOffer(specialOffer)">
                    {{config.buttonText}}
                </a>
            </div>
            <div class="widget_price no-price" *ngIf="specialOffer.discountedPrice.type == 4">
                {{specialOffer.discountedPrice.label}}
                <span class="widget_please-call">Please call {{companyStoreService?.branch?.phoneNumber}}</span>
            </div>
        </div> 
    </div>
    
</ng-template>
