import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AddressService {

    constructor(private http: HttpClient) { }

    get(branchGuid: string, postcode: string) {
        return this.http.get<any>(`${environment.apiUrl}/Api/PostcodeLookup/Get?branchGuid=${branchGuid}&postcode=${postcode}`);
    }
}
