import {
	Component,
	OnInit,
	Input,
	ElementRef,
	ViewChild,
	OnDestroy,
} from '@angular/core';
import {
	UntypedFormGroup,
	Validators,
	UntypedFormControl,
	ValidatorFn,
	AbstractControl,
	ValidationErrors,
	AsyncValidatorFn,
} from '@angular/forms';
import {
	IComponentConfig,
	IConfigPrefferedDateConfiguration,
} from 'src/app/interfaces/config.interface';
import {
	IVehicleModel,
	IVehicleMake,
} from 'src/app/interfaces/vehicle-data.interface';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';
import { CustomerStoreService } from 'src/app/services/customer/customer-store.service';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import {
	IConsentItem,
	ICustomerConsent,
} from 'src/app/interfaces/customer.interface';
import { MatDialog } from '@angular/material/dialog';
import { DialogInfoComponent } from '../../dialogs/dialog-info/dialog-info.component';
import { BookingModel } from 'src/app/models/booking.model';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { DialogLoginComponent } from '../../dialogs/dialog-login/dialog-login.component';
import { Router } from '@angular/router';
import { Moment } from 'moment';
import * as moment from 'moment';
import { AddressStoreService } from 'src/app/services/address/address-store.service';
import { IPostcodeResult } from 'src/app/interfaces/postcode-result.interface';
import { DialogSelectAddressComponent } from '../../dialogs/dialog-select-address/dialog-select-address.component';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TyreStoreService } from 'src/app/services/tyre/tyre-store.service';
import { SpecialOfferStoreService } from 'src/app/services/special-offer/special-offer-store.service';
import { SlotTypeStoreService } from 'src/app/services/slot-type/slot-type-store.service';
import { environment } from 'src/environments/environment';
import { IframeResizerStoreService } from 'src/app/services/iframe-resizer/iframe-resizer-store.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IFuelType } from 'src/app/interfaces/vehicle.interface';
import { LoggerStoreService } from '../../../services/logger/logger-store.service';
import { AnalyticsStoreService } from 'src/app/services/analytics/analytics-store.service';
import { CommunicationMethod } from 'src/app/global/enums';
import { IPayPalOrderRequest } from '../paypal-buttons/paypal.interface';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { IOnlineShoppingBasket } from '../../../interfaces/booking.interface';
import { CustomerModel } from '../../../models/customer.model';
import { ClientService } from '../../../services/client/client.service';
import {
	EOnlineShoppingBasketIssueType,
	IOnlineShoppingBasketResponse,
} from '../../../interfaces/shopping-basket.interface';

export interface ValidationResult {
	[key: string]: boolean;
}

export class PasswordValidator {
	public static strong(control: UntypedFormControl): ValidationResult {
		if (!control) return;
		let hasNumber = /\d/.test(control.value);
		let hasUpper = /[A-Z]/.test(control.value);
		let hasLower = /[a-z]/.test(control.value);

		const valid = hasNumber && (hasUpper || hasLower);
		if (!valid) {
			// return what´s not valid
			return { strong: true };
		}
		return null;
	}
}

@Component({
	selector: 'app-checkout-enter-details-form',
	templateUrl: './checkout-enter-details-form.component.html',
	styleUrls: ['./checkout-enter-details-form.component.scss'],
})
export class CheckoutEnterDetailsForm implements OnInit, OnDestroy {
	destroy$ = new Subject();
	env: any;
	@Input() form: UntypedFormGroup;
	@Input() editCustomer: boolean;
	config: IComponentConfig;
	btnSaving = false;
	btnPostcodeLookup = false;
	postcode = '';
	updatedCustomer = false;
	formError = false;
	showVehicleEntry = false;
	showVrmResult = false;
	showPaypalCheckout = false;
	showDefaultCheckout = true;

	payPalOrder: IPayPalOrderRequest;

	defaultCheck = {};
	preferedDateFilter = (d: Date | null | any): boolean => {
		if (this.prefferedDateConfiguration) {
			let active = true;
			let offset = 0;

			if (this.prefferedDateConfiguration.offset > 0) {
				offset = this.prefferedDateConfiguration.offset;
			}

			if (this.prefferedDateConfiguration?.dates?.length) {
				active = !this.prefferedDateConfiguration.dates.find((x) =>
					x.isSame(moment(d), 'day')
				);
			}

			if (
				this.prefferedDateConfiguration.mondayDisabled &&
				moment(d).isoWeekday() == 1
			) {
				active = false;
			}

			if (
				this.prefferedDateConfiguration.tuesdayDisabled &&
				moment(d).isoWeekday() == 2
			) {
				active = false;
			}

			if (
				this.prefferedDateConfiguration.wednesdayDisabled &&
				moment(d).isoWeekday() == 3
			) {
				active = false;
			}

			if (
				this.prefferedDateConfiguration.thursdayDisabled &&
				moment(d).isoWeekday() == 4
			) {
				active = false;
			}

			if (
				this.prefferedDateConfiguration.fridayDisabled &&
				moment(d).isoWeekday() == 5
			) {
				active = false;
			}

			if (
				this.prefferedDateConfiguration.saturdayDisabled &&
				moment(d).isoWeekday() == 6
			) {
				active = false;
			}

			if (
				this.prefferedDateConfiguration.sundayDisabled &&
				moment(d).isoWeekday() == 7
			) {
				active = false;
			}

			if (moment(d).isSameOrBefore(moment().add(offset, 'days'), 'day')) {
				active = false;
			}

			return active;
		} else {
			// Prevent dates in the past being selected
			return moment(d).isSameOrAfter(moment(), 'day');
		}
	};

	@ViewChild('vehicleLookupWrapper') vehicleLookupWrapper: ElementRef;
	@ViewChild('vrmResultWrapper') vrmResultWrapper: ElementRef;

	constructor(
		public companyStoreService: CompanyStoreService,
		public customerStoreService: CustomerStoreService,
		public vehicleStoreService: VehicleStoreService,
		public authStoreService: AuthStoreService,
		public loggerStoreService: LoggerStoreService,
		private iframeResizerStoreService: IframeResizerStoreService,
		private modalService: NgbModal,
		private clientService: ClientService,
		public basketStoreService: BasketStoreService,
		public configStoreService: ConfigStoreService,
		public addressStoreService: AddressStoreService,
		private analyticsStoreService: AnalyticsStoreService,
		private snackBar: MatSnackBar,
		private router: Router,
		public dialog: MatDialog
	) {
		this.env = environment;
	}

	completeVrm() {
		this.showVrmResult = true;

		this.form.removeControl('registrationFormControl');
		this.form.removeControl('engineSizeFormControl');
		this.form.removeControl('fuelTypeFormControl');
		this.form.removeControl('vehicleModelFormControl');
		this.form.removeControl('vehicleMakesFormControl');
	}

	vrmAvailable() {
		if (
			this.companyStoreService.company.companyLevelVrmAvailable ||
			this.companyStoreService.branch.branchLevelVrmAvailable
		) {
			return true;
		} else {
			return false;
		}
	}

	resetVehicle() {
		this.vehicleStoreService.clearVehicle();
		this.basketStoreService.refreshPrices();
		this.showVrmResult = false;
	}

	prefferedDateConfiguration: IConfigPrefferedDateConfiguration;

	async ngOnInit(): Promise<void> {
		this.basketStoreService.getTermsAndPrivacy(
			this.companyStoreService.branch.guid
		);
		this.config = this.configStoreService.getComponentConfig(
			'CheckoutEnterDetailsForm'
		);

		if (this.config.preferredDateConfiguration) {
			try {
				this.prefferedDateConfiguration = JSON.parse(
					this.config.preferredDateConfiguration
				);

				if (this.prefferedDateConfiguration?.dates) {
					const jsonDates: any[] =
						this.prefferedDateConfiguration.dates.split(',');
					const dates = [];
					jsonDates.forEach((date) => {
						dates.push(moment(date, 'DD-MM-YYYY'));
					});
					this.prefferedDateConfiguration.dates = dates;
				}
			} catch (e) {
				this.loggerStoreService.log(
					'Checkout Enter Details: Unable to parse preferred date JSON.'
				);
			}
		}

		this.showPaypalButtons();
		this.showConfirmButton();
		this.editCustomer =
			this.editCustomer == undefined ? false : this.editCustomer;
		this.showVehicleEntry = this.vehicleStoreService.hasValidVehicle();

		// Add email form field
		if (
			this.config.forms.customer.emailAddress.enabled &&
			!this.authStoreService.isLoggedIn() &&
			!this.editCustomer
		) {
			const emailValidators = [Validators.email];
			const asyncEmailValidators = [this.emailExistsValidator()];
			if (this.config.forms.customer.emailAddress.required) {
				emailValidators.push(Validators.required);
			}
			this.form.addControl(
				'customerEmailAddressFormControl',
				new UntypedFormControl(
					null,
					emailValidators,
					asyncEmailValidators
				)
			);
		}

		// Add password form fields
		if (
			this.config.forms.customer.password.enabled &&
			!this.authStoreService.isLoggedIn() &&
			!this.editCustomer
		) {
			const passwordValidators = [
				PasswordValidator.strong,
				Validators.minLength(6),
			];
			if (this.config.forms.customer.emailAddress.required) {
				passwordValidators.push(Validators.required);
			}
			this.form.addControl(
				'customerPasswordFormControl',
				new UntypedFormControl(null, passwordValidators)
			);
			this.form.addControl(
				'customerRepeatPasswordFormControl',
				new UntypedFormControl(null, [this.validatePasswords()])
			);
		}

		// Add title form field
		if (this.config.forms.customer.title.enabled) {
			const titleValidators = [Validators.maxLength(20)];
			if (this.config.forms.customer.title.required) {
				titleValidators.push(Validators.required);
			}
			this.form.addControl(
				'customerTitleFormControl',
				new UntypedFormControl(null, titleValidators)
			);
		}

		// Add firstName form field
		if (this.config.forms.customer.firstName.enabled) {
			const firstNameValidators = [Validators.maxLength(50)];
			if (this.config.forms.customer.firstName.required) {
				firstNameValidators.push(Validators.required);
			}
			this.form.addControl(
				'customerFirstNameFormControl',
				new UntypedFormControl(null, firstNameValidators)
			);
		}

		// Add surname form field
		if (this.config.forms.customer.surname.enabled) {
			const surnameValidators = [Validators.maxLength(50)];
			if (this.config.forms.customer.surname.required) {
				surnameValidators.push(Validators.required);
			}
			this.form.addControl(
				'customerSurnameFormControl',
				new UntypedFormControl(null, surnameValidators)
			);
		}

		// Add company form field
		if (this.config.forms.customer.company.enabled) {
			const companyValidators = [Validators.maxLength(50)];
			if (this.config.forms.customer.company.required) {
				companyValidators.push(Validators.required);
			}
			this.form.addControl(
				'customerCompanyFormControl',
				new UntypedFormControl(null, companyValidators)
			);
		}

		// Add mobile number form field
		if (this.config.forms.customer.mobileNumber.enabled) {
			const mobileNumberValidators = [
				Validators.minLength(11),
				Validators.maxLength(11),
			];
			if (this.config.forms.customer.mobileNumber.required) {
				mobileNumberValidators.push(Validators.required);
			}
			this.form.addControl(
				'customerMobileNumberFormControl',
				new UntypedFormControl(null, mobileNumberValidators)
			);
		}

		// Add landline number form field
		if (this.config.forms.customer.landlineNumber.enabled) {
			const landlineNumberValidators = [
				Validators.minLength(9),
				Validators.maxLength(11),
			];
			if (this.config.forms.customer.landlineNumber.required) {
				landlineNumberValidators.push(Validators.required);
			}
			this.form.addControl(
				'customerLandlineNumberFormControl',
				new UntypedFormControl(null, landlineNumberValidators)
			);
		}

		if (this.companyStoreService.branch.customerAddressEnabled) {
			this.configureAddressFields();
		}

		if (this.authStoreService.isLoggedIn()) {
			this.loadFormFromCustomer();
		}

		if (!this.editCustomer) {
			this.form.addControl(
				'acceptTermsFormControl',
				new UntypedFormControl(false, Validators.requiredTrue)
			);
			this.form.addControl(
				'bookingNotesFormControl',
				new UntypedFormControl('')
			);
		}

		// Does basket contain items that cannot be added to the VGM diary?
		if (this.basketStoreService.unbookableSlotTypes.length > 0) {
			this.form.addControl(
				'preferedDateFormControl',
				new UntypedFormControl(null, Validators.required)
			);
			this.form.addControl(
				'preferedTimeFormControl',
				new UntypedFormControl(null, Validators.required)
			);
		}

		// Get consent options
		this.customerStoreService
			.getCustomerConsent(this.companyStoreService.branch.guid)
			.then(() => {
				// set label
				this.customerStoreService.formattedCustomerConsent?.groups.forEach(
					(consentGroup) => {
						consentGroup.communicationTypes.forEach((consent) => {
							if (
								consent.communicationMethod.toLowerCase() ==
								CommunicationMethod.toString(
									CommunicationMethod.SMS
								).toLowerCase()
							) {
								consent.label =
									this.customerStoreService.customerConsent.smsLabel;
							}
							if (
								consent.communicationMethod.toLowerCase() ==
								CommunicationMethod.toString(
									CommunicationMethod.Email
								).toLowerCase()
							) {
								consent.label =
									this.customerStoreService.customerConsent.emailLabel;
							}
						});
					}
				);

				// set default consent only if we aren't default customer
				if (!this.authStoreService.isLoggedIn() && !this.editCustomer) {
					this.customerStoreService.formattedCustomerConsent?.groups.forEach(
						(consentGroup) => {
							consentGroup.communicationTypes.forEach(
								(consent) => {
									if (
										consent.communicationMethod.toLowerCase() ==
										CommunicationMethod.toString(
											CommunicationMethod.SMS
										).toLowerCase()
									) {
										consent.isTicked =
											this.customerStoreService.customerConsent.smsCheckedDefault;
									}
									if (
										consent.communicationMethod.toLowerCase() ==
										CommunicationMethod.toString(
											CommunicationMethod.Email
										).toLowerCase()
									) {
										consent.isTicked =
											this.customerStoreService.customerConsent.emailCheckedDefault;
									}
								}
							);
						}
					);
				}
			});

		if (
			this.basketStoreService.enabledOnlinePayment &&
			this.config.onlinePaymentsPaypal
		) {
			// if the price refreshed, please try get a new paypal order
			this.basketStoreService.basketPricesRefreshed$
				.pipe(takeUntil(this.destroy$))
				.subscribe((c) => {
					// if the basket Prices Refreshed is true,
					// and the form is valid,
					// and we aren't editing the customer
					// please create a paypal order object.
					if (c && this.form.valid && !this.editCustomer) {
						this.loggerStoreService.log(
							'CheckoutEnterDetailsForm: price refreshed, form valid'
						);
						this.getPayPalOrder();
					}
				});

			this.form.statusChanges
				.pipe(takeUntil(this.destroy$))
				.subscribe(() => {
					// if the form is valid
					// and we are not currenly refreshing prices
					// and we don't already have a paypal order object
					// and we aren't editing the customer
					// please create a paypal order object.
					if (
						this.form.valid &&
						!this.basketStoreService.refreshingPrices &&
						!this.payPalOrder &&
						!this.editCustomer
					) {
						this.loggerStoreService.log(
							'CheckoutEnterDetailsForm: form valid, not refreshing prices, no order yet'
						);

						// get the paypal order
						this.getPayPalOrder();
					}
				});
		}
	}

	postcodeSearchError = false;
	showPostcodeLookup = true;
	lookupAddress() {
		if (this.btnPostcodeLookup) {
			return;
		}
		this.btnPostcodeLookup = true;
		this.addressStoreService
			.lookupPostcode(
				this.companyStoreService.branch.guid,
				this.form.controls.postcodeSearchFormControl.value
			)
			.then(() => {
				const result: IPostcodeResult[] =
					this.addressStoreService.postcodeResults;
				this.btnPostcodeLookup = false;
				if (result.length == 1) {
					if (result[0].postcodeSearchError) {
						this.postcodeSearchError = true;
					} else {
						this.updateAddressFromPostcodeResult(result[0]);
						this.postcodeSearchError = false;
					}
				} else if (result.length > 1) {
					this.iframeResizerStoreService.scrollToIFrameTop();
					const modalRef = this.modalService.open(
						DialogSelectAddressComponent
					);

					modalRef.result.then(
						(response) => {
							if (response) {
								this.updateAddressFromPostcodeResult(response);
							}
						},
						(reason) => {}
					);
					this.postcodeSearchError = false;
				} else {
					this.postcodeSearchError = true;
				}
			});
	}

	changeHowContact() {
		this.router.navigate([
			this.authStoreService.clientId,
			this.configStoreService.configId,
			'account',
			'edit-customer',
		]);
	}

	hideConsentExpiry(consent) {
		if (
			consent.expiry &&
			consent.expiry != '0001-01-01T00:00:00' &&
			!consent.isRevoked
		) {
			return false;
		}

		return true;
	}

	updateAddressFromPostcodeResult(postcodeResult: IPostcodeResult) {
		this.showPostcodeLookup = false;
		this.form.controls.customerAddress1FormControl.setValue(
			postcodeResult.displayAddress1
		);
		this.form.controls.customerAddress2FormControl.setValue(
			postcodeResult.displayAddress2
		);
		this.form.controls.customerAddress3FormControl.setValue(
			postcodeResult.displayAddress3
		);
		this.form.controls.customerTownCityFormControl.setValue(
			postcodeResult.townCity
		);
		this.form.controls.customerCountyFormControl.setValue(
			postcodeResult.county
		);
		this.form.controls.customerPostcodeFormControl.setValue(
			postcodeResult.postcode
		);
	}

	configureAddressFields() {
		if (this.config.forms.customer.address1.enabled) {
			const validators = [Validators.maxLength(100)];
			if (this.config.forms.customer.address1.required) {
				validators.push(Validators.required);
			}
			this.form.addControl(
				'customerAddress1FormControl',
				new UntypedFormControl(null, validators)
			);
		}
		if (this.config.forms.customer.address2.enabled) {
			const validators = [Validators.maxLength(50)];
			if (this.config.forms.customer.address2.required) {
				validators.push(Validators.required);
			}
			this.form.addControl(
				'customerAddress2FormControl',
				new UntypedFormControl(null, validators)
			);
		}
		if (this.config.forms.customer.address3.enabled) {
			const validators = [Validators.maxLength(50)];
			if (this.config.forms.customer.address3.required) {
				validators.push(Validators.required);
			}
			this.form.addControl(
				'customerAddress3FormControl',
				new UntypedFormControl(null, validators)
			);
		}
		if (this.config.forms.customer.townCity.enabled) {
			const validators = [Validators.maxLength(50)];
			if (this.config.forms.customer.townCity.required) {
				validators.push(Validators.required);
			}
			this.form.addControl(
				'customerTownCityFormControl',
				new UntypedFormControl(null, validators)
			);
		}
		if (this.config.forms.customer.county.enabled) {
			const validators = [Validators.maxLength(50)];
			if (this.config.forms.customer.county.required) {
				validators.push(Validators.required);
			}
			this.form.addControl(
				'customerCountyFormControl',
				new UntypedFormControl(null, validators)
			);
		}
		if (this.config.forms.customer.postcode.enabled) {
			const validators = [Validators.maxLength(12)];
			if (this.config.forms.customer.postcode.required) {
				validators.push(Validators.required);
			}
			this.form.addControl(
				'customerPostcodeFormControl',
				new UntypedFormControl(null, validators)
			);
		}
		// if search enabled
		this.form.addControl(
			'postcodeSearchFormControl',
			new UntypedFormControl(null)
		);
	}

	validatePasswords(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const match =
				control.value ==
				this.form.controls.customerPasswordFormControl?.value;
			return match ? null : { passwordMatch: { value: control.value } };
		};
	}

	emailExistsValidator(): AsyncValidatorFn {
		return async (
			control: AbstractControl
		): Promise<ValidationErrors | null> => {
			const value = control.value;

			if (!value) {
				return null;
			}
			const exists = await this.customerStoreService.checkEmailExists(
				value
			);

			if (exists == 0 || exists == 2) {
				return null;
			} else {
				return { emailExists: true };
			}
		};
	}

	filteredConsentTrue(communicationTyres: any[]) {
		return communicationTyres.filter((c) => !c.requiresConsent);
	}

	filteredConsentFalse(communicationTyres: any[]) {
		return communicationTyres.filter((c) => c.requiresConsent);
	}

	showPrivacyPolicy() {
		this.iframeResizerStoreService.scrollToIFrameTop();
		const modalRef = this.modalService.open(DialogInfoComponent);
		modalRef.componentInstance.data = {
			title: 'Privacy policy',
			innerHtml: this.customerStoreService.customerConsent.privacyPolicy,
			html: false,
		};

		modalRef.result.then(
			(response) => {},
			(reason) => {}
		);
	}

	showTerms() {
		this.iframeResizerStoreService.scrollToIFrameTop();

		const modalRef = this.modalService.open(DialogInfoComponent);
		modalRef.componentInstance.data = {
			title: 'Terms and conditions',
			innerHtml: this.basketStoreService.termsAndPrivacy,
			html: true,
		};

		modalRef.result.then(
			(response) => {},
			(reason) => {}
		);
	}

	login() {
		this.iframeResizerStoreService.scrollToIFrameTop();

		const modalRef = this.modalService.open(DialogLoginComponent);
		modalRef.componentInstance.data = {
			template: 'Login',
			title: 'Login to your account',
		};

		modalRef.result.then(
			(result) => {
				if (result == true) {
					// remove existing fields
					// logged in
					this.loadFormFromCustomer();
					this.removeAccountInformationFormFields();

					// Refreshing slot types and special offers. This will update prices.
					this.basketStoreService.refreshPrices();
				}
			},
			(reason) => {}
		);
	}

	removeAccountInformationFormFields() {
		// Add email form field
		if (this.form.controls.customerEmailAddressFormControl) {
			this.form.removeControl('customerEmailAddressFormControl');
		}
		if (this.form.controls.customerPasswordFormControl) {
			this.form.removeControl('customerPasswordFormControl');
		}
		if (this.form.controls.customerRepeatPasswordFormControl) {
			this.form.removeControl('customerRepeatPasswordFormControl');
		}
	}

	loadFormFromCustomer() {
		if (this.form.controls.customerTitleFormControl) {
			this.form
				.get('customerTitleFormControl')
				.setValue(this.customerStoreService.customer.title);
			this.form.controls.customerTitleFormControl.markAsTouched();
		}

		if (this.form.controls.customerFirstNameFormControl) {
			this.form
				.get('customerFirstNameFormControl')
				.setValue(this.customerStoreService.customer.firstName);
			this.form.controls.customerFirstNameFormControl.markAsTouched();
		}

		if (this.form.controls.customerSurnameFormControl) {
			this.form
				.get('customerSurnameFormControl')
				.setValue(this.customerStoreService.customer.surname);
			this.form.controls.customerSurnameFormControl.markAsTouched();
		}

		if (this.form.controls.customerCompanyFormControl) {
			this.form
				.get('customerCompanyFormControl')
				.setValue(this.customerStoreService.customer.company);
			this.form.controls.customerCompanyFormControl.markAsTouched();
		}

		if (this.form.controls.customerMobileNumberFormControl) {
			this.form
				.get('customerMobileNumberFormControl')
				.setValue(
					this.customerStoreService.customer.mobile
						.replace(/\s/g, '')
						.trim()
				);
			this.form.controls.customerMobileNumberFormControl.markAsTouched();
		}

		if (this.form.controls.customerLandlineNumberFormControl) {
			this.form
				.get('customerLandlineNumberFormControl')
				.setValue(
					this.customerStoreService.customer.phone
						.replace(/\s/g, '')
						.trim()
				);
			this.form.controls.customerLandlineNumberFormControl.markAsTouched();
		}

		if (this.form.controls.customerAddress1FormControl) {
			this.form
				.get('customerAddress1FormControl')
				.setValue(this.customerStoreService.customer.address1);
			this.form.controls.customerAddress1FormControl.markAsTouched();
		}

		if (this.form.controls.customerAddress2FormControl) {
			this.form
				.get('customerAddress2FormControl')
				.setValue(this.customerStoreService.customer.address2);
			this.form.controls.customerAddress2FormControl.markAsTouched();
		}

		if (this.form.controls.customerAddress3FormControl) {
			this.form
				.get('customerAddress3FormControl')
				.setValue(this.customerStoreService.customer.address3);
			this.form.controls.customerAddress3FormControl.markAsTouched();
		}

		if (this.form.controls.customerTownCityFormControl) {
			this.form
				.get('customerTownCityFormControl')
				.setValue(this.customerStoreService.customer.townCity);
			this.form.controls.customerTownCityFormControl.markAsTouched();
		}

		if (this.form.controls.customerCountyFormControl) {
			this.form
				.get('customerCountyFormControl')
				.setValue(this.customerStoreService.customer.county);
			this.form.controls.customerCountyFormControl.markAsTouched();
		}

		if (this.form.controls.customerPostcodeFormControl) {
			this.form
				.get('customerPostcodeFormControl')
				.setValue(this.customerStoreService.customer.postcode);
			this.form.controls.customerPostcodeFormControl.markAsTouched();
		}

		if (!this.customerStoreService.checkValidAddress()) {
			this.showPostcodeLookup = true;
		} else {
			this.showPostcodeLookup = false;
		}
	}

	public findInvalidControls() {
		const invalid = [];
		const controls = this.form.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name);
			}
		}
		return invalid;
	}

	async updateCustomer() {
		if (this.btnSaving) {
			return;
		}
		if (!this.authStoreService.isLoggedIn()) {
			return;
		}
		this.btnSaving = true;
		this.customerStoreService.customer.consentLogItems =
			this.customerStoreService.getConsentLogItems(
				this.customerStoreService.formattedCustomerConsent?.groups
			);
		this.customerStoreService.updateCustomerFromForm(this.form);
		await this.customerStoreService.updateCustomer();
		setTimeout(() => {
			this.btnSaving = false;
			this.updatedCustomer = true;
		}, this.config.loadingPanelDelay);
	}

	async confirmBooking() {
		if (this.btnSaving || !this.form.valid) {
			// We mark every form item as touched to trigger it's individual validation.
			for (let i in this.form.controls) {
				this.form.controls[i].markAsTouched();
			}
			this.formError = true;
			return;
		}
		this.formError = false;
		this.btnSaving = true;

		const booking = await this.createBookingFromForm();

		let response: IOnlineShoppingBasket;

		if (
			this.basketStoreService.poaSlotTypes.length > 0 ||
			this.basketStoreService.unbookableSlotTypes.length > 0
		) {
			response = await this.basketStoreService.submitPOABooking(
				this.companyStoreService.branch.guid,
				booking
			);
		} else {
			response = await this.basketStoreService.submitBooking(
				this.companyStoreService.branch.guid,
				booking
			);
		}

		await this.handleBookingResponse(response, booking);
	}

	btnPaypalSaving = false;
	btnPaypalDisabled = true;

	async getPayPalOrder() {
		this.loggerStoreService.log('Getting paypal order');
		this.btnPaypalDisabled = true;

		const booking = await this.createBookingFromForm();

		try {
			this.payPalOrder = await this.basketStoreService.getPayPalOrder(
				this.companyStoreService.branch.guid,
				booking
			);
		} catch (error) {
			const response = error as IOnlineShoppingBasketResponse[];
			this.iframeResizerStoreService.scrollToIFrameTop();
			if (
				response.some(
					(c) =>
						c.issueType == EOnlineShoppingBasketIssueType.Blacklist
				)
			) {
				const modalRef = this.modalService.open(DialogInfoComponent);
				modalRef.componentInstance.data = {
					title: 'Online booking error',
					innerHtml: `<div>There is a problem with completing your booking. Please call us on ${this.companyStoreService.branch.phoneNumber}.</div>`,
					html: true,
				};
			} else {
				const modalRef = this.modalService.open(DialogInfoComponent);
				modalRef.componentInstance.data = {
					title: 'Online payment error',
					innerHtml: `<div>There was an error creating your paypal order. </div>
                                <div>Please check the form or start again.</div>
                                <div>If the problem continues please contact us.</div>
                                `,
					html: true,
				};
			}

			this.form.markAllAsTouched();
			throw new Error(
				`Paypal Basket Validation Error: ${JSON.stringify(error)}`
			);
		}

		this.btnPaypalDisabled = false;
	}

	async completePayPalOrder(event) {
		this.loggerStoreService.log(
			'CheckoutEnterDetailsForm: Completing paypal order'
		);
		this.loggerStoreService.log(event);
		if (this.btnPaypalDisabled || !this.form.valid) {
			// We mark every form item as touched to trigger it's individual validation.
			for (let i in this.form.controls) {
				this.form.controls[i].markAsTouched();
			}
			this.formError = true;
			return;
		}
		this.formError = false;
		this.btnPaypalSaving = true;

		const payPalOrderID = event.orderID;
		const booking = await this.createBookingFromForm();
		const response = await this.basketStoreService.completePayPalOrder(
			this.companyStoreService.branch.guid,
			booking,
			payPalOrderID
		);

		this.handleBookingResponse(response, booking);
	}

	private async createBookingFromForm(): Promise<BookingModel> {
		this.customerStoreService.customer.consentLogItems =
			this.customerStoreService.getConsentLogItems(
				this.customerStoreService.formattedCustomerConsent?.groups
			);
		const bookingDate = this.basketStoreService.bookingDateTime;
		if (bookingDate) {
			bookingDate.hour(0);
			bookingDate.minute(0);
		}

		// If customer form is there, take the values from the form and over-right this.customerStoreService.customer
		this.customerStoreService.updateCustomerFromForm(this.form);
		// If vehicle form is there, take the values from the form and over-right this.vehicleStoreService.vehicle
		if (!this.vehicleStoreService.hasValidVehicle() && !this.editCustomer) {
			this.vehicleStoreService.saveVehicleForm(this.form);
			await this.basketStoreService.refreshPrices();
		}

		if (!this.companyStoreService?.branch) {
			console.error('We lost branch when completing see BUG 5629');
		}

		let booking = new BookingModel(
			this.companyStoreService.branch,
			this.customerStoreService.customer,
			this.basketStoreService.bookingSlotRange,
			this.form.controls.bookingNotesFormControl
				? this.form.controls.bookingNotesFormControl.value
				: '',
			this.companyStoreService.company,
			this.vehicleStoreService.vehicle,
			this.customerStoreService.customer.consentLogItems,
			bookingDate
		);

		if (
			this.basketStoreService.poaSlotTypes.length > 0 ||
			this.basketStoreService.unbookableSlotTypes.length > 0
		) {
			if (this.form.controls.preferedDateFormControl) {
				booking.preferredDate =
					this.form.controls.preferedDateFormControl.value;
			} else {
				console.error('Missing prefered date control');
			}

			if (this.form.controls.preferedTimeFormControl) {
				booking.preferredTime =
					this.form.controls.preferedTimeFormControl.value;
			} else {
				console.error('Missing prefered time control');
			}
		}

		booking.onlineShoppingBasket = {
			slotTypes: this.basketStoreService.slotTypes,
			extras: this.basketStoreService.extras,
			tyres: this.basketStoreService.tyres,
			specialOffers: this.basketStoreService.specialOffers,
		};

		if (!this.authStoreService.isLoggedIn()) {
			booking.customer.password =
				this.form.controls.customerPasswordFormControl?.value;
		}

		booking.clientInfo = this.clientService.clientInfo;

		return booking;
	}

	public payPalSmartButtonsError(error) {
		this.iframeResizerStoreService.scrollToIFrameTop();
		const modalRef = this.modalService.open(DialogInfoComponent);
		modalRef.componentInstance.data = {
			title: 'Online payment error',
			innerHtml: `<div>There was an error creating your paypal order. Please try again.</div>
                        <div>If the problem continues please contact us.</div>
                        `,
			html: true,
		};

		throw new Error(`Paypal Smart Buttons Error: ${error}`);
	}

	private async handleBookingResponse(
		response: IOnlineShoppingBasket,
		booking: BookingModel
	) {
		this.loggerStoreService.log(
			'CheckoutEnterDetailsForm: Handling booking response'
		);
		this.loggerStoreService.log(response);
		let error = false;
		let slotError = false;
		let dateTimeError = false;
		let customerError = false;
		let customerDetailError = false;
		let vehicleDetailError = false;
		let tyreError = false;
		let onlinePaymentError = false;
		let blacklistDetection = false;
		response.response.forEach((issue) => {
			if (issue.issueType == 0) {
				if (issue.issueDescription.indexOf('payer_email:') > -1) {
					let found = issue.issueDescription.substring(
						issue.issueDescription.indexOf(':') + 1,
						issue.issueDescription.length
					);
					found = found.trim();
					booking.payerEmail = found;
				}

				if (issue.issueDescription.indexOf('soft_descriptor:') > -1) {
					let found = issue.issueDescription.substring(
						issue.issueDescription.indexOf(':') + 1,
						issue.issueDescription.length
					);
					found = found.trim();
					booking.softDescriptor = found;
				}
			}

			if (
				issue.issueType == 1 ||
				issue.issueType == 2 ||
				issue.issueType == 3
			) {
				slotError = true;
				error = true;
			}
			if (issue.issueType == 4) {
				tyreError = true;
				error = true;
			}
			if (issue.issueType == 5 || issue.issueType == 6) {
				dateTimeError = true;
				error = true;
			}
			if (issue.issueType == 7) {
				customerError = true;
				error = true;
			}
			if (issue.issueType == 8) {
				customerDetailError = true;
				error = true;
			}
			if (issue.issueType == 9) {
				vehicleDetailError = true;
				error = true;
			}
			if (issue.issueType == 10) {
				onlinePaymentError = true;
				error = true;
			}
			if (issue.issueType == 11) {
				blacklistDetection = true;
				error = true;
			}
		});

		if (error) {
			if (slotError) {
				this.basketStoreService.slotTypes = response.slotTypes;
				this.basketStoreService.specialOffers = response.specialOffers;
				this.basketStoreService.extras = response.extras;
				if (
					this.companyStoreService.branch.onlinePaymentEnabled &&
					this.config.onlinePaymentsPaypal
				) {
					// Swap slots
					this.snackBar
						.open(`The prices of some of your selected services has changed. 
                    Please check your updated basket total and retry. You have not been charged.`);
				} else {
					// Swap slots
					this.snackBar.open(
						`The prices of some of your selected services has changed. Please check your updated basket total and resubmit.`
					);
				}
			}

			if (dateTimeError) {
				this.iframeResizerStoreService.scrollToIFrameTop();
				const modalRef = this.modalService.open(DialogInfoComponent);
				if (
					this.companyStoreService.branch.onlinePaymentEnabled &&
					this.config.onlinePaymentsPaypal
				) {
					modalRef.componentInstance.data = {
						title: 'Date & time no longer available',
						innerHtml: `<div>The date and time you selected is no longer available. 
                        When you close this dialog, you'll be redirected to the previous step to select a new date and time. 
                        You have not been charged.</div>`,
						html: true,
					};
				} else {
					modalRef.componentInstance.data = {
						title: 'Date & time no longer available',
						innerHtml: `<div>The date and time you selected is no longer available. 
                        When you close this dialog, you'll be redirected to the previous step to select a new date and time.</div>`,
						html: true,
					};
				}

				modalRef.result.then(
					(response) => {
						this.router.navigate([
							this.authStoreService.clientId,
							this.configStoreService.configId,
							'booking',
							'select-date-time',
						]);
					},
					(reason) => {}
				);
			}

			if (customerError) {
				this.iframeResizerStoreService.scrollToIFrameTop();
				const modalRef = this.modalService.open(DialogInfoComponent);
				modalRef.componentInstance.data = {
					title: 'Customer account error',
					innerHtml: `<div>There is an error with this account, please check the email is not in use.</div>`,
					html: true,
				};
			}

			if (customerDetailError) {
				this.iframeResizerStoreService.scrollToIFrameTop();
				const modalRef = this.modalService.open(DialogInfoComponent);

				const customerDetailErrors = response.response.filter(
					(c) => (c.issueType = 8)
				);
				let customerDetailErrorsStr = '';
				customerDetailErrors.forEach((issue) => {
					customerDetailErrorsStr += `${issue.issueDescription} `;
				});

				modalRef.componentInstance.data = {
					title: 'Customer details error',
					innerHtml:
						`<div>` +
						customerDetailErrorsStr +
						`- please amend and try again.</div>`,
					html: true,
				};
			}

			if (vehicleDetailError) {
				this.iframeResizerStoreService.scrollToIFrameTop();
				const modalRef = this.modalService.open(DialogInfoComponent);

				const vehicleDetailErrors = response.response.filter(
					(c) => (c.issueType = 9)
				);
				let vehicleDetailErrorsStr = '';
				vehicleDetailErrors.forEach((issue) => {
					vehicleDetailErrorsStr += `${issue.issueDescription} `;
				});

				modalRef.componentInstance.data = {
					title: 'Vehicle details error',
					innerHtml:
						`<div>` +
						vehicleDetailErrorsStr +
						`- please amend and try again.</div>`,
					html: true,
				};
			}

			if (onlinePaymentError) {
				this.iframeResizerStoreService.scrollToIFrameTop();
				const modalRef = this.modalService.open(DialogInfoComponent);

				const onlinePaymentErrors = response.response.filter(
					(c) => (c.issueType = 10)
				);
				let onlinePaymentErrorStr = 'Online Payment API Error: ';
				onlinePaymentErrors.forEach((issue) => {
					onlinePaymentErrorStr += `${issue.issueDescription} `;
				});

				if (
					onlinePaymentErrorStr.toLowerCase().indexOf('declined') > -1
				) {
					modalRef.componentInstance.data = {
						title: 'Online payment error',
						innerHtml: `<div>This account or card has been declined. Please try again.</div>`,
						html: true,
					};
				} else {
					modalRef.componentInstance.data = {
						title: 'Online payment error',
						innerHtml: `<div>There was an error taking payment. You have not been charged.</div>`,
						html: true,
					};
					this.loggerStoreService.logCritialToRollBar(
						new Error(onlinePaymentErrorStr)
					);
				}
			}

			if (blacklistDetection) {
				this.iframeResizerStoreService.scrollToIFrameTop();
				const modalRef = this.modalService.open(DialogInfoComponent);
				modalRef.componentInstance.data = {
					title: 'Online booking error',
					innerHtml: `<div>There is a problem with completing your booking. Please call us on ${this.companyStoreService.branch.phoneNumber}.</div>`,
					html: true,
				};
			}

			setTimeout(() => {
				this.btnSaving = false;
				this.btnPaypalSaving = false;
			}, this.config.loadingPanelDelay);
		} else {
			this.analyticsStoreService.trackPurchase(booking);
			this.basketStoreService.updateCompletedBooking(booking, response);

			if (!this.authStoreService.isLoggedIn()) {
				// If we're creating a new user, after the booking has been accepted, we need to log the user in, then get
				// a DB version of the customer.
				const tempPassword =
					this.form.controls.customerPasswordFormControl?.value;
				await this.authStoreService.userLogin(
					this.customerStoreService.customer.email,
					tempPassword,
					this.companyStoreService.branch.guid
				);
				await this.customerStoreService.getCustomer(
					this.companyStoreService.branch.guid
				);

				this.loggerStoreService.log(
					'CheckoutEnterDetailsForm: Navigating to confirmation'
				);
				// navigate
				this.iframeResizerStoreService.scrollToIFrameTop();
				setTimeout(() => {
					this.btnSaving = false;
					this.router.navigate([
						this.authStoreService.clientId,
						this.configStoreService.configId,
						'booking',
						'confirmation',
					]);
				}, this.config.loadingPanelDelay);
			} else {
				this.loggerStoreService.log(
					'CheckoutEnterDetailsForm: Navigating to confirmation'
				);
				// navigate
				setTimeout(() => {
					this.btnSaving = false;
					this.iframeResizerStoreService.scrollToIFrameTop();
					this.router.navigate([
						this.authStoreService.clientId,
						this.configStoreService.configId,
						'booking',
						'confirmation',
					]);
				}, this.config.loadingPanelDelay);
			}
		}
	}

	showConfirmButton() {
		let result = true;

		if (
			this.companyStoreService.branch.onlinePaymentEnabled &&
			this.config.onlinePaymentsPaypal &&
			this.basketStoreService.onlinePaymentEnabled
		) {
			// if we have paypal enabled, and none ofitems in the basket are online payment enabled
			// hide this button
			result = false;
		}

		if (this.basketStoreService.poaSlotTypes.length > 0) {
			// but if we contain unbookable slot type, show
			result = true;
		}

		if (this.basketStoreService.unbookableSlotTypes.length > 0) {
			result = true;
		}

		if (this.basketStoreService.getBasketTotal() <= 0) {
			// Don't bother showing paypal if the total is free
			result = true;
		}

		this.showDefaultCheckout = result;
	}

	showPaypalButtons() {
		let result = false;

		if (
			this.companyStoreService.branch.onlinePaymentEnabled &&
			this.config.onlinePaymentsPaypal &&
			this.basketStoreService.onlinePaymentEnabled
		) {
			// if we have paypal enabled, and any items in the basket are online payment enabled
			// show this button
			result = true;
		}

		if (
			this.basketStoreService.poaSlotTypes.length > 0 ||
			this.basketStoreService.unbookableSlotTypes.length > 0
		) {
			// but if we contain unbookable slot type, hide
			result = false;
		}

		if (this.basketStoreService.getBasketTotal() <= 0) {
			// Don't bother showing paypal if the total is free
			result = false;
		}

		if (this.editCustomer) {
			// If we are editing the customer details, don't show
			result = false;
		}

		this.showPaypalCheckout = result;
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
