<app-basket template="large"></app-basket>
<div class="nav-buttons" *ngIf="extraStoreService.extras.length && config.skipUpsell">
    <button class="btn btn-lg btn-light" (click)="back()"><i class="fas fa-chevron-left"></i> Back</button>
    <span class="middle-spacer"></span>
    <button class="btn btn-lg btn-primary" (click)="navigate()" [disabled]="!basketStoreService.getBasketNumberOfItems()">Continue <i class="fas fa-chevron-right"></i></button>
</div>
<div *ngIf="config.skipUpsell">
    <app-special-offer-upgrade-list></app-special-offer-upgrade-list>
    <app-extra-list></app-extra-list>
</div>

<div class="nav-buttons">
    <button class="btn btn-lg btn-light" (click)="back()"><i class="fas fa-chevron-left"></i> Back</button>
    <span class="middle-spacer"></span>
    <button class="btn btn-lg btn-primary" (click)="navigate()" [disabled]="!basketStoreService.getBasketNumberOfItems()">Continue <i class="fas fa-chevron-right"></i></button>
</div>

