
<ng-container *ngIf="config.template == 'list' && specialOfferStoreService.showSpecialOffers()">
    <div class="special-offer-group list">
        <h4>
            <span>Special offers</span>
            <i class="fas fa-chevron-up" [hidden]="hideSpecialOffers" (click)="hideSpecialOffers = !hideSpecialOffers"></i>
            <i class="fas fa-chevron-down" [hidden]="!hideSpecialOffers" (click)="hideSpecialOffers = !hideSpecialOffers"></i>
        </h4>
        <div [hidden]="hideSpecialOffers">
            <ng-container *ngFor="let specialOffer of specialOfferStoreService.specialOffers">
                <ng-template *ngIf="specialOfferStoreService.showSpecialOffer(specialOffer)" [ngTemplateOutlet]="SpecialOfferListItem" [ngTemplateOutletContext]="{specialOffer: specialOffer}"></ng-template>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="config.template == 'tile' && specialOfferStoreService.showSpecialOffers()">
    <div class="special-offer-group tiles">
        <h4>
            <span>Special Offers</span>
            <i class="fas fa-chevron-up" [hidden]="hideSpecialOffers" (click)="hideSpecialOffers = !hideSpecialOffers"></i>
            <i class="fas fa-chevron-down" [hidden]="!hideSpecialOffers" (click)="hideSpecialOffers = !hideSpecialOffers"></i>
        </h4>
        <div class="form-row" [hidden]="hideSpecialOffers">
            <ng-container *ngFor="let specialOffer of specialOfferStoreService.specialOffers">
                <ng-template *ngIf="specialOfferStoreService.showSpecialOffer(specialOffer)" [ngTemplateOutlet]="SpecialOfferTile" [ngTemplateOutletContext]="{specialOffer: specialOffer}"></ng-template>
            </ng-container>
        </div>
    </div>
</ng-container>



<!-- Individual templates -->

<ng-template #SpecialOfferTile let-specialOffer="specialOffer">
    <div class="col-md-6 col-lg-4 d-flex">
        <div class="special-offer-tile">
            <div class="description">
                <img *ngIf="config.services.showSpecialOfferIcon" src="https://branchresources.motasoftvgm.co.uk/{{specialOffer.bookingSystemIcon}}" onError="this.src='https://branchresources.motasoftvgm.co.uk//branchresources/global/images/slottypes/icons/iconset1/blue/repairs.png'" class="icon" />     
                <div>
                    <p>{{specialOffer.name}} 
                        <span class="price-preamble save" *ngIf="specialOffer.discountedPrice.type == 3">Save £{{(specialOffer.fullPrice.value - specialOffer.discountedPrice.value) | number:"1.2-2"}}</span>
                        <span class="price-preamble save" *ngIf="specialOffer.discountedPrice.type != 3">Save at least £{{(specialOffer.fullPrice.value - specialOffer.discountedPrice.value) | number:"1.2-2"}}</span>
                    </p>
                    <span [hidden]="!config.services.showSpecialOfferLongDescription">
                        {{specialOffer.generalInformation}}
                    </span>
                    <ul>
                        <li *ngFor="let slotType of specialOffer.slotTypes"><i class="fas fa-check"></i> {{slotType.shortDescription}}</li>
                    </ul>
                </div>
            </div>
            <div class="price" *ngIf="specialOffer.discountedPrice.type != 4">
                <span class="value">
                    <span class="price-preamble">{{getPricePreamble(specialOffer)}}</span>
                    <span class="price-preamble strikethrough" *ngIf="specialOffer.discountedPrice.type == 3">£{{specialOffer.fullPrice.value | number:"1.2-2"}}</span>
                    {{"£" + (specialOffer.discountedPrice.value | number:"1.2-2")}}
                </span>
                <a href="javascript:void(0)" [ngClass]="{'btn-primary': !basketStoreService.specialOfferSelected(specialOffer), 'btn-success': basketStoreService.specialOfferSelected(specialOffer)}" class="btn btn-sm btn-primary" (click)="addRemoveSpecialOffer(specialOffer)">
                    <i class="fas fa-plus" [hidden]="basketStoreService.specialOfferSelected(specialOffer)"></i>
                    <i class="fas fa-check" [hidden]="!basketStoreService.specialOfferSelected(specialOffer)"></i>
                    <span [hidden]="basketStoreService.specialOfferSelected(specialOffer)">{{config.buttonText}}</span>
                </a>
            </div>
            <div class="price no-price" *ngIf="specialOffer.discountedPrice.type == 4">
                {{specialOffer.discountedPrice.label}}
                <span class="please-call">Please call {{companyStoreService?.branch?.phoneNumber}}</span>
            </div>
        </div> 
     </div>
</ng-template>

<ng-template #SpecialOfferListItem let-specialOffer="specialOffer">
    <div class="special-offer-list-item">
        <div class="description">
            <img *ngIf="config.services.showSpecialOfferIcon" src="https://branchresources.motasoftvgm.co.uk/{{specialOffer.bookingSystemIcon}}" onError="this.src='https://branchresources.motasoftvgm.co.uk//branchresources/global/images/slottypes/icons/iconset1/blue/repairs.png'" class="icon" />     
            <div>
                <p>{{specialOffer.name}} 
                    <span class="price-preamble save" *ngIf="specialOffer.discountedPrice.type == 3">Save £{{(specialOffer.fullPrice.value - specialOffer.discountedPrice.value) | number:"1.2-2"}}</span>
                    <span class="price-preamble save" *ngIf="specialOffer.discountedPrice.type != 3">Save at least £{{(specialOffer.fullPrice.value - specialOffer.discountedPrice.value) | number:"1.2-2"}}</span>
                </p>
                <span [hidden]="!config.services.showSpecialOfferLongDescription">
                    {{specialOffer.generalInformation}}
                </span>
                <ul>
                    <li *ngFor="let slotType of specialOffer.slotTypes"><i class="fas fa-check"></i> {{slotType.shortDescription}}</li>
                </ul>
            </div>
            
        </div>
        
        <div class="price">
            <span class="value" *ngIf="specialOffer.discountedPrice.type != 4">
                <span class="price-preamble">{{getPricePreamble(specialOffer)}}</span>
                <span class="price-preamble strikethrough" *ngIf="specialOffer.discountedPrice.type == 3">£{{specialOffer.fullPrice.value | number:"1.2-2"}}</span>
                {{"£" + (specialOffer.discountedPrice.value | number:"1.2-2")}}
            </span>
            <span class="value no-price" *ngIf="specialOffer.discountedPrice.type == 4">
                {{specialOffer.discountedPrice.label}}
                <span class="please-call">Please call {{companyStoreService?.branch?.phoneNumber}}</span>
            </span>
            <a *ngIf="specialOffer.discountedPrice.type != 4" href="javascript:void(0)" [ngClass]="{'btn-primary': !basketStoreService.specialOfferSelected(specialOffer), 'btn-success': basketStoreService.specialOfferSelected(specialOffer)}" class="btn btn-sm btn-primary" (click)="addRemoveSpecialOffer(specialOffer)">
                <i class="fas fa-plus" [hidden]="basketStoreService.specialOfferSelected(specialOffer)"></i>
                <i class="fas fa-check" [hidden]="!basketStoreService.specialOfferSelected(specialOffer)"></i>
                <span [hidden]="basketStoreService.specialOfferSelected(specialOffer)">{{config.buttonText}}</span>
            </a>
        </div>
    </div>
</ng-template>
