import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ISpecialOffer } from 'src/app/interfaces/special-offer.interface';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SpecialOfferService {

    constructor(private http: HttpClient) { }

    get(branchGuid: string, vehicleMake: string, fuelTypeID: number, engineCC: number, getNextAvailableSlotDates:boolean) {
        let params: any = {};
        if (branchGuid) { params.branchGuid = branchGuid; }
        if (vehicleMake) { params.vehicleMake = vehicleMake; }
        if (fuelTypeID) { params.fuelTypeID = fuelTypeID; }
        if (engineCC) { params.engineCC = engineCC; }
        if (getNextAvailableSlotDates) { params.getNextAvailableSlotDates = getNextAvailableSlotDates; }

        const queryString = Object.keys(params).map(function(key) {
            return key + '=' + params[key]
        }).join('&');

        return this.http.get<ISpecialOffer[]>(`${environment.apiUrl}/Api/SpecialOffers/Get?${queryString}`).pipe(shareReplay(1));;
    }
}
