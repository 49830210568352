import { Inject, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthStoreService } from './auth-store.service';
import { CompanyStoreService } from '../company/company-store.service';
import { AuthService } from './auth.service';
import { LocalStorageStoreService } from '../local-storage/local-storage-store.service';
import { LoggerStoreService } from '../logger/logger-store.service';
import { ConfigStoreService } from '../config/config-store.service';
import { LocalStorageKey } from 'src/app/global/enums';
import { WINDOW } from 'src/app/app.module';
import { ClientService } from '../client/client.service';
import { EMPTY } from 'rxjs';

declare var companyGuid;
declare var bookingSystemId;

@Injectable({
	providedIn: 'root',
})
export class AuthResolverService implements Resolve<any> {
	constructor(
		private authStoreService: AuthStoreService,
		private localStorageStoreService: LocalStorageStoreService,
		private configStoreService: ConfigStoreService,
		private clientService: ClientService,
		private loggerStoreService: LoggerStoreService,
		private companyStoreService: CompanyStoreService,
		@Inject(WINDOW) private window: Window
	) {
		this.loggerStoreService.log('Auth Resolver Initialised');
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// set device info
		this.clientService.clientInfo.startingURL = this.getParameterByName('startingUrl') || this.window.location.href;

		this.authStoreService.clientId = route?.params?.companyGuid;
		this.loggerStoreService.log('Auth Resolver: Company guid from route: ' + route?.params?.companyGuid);
		this.configStoreService.configId = route?.params?.bookingSystemId;
		this.loggerStoreService.log('Auth Resolver: Booking system ID from route: ' + route?.params?.bookingSystemId);

		this.authStoreService.init();

		// Deal with company guid etc... at this point.
		const localStorageClientID = this.localStorageStoreService.get(LocalStorageKey.ClientID, this.authStoreService.clientId);
		if (localStorageClientID && localStorageClientID != this.authStoreService.clientId) {
			// We have a client ID in local storage, but it doesn't match the client ID that we're currently using.
			// So we'll clear out everything in local storage so that the app can start fresh.
			this.localStorageStoreService.clearLocalStorage(this.authStoreService.clientId, true);
			this.loggerStoreService.log('Auth Store Service: Client ID is different to the one stored in local storage. Clearing localstorage.');
		} else {
			// There's no client ID in local storage, so we'll set it.
			if (!localStorageClientID) {
				this.localStorageStoreService.set(LocalStorageKey.ClientID, this.authStoreService.clientId, this.authStoreService.clientId);
			}
			this.loggerStoreService.log('Auth Store Service: Setting clientID: ' + this.authStoreService.clientId);
		}
		this.authStoreService.passwordResetKey = this.getParameterByName('passwordResetKey') || '';
		this.configStoreService.startingBranchGuid = this.getParameterByName('branchGuid') || '';
		this.configStoreService.startingVehicleType = this.getParameterByName('vehicleType') || '';
		this.configStoreService.startingVehicleRegistration = this.getParameterByName('vehicleRegistration') || '';
		this.configStoreService.startingTyreVehicleRegistration = this.getParameterByName('tyreVehicleRegistration') || '';
		this.configStoreService.startingTyreWidth = this.getParameterByName('tyreWidth') || '';
		this.configStoreService.startingTyreProfile = this.getParameterByName('tyreProfile') || '';
		this.configStoreService.startingTyreDiameter = this.getParameterByName('tyreDiameter') || '';
		this.configStoreService.startingTyreSpeed = this.getParameterByName('tyreSpeed') || '';

		this.configStoreService.startingSlotType = this.getParameterByName('slotTypeID') || '';
		this.configStoreService.startingSpecialOffer = this.getParameterByName('specialOfferID') || '';

		return this.authStoreService
			.getToken()
			.then(() => {
				return this.companyStoreService.getCompany();
			})
			.catch((response) => {
				return EMPTY;
			});
	}

	getParameterByName(name, url = '') {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}
}
