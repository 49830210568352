import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetVrmLookupComponent } from './widget-vrm-lookup/widget-vrm-lookup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { VehicleModule } from '../vehicle/vehicle.module';
import { CompanyModule } from '../company/company.module';
import { WidgetBookingSystemLinkComponent } from './widget-booking-system-link/widget-booking-system-link.component';
import { WidgetTyreSizeComponent } from './widget-tyre-size/widget-tyre-size.component';
import { WidgetVehicleFeedComponent } from './widget-vehicle-feed/widget-vehicle-feed.component';
import { WidgetMOTReminderComponent } from './widget-mot-reminder/widget-mot-reminder.component';
import { WidgetServicesComponent } from './widget-services/widget-services.component';
import { WidgetServicesListComponent } from './widget-services-list/widget-services-list.component';
import { WidgetServiceChecklistComponent } from './widget-service-checklist/widget-service-checklist.component';

import {
    RECAPTCHA_SETTINGS,
    RecaptchaModule,
    RecaptchaSettings,
    RecaptchaFormsModule,
} from 'ng-recaptcha';
import { EqualHeightDirective } from 'src/app/directives/equal-height/equal-height.directive';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SlotTypeModule } from '../slot-type/slot-type.module';

const reCaptchaSettings: RecaptchaSettings = { siteKey: '6Ldp8uMUAAAAAL1V9oP3JrFABy1oqK7aic4j_mQk' };

@NgModule({
	declarations: [
		WidgetVrmLookupComponent,
		WidgetTyreSizeComponent,
        WidgetVehicleFeedComponent,
		WidgetBookingSystemLinkComponent,
		WidgetMOTReminderComponent,
		WidgetServicesComponent,
		WidgetServicesListComponent,
		WidgetServiceChecklistComponent
	],
	imports: [
		CommonModule,
		MaterialModule,
		PipesModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
		SlotTypeModule,
        VehicleModule,
		CompanyModule,
        RecaptchaModule,
        RecaptchaFormsModule,
		DirectivesModule
	],
	exports: [
		WidgetVrmLookupComponent,
		WidgetTyreSizeComponent,
        WidgetVehicleFeedComponent,
		WidgetBookingSystemLinkComponent,
		WidgetMOTReminderComponent
	],
	providers: [
		{ provide: RECAPTCHA_SETTINGS, useValue: reCaptchaSettings },
	]
})
export class WidgetsModule { }
