import { Injectable, Injector, ɵɵsetComponentScope } from '@angular/core';
import { ICustomer, ICustomerConsent, IConsentItem } from 'src/app/interfaces/customer.interface';
import { BehaviorSubject } from 'rxjs';
import { LoggerStoreService } from '../logger/logger-store.service';
import { CustomerService } from './customer.service';
import { CompanyStoreService } from '../company/company-store.service';
import { BasketService } from '../basket/basket.service';
import { BasketStoreService } from '../basket/basket-store.service';
import { UntypedFormGroup } from '@angular/forms';
import { CustomerModel } from 'src/app/models/customer.model';
import { ICustomerBooking } from 'src/app/interfaces/customer-booking.interface';
import { ConfigStoreService } from '../config/config-store.service';
import { IMOTVehicle } from '../../interfaces/vehicle.interface';
import { ICustomerCreateMOTReminder } from '../../interfaces/create-MOT-reminder.interface';

@Injectable({
	providedIn: 'root',
})
export class CustomerStoreService {
	constructor(
		private loggerStoreService: LoggerStoreService,
		private companyStoreService: CompanyStoreService,
		private configStoreService: ConfigStoreService,
		private customerService: CustomerService,
		private injector: Injector
	) {
		this.customer = new CustomerModel();
	}

	private readonly _customer = new BehaviorSubject<ICustomer>({} as ICustomer);
	readonly customer$ = this._customer.asObservable();

	get customer(): ICustomer {
		return this._customer.getValue();
	}

	set customer(val: ICustomer) {
		this._customer.next(val);
	}

	resetCustomer() {
		this.customer = new CustomerModel();
	}

	async getCustomer(branchGuid: string) {
		if (!branchGuid) {
			this.loggerStoreService.log(`Customer Store Service: No Branch Guid.`);
			return;
		}

		this.customer = await this.customerService.get(branchGuid).toPromise();
		this.getCustomerConsent(this.companyStoreService.branch.guid);
		this.loggerStoreService.log(`Customer Service: Received customer.`);
		this.loggerStoreService.table(this.customer);
	}

	updateCustomerFromForm(form: UntypedFormGroup) {
		if (form.controls.customerTitleFormControl) {
			this.customer.title = form.controls.customerTitleFormControl.value || '';
		}
		if (form.controls.customerEmailAddressFormControl) {
			this.customer.email = form.controls.customerEmailAddressFormControl.value || '';
		}
		if (form.controls.customerFirstNameFormControl) {
			this.customer.firstName = form.controls.customerFirstNameFormControl.value || '';
		}
		if (form.controls.customerSurnameFormControl) {
			this.customer.surname = form.controls.customerSurnameFormControl.value || '';
		}
		if (form.controls.customerCompanyFormControl) {
			this.customer.company = form.controls.customerCompanyFormControl.value || '';
		}
		if (form.controls.customerMobileNumberFormControl) {
			this.customer.mobile = form.controls.customerMobileNumberFormControl.value || '';
		}
		if (form.controls.customerLandlineNumberFormControl) {
			this.customer.phone = form.controls.customerLandlineNumberFormControl.value || '';
		}
		if (form.controls.customerAddress1FormControl) {
			this.customer.address1 = form.controls.customerAddress1FormControl.value || '';
		}
		if (form.controls.customerAddress2FormControl) {
			this.customer.address2 = form.controls.customerAddress2FormControl.value || '';
		}
		if (form.controls.customerAddress3FormControl) {
			this.customer.address3 = form.controls.customerAddress3FormControl.value || '';
		}
		if (form.controls.customerTownCityFormControl) {
			this.customer.townCity = form.controls.customerTownCityFormControl.value || '';
		}
		if (form.controls.customerCountyFormControl) {
			this.customer.county = form.controls.customerCountyFormControl.value || '';
		}
		if (form.controls.customerPostcodeFormControl) {
			this.customer.postcode = form.controls.customerPostcodeFormControl.value || '';
		}
	}

	async checkEmailExists(emailAddress: string) {
		if (!emailAddress) return;
		const exists = await this.customerService.checkEmailExists(this.companyStoreService.branch.guid, emailAddress).toPromise();
		return exists;
	}

	checkValidAddress() {
		if (this.customer.address1 && this.customer.townCity && this.customer.postcode) {
			return true;
		} else {
			return false;
		}
	}

	async updateCustomer(customer: ICustomer = this.customer) {
		await this.customerService.update(this.companyStoreService.branch.guid, customer, false).toPromise();
	}

	async updateCustomerPassword(oldPassword: string, newPassword: string) {
		const password = {
			oldPassword: oldPassword,
			newPassword: newPassword,
		};
		return await this.customerService.updatePassword(this.companyStoreService.branch.guid, password).toPromise();
	}

	async recoverPassword(branchGuid: string, emailAddress: string, requestUrl: string, clientId: string) {
		// Client ID is passed in from component, as using the auth service in here causes circular dep issue.
		if (!requestUrl) {
			requestUrl = window.location.href.split('#')[0] + '%23/' + clientId + '/' + this.configStoreService.configId;
		}
		await this.customerService.requestPasswordRecoveryEmail(emailAddress, branchGuid, requestUrl, clientId).toPromise();
	}

	async resetPassword(newPassword: string, key: string) {
		await this.customerService.resetPassword('', newPassword, key).toPromise();
	}

	private readonly _customerConsent = new BehaviorSubject<ICustomerConsent>({} as ICustomerConsent);
	readonly customerConsent$ = this._customerConsent.asObservable();

	get customerConsent(): ICustomerConsent {
		return this._customerConsent.getValue();
	}

	set customerConsent(val: ICustomerConsent) {
		this._customerConsent.next(val);
	}

	formattedCustomerConsent: any;

	async getCustomerConsent(branchGuid: string) {
		const basketStoreService = this.injector.get(BasketStoreService);
		const slotTypeIDs = basketStoreService.getSelectedSlotTypeIDs().join(',') || '';
		const bookingDateTime = basketStoreService.bookingDateTime?.format('YYYY-MM-DD') || null;
		this.customerConsent = await this.customerService.getCustomerConsent(branchGuid, slotTypeIDs, bookingDateTime).toPromise();
		this.customerConsent?.consentItems?.map((c) => {
			if (c) c.isTicked = c.hasConsent;
		});
		this.formattedCustomerConsent = this.formatCustomerConsent(this.customerConsent);
		this.loggerStoreService.log(`Customer Service: Received customer consent.`);
		this.loggerStoreService.table(this.customer);
	}

	setCustomerConsent(consentLogItems: any) {
		this.customer.consentLogItems = consentLogItems;
	}

	getConsentLogItems(groups: any[]) {
		const consentLogItems = [];
		groups.forEach((group) => {
			group.communicationTypes.forEach((communicationType) => {
				consentLogItems.push(communicationType);
			});
		});

		return consentLogItems;
	}

	public formatedCustomerAddress(customer: ICustomer) {
		if (!customer) return '';
		let formattedAddress = '';
		formattedAddress += customer.address1 ? customer.address1 + ', ' : '';
		formattedAddress += customer.address2 ? customer.address2 + ', ' : '';
		formattedAddress += customer.address3 ? customer.address3 + ', ' : '';
		formattedAddress += customer.townCity ? customer.townCity + ' ' : '';
		formattedAddress += customer.postcode ? customer.postcode + ' ' : '';
		return formattedAddress;
	}

	public formatCustomerConsent(customerConsent: ICustomerConsent) {
		let uniqueCommunicationTypes: any = [];
		let customerConsentGroups: any = [];
		customerConsent?.consentItems.forEach((consentItem: IConsentItem) => {
			let index: any = uniqueCommunicationTypes.indexOf(consentItem.communicationType);
			if (index == -1) {
				uniqueCommunicationTypes.push(consentItem.communicationType);
				let preamble: any = consentItem.communicationType === 'Reminders' ? customerConsent.remindersPreamble : customerConsent.marketingPreamble;
				let label: any = consentItem.communicationMethod === 'Email' ? customerConsent.emailLabel : customerConsent.smsLabel;
				let group: any = {
					title: consentItem.communicationType,
					preamble: preamble,
					communicationTypes: [],
					optedIn: consentItem.hasConsent,
					label: customerConsent.emailLabel,
				};
				group.communicationTypes.push(consentItem);
				customerConsentGroups.push(group);
			} else {
				for (var i: number = 0; i < customerConsentGroups.length; i++) {
					if (customerConsentGroups[i].title === consentItem.communicationType) {
						customerConsentGroups[i].communicationTypes.push(consentItem);
						if (customerConsentGroups[i].optedIn && !consentItem.hasConsent) {
							customerConsentGroups[i].optedIn = false;
						}
						break;
					}
				}
			}
		});
		customerConsent.groups = customerConsentGroups;

		return customerConsent;
	}

	private readonly _customerBookings = new BehaviorSubject<ICustomerBooking[]>([]);
	readonly customerBookings$ = this._customerBookings.asObservable();

	get customerBookings(): ICustomerBooking[] {
		return this._customerBookings.getValue();
	}

	set customerBookings(val: ICustomerBooking[]) {
		this._customerBookings.next(val);
	}

	async getCustomerBookings(branchGuid: string) {
		if (!branchGuid) {
			return;
		}
		this.customerBookings = await this.customerService.getBookings(branchGuid).toPromise();
	}

	async createMOTReminder(branchGuid: string, vehicle: IMOTVehicle, firstName: string, surname: string, email?: string, phoneNumber?: string) {
		// check we have details, we should also have an email or a phonenumber, one will do but none should fail.
		if (!branchGuid || !firstName || !surname || !(email || phoneNumber)) {
			return Promise.reject(new Error('Missing paramaters'));
		}

		const dataContract: ICustomerCreateMOTReminder = {
			firstName: firstName,
			surname: surname,
			email: email,
			phoneNumber: phoneNumber,
			vehicle: vehicle,
		};

		console.log(dataContract);

		return this.customerService.createMOTReminder(branchGuid, dataContract).toPromise();
	}
}
