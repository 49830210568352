<div class="manual-input-wrapper">
	<label>Select your <strong>tyre size</strong>.</label>
	<div class="form-row form-fields tyre-sizes">
		<div class="col-3">
			<mat-form-field [appearance]="config.formAppearance">
				<mat-label>Width</mat-label>
				<mat-select
					(selectionChange)="selectWidth($event.value)"
					[(ngModel)]="selectedWidth"
					name="selectedWidth"
				>
					<mat-option
						*ngFor="let tyreWidth of tyreStoreService.tyreSizes"
						[value]="tyreWidth"
					>
						{{ tyreWidth.value }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-3">
			<mat-form-field [appearance]="config.formAppearance">
				<mat-label>Profile</mat-label>
				<mat-select
					(selectionChange)="selectProfile($event.value)"
					[disabled]="!selectedWidth"
					[(ngModel)]="selectedProfile"
					name="selectedProfile"
				>
					<mat-option
						*ngFor="let tyreProfile of selectedWidth?.profiles"
						[value]="tyreProfile"
					>
						{{ tyreProfile.value }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-3">
			<mat-form-field [appearance]="config.formAppearance">
				<mat-label>Rim</mat-label>
				<mat-select
					(selectionChange)="selectRim($event.value)"
					[disabled]="!selectedProfile"
					[(ngModel)]="selectedDiameter"
					name="selectedDiameter"
				>
					<mat-option
						*ngFor="let tyreDiameter of selectedProfile?.rims"
						[value]="tyreDiameter"
					>
						{{ tyreDiameter.value }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-3">
			<mat-form-field [appearance]="config.formAppearance">
				<mat-label>Speed</mat-label>
				<mat-select
					(selectionChange)="selectSpeed($event.value)"
					[disabled]="!selectedDiameter"
					[(ngModel)]="selectedSpeed"
					name="selectedSpeed"
				>
					<mat-option
						*ngFor="let tyreSpeed of selectedDiameter?.speeds"
						[value]="tyreSpeed"
					>
						{{ tyreSpeed.value }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
</div>
<button
	class="btn btn-lg btn-block btn-primary"
	(click)="saveTyreSize($event)"
	[disabled]="!selectedSpeed"
>
	{{ config.buttonText == "Continue" ? "Find tyres" : config.buttonText }}
</button>
