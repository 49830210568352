import { Component, OnInit, Input } from '@angular/core';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { Moment } from 'moment';
import * as moment from 'moment';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { Router } from '@angular/router';
import { TyreStoreService } from 'src/app/services/tyre/tyre-store.service';
import { SlotTypeType } from 'src/app/global/enums';

@Component({
    selector: 'app-basket',
    templateUrl: './basket.component.html',
    styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit {

    @Input() template: string;
    config: IComponentConfig;
    
    public slotTypeType = SlotTypeType; // Exposes Enum to template

    constructor(
        public basketStoreService: BasketStoreService,
        private configStoreService: ConfigStoreService,
        public tyreStoreService: TyreStoreService,
        public companyStoreService: CompanyStoreService,
        public router: Router) { }

    ngOnInit(): void { 
        this.config = this.configStoreService.getComponentConfig('BasketComponent');
        this.template = this.template ? this.template : this.config.template;
    }

    isBasketEmpty() {
        if (!this.basketStoreService.slotTypes.length &&
            !this.basketStoreService.specialOffers.length &&
            !this.basketStoreService.tyres.length) {
                return false;
            }
        return true;
    }

    showDelete() {
        if (this.router.url.includes('/booking/enter-details') || this.router.url.includes('/booking/select-date-time') || this.router.url.includes('/booking/add-extras')) {
            return false;
        }
        return true;
    }

}
