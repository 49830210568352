import { Component, OnInit, ChangeDetectorRef, Input, ElementRef } from '@angular/core';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { environment } from 'src/environments/environment';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { IBranch } from 'src/app/interfaces/branch.interface';
import { ICompany } from 'src/app/interfaces/company.interface';
import { VehicleType } from '../../../global/enums';

@Component({
	selector: 'app-widget-vrm-lookup',
	templateUrl: './widget-vrm-lookup.component.html',
	styleUrls: ['./widget-vrm-lookup.component.scss']
})
export class WidgetVrmLookupComponent implements OnInit {

	config: IComponentConfig;
	loading = true;
	vehicleRegistration = '';
	selectedBranch: IBranch;
	selectedVehicleType: VehicleType;
	company: ICompany;
	env: any;
	@Input() clientId: string;
	@Input() configId: number;
	@Input() fallback = false;

	eSelectedVehicleType = VehicleType;
	
	constructor(
		public companyStoreService: CompanyStoreService,		
		private authStoreService: AuthStoreService,
		private cd: ChangeDetectorRef,
		public configStoreService: ConfigStoreService,
		private element: ElementRef) {
			this.env = environment;
		}

	ngOnInit() {
		this.getCompany();
	}

	async getCompany() {
		if (this.clientId && this.configId) {
			// If we don't pass in a client ID and config ID, it's reasonable to assume this is being loaded within the app,
			// so the injected services will have the info we require.
			this.authStoreService.clientId = this.clientId;
			this.configStoreService.configId = +this.configId || 0;
			await this.authStoreService.getToken();
			await this.companyStoreService.getCompany();
			this.configStoreService.updateThemeColours();
		}
		this.config = this.configStoreService.getComponentConfig('WidgetVrmLookupComponent');

		setTimeout(() => {
			this.loading = false;
			this.cd.detectChanges();
		}, this.config.loadingPanelDelay);
	}

	getCompanyBranchName() {
		if (this.companyStoreService.company?.branches?.length > 1 && this.selectedBranch) {
			return this.companyStoreService.formatBranchName(this.companyStoreService.branch);
		} else {
			return this.companyStoreService?.company?.name;
		}
	}

	doVrm() {
		if (this.isButtonDisabled()) {
			return;
		}
		this.loading = true;
		let url = window.location.search;
			url = url.replace("?", '');
		let queryString = `?startingUrl=${window.location.href}&vehicleRegistration=${this.vehicleRegistration}`;

		if (this.selectedBranch?.guid) {
			queryString += `&branchGuid=${this.selectedBranch.guid}`;
		}	
		
		if (this.selectedVehicleType) {
			const selectedVehicleTypeID = this.eSelectedVehicleType[this.selectedVehicleType.toString().replace('vehicleTypes.', '')];
			queryString += `&vehicleType=${selectedVehicleTypeID}`;
		}
		queryString += `${url ? '&' + url: ''}`;
		
		if (this.fallback && (!this.configId || !this.clientId)) { 
			// Here, we are a fallback widget AND we are loaded within the iframe, so we are specifically going to target
			// the parent window.
			window.top.location.href = this.env.baseUrl + this.authStoreService.clientId + '/' + this.configStoreService.configId + queryString;
			
		} else if (this.fallback && this.element.nativeElement.parentElement.getBoundingClientRect().width < this.config.parentElementCutOff) {			
			// Here, we are a fallback widget and
			// the parent element is too small so redirect to hosted booking system please
			window.location.href = this.env.baseUrl + this.authStoreService.clientId + '/' + this.configStoreService.configId + queryString;
		} else {
			if (window.innerWidth < this.config.mobileCutoff && this.config.mobileRedirect) {
				// Here, we redirect to a pure booking system url/route.
				window.location.href = this.env.baseUrl + this.authStoreService.clientId + '/' + this.configStoreService.configId + queryString;
			} else {
				window.location.href = this.config.bookingSystemUrl + queryString;
			}
		}
	}

	branchSelected(branch: IBranch) {
		this.selectedBranch = branch;
		this.cd.detectChanges();
	}

	vehicleTypeSelected(event) {
		this.selectedVehicleType = event.data.type;
		this.cd.detectChanges();
	}

	isButtonDisabled() {
		let disabled = false;

		if (!this.vehicleRegistration) {
			disabled = true;
		}

		if (this.companyStoreService.company?.branches?.length > 1 && !this.selectedBranch) {
			disabled = true;
		}

		if (this.configStoreService?.config?.selectVehicleType && !this.selectedVehicleType) {
			disabled = true;
		}

		return disabled;
	}
}
