import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import { environment } from 'src/environments/environment';

import { ICompany } from 'src/app/interfaces/company.interface';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { IBranch } from 'src/app/interfaces/branch.interface';

import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { VehicleFeedStoreService } from '../../../services/vehicle-feed/vehicle-feed-store.service';

@Component({
	selector: 'app-widget-vehicle-feed',
	templateUrl: './widget-vehicle-feed.component.html',
	styleUrls: ['./widget-vehicle-feed.component.scss'],
})
export class WidgetVehicleFeedComponent implements OnInit {
	config: IComponentConfig;
	loading = true;
	showLoadingPanel = false;
	searchType = 'vehicle';
	wide = false;

	selectedVehicleMake = 'Any';
	selectedVehicleModel = 'Any';
	selectedPriceMin = 'Any';
	selectedPriceMax = 'Any';

	company: ICompany;
	env: any;

	@Input() clientId: string;
	@Input() configId: number;
	@Input() fallback = false;
	@ViewChild('vehicleFeedLookup') vehicleFeedLookup: ElementRef;

	constructor(
		public companyStoreService: CompanyStoreService,
		public vehicleFeedStoreService: VehicleFeedStoreService,
		private authStoreService: AuthStoreService,
		private configStoreService: ConfigStoreService,
		private cd: ChangeDetectorRef,
		private element: ElementRef
	) {
		this.env = environment;
	}

	ngOnInit(): void {
		this.getCompany();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.calculateWidth();
	}

	calculateWidth() {
		const width = this.vehicleFeedLookup.nativeElement.offsetWidth;
		if (width > 1210) {
			this.wide = true;
		} else {
			this.wide = false;
		}
	}

	async getCompany() {
		try {
			this.authStoreService.clientId = this.clientId;
			this.configStoreService.configId = +this.configId || 0;
			await this.authStoreService.getToken();
			await this.companyStoreService.getCompany();
			this.configStoreService.updateThemeColours();

			this.config = this.configStoreService.getComponentConfig(
				'WidgetVehicleFeedComponent'
			);

			this.getVehicles();
			this.calculateWidth();

			setTimeout(() => {
				this.loading = false;
				this.cd.detectChanges();
			}, this.config.loadingPanelDelay);
		} catch (error) {
			this.loading = false;
			console.error(error);
		}
	}

	getVehicles(): void {
		this.vehicleFeedStoreService.initialiseService();
		if (this.companyStoreService?.branch.guid) {
			this.vehicleFeedStoreService.getVehicleFeed(this.companyStoreService.branch.guid);
		}
	}


	searchVehicleFeed(): void {
		this.showLoadingPanel = true;

		let url = window.location.search;
			url = url.replace("?", '');
		
		let params: any = {};

		if(this.selectedVehicleMake && 
			this.selectedVehicleMake != "Any"){
			params.make = this.selectedVehicleMake;
		}
		if(this.selectedVehicleModel &&
			this.selectedVehicleModel != "Any"){
			params.model= this.selectedVehicleModel;
		}
		if(this.selectedPriceMin &&
			this.selectedPriceMin != "Any"){
			params.pricemin= this.selectedPriceMin;
		}
		if(this.selectedPriceMax &&
			this.selectedPriceMax != "Any"){
			params.pricemax= this.selectedPriceMax;
		}

        let queryString = Object.keys(params).map((key) => {
            return key + '=' + params[key]
        }).join('&');


		queryString += `&startingUrl=${window.location.href}`;

		setTimeout(() => {
			if(this.fallback && this.element.nativeElement.parentElement.getBoundingClientRect().width < this.config.parentElementCutOff)
			{
				// Here, we are a fallback widget and
				// the parent element is too small so redirect to pure booking system please
				window.location.href = this.env.baseUrl + this.authStoreService.clientId + '/' + this.configStoreService.configId + queryString;
			} 
			else 
			{
				if (window.innerWidth < this.config.mobileCutoff && this.config.mobileRedirect) {
					// Here, we redirect to a pure booking system url/route.
					window.location.href = `${this.env.baseUrl}${this.authStoreService.clientId}/${this.configStoreService.configId}?${queryString}`;
				} else {
					// TODO:
					// vehicleFeedUrl Config
					window.location.href = `${this.config.vehicleFeedUrl}?${queryString}`;
				}
			}
		}, this.config.loadingPanelDelay*2); 
	}

	disableModel(): string {
		// tslint:disable-next-line: no-string-literal
		if (this.vehicleFeedStoreService.filterDisplay['disableModel']) {
			return '';
		} else {
			return null;
		}
	}

	public filterVehicleMake(vehicleMake: string): void {
		this.selectedVehicleMake = 'Any';
		this.vehicleFeedStoreService.vehicleModels = [];
		this.selectedVehicleModel = 'Any';
		// tslint:disable-next-line: no-string-literal
		this.vehicleFeedStoreService.filterDisplay['disableModel'] = true;

		if (vehicleMake && vehicleMake != 'Any') 
		{
			this.selectedVehicleMake = vehicleMake.toLowerCase();

			this.vehicleFeedStoreService.vehicleFeedFull.stock
			.filter(
				(c) =>
					c.vehicle.make.toLowerCase() ===
					vehicleMake.toLowerCase()
			)
			.map((c) => c.vehicle.model)
			.forEach((c) => {
				this.vehicleFeedStoreService.vehicleModels.push(c.toLowerCase());
			});
			
	
			if (this.vehicleFeedStoreService.vehicleModels.length > 0) {
				// tslint:disable-next-line: no-string-literal
				this.vehicleFeedStoreService.filterDisplay['disableModel'] = false;
			}
		} 
	}
}
