import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-dialog-info',
	templateUrl: './dialog-info.component.html',
	styleUrls: ['./dialog-info.component.scss']
})
export class DialogInfoComponent implements OnInit {

	@Input() data;

	constructor(public modal: NgbActiveModal) { }

	ngOnInit(): void { }

}
