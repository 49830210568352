<div class="modal-header mst">
    <h4 class="modal-title">{{data.title}}</h4>
    <button class="btn-close-dialog" (click)="modal.dismiss('Closed modal')">
        <i class="fas fa-times"></i>
    </button>
</div>
<div mat-dialog-content *ngIf="data.html" class="modal-body mst" [innerHTML]="data.innerHtml">
 
</div>
<div mat-dialog-content *ngIf="!data.html" class="modal-body mst content">
    {{data.innerHtml}}
</div>
<div class="modal-footer mst">
    <button type="button" class="btn btn-default" (click)="modal.close(true)">Close</button>
</div>
