<h4>Your existing bookings</h4>

<div class="manage-bookings">

    <div class="booking" *ngFor="let booking of customerStoreService.customerBookings">
        <div class="header">
            <div class="booking-id">
                <label>Booking ID</label>
                {{booking.id}}
            </div>
            <div class="booking-date">
                <label>Booking date & time</label>
                {{formatBookingDateTime(booking) | moment:'ddd, MMMM Do h:mma'}}
            </div>
            <div class="vehicle">
                <label>Vehicle</label>
                {{booking.vehicleMake}} {{booking.vehicleModel}} {{booking.vehicleRegistration | uppercase}}
            </div>
        </div>
        <div class="item" *ngFor="let item of booking.shoppingBasketItems">
            <div class="part-name">
                {{item.partName}}
            </div>
            <div class="quantity">
                {{item.quantity}}
            </div>
            <div class="price">
                <span *ngIf="(item.discountNetPrice + item.discountVat > 0)">£{{(item.discountNetPrice + item.discountVat) | number:"1.2-2"}}</span>
                <span *ngIf="(item.discountNetPrice + item.discountVat === 0)">£{{(item.fullNetPrice + item.fullVat) | number:"1.2-2"}}</span>
            </div>
        </div>
        <div class="footer">
           <span>Total:</span> £{{getBookingTotal(booking) | number:"1.2-2"}}
        </div>
    </div>

</div>