import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { ISlotRange } from 'src/app/interfaces/slot.interface';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';

@Component({
	selector: 'app-checkout-select-date-time',
	templateUrl: './checkout-select-date-time.component.html',
	styleUrls: ['./checkout-select-date-time.component.scss']
})
export class CheckoutSelectDateTimeComponent implements OnInit {

	config: IComponentConfig;

	constructor(
		public basketStoreService: BasketStoreService,
		private configStoreService: ConfigStoreService,
		public companyStoreService: CompanyStoreService) { }

	ngOnInit(): void {
		this.config = this.configStoreService.getComponentConfig('CheckoutSelectDateTimeComponent');
	}

	getMonthName(monthNumber: number) {
		switch (monthNumber) {
			case 1:
				return 'January';
			case 2:
				return 'February';
			case 3:
				return 'March';
			case 4:
				return 'April';
			case 5:
				return 'May';
			case 6:
				return 'June';
			case 7:
				return 'July';
			case 8:
				return 'August';
			case 9:
				return 'September';
			case 10:
				return 'October';
			case 11:
				return 'November';
			case 12:
				return 'December';
			default:
				return '';
		}
	}

	selectSlot($event) {
		this.basketStoreService.selectBookingSlotRange($event.value);
	}

}
