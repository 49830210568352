import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';

const material = [
	MatInputModule,
	MatIconModule,
	MatButtonModule,
	MatSelectModule,
	MatRadioModule,
	MatButtonToggleModule,
	MatAutocompleteModule,
	MatProgressSpinnerModule,
	MatSliderModule,
	MatDatepickerModule,
	MatTooltipModule,
	MatMomentDateModule,
	MatCheckboxModule,
	MatSlideToggleModule,
	MatButtonModule,
	MatIconModule,
	MatTooltipModule,
	MatFormFieldModule,
	MatDialogModule,
	MatDividerModule,
	MatChipsModule,
	MatSnackBarModule,
	MatMenuModule,
	MatProgressBarModule,
	MatRippleModule,
	MatSidenavModule,
	MatSortModule,
	MatTableModule,
	MatTabsModule,
	MatStepperModule,
	MatPaginatorModule,
];

@NgModule({
	declarations: [],
	imports: [CommonModule, ...material],
	providers: [
		{ provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
		{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
	],
	exports: [...material],
})
export class MaterialModule {}
