import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, CanLoad, Route, UrlSegment } from '@angular/router';
import { StepSelectLocationComponent } from './components/steps/step-select-location/step-select-location.component';
import { StepSelectServicesComponent } from './components/steps/step-select-services/step-select-services.component';
import { StepAddExtrasComponent } from './components/steps/step-add-extras/step-add-extras.component';
import { StepSelectDateTimeComponent } from './components/steps/step-select-date-time/step-select-date-time.component';
import { StepEnterDetailsComponent } from './components/steps/step-enter-details/step-enter-details.component';
import { StepSelectVehicleComponent } from './components/steps/step-select-vehicle/step-select-vehicle.component';
import { StepBookingConfirmationComponent } from './components/steps/step-booking-confirmation/step-booking-confirmation.component';
import { AuthResolverService } from 'src/app/services/auth/auth-resolver.service';
import { AccountManageBookingsComponent } from './components/account/account-manage-bookings/account-manage-bookings.component';
import { AccountEditCustomerComponent } from './components/account/account-edit-customer/account-edit-customer.component';
import { AccountChangePasswordComponent } from './components/account/account-change-password/account-change-password.component';
import { AccountPasswordResetComponent } from './components/account/account-password-reset/account-password-reset.component';
import { LayoutMainComponent } from './components/layout/layout-main/layout-main.component';
import { VehicleFeedComponent } from './components/vehicle-feed/vehicle-feed/vehicle-feed.component';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VehicleFeedDetailComponent } from './components/vehicle-feed/vehicle-feed-detail/vehicle-feed-detail.component';
import { VehicleFeedListComponent } from './components/vehicle-feed/vehicle-feed-list/vehicle-feed-list.component';
import { ValidBasketGuard } from './guards/valid-basket.guard';
import { StepSelectVehicleTypeComponent } from './components/steps/step-select-vehicle-type/step-select-vehicle-type.component';
import { StepBasketComponent } from './components/steps/step-basket/step-basket.component';

declare var enableHashing;

const routes: Routes = [
    {
        path: ":companyGuid/:bookingSystemId",
        component: LayoutMainComponent,
        children: [
            {
                path: "booking/select-location",
                component: StepSelectLocationComponent
            },
            {
                path: "booking/select-vehicle-type",
                component: StepSelectVehicleTypeComponent
            },
            {
                path: "booking/select-services",
                component: StepSelectServicesComponent
            },
            {
                path: "booking/select-vehicle",
                component: StepSelectVehicleComponent
            },
            {
                path: "booking/basket",
                component: StepBasketComponent
            },
            {
                path: "booking/add-extras",
                component: StepAddExtrasComponent
            },
            {
                path: "booking/select-date-time",
                component: StepSelectDateTimeComponent,
                canActivate: [ValidBasketGuard],
                canLoad: [ValidBasketGuard]         
            },
            {
                path: "booking/enter-details",
                component: StepEnterDetailsComponent,
                canActivate: [ValidBasketGuard],
                canLoad: [ValidBasketGuard]            
            },
            {
                path: "booking/confirmation",
                component: StepBookingConfirmationComponent
            },
            {
                path: "account/manage-bookings",
                component: AccountManageBookingsComponent
            },
            {
                path: "account/edit-customer",
                component: AccountEditCustomerComponent
            },
            {
                path: "account/change-password",
                component: AccountChangePasswordComponent
            },
            {
                path: "account/password-reset/:passwordResetKey",
                component: AccountPasswordResetComponent
            }
        ],
        resolve: { auth: AuthResolverService }
    },
    {   
        path: ":companyGuid/:bookingSystemId/vehicle",
        component: VehicleFeedComponent,
        children:[
            {
                path: "list",
                component: VehicleFeedListComponent
            },
            {
                path: ":stockItemID",
                component: VehicleFeedDetailComponent
            }
        ],
        resolve: { auth: AuthResolverService }
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
