import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vehicle-feed-features-icons',
  templateUrl: './vehicle-feed-features-icons.component.html',
  styleUrls: ['./vehicle-feed-features-icons.component.scss']
})
export class VehicleFeedFeaturesIconsComponent implements OnInit {

  showDefault = true;
  showBluetooth = false;
  
  @Input() value: string;

  constructor() { }

  ngOnInit(): void {
    this.showCorrectImage();
  }

  showCorrectImage() {
    switch (true) {
      case this.value.toLowerCase().includes("bluetooth"):
        this.showBluetooth = true;
        this.showDefault = false;
        break;
      default:
        break;
    };
  }
}
