<ng-container [ngSwitch]="template">
  
    <ng-container *ngSwitchCase="'dropdown'">
        <mat-form-field [appearance]="config.formAppearance">
            <mat-label>Select a vehicle type</mat-label>
            <mat-select (selectionChange)="selectVehicleType($event, $event.value)">
                <mat-option value="vehicleTypes.Car" *ngIf="config.vehicleTypeCarEnabled">
                    {{config.vehicleTypeCarTitle}}
                </mat-option>
                <mat-option value="vehicleTypes.Bike" *ngIf="config.vehicleTypeBikeEnabled">
                    {{config.vehicleTypeBikeTitle}}
                </mat-option>
                <mat-option value="vehicleTypes.Van" *ngIf="config.vehicleTypeVanEnabled">
                    {{config.vehicleTypeVanTitle}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'list'">
        <div class="form-row cards" equalHeight="description">
            
            <div class="col card" *ngIf="config.vehicleTypeCarEnabled">
                <div class="vehicle-type-list-item text-center">
                    <div class="icon">
                        <img src="{{config.vehicleTypeCarImage}}" />
                    </div>
                    <div class="description">
                        <h5 class="text-truncate font-weight-bold">{{config.vehicleTypeCarTitle}}</h5>
                        <p class="text-muted">{{config.vehicleTypeCarDescription}}</p>
                        <div>
                            <button class="btn btn-primary" (click)="selectVehicleType($event, vehicleTypes.Car)">{{config.buttonText}}</button>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div class="col card" *ngIf="config.vehicleTypeVanEnabled">
                <div class="vehicle-type-list-item text-center">
                    <div class="icon">
                        <img src="{{config.vehicleTypeVanImage}}" />
                    </div>
                    <div class="description">
                        <h5 class="text-truncate font-weight-bold">{{config.vehicleTypeVanTitle}}</h5>
                        <p class="text-muted">{{config.vehicleTypeVanDescription}}</p>
                        <div>
                            <button class="btn btn-primary" (click)="selectVehicleType($event, vehicleTypes.Van)">{{config.buttonText}}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col card" *ngIf="config.vehicleTypeBikeEnabled">
                <div class="vehicle-type-list-item text-center">
                    <div class="icon">
                        <img src="{{config.vehicleTypeBikeImage}}" />
                    </div>
                    <div class="description">
                        <h5 class="text-truncate font-weight-bold">{{config.vehicleTypeBikeTitle}}</h5>
                        <p class="text-muted">{{config.vehicleTypeBikeDescription}}</p>
                        <div>
                            <button class="btn btn-primary" (click)="selectVehicleType($event, vehicleTypes.Bike)">{{config.buttonText}}</button>
                        </div>
                    </div>
                </div>
            </div>
             
        </div>
    </ng-container>

</ng-container>
