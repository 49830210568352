import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CustomerStoreService } from 'src/app/services/customer/customer-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { LoggerStoreService } from 'src/app/services/logger/logger-store.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-account-password-recovery',
	templateUrl: './account-password-recovery.component.html',
	styleUrls: ['./account-password-recovery.component.scss']
})
export class AccountPasswordRecoveryComponent implements OnInit {

	env: any;
	config: IComponentConfig;
	btnLoading = false;
	passwordRecoveryErrorResponse = '';
	passwordRecoveryForm: UntypedFormGroup;
	passwordRecoverySent = false;

	constructor(
		private customerStoreService: CustomerStoreService,
		private customerService: CustomerService,
		private authStoreService: AuthStoreService,
		private configStoreService: ConfigStoreService,
		private loggerStoreService: LoggerStoreService,
		public companyStoreService: CompanyStoreService) {
			this.env = environment;
		}

	ngOnInit(): void {
		this.config = this.configStoreService.getComponentConfig('AccountPasswordRecoveryComponent');
		this.passwordRecoveryForm = new UntypedFormGroup({
			'emailAddressFormControl': new UntypedFormControl(null, [Validators.required, Validators.email])
		});
	}

	recoverPassword() {
		
		if (!this.passwordRecoveryForm.valid || this.btnLoading) {return;}
		this.btnLoading = true;
		this.customerStoreService.recoverPassword(this.companyStoreService.branch.guid, this.passwordRecoveryForm.controls.emailAddressFormControl.value, this.config.passwordRecoveryUrl, this.authStoreService.clientId)
		.then(response => {
			this.passwordRecoveryForm.controls['emailAddressFormControl'].setErrors(null);
			this.passwordRecoverySent = true;
			this.btnLoading = false;
		 })
		.catch(httpErrorReponse => {
			this.loggerStoreService.table(httpErrorReponse);
			this.passwordRecoveryErrorResponse = httpErrorReponse?.error?.modelState?.errors.join('. ') + ".";
			this.passwordRecoveryForm.controls['emailAddressFormControl'].setErrors({ 'serverError': true });
			this.btnLoading = false;
		});
	}

}
