import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { VehicleType } from 'src/app/global/enums';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';
import { BasketStoreService } from '../../../services/basket/basket-store.service';

@Component({
    selector: 'app-vehicle-type',
    templateUrl: './vehicle-type.component.html',
    styleUrls: ['./vehicle-type.component.scss']
})
export class VehicleTypeComponent implements OnInit {

    vehicleTypes = VehicleType;

    config: IComponentConfig;
    columns: string;
    @Input() template: string;
    @Output() vehicleTypeSelected = new EventEmitter<any>();

    constructor(
        private vehicleStoreService: VehicleStoreService,
        private configStoreService: ConfigStoreService,
        private basketStoreService: BasketStoreService,
        private authStoreService: AuthStoreService,
        private router: Router) { }

    ngOnInit(): void {
        this.config = this.configStoreService.getComponentConfig('VehicleTypeComponent');
        // if a template is passed directly into the component, then use that, else, use the default template from config.
        this.template = this.template ? this.template : this.config.template;

        // if we show this component we need to clear the basket to stop users having invalid slot types in the basket
        // if this component is used anywhere that isn't the start/a widget, then this will need a check on it.
        // But that seems unlikely as this is to fix BUG 4723.
        this.basketStoreService.clearBasket();

    }

    selectVehicleType($event, type: VehicleType) {
        const event = {
            event: "User Selected",
            data: {
                type: type
            }
        }
        this.vehicleTypeSelected.emit(event);
    }

}
