<p>
	Showing {{vehicleFeedStoreService.pagedVehicleFeed.length}} Vehicles
</p>

<ng-container *ngIf="template == 'card'">
	<div *ngIf="!vehicleFeedStoreService.loading" class="form-row vehicle-feed-card-wrapper">
		<ng-template *ngFor="let stock of vehicleFeedStoreService.pagedVehicleFeed"
			[ngTemplateOutlet]="VehicleCardItem" [ngTemplateOutletContext]="{stock: stock}"></ng-template>
	</div>
	<div *ngIf="vehicleFeedStoreService.loading" class="form-row vehicle-feed-card-wrapper">
		<ng-template *ngFor="let number of [0,1,2,3,4,5,6,7,8]" [ngTemplateOutlet]="VehicleCardItemPlaceholder">
		</ng-template>
	</div>
</ng-container>

<mat-paginator (page)="vehicleFeedStoreService.pageChangeEvent($event)" [length]="vehicleFeedStoreService.pageLength"
	[pageSize]="vehicleFeedStoreService.pageSize" [pageSizeOptions]="vehicleFeedStoreService.pageSizeOptions">
</mat-paginator>

<ng-template #VehicleCardItemPlaceholder>
	<div class="vehicle-feed-card-item loading">

		<div class="vehicle-feed-image-wrapper">

		</div>

		<div class="vehicle-feed-details">
			<div class="manufacturer-logo-wrapper shimmer">
				loading
			</div>
			<div class="tread-pattern shimmer">
				loading
			</div>
			<div class="description shimmer">
				loading
			</div>
			<div class="label-wrapper shimmer">
				loading
			</div>
			<div class="vehicle-feed-icons-wrapper">
				<div class="icons shimmer">
					loading
				</div>
			</div>
		</div>

		<div class="enquire">
			<div class="spacer"></div>
			<div class="pricing-wrapper shimmer">
				<div class="pricing">
					<div class="price">
						loading
					</div>
					<div class="price-description">
					</div>
				</div>
			</div>
			<div class="btn-wrapper shimmer">
				<button class="btn btn-block btn-lg  btn-enquire">Loading</button>

			</div>
		</div>

	</div>
</ng-template>

<ng-template #VehicleCardItem let-stock="stock">
	<div class="col-sm-6 col-md-4">
		<div class="vehicle-feed-card-item">

			<div class="vehicle-feed-image-wrapper">
				<div [ngClass]="{'cover' : configStoreService.config.vehicleFeedCardImage == 'cover',
								 'contain' : configStoreService.config.vehicleFeedCardImage == 'contain',
								 'auto' : configStoreService.config.vehicleFeedCardImage == 'auto'}"
					class="vehicle-feed-image" style="background-image: url({{getImageUrl(stock.id)}})">
				</div>		
			</div>

  
			<div class="vehicle-feed-details">

				<div class="price">
					{{ stock.vehicle.price | currency:'GBP':'symbol':'.0' }}
				</div>

				<div class="make-model"> 
					<p>{{ stock.vehicle.make | titlecase }} {{ stock.vehicle.model | titlecase }} {{ stock.vehicle.derivative | titlecase }}</p> 
				</div>
				<div class="year-miles">
					<span class="years">{{ stock.vehicle.manufacturedYearString | titlecase }}</span> 
					<span *ngIf="stock.vehicle.mileage">|</span> 
					<span class="miles" *ngIf="stock.vehicle.mileage">{{ stock.vehicle.mileage | number:'':'en-GB'}}</span> 
					<span *ngIf="stock.vehicle.mileage">miles</span>
				</div>
				
			</div>

			<div class="enquire">
				<div class="btn-wrapper">
					<button class="btn btn-block btn-lg btn-primary btn-enquire"
						(click)="navigateToDetails(stock.stockItemID)"><p>View</p><i class="fas fa-chevron-right"></i></button>
				</div>
			</div>

		</div>
	</div>
</ng-template>
