import { Injectable } from '@angular/core';
import { Form, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { IAutoTraderDealer, IAutoTraderVehicleFeedEnquire, IAutoTraderVehicle, IAutoTraderStock } from 'src/app/interfaces/vehicle-feed.interface';
import { AuthStoreService } from '../auth/auth-store.service';
import { CompanyStoreService } from '../company/company-store.service';
import { ConfigStoreService } from '../config/config-store.service';
import { LoggerStoreService } from '../logger/logger-store.service';
import { VehicleFeedService } from './vehicle-feed.service';
import { ICustomer } from '../../interfaces/customer.interface';
import { CustomerModel } from 'src/app/models/customer.model';
import { Params } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class VehicleFeedStoreService {
	public loading = true;
	public sortSelection = 'lowToHigh';
	public pageLength: number;
	public pageSize = 18;
	public pageSizeOptions = [18, 27, 36];
	config: IComponentConfig;

	constructor(
		private companyStoreService: CompanyStoreService,
		private loggerStoreService: LoggerStoreService,
		private authStoreService: AuthStoreService,
		private configStoreService: ConfigStoreService,
		private vehicleFeedService: VehicleFeedService
	) {}

	public async initialiseService() {
		this.config = this.configStoreService.getComponentConfig('VehicleFeedStoreService');

		if (!this.companyStoreService.vehicleDealer) {
			this.loggerStoreService.log(`Vehicle Feed Store Service: No vehicle dealer`);
			return;
		}

		if (this.authStoreService?.authorizationData?.access_token) {
			await this.getVehicleFeed(this.companyStoreService.branch?.guid).then(() => {
				this.configureVehicleFeedFilters();
				this.checkFilterFromUrlParams();
				this.filterAndSortVehicles();
			});
		}

		this.loggerStoreService.log(`Vehicle Feed Store Service: Initialized`);
	}

	//#region Vehicle Feed Property
	private readonly _vehicleFeedFull = new BehaviorSubject<IAutoTraderDealer>(null);
	readonly vehicleFeedFull$ = this._vehicleFeedFull.asObservable();

	get vehicleFeedFull(): IAutoTraderDealer {
		return this._vehicleFeedFull.getValue();
	}

	set vehicleFeedFull(val: IAutoTraderDealer) {
		this._vehicleFeedFull.next(val);
	}
	//#endregion

	//#region Sort And Filtered Vehicle Feed Property
	public readonly _sortAndFilteredVehicleFeed = new BehaviorSubject<IAutoTraderStock[]>([]);
	readonly sortAndFilteredVehicleFeed$ = this._sortAndFilteredVehicleFeed.asObservable();

	get sortAndFilteredVehicleFeed(): IAutoTraderStock[] {
		return this._sortAndFilteredVehicleFeed.getValue();
	}

	set sortAndFilteredVehicleFeed(val: IAutoTraderStock[]) {
		this._sortAndFilteredVehicleFeed.next(val);
	}
	//#endregion

	//#region Paged Vehicle Feed Property
	public readonly _pagedVehicleFeed = new BehaviorSubject<IAutoTraderStock[]>([]);
	readonly pagedVehicleFeed$ = this._pagedVehicleFeed.asObservable();

	get pagedVehicleFeed(): IAutoTraderStock[] {
		return this._pagedVehicleFeed.getValue();
	}

	set pagedVehicleFeed(val: IAutoTraderStock[]) {
		this._pagedVehicleFeed.next(val);
	}
	//#endregion

	async getVehicleFeed(branchGuid: string) {
		if (this.vehicleFeedFull?.stock.length > 0) {
			return;
		}

		this.vehicleFeedFull = await this.vehicleFeedService.getAutoTraderVehicleFeed(this.companyStoreService.vehicleDealer.id).toPromise();

		this.loggerStoreService.log(`Vehicle Feed Store Service: Received ${this.vehicleFeedFull?.stock?.length} Vehicles.`);
		this.loggerStoreService.table(this.vehicleFeedFull.stock);
		this.filterAndSortVehicles();
	}

	public pageChangeEvent(event) {
		this.loading = true;
		const offset = (event.pageIndex + 1 - 1) * event.pageSize;
		this.pagedVehicleFeed = this.sortAndFilteredVehicleFeed.slice(offset).slice(0, event.pageSize);
		setTimeout(() => {
			this.loading = false;
		}, this.config.loadingPanelDelay);
	}

	//#region Filter Vehicle Feed
	//#region Filter Display
	public filterDisplay = {};
	public showFiltersMobile = false;
	//#endregion

	//#region Filter properties
	public vehicleMakes: string[] = [];
	public selectedVehicleMake: string;

	public vehicleModels: string[] = [];
	public selectedVehicleModel: string;

	// these are string types but are just numbers
	// this hacks around mat-select not changing its value when
	// selectedPriceMin/Max is set, functions surrounding these should convert them to
	// number (eg +this.selectedPriceMin)
	public vehiclePriceMins: string[];
	public selectedPriceMin: string;

	public vehiclePriceMaxs: string[];
	public selectedPriceMax: string;

	public vehicleMileageMins: number[];
	public selectedMileageMin: number;

	public vehicleMileageMaxs: number[];
	public selectedMileageMax: number;

	public vehicleGearboxes: string[] = [];
	public selectedVehicleGearbox: string;

	public vehicleFuelTypes: string[] = [];
	public selectedVehicleFuelType: string;

	public vehicleBodyTypes: string[] = [];
	public selectedVehicleBodyType: string;

	public vehicleEngineSizes: string[] = [];
	public selectedVehicleEngineSize: string;

	public vehicleDoors: number[] = [];
	public selectedVehicleDoors: number;

	public vehicleColours: string[] = [];
	public selectedVehicleColour: string;

	//#endregion

	public filterFromUrlParams: Params[] = [];

	//#region config/init
	configureVehicleFeedFilters() {
		//#region Filter Display
		// tslint:disable: no-string-literal
		this.filterDisplay['showMakeModel'] = true;
		this.filterDisplay['disableModel'] = true;
		this.filterDisplay['showPrice'] = true;
		this.filterDisplay['showMileage'] = true;
		this.filterDisplay['showGearbox'] = true;
		this.filterDisplay['showFuelType'] = true;
		this.filterDisplay['showBodyType'] = true;
		this.filterDisplay['showEngineSize'] = true;
		this.filterDisplay['showDoors'] = true;
		this.filterDisplay['showColour'] = true;
		// tslint:enable: no-string-literal
		//#endregion

		this.vehicleMakes = [];
		this.selectedVehicleMake = undefined;
		this.vehicleModels = [];
		this.selectedVehicleModel = undefined;
		this.vehicleGearboxes = [];
		this.selectedVehicleGearbox = undefined;
		this.vehicleFuelTypes = [];
		this.selectedVehicleFuelType = undefined;
		this.vehicleBodyTypes = [];
		this.selectedVehicleBodyType = undefined;
		this.vehicleEngineSizes = [];
		this.selectedVehicleEngineSize = undefined;
		this.vehicleDoors = [];
		this.selectedVehicleDoors = undefined;
		this.vehicleColours = [];
		this.selectedVehicleColour = undefined;

		this.vehiclePriceMins = [];
		this.selectedPriceMin = undefined;
		this.vehiclePriceMaxs = [];
		this.selectedPriceMax = undefined;

		this.vehicleMileageMins = [];
		this.selectedMileageMin = -1;
		this.vehicleMileageMaxs = [];
		this.selectedMileageMax = -1;
		this.vehicleFeedFull?.stock?.forEach((c) => {
			if (c.vehicle.make.toLowerCase() && !this.vehicleMakes.includes(c.vehicle.make.toLowerCase())) {
				this.vehicleMakes.push(c.vehicle.make.toLowerCase());
			}
			if (c.vehicle.transmission.toLowerCase() && !this.vehicleGearboxes.includes(c.vehicle.transmission.toLowerCase())) {
				this.vehicleGearboxes.push(c.vehicle.transmission.toLowerCase());
			}
			if (c.vehicle.fuelType.toLowerCase() && !this.vehicleFuelTypes.includes(c.vehicle.fuelType.toLowerCase())) {
				this.vehicleFuelTypes.push(c.vehicle.fuelType.toLowerCase());
			}
			if (c.vehicle.bodyType.toLowerCase() && !this.vehicleBodyTypes.includes(c.vehicle.bodyType.toLowerCase())) {
				this.vehicleBodyTypes.push(c.vehicle.bodyType.toLowerCase());
			}
			if (
				c.vehicle.engineSize &&
				`${c.vehicle.engineSize}${c.vehicle.engineSizeUnit.toLowerCase()}` &&
				!this.vehicleEngineSizes.includes(`${c.vehicle.engineSize}${c.vehicle.engineSizeUnit.toLowerCase()}`)
			) {
				this.vehicleEngineSizes.push(`${c.vehicle.engineSize}${c.vehicle.engineSizeUnit.toLowerCase()}`);
			}
			if (c.vehicle.doors > 0 && !this.vehicleDoors.includes(c.vehicle.doors)) {
				this.vehicleDoors.push(c.vehicle.doors);
			}
			if (c.vehicle.colour.toLowerCase() && !this.vehicleColours.includes(c.vehicle.colour.toLowerCase())) {
				this.vehicleColours.push(c.vehicle.colour.toLowerCase());
			}
			this.addVehiclePriceMins(c.vehicle);
			this.addVehiclePriceMaxs(c.vehicle);
			this.addVehicleMileageMins(c.vehicle);
			this.addVehicleMileageMaxs(c.vehicle);
		});

		this.vehicleMakes = this.vehicleMakes.sort((c1, c2) => this.sortAlphaNum(c1[0], c2[0]));
		this.vehicleGearboxes = this.vehicleGearboxes.sort((c1, c2) => this.sortAlphaNum(c1[0], c2[0]));
		this.vehicleFuelTypes = this.vehicleFuelTypes.sort((c1, c2) => this.sortAlphaNum(c1[0], c2[0]));
		this.vehicleBodyTypes = this.vehicleBodyTypes.sort((c1, c2) => this.sortAlphaNum(c1[0], c2[0]));
		this.vehicleEngineSizes = this.vehicleEngineSizes.sort((c1, c2) => this.sortAlphaNum(c1[0], c2[0]));
		this.vehicleDoors = this.vehicleDoors.sort((c1, c2) => this.sortAlphaNum(c1[0], c2[0]));
		this.vehicleColours = this.vehicleColours.sort((c1, c2) => this.sortAlphaNum(c1[0], c2[0]));

		this.fillVehiclePriceMins();
		this.fillVehiclePriceMaxs();
		this.vehiclePriceMins.sort((c1, c2) => +c1 - +c2);
		this.vehiclePriceMaxs.sort((c1, c2) => +c1 - +c2);

		this.fillVehicleMileageMins();
		this.fillVehicleMileageMaxs();
		this.vehicleMileageMins.sort((c1, c2) => c1 - c2);
		this.vehicleMileageMaxs.sort((c1, c2) => c1 - c2);
	}

	sortAlphaNum(a: string, b: string) {
		if (a && b) {
			const reA = /[^a-zA-Z]/g;
			const reN = /[^0-9]/g;
			const aA = a.replace(reA, '');
			const bA = b.replace(reA, '');
			if (aA === bA) {
				const aN = parseInt(a.replace(reN, ''), 10);
				const bN = parseInt(b.replace(reN, ''), 10);
				return aN === bN ? 0 : aN > bN ? 1 : -1;
			} else {
				return aA > bA ? 1 : -1;
			}
		}
		return 0;
	}

	public resetVehicleFeedFilters() {
		this.configureVehicleFeedFilters();
		this.filterAndSortVehicles();
	}

	resetVehicleFeedPriceFilters() {
		this.vehiclePriceMins = [];
		this.vehiclePriceMaxs = [];
		this.vehicleFeedFull.stock.forEach((c) => {
			this.addVehiclePriceMins(c.vehicle);
			this.addVehiclePriceMaxs(c.vehicle);
		});
	}

	resetVehicleFeedMileageFilters() {
		this.vehicleMileageMins = [];
		this.vehicleMileageMaxs = [];
		this.vehicleFeedFull.stock.forEach((c) => {
			this.addVehicleMileageMins(c.vehicle);
			this.addVehicleMileageMaxs(c.vehicle);
		});
	}

	addVehiclePriceMins(vehicle: IAutoTraderVehicle) {
		const priceRoundedDown = Math.floor(vehicle.price / 500) * 500;
		this.vehiclePriceMins.push(priceRoundedDown.toString());
	}

	addVehiclePriceMaxs(vehicle: IAutoTraderVehicle) {
		const priceRoundedUp = Math.ceil(vehicle.price / 500) * 500;
		this.vehiclePriceMaxs.push(priceRoundedUp.toString());
	}

	fillVehiclePriceMins(maxNumber?: number) {
		const largestNumber = this.vehiclePriceMins.sort((c1, c2) => -(+c1 - +c2))[0];
		const endingNumber = maxNumber ? maxNumber : largestNumber;
		this.vehiclePriceMins = [];
		for (let i = 0; i < endingNumber; i = i + 500) {
			this.vehiclePriceMins.push(i.toString());
		}
	}

	fillVehiclePriceMaxs(minNumber?: number) {
		const startingNumber = minNumber ? minNumber + 500 : 500;
		const largestNumber = this.vehiclePriceMaxs.sort((c1, c2) => -(+c1 - +c2))[0];
		this.vehiclePriceMaxs = [];
		for (let i = startingNumber; i < +largestNumber + 500; i = i + 500) {
			this.vehiclePriceMaxs.push(i.toString());
		}
	}

	addVehicleMileageMins(vehicle: IAutoTraderVehicle) {
		const mileageRoundedDown = Math.floor(vehicle.mileage / 500) * 500;
		this.vehicleMileageMins.push(mileageRoundedDown);
	}

	addVehicleMileageMaxs(vehicle: IAutoTraderVehicle) {
		const mileageRoundedUp = Math.ceil(vehicle.mileage / 500) * 500;
		this.vehicleMileageMaxs.push(mileageRoundedUp);
	}

	fillVehicleMileageMins(maxNumber?: number) {
		const largestNumber = this.vehicleMileageMins.sort((c1, c2) => -(c1 - c2))[0];
		const endingNumber = maxNumber ? maxNumber : largestNumber;
		this.vehicleMileageMins = [];
		for (let i = 0; i < endingNumber; i = i + 500) {
			this.vehicleMileageMins.push(i);
		}
	}

	fillVehicleMileageMaxs(minNumber?: number) {
		const startingNumber = minNumber ? minNumber + 500 : 500;
		const largestNumber = this.vehicleMileageMaxs.sort((c1, c2) => -(c1 - c2))[0];
		this.vehicleMileageMaxs = [];
		for (let i = startingNumber; i < largestNumber + 500; i = i + 500) {
			this.vehicleMileageMaxs.push(i);
		}
	}
	//#endregion

	//#region filter functions
	public filterVehicleMake(vehicleMake) {
		this.selectedVehicleMake = undefined;
		this.selectedVehicleModel = undefined;
		this.vehicleModels = [];
		this.filterDisplay['disableModel'] = true;

		if (vehicleMake) {
			this.selectedVehicleMake = vehicleMake.toLowerCase();

			this.vehicleFeedFull.stock
				.filter((c) => c.vehicle.make.toLowerCase() === vehicleMake.toLowerCase())
				.map((c) => c.vehicle.model)
				.forEach((c) => {
					this.vehicleModels.push(c.toLowerCase());
				});

			this.vehicleModels = Array.from(new Set(this.vehicleModels.sort((c1, c2) => this.sortAlphaNum(c1[0], c2[0]))));

			if (this.vehicleModels.length > 0) {
				// tslint:disable-next-line: no-string-literal
				this.filterDisplay['disableModel'] = false;
			}
		}

		setTimeout(() => {
			this.filterAndSortVehicles();
		}, 50);
	}

	public filterVehicleModel(vehicleModel) {
		this.selectedVehicleModel = undefined;
		if (vehicleModel) {
			this.selectedVehicleModel = vehicleModel.toLowerCase();
		}

		setTimeout(() => {
			this.filterAndSortVehicles();
		}, 50);
	}

	public filterVehiclePriceMin(vehiclePriceMin: string) {
		this.selectedPriceMin = vehiclePriceMin;
		if (!this.selectedPriceMax) {
			this.fillVehiclePriceMaxs(+this.selectedPriceMin);
		} else if (this.selectedPriceMax && this.selectedPriceMin) {
			this.resetVehicleFeedPriceFilters();
			this.fillVehiclePriceMins(+this.selectedPriceMax);
			this.fillVehiclePriceMaxs(+this.selectedPriceMin);
		}
		setTimeout(() => {
			this.filterAndSortVehicles();
		}, 50);
	}

	public filterVehiclePriceMax(vehiclePriceMax: string) {
		this.selectedPriceMax = vehiclePriceMax;
		if (!this.selectedPriceMin) {
			this.fillVehiclePriceMins(+this.selectedPriceMax);
		} else if (this.selectedPriceMax && this.selectedPriceMin) {
			this.resetVehicleFeedPriceFilters();
			this.fillVehiclePriceMins(+this.selectedPriceMax);
			this.fillVehiclePriceMaxs(+this.selectedPriceMin);
		}
		setTimeout(() => {
			this.filterAndSortVehicles();
		}, 50);
	}

	public filterVehicleMileageMin(vehicleMileageMin: number) {
		this.selectedMileageMin = vehicleMileageMin;
		if (this.selectedMileageMax === -1) {
			this.fillVehicleMileageMaxs(this.selectedMileageMin);
		} else if (this.selectedMileageMax > -1 && this.selectedMileageMin > -1) {
			this.resetVehicleFeedMileageFilters();
			this.fillVehicleMileageMins(this.selectedMileageMax);
			this.fillVehicleMileageMaxs(this.selectedMileageMin);
		}
		setTimeout(() => {
			this.filterAndSortVehicles();
		}, 50);
	}

	public filterVehicleMileageMax(vehicleMileageMax: number) {
		this.selectedMileageMax = vehicleMileageMax;
		if (this.selectedMileageMin === -1) {
			this.fillVehicleMileageMins(this.selectedMileageMax);
		} else if (this.selectedMileageMax > -1 && this.selectedMileageMin > -1) {
			this.resetVehicleFeedMileageFilters();
			this.fillVehicleMileageMins(this.selectedMileageMax);
			this.fillVehicleMileageMaxs(this.selectedMileageMin);
		}
		setTimeout(() => {
			this.filterAndSortVehicles();
		}, 50);
	}

	public filterVehicleGearbox(vehicleGearbox: string) {
		this.selectedVehicleGearbox = undefined;
		// filter
		if (vehicleGearbox) {
			this.selectedVehicleGearbox = vehicleGearbox.toLowerCase();
		}

		setTimeout(() => {
			this.filterAndSortVehicles();
		}, 50);
	}

	public filterVehicleFuelType(vehicleFuelType: string) {
		this.selectedVehicleFuelType = undefined;
		// filter
		if (vehicleFuelType) {
			this.selectedVehicleFuelType = vehicleFuelType.toLowerCase();
		}

		setTimeout(() => {
			this.filterAndSortVehicles();
		}, 50);
	}

	public filterVehicleBodyType(vehicleBodyType: string) {
		this.selectedVehicleBodyType = undefined;
		// filter
		if (vehicleBodyType) {
			this.selectedVehicleBodyType = vehicleBodyType.toLowerCase();
		}

		setTimeout(() => {
			this.filterAndSortVehicles();
		}, 50);
	}

	public filterVehicleEngineSize(vehicleEngineSize: string) {
		this.selectedVehicleEngineSize = undefined;
		// filter
		if (vehicleEngineSize) {
			this.selectedVehicleEngineSize = vehicleEngineSize.toLowerCase();
		}

		setTimeout(() => {
			this.filterAndSortVehicles();
		}, 50);
	}

	public filterVehicleDoors(vehicleDoor: number) {
		this.selectedVehicleDoors = undefined;
		// filter
		if (vehicleDoor) {
			this.selectedVehicleDoors = vehicleDoor;
		}

		setTimeout(() => {
			this.filterAndSortVehicles();
		}, 50);
	}

	public filterVehicleColour(vehicleColour: string) {
		this.selectedVehicleColour = undefined;
		// filter
		if (vehicleColour) {
			this.selectedVehicleColour = vehicleColour;
		}

		setTimeout(() => {
			this.filterAndSortVehicles();
		}, 50);
	}

	//#endregion

	public checkFilterFromUrlParams() {
		if (this.vehicleFeedFull?.stock) {
			this.filterFromUrlParams.forEach((param) => {
				// tslint:disable: no-string-literal
				if (param['make']) {
					this.selectedVehicleMake = param['make'];
					this.vehicleFeedFull.stock
						.filter((c) => c.vehicle.make.toLowerCase() === param['make'].toLowerCase())
						.map((c) => c.vehicle.model)
						.forEach((c) => {
							this.vehicleModels.push(c.toLowerCase());
						});

					if (this.vehicleModels.length > 0) {
						// tslint:disable-next-line: no-string-literal
						this.filterDisplay['disableModel'] = false;
					}
				}
				if (param['model']) this.selectedVehicleModel = param['model'];
				if (param['pricemin']) this.selectedPriceMin = param['pricemin'];
				if (param['pricemax']) this.selectedPriceMax = param['pricemax'];
				if (param['mileagemin']) this.selectedMileageMin = param['mileagemin'];
				if (param['mileagemax']) this.selectedMileageMax = param['mileagemax'];
				if (param['gearbox']) this.selectedVehicleGearbox = param['gearbox'];
				if (param['fueltype']) this.selectedVehicleFuelType = param['fueltype'];
				if (param['bodytype']) this.selectedVehicleBodyType = param['bodytype'];
				if (param['enginesize']) this.selectedVehicleEngineSize = param['enginesize'];
				if (param['doors']) this.selectedVehicleDoors = param['doors'];
				if (param['colour']) this.selectedVehicleColour = param['colour'];
				// tslint:enable: no-string-literal
			});

			this.filterAndSortVehicles();
		}
	}

	filteredVehicles: IAutoTraderVehicle[] = [];
	public filterAndSortVehicles() {
		if (this.vehicleFeedFull?.stock) {
			let filteredVehicles = [...this.vehicleFeedFull.stock];
			const makeActive = this.selectedVehicleMake != undefined;
			const modelActive = this.selectedVehicleModel != undefined;
			const priceMinActive = this.selectedPriceMin != undefined;
			const priceMaxActive = this.selectedPriceMax != undefined;
			const mileageMinActive = this.selectedMileageMin > -1;
			const mileageMaxActive = this.selectedMileageMax > -1;
			const gearboxActive = this.selectedVehicleGearbox != undefined;
			const fuelTypeActive = this.selectedVehicleFuelType != undefined;
			const bodyTypeActive = this.selectedVehicleBodyType != undefined;
			const engineSizeActive = this.selectedVehicleEngineSize != undefined;
			const doorActive = this.selectedVehicleDoors != undefined;
			const colourActive = this.selectedVehicleColour != undefined;

			for (let i = filteredVehicles.length - 1; i >= 0; i--) {
				const make = filteredVehicles[i].vehicle.make.toLowerCase();
				if (makeActive && this.selectedVehicleMake != make) {
					filteredVehicles.splice(i, 1);
					continue;
				}

				const model = filteredVehicles[i].vehicle.model.toLowerCase();
				if (modelActive && this.selectedVehicleModel != model) {
					filteredVehicles.splice(i, 1);
					continue;
				}

				const price = filteredVehicles[i].vehicle.price;
				if (priceMinActive && +this.selectedPriceMin > price) {
					filteredVehicles.splice(i, 1);
					continue;
				}
				if (priceMaxActive && +this.selectedPriceMax < price) {
					filteredVehicles.splice(i, 1);
					continue;
				}

				const mileage = filteredVehicles[i].vehicle.mileage;
				if (mileageMinActive && this.selectedMileageMin > mileage) {
					filteredVehicles.splice(i, 1);
					continue;
				}
				if (mileageMaxActive && this.selectedMileageMax < mileage) {
					filteredVehicles.splice(i, 1);
					continue;
				}

				const gearbox = filteredVehicles[i].vehicle.transmission.toLowerCase();
				if (gearboxActive && this.selectedVehicleGearbox != gearbox) {
					filteredVehicles.splice(i, 1);
					continue;
				}

				const fuelType = filteredVehicles[i].vehicle.fuelType.toLowerCase();
				if (fuelTypeActive && this.selectedVehicleFuelType != fuelType) {
					filteredVehicles.splice(i, 1);
					continue;
				}

				const bodyType = filteredVehicles[i].vehicle.bodyType.toLowerCase();
				if (bodyTypeActive && this.selectedVehicleBodyType != bodyType) {
					filteredVehicles.splice(i, 1);
					continue;
				}

				const engineSize = `${filteredVehicles[i].vehicle.engineSize}${filteredVehicles[i].vehicle.engineSizeUnit.toLowerCase()}`;
				if (engineSizeActive && this.selectedVehicleEngineSize != engineSize) {
					filteredVehicles.splice(i, 1);
					continue;
				}

				const door = filteredVehicles[i].vehicle.doors;
				if (doorActive && this.selectedVehicleDoors != door) {
					filteredVehicles.splice(i, 1);
					continue;
				}

				const colour = filteredVehicles[i].vehicle.colour.toLowerCase();
				if (colourActive && this.selectedVehicleColour != colour) {
					filteredVehicles.splice(i, 1);
					continue;
				}
			}
			this.sortAndFilteredVehicleFeed = filteredVehicles;
			this.sortVehicles();
		} else {
			this.loading = false;
		}
	}

	//#endregion

	//#region Sort Vehicle Feed

	public sortCode = 'priceLowHigh';

	public sortVehicles() {
		if (this.sortCode) {
			switch (this.sortCode) {
				case 'priceHighLow': {
					this.sortAndFilteredVehicleFeed = this.sortAndFilteredVehicleFeed.sort((c1, c2) => {
						if (c1.vehicle.price < c2.vehicle.price) {
							return 1;
						}

						if (c1.vehicle.price > c2.vehicle.price) {
							return -1;
						}

						return 0;
					});
					break;
				}
				case 'priceLowHigh': {
					this.sortAndFilteredVehicleFeed = this.sortAndFilteredVehicleFeed.sort((c1, c2) => {
						if (c1.vehicle.price > c2.vehicle.price) {
							return 1;
						}

						if (c1.vehicle.price < c2.vehicle.price) {
							return -1;
						}

						return 0;
					});
					break;
				}
				case 'mileageHighLow': {
					this.sortAndFilteredVehicleFeed = this.sortAndFilteredVehicleFeed.sort((c1, c2) => {
						if (c1.vehicle.mileage < c2.vehicle.mileage) {
							return 1;
						}

						if (c1.vehicle.mileage > c2.vehicle.mileage) {
							return -1;
						}

						return 0;
					});
					break;
				}
				case 'mileageLowHigh': {
					this.sortAndFilteredVehicleFeed = this.sortAndFilteredVehicleFeed.sort((c1, c2) => {
						if (c1.vehicle.mileage > c2.vehicle.mileage) {
							return 1;
						}

						if (c1.vehicle.mileage < c2.vehicle.mileage) {
							return -1;
						}

						return 0;
					});
					break;
				}
				case 'addedDateHighLow': {
					this.sortAndFilteredVehicleFeed = this.sortAndFilteredVehicleFeed.sort((c1, c2) => {
						return -(moment(c1.createdDate).toDate().getTime() - moment(c2.createdDate).toDate().getTime());
					});
					break;
				}
				case 'addedDateLowHigh': {
					this.sortAndFilteredVehicleFeed = this.sortAndFilteredVehicleFeed.sort((c1, c2) => {
						return moment(c1.createdDate).toDate().getTime() - moment(c2.createdDate).toDate().getTime();
					});
					break;
				}
			}
		}
		this.pageLength = this.sortAndFilteredVehicleFeed.length;
		this.pagedVehicleFeed = this.sortAndFilteredVehicleFeed.slice((0 + 1 - 1) * this.pageSize).slice(0, this.pageSize);
		setTimeout(() => {
			this.loading = false;
		}, this.config.loadingPanelDelay);
	}

	//#endregion

	public async submitEnquiry(stock: IAutoTraderStock, form: UntypedFormGroup): Promise<string> {
		let result = '';
		const customer: CustomerModel = new CustomerModel();
		customer.title = form.controls.titleFormControl.value;
		customer.firstName = form.controls.firstNameFormControl.value;
		customer.surname = form.controls.surnameFormControl.value;
		customer.email = form.controls.emailFormControl.value;
		customer.phone = form.controls.phoneFormControl.value;

		const emailPayload: IAutoTraderVehicleFeedEnquire = {
			customer: customer,
			stockItem: stock,
			customerMessage: form.controls.messageFormControl.value,
		};

		await this.vehicleFeedService
			.sendEnquiry(this.companyStoreService.vehicleDealer.id, emailPayload)
			.toPromise()
			.then(() => {
				result = 'Thank you we will be in contact shortly';
			})
			.catch(() => {
				result = 'There was an error sending this enquiry, please try again';
			});

		return result;
	}
}
