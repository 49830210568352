import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoggerStoreService } from 'src/app/services/logger/logger-store.service';
import { CustomerStoreService } from 'src/app/services/customer/customer-store.service';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { NavigationStoreService } from 'src/app/services/navigation/navigation-store.service';

@Component({
	selector: 'app-step-enter-details',
	templateUrl: './step-enter-details.component.html',
	styleUrls: ['./step-enter-details.component.scss']
})
export class StepEnterDetailsComponent implements OnInit {

	enterDetailsForm: UntypedFormGroup;

	constructor(
		public dialog: MatDialog,
        private loggerStoreService: LoggerStoreService,
		public basketStoreService: BasketStoreService,
		public vehicleStoreService: VehicleStoreService,
		public customerStoreService: CustomerStoreService,
		private navigationStoreService: NavigationStoreService) { }

	ngOnInit(): void {
		console.log("Step Enter Details: Checking Basket");
		if (!this.basketStoreService.validateBasket()) {
			console.log("Step Enter Details: Invalid Basket");
			this.navigationStoreService.goToFirstStep(); 
		} 
		this.loggerStoreService.log(`Step Enter Details: Initialised.`);
		this.enterDetailsForm = new UntypedFormGroup({});
	}

}
