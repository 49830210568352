import { IVehicle, IFuelType } from '../interfaces/vehicle.interface';
import { ITyreSet, ITyreSize } from '../interfaces/tyre.interface';

export class VehicleModel implements IVehicle {

    make: string = '';
    model: string = '';
    registrationNumber:string = '';
    engineCC: number = 0;
    fuelTypeID:number = 0;
    fuel: string = '';
    fuelType: IFuelType = {description: '', id: 0};
    color: string = '';
    engineNumber: string = '';
    engineCode: string = '';
    transmission: string = '';
    body: string = '';
    vin: string = '';
    manufactureDate: string = '';
    weight: string = '';
    transmissionId: number = 0;
    categoryId: number = 0;
    tyreSize: ITyreSize = {width: '', diameter: '', profile: '', rating: ''};
    tyreSetList: ITyreSet[] = [];

    public constructor(init?: Partial<IVehicle>) {
        Object.assign(this, init);
	}
    
}