<ng-container *ngIf="config.template == 'list'">
	<div class="tyre-launch-list-item {{ searchType }}-search">
		<h4>
			<span>{{ config.title }}</span>
			<!-- <i class="fas fa-chevron-up" [hidden]="hideTyres" (click)="hideTyres =! hideTyres"></i>
            <i class="fas fa-chevron-down" [hidden]="!hideTyres" (click)="hideTyres =! hideTyres"></i> -->
		</h4>
		<p *ngIf="config.description != '' && !hideTyres">
			{{ config.description }}
		</p>

		<div
			class="tabbed-nav"
			*ngIf="vehicleStoreService.vrmAvailable() && !hideTyres"
		>
			<button
				(click)="searchType = 'vrm'"
				[ngClass]="{ active: searchType == 'vrm' }"
			>
				Search by reg
			</button>
			<button
				(click)="searchType = 'manual'"
				[ngClass]="{ active: searchType == 'manual' }"
			>
				Search by size
			</button>
		</div>

		<div class="tyre-search" *ngIf="!hideTyres">
			<div
				class="tyre-vrm-lookup"
				*ngIf="vehicleStoreService.vrmAvailable()"
			>
				<app-vehicle-vrm-lookup
					[checkTyres]="true"
					(vrmComplete)="vrmComplete(true)"
				></app-vehicle-vrm-lookup>
			</div>
			<div class="or" *ngIf="vehicleStoreService.vrmAvailable()">
				<span>Or</span>
			</div>
			<div class="manual-entry">
				<app-tyre-size-form
					(confirmTyreSize)="findTyresBySize()"
				></app-tyre-size-form>
			</div>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="config.template == 'banner'"> </ng-container>
