import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TyreLaunchComponent } from './tyre-launch/tyre-launch.component';
import { TyreSizeFormComponent } from './tyre-size-form/tyre-size-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VehicleModule } from '../vehicle/vehicle.module';
import { MaterialModule } from 'src/app/material.module';
import { TyreSizeResultsComponent } from './tyre-size-results/tyre-size-results.component';
import { TyreResultsComponent } from './tyre-results/tyre-results.component';
import { TyreLabelComponent } from './tyre-label/tyre-label.component';
import { TyreIconsComponent } from './tyre-icons/tyre-icons.component';

@NgModule({
    declarations: [TyreLaunchComponent, TyreSizeFormComponent, TyreSizeResultsComponent, TyreResultsComponent, TyreLabelComponent, TyreIconsComponent],
    imports: [
        CommonModule,
        FormsModule,
        VehicleModule,
        ReactiveFormsModule,
        MaterialModule
    ],
    exports: [
        TyreLaunchComponent, TyreSizeFormComponent, TyreSizeResultsComponent, TyreResultsComponent
    ]
})
export class TyresModule { }
