export interface IOnlineShoppingBasketValid { 
    onlineShoppingBasketItems: IOnlineShoppingBasketItem[];
    response: IOnlineShoppingBasketResponse[];
}

export interface IOnlineShoppingBasketItem {
    slotID: number;
    specialOfferID: number;
    specialOfferName: string;
    specialOfferGeneralInformation: string;
    foreignRecordID: number;
    partNumber: string;
    description: string;
    fullNetPrice: number;
    fullVat: number;
    discountNetPrice: number;
    discountVat: number;
    quantity: number;   
}

export interface IOnlineShoppingBasketResponse {
    issueType: EOnlineShoppingBasketIssueType;
    issueDescription: string;
}

export enum EOnlineShoppingBasketIssueType {
    None = 0,
    SlotType = 1,
    Extra = 2,
    SpecialOffer = 3,
    Tyre = 4,
    BookingDate = 5,
    BookingTime = 6,
    CustomerEmail = 7,
    CustomerDetail = 8,
    VehicleDetail = 9,
    OnlinePayment = 10,
    Blacklist = 11
}
