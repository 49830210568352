import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ICompany } from 'src/app/interfaces/company.interface';

@Injectable({
	providedIn: 'root'
})
export class CompanyService {

	constructor(private http: HttpClient) { }

	get() {
		return this.http.get<ICompany>(`${environment.apiUrl}/Api/Company/Get/`);
    }
    
    
}