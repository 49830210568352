import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { Router } from '@angular/router';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { ExtraStoreService } from 'src/app/services/extra/extra-store.service';
import { IComponentConfig } from 'src/app/interfaces/config.interface';

@Component({
    selector: 'app-step-basket',
    templateUrl: './step-basket.component.html',
    styleUrls: ['./step-basket.component.scss']
})
export class StepBasketComponent implements OnInit {

    config: IComponentConfig;

    constructor(
        private location: Location,
        public basketStoreService: BasketStoreService,
        public configStoreService: ConfigStoreService,
        public authStoreService: AuthStoreService,
        public extraStoreService: ExtraStoreService,
        public router: Router) { }

    ngOnInit(): void {
        this.config = this.configStoreService.getComponentConfig('StepBasketComponent');
    }

    back() {
        this.location.back();
    }

    navigate() {
        if (this.basketStoreService.unbookableSlotTypes.length) {
            this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'enter-details']);
        } else {
            this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-date-time']);

        }
    }

}
