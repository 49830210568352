import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutSelectDateTimeComponent } from './checkout-select-date-time/checkout-select-date-time.component';
import { MaterialModule } from 'src/app/material.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogsModule } from '../dialogs/dialogs.module';
import { CheckoutEnterDetailsForm } from './checkout-enter-details-form/checkout-enter-details-form.component';
import { VehicleModule } from '../vehicle/vehicle.module';
import { CheckoutCalendarComponent } from './checkout-calendar/checkout-calendar.component';
import { CheckoutCalendarHeaderComponent } from './checkout-calendar-header/checkout-calendar-header.component';
import { PaypalButtonsComponent } from './paypal-buttons/paypal-buttons.component';

@NgModule({
	declarations: [
		CheckoutSelectDateTimeComponent,
		CheckoutEnterDetailsForm,
		CheckoutCalendarComponent,
		CheckoutCalendarHeaderComponent,
		PaypalButtonsComponent
	],
	imports: [
		CommonModule,
		MaterialModule,
		PipesModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        VehicleModule
	],
	exports: [
		CheckoutSelectDateTimeComponent,
		CheckoutEnterDetailsForm,
		CheckoutCalendarComponent,
		PaypalButtonsComponent
	]
})
export class CheckoutModule { }
