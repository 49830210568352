import { Component, OnInit } from '@angular/core';
import { VehicleStoreService } from '../../../services/vehicle/vehicle-store.service';
import { AuthStoreService } from '../../../services/auth/auth-store.service';
import { ConfigStoreService } from '../../../services/config/config-store.service';
import { Router } from '@angular/router';
import { IComponentConfig } from 'src/app/interfaces/config.interface';

@Component({
    selector: 'app-step-select-vehicle-type',
    templateUrl: './step-select-vehicle-type.component.html',
    styleUrls: ['./step-select-vehicle-type.component.scss']
})
export class StepSelectVehicleTypeComponent implements OnInit {

    config: IComponentConfig;

    constructor(
        private vehicleStoreService: VehicleStoreService,
        private configStoreService: ConfigStoreService,
        private authStoreService: AuthStoreService,
        private router: Router) { }

    ngOnInit(): void {
        this.config = this.configStoreService.getComponentConfig('StepSelectVehicleTypeComponent');
    }

    selectVehicleType(event) {
        this.vehicleStoreService.vehicleType = event.data.type;
        if (this.config.startWithVehicle && !this.config.tyres.enabled) {
            this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-vehicle']);
        } else {
            this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-services']);
        }
    }
}
