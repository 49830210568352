import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExtraListComponent } from './extra-list/extra-list.component';
import { MaterialModule } from 'src/app/material.module';



@NgModule({
    declarations: [ExtraListComponent],
    imports: [
        CommonModule,
        MaterialModule
    ],
    exports: [
        ExtraListComponent
    ]
})
export class ExtraModule { }
