
<div class="basket" [ngClass]="{'compact': template == 'compact', 'large': template == 'large', 'mobile-footer': template == 'mobile-footer'}">
    <div class="header">
        <div>Booking summary</div>
        <div *ngIf="basketStoreService.bookingDateTime" class="selected-date-time"><i class="far fa-calendar-alt"></i> <span>{{basketStoreService.bookingDateTime | moment:'ddd, MMM Do'}}</span> - <span>{{basketStoreService.rangeLabel()}}</span></div>
    </div>
    <div class="no-items basket-item" [hidden]="isBasketEmpty()">
        <p>Your booking is currently empty.</p>
    </div>
    <ng-template *ngFor="let tyre of basketStoreService.tyres" [ngTemplateOutlet]="BasketItemTyre" [ngTemplateOutletContext]="{tyre: tyre}"></ng-template>
    <ng-template *ngFor="let specialOffer of basketStoreService.specialOffers" [ngTemplateOutlet]="BasketItemSpecialOffer" [ngTemplateOutletContext]="{specialOffer: specialOffer}"></ng-template>
    <ng-template *ngFor="let slotType of basketStoreService.nonPoaSlotTypes" [ngTemplateOutlet]="BasketItemSlotType" [ngTemplateOutletContext]="{slotType: slotType}"></ng-template>
    <ng-template *ngFor="let extra of basketStoreService.extras" [ngTemplateOutlet]="BasketItemExtra" [ngTemplateOutletContext]="{extra: extra}"></ng-template>
    <div *ngIf="basketStoreService.poaSlotTypes.length > 0" class="unpriced">
        <div class="mat-divider"></div>
        <div class="headers">
            <h4 class="unpriced-header">{{config.unpricedItemsHeader}}</h4>
            <h5 class="unpriced-subheader">{{config.unpricedItemsSubHeader}}</h5>
        </div>
        <ng-template *ngFor="let slotType of basketStoreService.poaSlotTypes" [ngTemplateOutlet]="BasketItemSlotType" [ngTemplateOutletContext]="{slotType: slotType}"></ng-template>
    </div>
    <div class="total">
        
        <div class="price">
            <span>Total:</span>
            {{"£" + (basketStoreService.getBasketTotal() | number:"1.2-2")}}
        </div>
        <div *ngIf="basketStoreService.poaSlotTypes.length > 0" class="unpriced-message">
            Total excludes {{config.unpricedItemsHeader | lowercase}}
        </div>
    </div>
    
</div>

<ng-template #BasketItemTyre let-tyre="tyre">
    <div class="basket-item">
        <img *ngIf="config.tyres.showTyreImage" src="https://bookingsystemapp.motasoftvgm.co.uk/{{tyre.image}}" onError="this.src='https://bookingsystemapp.motasoftvgm.co.uk//Images/TyreImages/default.jpg'" class="icon" />
        <div class="details">
            <div class="description">
                <h5>{{tyre.manufacturerName | titlecase}} - {{tyre.treadPattern}}</h5>
                <span class="long-description" [hidden]="!config.tyres.showTyreDescription">
                    {{config.tyres.tyreDescription}}
                </span>
            </div>
            <div class="price">
                <div *ngIf="template == 'compact'" class="quantity">Quantity: {{tyre.quantity}}</div>
                <div class="value">£{{tyre.fullPrice.value | number:"1.2-2"}}</div>
                <div class="quantity" *ngIf="template == 'large'">
                    Quantity: {{tyre.quantity}}
                </div>
            </div>
            <!-- <div class="quantity" *ngIf="template == 'compact'">
                Quantity: {{tyre.quantity}}
            </div> -->
            <a href="javascript:void(0)" class="remove" (click)="basketStoreService.addRemoveTyre(tyre)" *ngIf="showDelete()">
                <i class="fas fa-times"></i>
            </a>
        </div>
    </div>    
</ng-template>

<ng-template #BasketItemSlotType let-slotType="slotType">
    <div class="basket-item" *ngIf="slotType.id != tyreStoreService.tyreSlot.id">
        <img *ngIf="config.services.showSlotTypeIcon" src="https://branchresources.motasoftvgm.co.uk/{{slotType.image}}" onError="this.src='https://branchresources.motasoftvgm.co.uk/BranchResources/14/Images/SlotTypes/Icons/IconSet1/tacg/tacg/64x64-Servicing.png'" class="icon" />
        <div class="details">
            <div class="description">
                <h5>{{slotType.shortDescription}}</h5>
                <span class="long-description" [hidden]="!config.services.showSlotTypeLongDescription">{{slotType.longDescription}}</span>
            </div>
            <div class="price">
                <div class="value">
                    <span *ngIf="slotType.type != slotTypeType.POA">{{"£" + (slotType.fullPrice.value | number:"1.2-2")}}</span>
                    <span *ngIf="slotType.type == slotTypeType.POA">{{ slotType.priceOverwrite }}</span>
                </div>
                <!-- <div class="quantity" *ngIf="template == 'large'">
                    Quantity: 1
                </div>  -->
            </div>
            <!-- <div class="quantity" *ngIf="template == 'compact'">
                Quantity: 1
            </div> -->
            <a href="javascript:void(0)" class="remove" (click)="basketStoreService.addRemoveSlotType(slotType)" *ngIf="showDelete()">
                <i class="fas fa-times"></i>
            </a>
        </div>
    </div>    
</ng-template>

<ng-template #BasketItemSpecialOffer let-specialOffer="specialOffer">
    <div class="basket-item special-offer">
        <img *ngIf="config.services.showSpecialOfferIcon" src="https://branchresources.motasoftvgm.co.uk/{{specialOffer.bookingSystemIcon}}" onError="this.src='https://branchresources.motasoftvgm.co.uk/BranchResources/14/Images/SlotTypes/Icons/IconSet1/tacg/tacg/64x64-Servicing.png'" class="icon" />
        <div class="details">
            <div class="description">
                <h5>{{specialOffer.name}} <span class="strikethrough">(Normally £{{specialOffer.fullPrice.value | number:"1.2-2"}})</span></h5>
                <div class="long-description" [hidden]="!config.services.showSpecialOfferLongDescription">
                    {{specialOffer.generalInformation}}
                </div>
                <div class="slot-type" *ngFor="let slotType of specialOffer.slotTypes">
                    <i class="fas fa-check"></i>
                    <div>
                        <div class="slot-type-header">{{slotType.shortDescription}}</div>
                        <div class="slot-type-description">{{slotType.longDescription}}</div>
                    </div>
                </div>
            </div>
            
            <div class="price">
                <div class="value">{{"£" + (specialOffer.discountedPrice.value | number:"1.2-2")}}</div>
                <!-- <div class="quantity" *ngIf="template == 'large'">
                    Quantity: 1
                </div> -->
            </div>
            <!-- <div class="quantity" *ngIf="template == 'compact'">
                Quantity: 1
            </div> -->
            <a href="javascript:void(0)" class="remove" (click)="basketStoreService.addRemoveSpecialOffer(specialOffer)" *ngIf="showDelete()">
                <i class="fas fa-times"></i>
            </a>
        </div>
    </div> 
</ng-template>

<ng-template #BasketItemExtra let-extra="extra">
    <div class="basket-item">
        <img *ngIf="config.services.showExtraIcon" src="https://branchresources.motasoftvgm.co.uk/{{extra.image}}" onError="this.src='https://branchresources.motasoftvgm.co.uk/BranchResources/14/Images/SlotTypes/Icons/IconSet1/tacg/tacg/64x64-Servicing.png'" class="icon" />
        <div class="details">
            <div class="description">
                <h5>{{extra.name}}</h5>
                <span class="long-description" [hidden]="!config.services.showExtraDescription">{{extra.description}}</span>
            </div>
            
            <div class="price">
                <div class="value">{{"£" + (extra.fullPrice.value | number:"1.2-2")}}</div>
                <!-- <div class="quantity" *ngIf="template == 'large'">
                    Quantity: 1
                </div> -->
            </div>
            <!-- <div class="quantity" *ngIf="template == 'compact'">
                Quantity: 1
            </div> -->
            <a href="javascript:void(0)" class="remove" (click)="basketStoreService.addRemoveExtra(extra)" *ngIf="showDelete()">
                <i class="fas fa-times"></i>
            </a>
        </div>
    </div>    
</ng-template>
