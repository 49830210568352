import { Injectable } from '@angular/core';
import { LoggerStoreService } from '../logger/logger-store.service';
import { GoogleAnalyticsStoreService } from './google/google-analytics-store.service';
import { FacebookPixelStoreService } from './facebook/facebook-pixel-store.service';
import { IBooking } from 'src/app/interfaces/booking.interface';
import { ConfigStoreService } from '../config/config-store.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsStoreService {

  constructor(
    private loggerStoreService: LoggerStoreService,
    private configStoreService: ConfigStoreService,
    private googleAnalyticsStoreService: GoogleAnalyticsStoreService,
    private facebookPixelStoreService: FacebookPixelStoreService,
  ) { }

  init(){
    this.loggerStoreService.log("AnalyticsStoreService: Initalising Analytic services");
    if (this.configStoreService.config.googleAnalyticsID) {
			this.googleAnalyticsStoreService.configureGoogleAnalytics(this.configStoreService.config.googleAnalyticsID);
		}

		if (this.configStoreService.config.facebookPixelID) {
			this.facebookPixelStoreService.configureFacebookPixel(this.configStoreService.config.facebookPixelID);
		}
  }

  trackPageView(pageUrl: string){
    this.googleAnalyticsStoreService.pageView(pageUrl);
    this.facebookPixelStoreService.pageView(pageUrl);
  }

  trackPurchase(booking: IBooking) {
    this.googleAnalyticsStoreService.trackBooking(booking);
    this.facebookPixelStoreService.trackBooking(booking);
  }
}
