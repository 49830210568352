import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-tyre-label',
    templateUrl: './tyre-label.component.html',
    styleUrls: ['./tyre-label.component.scss']
})
export class TyreLabelComponent implements OnInit {

    @Input() template: string;
    @Input() wetGripGrade: string;
    @Input() fuelEfficiency: string;
    @Input() noiseDbBarRating: number;
    @Input() noiseDb: number;

    constructor() { }

    ngOnInit(): void { }

}
