<div class="vehicle-feed-list">

	<div class="form-row">
		<div class="col-6 col-lg-8 vehicle-filters-mobile-wrapper" [ngClass]="{'d-none' : vehicleFeedStoreService.showFiltersMobile}">
			<button class="btn btn-primary btn-vehicle-filters-mobile d-lg-none" 
				(click)="vehicleFeedStoreService.showFiltersMobile = !vehicleFeedStoreService.showFiltersMobile">Show filters</button>
		</div>
		<div class="col-6 col-lg-4 vehicle-feed-sort-wrapper uk-align-right" [ngClass]="{'d-none' : vehicleFeedStoreService.showFiltersMobile}">
			<mat-form-field [appearance]="config.formAppearance">
				<mat-label>Order by</mat-label>
				<mat-select name="sort" [(value)]="vehicleFeedStoreService.sortCode" (selectionChange)="sortResults()">
					<mat-option value="priceLowHigh"> Price: Low to High </mat-option>
					<mat-option value="priceHighLow"> Price: High to Low </mat-option>
					<mat-option value="mileageLowHigh"> Mileage: Low to High </mat-option>
					<mat-option value="mileageHighLow"> Mileage: High to Low </mat-option>
					<mat-option value="addedDateLowHigh"> Added Date: Low to High </mat-option>
					<mat-option value="addedDateHighLow"> Added Date: High to Low </mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
	<div class="row vehicle-feed-content">
		<div class="vehicle-feed-filter-wrapper col-lg-3 col-12">
			<app-vehicle-feed-filter></app-vehicle-feed-filter>
		</div>

		<div class="vehicle-feed-results-wrapper col-lg-9 col-12" [ngClass]="{'d-none' : vehicleFeedStoreService.showFiltersMobile}">
			<app-vehicle-feed-results></app-vehicle-feed-results>
		</div>
	</div>
</div>
