import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ICustomerConsent } from 'src/app/interfaces/customer.interface';
import { shareReplay } from 'rxjs/operators';
import { ICustomerCreateMOTReminder } from '../../interfaces/create-MOT-reminder.interface';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    constructor(private http: HttpClient) { }

    // Check if we need this as well as auth data
    get(branchGuid: string) {
        // Make sure this can only get called when we have auth data.
        return this.http.get<any>(`${environment.apiUrl}/Api/Customer/Get?branchGuid=${branchGuid}`);
    }

    getBookings(branchGuid: string) {
        return this.http
            .get<any>(`${environment.apiUrl}/Api/CustomerShoppingBaskets/Get?branchGuid=${branchGuid}`)
            .pipe(shareReplay(1));
    }

    getCustomerConsent(branchGuid: string, slotTypeIDs: string, bookingDate: string) {
        return this.http.get<ICustomerConsent>(`${environment.apiUrl}/Api/CustomerConsent/Get?branchGuid=${branchGuid}&slotTypeIDs=${slotTypeIDs}&bookingDate=${bookingDate}`);
    }

    // Check if email address already belongs to customer
    checkEmailExists(branchGuid: string, emailAddress: string) {
        return this.http.get<any>(`${environment.apiUrl}/Api/CheckCustomerEmailAddress/Get?branchGuid=${branchGuid}&emailAddress=${emailAddress}`);
    }

    // Update if logged in
    update(branchGuid: string, customer: any, isBooking: boolean) {
        // Check what 'isBooking' does. I suspect this is called as part of the checkout.
        return this.http.post<any>(`${environment.apiUrl}/Api/Customer/Update?branchGuid=${branchGuid}&isBooking=${isBooking}`, customer);
    }

    // Update password if already logged in
    updatePassword(branchGuid: string, password: any) {
        return this.http.post<any>(`${environment.apiUrl}/Api/CustomerPassword/Update?branchGuid=${branchGuid}`, password);
    }

    // Update password after clicking on link sent to email address
    resetPassword(branchGuid: string, newPassword: string, key: string) {
        return this.http.post<any>(`${environment.apiUrl}/Api/Customer/ResetPassword?branchGuid=${branchGuid}&password=${newPassword}&key=${encodeURIComponent(key)}`, '');
    }

    // Pass in email address and request a password reset email
    requestPasswordRecoveryEmail(email: string, branchGuid: string, resetPasswordUrl: string, clientID: string) {
        // Client ID is being injected differently than before.
        return this.http.post<any>(`${environment.apiUrl}/Api/Customer/RecoverPassword?email=${email}&clientID=${clientID}&branchGuid=${branchGuid}&resetPasswordUrl=${resetPasswordUrl}`, '');
    }
    
    createMOTReminder(branchGuid: string, dataContract: ICustomerCreateMOTReminder){       
        return this.http.post<any>(`${environment.apiUrl}/Api/Customer/CreateMOTReminder?branchGuid=${branchGuid}`, dataContract);    
    }
}
