import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { environment } from 'src/environments/environment';
import { ISpecialOffer } from '../../../interfaces/special-offer.interface';
import { SpecialOfferStoreService } from '../../../services/special-offer/special-offer-store.service';
import { ISlotType } from '../../../interfaces/slot-type.interface';
import { SlotTypeStoreService } from 'src/app/services/slot-type/slot-type-store.service';
import { IBranch } from 'src/app/interfaces/branch.interface';
import { ModalStoreService } from 'src/app/services/modal/modal-store.service';
import { SlotTypeType } from 'src/app/global/enums';

@Component({
	selector: 'app-widget-services-list',
	templateUrl: './widget-services-list.component.html',
	styleUrls: ['./widget-services-list.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class WidgetServicesListComponent implements OnInit {
	// Component properties
	config: IComponentConfig;
	loading = true;
	widgetAttributesErrors: string[] = [];
	hasBranchOnInit = false;
	staleData = false;
	pricesShownBranchName: string;
	env: any;
	openModal: any;

	// Component Data
	slotTypes: ISlotType[] = [];
	specialOffers: ISpecialOffer[] = [];

	@ViewChild('servicesList') servicesList: ElementRef;

	// Input Data
	@Input() clientId: string;
	@Input() configId: number;
	@Input() branchGuid: string;
	@Input() slotTypeGroupIds: string = '';
	@Input() specialOfferIds: string = '';
	@Input() slotTypeIds: string = '';

	constructor(
		public companyStoreService: CompanyStoreService,
		private slotTypeStoreService: SlotTypeStoreService,
		private specialOfferStoreService: SpecialOfferStoreService,
		private authStoreService: AuthStoreService,
		private cd: ChangeDetectorRef,
		public configStoreService: ConfigStoreService,
		public modalStoreService: ModalStoreService
	) {
		this.env = environment;
	}

	async ngOnInit() {
		await this.getCompany();
		if (this.widgetAttributesErrors.length <= 0 && this.branchGuid) {
			this.getWidgetData();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.calculateWidth();
	}

	widgetSize = '';
	calculateWidth() {
		const width = this.servicesList.nativeElement.offsetWidth;

		if (width <= 600) {
			this.widgetSize = 'narrow';
		} else {
			this.widgetSize = '';
		}
	}

	async getCompany() {
		if (this.clientId && this.configId) {
			// If we don't pass in a client ID and config ID, it's reasonable to assume this is being loaded within the app,
			// so the injected services will have the info we require.
			this.authStoreService.clientId = this.clientId;
			this.configStoreService.configId = +this.configId || 0;
			await this.authStoreService.getToken();
			await this.companyStoreService.getCompany();
			this.configStoreService.updateThemeColours();
		}
		this.config = this.configStoreService.getComponentConfig('WidgetServicesListComponent');

		if (!this.branchGuid) {
			if (this.companyStoreService?.company?.branches?.length > 1) {
				console.log('Missing branch Guid [Company has multiple branches]');
			} else {
				this.branchGuid = this.companyStoreService.company.branches[0].guid;
				this.hasBranchOnInit = true;
			}
		} else {
			this.hasBranchOnInit = true;
		}

		if (
			(!this.slotTypeIds && !this.specialOfferIds && !this.slotTypeGroupIds) ||
			(this.slotTypeIds?.split(',').length <= 0 && this.specialOfferIds?.split(',').length <= 0 && this.slotTypeGroupIds?.split(',').length <= 0)
		) {
			this.widgetAttributesErrors.push("Missing SpecialOffer and/or Slot Type ID's and/or Slot Type Group ID's");
		}
		this.calculateWidth();
		setTimeout(() => {
			this.widgetAttributesErrors.forEach((error) => {
				console.error(error);
			});
			if (!this.widgetAttributesErrors.length && !this.branchGuid) {
				this.loading = false;
			}
			this.cd.detectChanges();
		}, this.config.loadingPanelDelay);
	}

	getCompanyBranchName() {
		if (this.companyStoreService.company?.branches?.length > 1 && this.branchGuid) {
			return this.companyStoreService.formatBranchName(this.companyStoreService.branch);
		} else {
			return this.companyStoreService?.company?.name;
		}
	}

	async getWidgetData() {
		const slotTypeGroupIDs = this.slotTypeGroupIds?.trim()?.split(',') ?? [];
		const slotTypeIDs = this.slotTypeIds?.trim()?.split(',') ?? [];
		this.slotTypes = await this.slotTypeStoreService.getWidgetSlotTypes(this.branchGuid, slotTypeGroupIDs, slotTypeIDs);

		const specialOfferIDs = this.specialOfferIds?.trim()?.split(',') ?? [];
		this.specialOffers = await this.specialOfferStoreService.getWidgetSpecialOffers(this.branchGuid, specialOfferIDs);

		setTimeout(() => {
			this.loading = false;
			this.staleData = false;
			this.pricesShownBranchName = this.companyStoreService.branch.branchName;
			this.cd.detectChanges();
		}, this.config.loadingPanelDelay);
	}

	getPrices() {
		this.loading = true;
		this.staleData = true;
		this.getWidgetData();
	}

	branchSelected(branch: IBranch) {
		this.branchGuid = branch.guid;
		this.getPrices();
	}

	addToBasketAndNavigateSlotType(slotType: ISlotType) {
		this.loading = true;
		let url = window.location.search;
		url = url.replace('?', '');
		let queryString = `?startingUrl=${window.location.href}&slotTypeID=${slotType.id}`;

		if (this.branchGuid) {
			queryString += `&branchGuid=${this.branchGuid}`;
		}

		queryString += `${url ? '&' + url : ''}`;

		if (window.innerWidth < this.config.mobileCutoff && this.config.mobileRedirect) {
			// Here, we redirect to a pure booking system url/route.
			window.location.href = this.env.baseUrl + this.authStoreService.clientId + '/' + this.configStoreService.configId + queryString;
		} else {
			window.location.href = this.config.bookingSystemUrl + queryString;
		}
	}

	getPricePreambleSlotType(slotType: ISlotType): string {
		switch (slotType.fullPrice.type) {
			case 1:
				return 'Price from';
			case 2:
				return 'Price to';
			case 4:
				return slotType.fullPrice.label;
			default:
				return '';
		}
	}

	addToBasketAndNavigateSpecialOffer(specialOffer: ISpecialOffer) {
		this.loading = true;
		let url = window.location.search;
		url = url.replace('?', '');
		let queryString = `?specialOfferID=${specialOffer.id}`;

		if (this.branchGuid) {
			queryString += `&branchGuid=${this.branchGuid}`;
		}

		queryString += `${url ? '&' + url : ''}`;

		if (window.innerWidth < this.config.mobileCutoff && this.config.mobileRedirect) {
			// Here, we redirect to a pure booking system url/route.
			window.location.href = this.env.baseUrl + this.authStoreService.clientId + '/' + this.configStoreService.configId + queryString;
		} else {
			window.location.href = this.config.bookingSystemUrl + queryString;
		}
	}

	getPricePreambleSpecialOffer(specialOffer: ISpecialOffer): string {
		switch (specialOffer.discountedPrice.type) {
			case 1:
				return 'Price from';
			case 2:
				return 'Price to';
			case 4:
				return specialOffer.discountedPrice.label;
			default:
				return '';
		}
	}

	showSlotTypeInfoModal(slotType: ISlotType) {
		const slotTypeGroups = this.slotTypeStoreService.getSlotTypeGroupFromSlotType(slotType.id);
		// We just take the first one as theoretically, slot type could belong to multiple groups.
		if (slotTypeGroups.length == 1 && slotTypeGroups[0].checklistTemplate?.id > 0) {
			let content = `<div>${slotType.longDescription}</div><div class="widget_includes">The following items are checked:</div>`;
			slotTypeGroups[0].checklistTemplate.itemGroups.forEach((itemGroup) => {
				content += `<div class="widget_checklist-item-group-header">${itemGroup.name}</div>`;
				itemGroup.items.forEach((item) => {
					let compatible = this.checkSlotTypesCompatibility(slotType.id, item.slotTypesCompatibility);
					content += `<div class="widget_checklist-item">
                                        <div class="widget_checklist-item-name">${item.name}</div>
                                        <div class="widget_checklist-item-value">${
											compatible ? `<i class="fas fa-check text-success"></i>` : `<i class="fas fa-horizontal-rule text-muted"></i>`
										}</div>
                                    </div>`;
				});
			});

			this.modalStoreService.openModal(slotType.shortDescription, content);
		} else {
			this.modalStoreService.openModal(slotType.shortDescription, slotType.longDescription);
		}
	}

	checkSlotTypesCompatibility(slotTypeID: number, slotTypesCompatibility: { [key: number]: boolean }): boolean {
		const compatibilityValue = Object.keys(slotTypesCompatibility)
			// find our key (ID)
			.filter((key) => key === slotTypeID.toString())
			// if we find it, reduce the key/value to just its value
			// and assign that an object.
			.reduce((obj, key) => {
				obj = slotTypesCompatibility[key];
				return obj;
			}, {});

		// if we found something *and* its true return true.
		if (compatibilityValue) {
			return true;
		}
		// if we didn't find something *or* its false return false.
		return false;
	}

	showSpecialOfferInfoModal(specialOffer: ISpecialOffer) {
		let content = `<div>${specialOffer.generalInformation}</div><div class="widget_includes">Includes the following:</div><ul>`;
		specialOffer.slotTypes.forEach((element) => {
			content += `<li><i class=\"fas fa-check\"></i> ${element.shortDescription}</li>`;
		});
		content += `</ul>`;
		this.modalStoreService.openModal(specialOffer.name, content);
	}

	slotTypeHasMoreInfo(slotType: ISlotType) {
		if (!slotType) {
			return false;
		}
		if (slotType.longDescription && slotType.shortDescription != slotType.longDescription) {
			return true;
		} else {
			return false;
		}
	}

	isPOASlotType(slotType: ISlotType) {
		if (slotType.type == SlotTypeType.POA) {
			return true;
		}
		return false;
	}

	specialOfferHasMoreInfo(specialOffer: ISpecialOffer) {
		if (!specialOffer) {
			return false;
		} else {
			return true;
		}
	}
}
