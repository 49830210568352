import { Component, OnInit, Input } from '@angular/core';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { CustomerStoreService } from 'src/app/services/customer/customer-store.service';
import { FormErrorStateMatcher } from 'src/app/global/error-state-matcher';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { environment } from 'src/environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-dialog-login',
	templateUrl: './dialog-login.component.html',
	styleUrls: ['./dialog-login.component.scss']
})
export class DialogLoginComponent implements OnInit {

	@Input() data;
	env: any;
	config: IComponentConfig;
	loginForm: UntypedFormGroup;
	loading = false;
	btnLoginLoading = false;
	errorStateMatcher = new FormErrorStateMatcher();

	constructor(
		public modal: NgbActiveModal,
		public companyStoreService: CompanyStoreService,
		private configStoreService: ConfigStoreService,
		private customerStoreService: CustomerStoreService,
		private authStoreService: AuthStoreService) {
			this.env = environment;
		}

	ngOnInit(): void {
		this.config = this.configStoreService.getComponentConfig('DialogLoginComponent');
		this.loginForm = new UntypedFormGroup({
			'emailAddressFormControl': new UntypedFormControl(null, [Validators.required, Validators.email]),
			'passwordFormControl': new UntypedFormControl(null, [Validators.required])
		});
	}

	get email() {
		return this.loginForm.get('emailAddressFormControl');
	}

	get password() {
		return this.loginForm.get('passwordFormControl');
	}

	incorrectLogin = false;
	async doLogin() {
		if (!this.loginForm.valid) { return; }
		if (!this.btnLoginLoading) {
			this.btnLoginLoading = true;
			const username = this.loginForm.get('emailAddressFormControl').value;
			const password = this.loginForm.get('passwordFormControl').value;
			
			this.authStoreService.userLogin(username, password, this.companyStoreService.branch.guid).then(response => {
				// Is auth returns as a string not a boolean.
				if (response.isAuth == "true") {
					this.incorrectLogin = false;
					this.customerStoreService.getCustomer(this.companyStoreService.branch.guid).then(() => {
						setTimeout(() => {
							this.modal.close(true);
							setTimeout(() => {
								this.btnLoginLoading = false;
							}, this.config.loadingPanelDelay);
						}, this.config.loadingPanelDelay);
					})
				} else {
					this.incorrectLogin = true;
					this.btnLoginLoading = false;
				}
			});
		}
	}

	navigate(step: string, title?: string) {
		this.loading = true;
		switch (step) {
			case 'Login':
				setTimeout(() => {
					this.data.template = 'Login';
					this.data.title = title ? title : 'Login to your account';
					this.loading = false;
				}, this.config.loadingPanelDelay);
				break;
			case 'ForgottenPassword':
				setTimeout(() => {
					this.data.template = 'ForgottenPassword';
					this.data.title = title ? title : 'Forgotten password';
					this.loading = false;
				}, this.config.loadingPanelDelay);
				break;
		}
	}

}
