import { Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';

import { RollbarService } from '../../app.module';

import { BasketStoreService } from '../basket/basket-store.service';
import { VehicleStoreService } from '../vehicle/vehicle-store.service';
import { CustomerStoreService } from '../customer/customer-store.service';

@Injectable({
	providedIn: 'root',
})
export class LoggerStoreService {
	constructor(private injector: Injector) {}

	// #region Normal logging
	log(message) {
		if (!environment.production) {
			console.log(message);
		}
	}

	table(message) {
		if (!environment.production) {
			console.table(message);
		}
	}
	// #endregion

	// #region Rollbar logging
	logCritialToRollBar(error: Error) {
		if (environment.rollbarLogging) {
			const rollbar = this.initRollbarWithPayload();

			rollbar.critical(error);
		}
		console.error(error);
	}

	logErrorToRollBar(error: Error) {
		console.error(error);

		if (environment.rollbarLogging) {
			const message = error.message;
			const rollbar = this.initRollbarWithPayload();
			if (message.toLowerCase().includes('403') || message.toLowerCase().includes('429')) {
				rollbar.info(error);
				return;
			}

			if (message.toLowerCase().includes('paypal') || message.toLowerCase().includes('online payment')) {
				this.logCritialToRollBar(error);
				return;
			}

			rollbar.error(error);
		}
	}

	private initRollbarWithPayload() {
		const rollbar = this.injector.get(RollbarService);

		const basketStoreService = this.injector.get(BasketStoreService);
		const vehicleStoreService = this.injector.get(VehicleStoreService);
		const customerStoreService = this.injector.get(CustomerStoreService);

		const safeCustomer = customerStoreService.customer;
		if (safeCustomer) {
			safeCustomer.password = '';
			safeCustomer.firstName = '';
			safeCustomer.phone = '';
		}

		rollbar.configure({
			payload: {
				bookingDate: basketStoreService.bookingDateTime,
				slotRange: basketStoreService.bookingSlotRange,
				customer: safeCustomer || '',
				vehicle: vehicleStoreService.vehicle || '',
				slotTypes: basketStoreService.slotTypes || '',
				specialOffers: basketStoreService.specialOffers || '',
				tyres: basketStoreService.tyres || '',
				extras: basketStoreService.extras || '',
			},
		});

		return rollbar;
	}
	// #endregion
}
