<div [formGroup]="form" class="vehicle-form">
	<div class="select-vehicle-type btn-group" *ngIf="!this.vehicleStoreService.vehicleType">
		<button
			type="button"
			class="btn"
			[ngClass]="{
				'btn-primary': selectedVehicleType == 1,
				'btn-light': selectedVehicleType != 1
			}"
			(click)="selectVehicleType(1)"
		>
			Car
		</button>
		<button
			type="button"
			class="btn"
			[ngClass]="{
				'btn-primary': selectedVehicleType == 3,
				'btn-light': selectedVehicleType != 3
			}"
			(click)="selectVehicleType(3)"
		>
			Bike
		</button>
		<button
			type="button"
			class="btn"
			[ngClass]="{
				'btn-primary': selectedVehicleType == 2,
				'btn-light': selectedVehicleType != 2
			}"
			(click)="selectVehicleType(2)"
		>
			Van
		</button>
	</div>

	<div>
		<mat-form-field *ngIf="config.forms.vehicle.registration.enabled" [appearance]="config.formAppearance">
			<mat-label>Vehicle registration</mat-label>
			<input matInput formControlName="registrationFormControl" />
			<mat-error *ngIf="form.get('registrationFormControl').hasError('required')"> Registration is <strong>required</strong> </mat-error>
			<mat-error *ngIf="!form.get('registrationFormControl').hasError('required') && form.get('registrationFormControl')?.hasError('maxlength')">
				Vehicle registration is too long
			</mat-error>
		</mat-form-field>
	</div>
	<div class="form-row">
		<mat-form-field *ngIf="config.forms.vehicle.make.enabled" [appearance]="config.formAppearance" class="col-sm-6">
			<mat-label>Vehicle make</mat-label>
			<input type="text" matInput formControlName="vehicleMakesFormControl" [matAutocomplete]="makeAuto" />
			<mat-autocomplete autoActiveFirstOption #makeAuto="matAutocomplete" [displayWith]="displayMake" (optionSelected)="onMakeSelected($event)">
				<mat-option *ngFor="let make of filteredMakes | async" [value]="make">
					{{ make.name }}
				</mat-option>
			</mat-autocomplete>
			<mat-error *ngIf="form.get('vehicleMakesFormControl').hasError('required')"> Vehicle make is <strong>required</strong> </mat-error>
			<mat-error *ngIf="!form.get('vehicleMakesFormControl').hasError('required') && form.get('vehicleMakesFormControl')?.hasError('maxlength')">
				Vehicle make is too long
			</mat-error>
		</mat-form-field>
		<mat-form-field *ngIf="config.forms.vehicle.model.enabled" class="col-sm-6" [appearance]="config.formAppearance">
			<mat-label>Vehicle model</mat-label>
			<input type="text" matInput formControlName="vehicleModelFormControl" [matAutocomplete]="modelAuto" />
			<mat-autocomplete autoActiveFirstOption #modelAuto="matAutocomplete" [displayWith]="displayModel">
				<mat-option *ngFor="let model of filteredModels | async" [value]="model">
					{{ model.name }}
				</mat-option>
			</mat-autocomplete>
			<mat-error *ngIf="form.get('vehicleModelFormControl').hasError('required')"> Vehicle model is <strong>required</strong> </mat-error>
			<mat-error *ngIf="!form.get('vehicleModelFormControl').hasError('required') && form.get('vehicleModelFormControl')?.hasError('maxlength')">
				Vehicle model is too long
			</mat-error>
		</mat-form-field>
	</div>
	<div class="engine-size-wrapper form-row">
		<mat-form-field *ngIf="config.forms.vehicle.fuelType.enabled" class="col-sm-6" [appearance]="config.formAppearance">
			<mat-label>Select fuel type</mat-label>
			<mat-select formControlName="fuelTypeFormControl">
				<mat-option *ngFor="let fuelType of vehicleStoreService.fuelTypes" [value]="fuelType">
					{{ fuelType.description }}
				</mat-option>
			</mat-select>
			<mat-error *ngIf="form.get('engineSizeFormControl').hasError('required')"> Fuel type is <strong>required</strong> </mat-error>
		</mat-form-field>

		<mat-form-field *ngIf="config.forms.vehicle.engineSize.enabled" class="col-sm-6" [appearance]="config.formAppearance">
			<mat-label>Engine size</mat-label>
			<input matInput formControlName="engineSizeFormControl" type="number" (blur)="engineSizeChange()" />
			<span matSuffix *ngIf="form.get('engineSizeFormControl')?.enabled">cc</span>

			<mat-hint align="start" *ngIf="form.get('engineSizeFormControl')?.enabled">Please enter your engine size in CC. E.g. 1200.</mat-hint>
			<mat-error *ngIf="form.get('engineSizeFormControl').hasError('required')"> Engine size is <strong>required</strong> </mat-error>
		</mat-form-field>
	</div>
</div>
