<!-- Only Tyres -->
<ng-container *ngIf="tyresOnly">
    <div class="tyre-results-wrapper">
        <app-tyre-results (tyreSelected)="tyreSelected()" (continue)="continue()"></app-tyre-results>
    </div>
</ng-container>

<!-- Everything -->
<ng-container *ngIf="!tyresOnly">
    <div *ngIf="config.tyres.enabled && companyStoreService.branch.tyreSlotID > 0">
        <app-tyre-launch></app-tyre-launch>
    </div>
    <div *ngIf="config.services.enabled">        
        
        <app-special-offer-list></app-special-offer-list>
        <app-slot-type-list></app-slot-type-list>

    </div>
</ng-container>
