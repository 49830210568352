import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-tyre-icons',
    templateUrl: './tyre-icons.component.html',
    styleUrls: ['./tyre-icons.component.scss']
})
export class TyreIconsComponent implements OnInit {

    @Input() template: string;
    @Input() tyreCategory: number;
    @Input() summerTyre: boolean;
    @Input() winterTyre: boolean;
    @Input() extraLoad: boolean;
    @Input() runFlat: boolean;
    @Input() suvTyre: boolean;
    @Input() vanTyre: boolean;

    constructor() { }

    ngOnInit(): void { }

}
