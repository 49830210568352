<ng-container *ngIf="vrmType == 'Standard'">
    <h4>
        <span>{{config.title || 'Enter your vehicle registration'}}</span>
    </h4>
    <div class="standard-vrm">
        <app-vehicle-vrm-lookup [checkTyres]="false" (vrmComplete)="vrmComplete(false)" (enterVehicleManually)="enterVehicleManually($event)"></app-vehicle-vrm-lookup>
    </div>
</ng-container>

<ng-container *ngIf="vrmType == 'Tyres'">
    <h4>
        <span>{{config.title || 'Reserve tyres online'}}</span>
    </h4>

    <div class="{{searchType}}-search">
        <div class="tabbed-nav" *ngIf="vehicleStoreService.vrmAvailable()">
            <button (click)="searchType = 'vrm'" [ngClass]="{'active': searchType == 'vrm'}">
                Search by reg
            </button>
            <button (click)="searchType = 'manual'" [ngClass]="{'active': searchType == 'manual'}">
                Search by size
            </button>
        </div>

        <div class="tyre-search">
            <div class="tyre-vrm-lookup" *ngIf="vehicleStoreService.vrmAvailable()">
                <app-vehicle-vrm-lookup [checkTyres]="true" (vrmComplete)="vrmComplete(true)" (enterVehicleManually)="enterVehicleManually($event)"></app-vehicle-vrm-lookup>
            </div>
            <div class="or" *ngIf="vehicleStoreService.vrmAvailable()">
                <span>Or</span>
            </div>
            
            <div class="manual-entry">
                <app-tyre-size-form (confirmTyreSize)="confirmTyreSize()"></app-tyre-size-form>
            </div>
        </div>
        <div class="tyre-image">
            <img src="{{config.tyres.tyreSizeDescriptionImage}}" />
        </div>
    </div>

</ng-container>