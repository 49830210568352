import { Component, OnInit, Output, EventEmitter, Pipe } from '@angular/core';
import { ExtraStoreService } from 'src/app/services/extra/extra-store.service';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { IExtra } from 'src/app/interfaces/extra.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';

@Component({
    selector: 'app-extra-list',
    templateUrl: './extra-list.component.html',
    styleUrls: ['./extra-list.component.scss']
})
export class ExtraListComponent implements OnInit {

    config: IComponentConfig;

    constructor(
        public extraStoreService: ExtraStoreService,
        public basketStoreService: BasketStoreService,
        private configStoreService: ConfigStoreService
    ) { }

    ngOnInit(): void { 
        this.config = this.configStoreService.getComponentConfig('ExtraListComponent');
    }

    addRemoveExtra(extra: IExtra) {
        this.basketStoreService.addRemoveExtra(extra);
    }
}
