import { Injectable } from '@angular/core';
import { VehicleService } from './vehicle.service';
import { BehaviorSubject } from 'rxjs';
import { IVehicle, IFuelType } from 'src/app/interfaces/vehicle.interface';
import { LoggerStoreService } from '../logger/logger-store.service';
import { IVehicleData } from 'src/app/interfaces/vehicle-data.interface';
import { UntypedFormGroup } from '@angular/forms';
import { VehicleModel } from 'src/app/models/vehicle.model';
import { CompanyStoreService } from '../company/company-store.service';
import { ITyreSize } from 'src/app/interfaces/tyre.interface';
import { LocalStorageStoreService } from '../local-storage/local-storage-store.service';
import { VehicleType } from 'src/app/global/enums';
import { IMOTVehicle } from '../../interfaces/vehicle.interface';
import { AuthStoreService } from '../auth/auth-store.service';
import { LocalStorageKey } from '../../global/enums';

@Injectable({
	providedIn: 'root',
})
export class VehicleStoreService {
	constructor(
		private vehicleService: VehicleService,
		private loggerStoreService: LoggerStoreService,
		private authStoreService: AuthStoreService,
		private companyStoreService: CompanyStoreService,
		private localStorageStoreService: LocalStorageStoreService
	) {
		this.vehicle = new VehicleModel();
	}

	public vehicleType: VehicleType;

	private readonly _vehicle = new BehaviorSubject<IVehicle>({} as IVehicle);
	readonly vehicle$ = this._vehicle.asObservable();

	get vehicle(): IVehicle {
		return this._vehicle.getValue();
	}

	set vehicle(val: IVehicle) {
		this._vehicle.next(val);
	}

	async doVrmLookup(branchGuid: string, vehicleRegistration: string) {
		if (!branchGuid) {
			this.loggerStoreService.log(`Vehicle Store Service: No Branch Guid.`);
			return;
		}
		if (vehicleRegistration == this.vehicle?.registrationNumber && this.vehicle?.tyreSetList?.length > 0) {
			// If we have a vehicle, even from local storage, and it has a tyreset list, just return that rather than do additional VRM lookups.
			return this.vehicle;
		}
		this.loggerStoreService.log(`Vehicle Store Service: Attempting VRM Lookup with registration ${vehicleRegistration}.`);
		this.vehicle = await this.vehicleService.get(vehicleRegistration, branchGuid).toPromise();

		if (this.vehicle.tyreSetList.length) {
			this.vehicle.tyreSetList = this.vehicle.tyreSetList.sort((a, b) => (+a.frontTyre.diameter > +b.frontTyre.diameter ? 1 : -1));
			this.getFrontTyreSizes();
			this.getRearTyreSizes();
		}
		this.localStorageStoreService.set(LocalStorageKey.Vehicle, this.authStoreService.clientId, JSON.stringify(this.vehicle));
		this.loggerStoreService.table(this.vehicle);
		return this.vehicle;
	}

	async checkVehicleMOT(vehicleRegistration: string) {
		this.loggerStoreService.log(`Vehicle Store Service: Attempting DVSA VRM Lookup with registration ${vehicleRegistration}.`);
		const dvsaVehicle: IMOTVehicle = await this.vehicleService.getMOT(vehicleRegistration).toPromise();

		console.log(dvsaVehicle);

		this.localStorageStoreService.set(LocalStorageKey.DvsaVehicle, this.authStoreService.clientId, JSON.stringify(dvsaVehicle));
		return dvsaVehicle;
	}

	hasValidVehicle() {
		if (!this.vehicle?.make || !this.vehicle?.registrationNumber) {
			return false;
		} else {
			return true;
		}
	}

	async clearVehicle() {
		this.vehicle = new VehicleModel();
		this.localStorageStoreService.remove(LocalStorageKey.Vehicle, this.authStoreService.clientId);
	}

	getOrderedTyreList() {
		if (!this.vehicle.tyreSetList?.length) return [];
		return this.vehicle.tyreSetList.sort((a, b) => (+a.frontTyre.diameter > +b.frontTyre.diameter ? 1 : -1));
	}

	availableFrontTyreSizes: ITyreSize[] = [];
	getFrontTyreSizes() {
		if (!this.vehicle.tyreSetList?.length) return [];
		const frontTyreSizes: ITyreSize[] = [];

		this.vehicle.tyreSetList.forEach((tyreSet) => {
			if (tyreSet?.frontTyre?.width && tyreSet?.frontTyre?.profile && tyreSet?.frontTyre?.diameter) {
				const index = frontTyreSizes.indexOf(
					frontTyreSizes.find((ts) => ts.width == tyreSet.frontTyre.width && ts.profile == tyreSet.frontTyre.profile && ts.diameter == tyreSet.frontTyre.diameter)
				);
				if (index == -1) {
					frontTyreSizes.push(tyreSet.frontTyre);
				}
			}
		});

		this.availableFrontTyreSizes = frontTyreSizes.sort((a, b) => {
			if (+a.width > +b.width) return 1;
			if (+a.width < +b.width) return -1;

			if (+a.profile > +b.profile) return 1;
			if (+a.profile < +b.profile) return -1;

			if (+a.diameter > +b.diameter) return 1;
			if (+a.diameter < +b.diameter) return -1;
		});
	}

	availableRearTyreSizes: ITyreSize[] = [];
	getRearTyreSizes() {
		if (!this.vehicle.tyreSetList?.length) return [];
		const rearTyreSizes: ITyreSize[] = [];

		this.vehicle.tyreSetList.forEach((tyreSet) => {
			if (tyreSet?.rearTyre?.width && tyreSet?.rearTyre?.profile && tyreSet?.rearTyre?.diameter) {
				const index = rearTyreSizes.indexOf(
					rearTyreSizes.find((ts) => ts.width == tyreSet.rearTyre.width && ts.profile == tyreSet.rearTyre.profile && ts.diameter == tyreSet.rearTyre.diameter)
				);
				if (index == -1) {
					rearTyreSizes.push(tyreSet.rearTyre);
				}
			}
		});

		this.availableRearTyreSizes = rearTyreSizes.sort((a, b) => {
			if (+a.width > +b.width) return 1;
			if (+a.width < +b.width) return -1;

			if (+a.profile > +b.profile) return 1;
			if (+a.profile < +b.profile) return -1;

			if (+a.diameter > +b.diameter) return 1;
			if (+a.diameter < +b.diameter) return -1;
		});
	}

	isValidVrmResult(vehicle: IVehicle = this.vehicle, checkTyreSizes: boolean = false) {
		if (!vehicle.make || vehicle.make === 'N/A' || !vehicle.registrationNumber || vehicle.registrationNumber === '') {
			return false;
		}
		if (checkTyreSizes && !this.vehicle.tyreSetList.length) {
			return false;
		}
		return true;
	}

	getFormattedVehicle(vehicle: IVehicle = this.vehicle) {
		let formattedVehicle = '';
		if (vehicle.make) {
			formattedVehicle += vehicle.make + ' ';
		}
		if (vehicle.model) {
			formattedVehicle += vehicle.model + ' ';
		}
		if (vehicle.registrationNumber) {
			formattedVehicle += vehicle.registrationNumber.toUpperCase() + ' ';
		}
		return formattedVehicle;
	}

	getFormattedTyreSize() {
		let formattedTyreSize = '';
		if (this.vehicle?.tyreSize?.width) {
			formattedTyreSize += this.vehicle.tyreSize.width + '/';
		}
		if (this.vehicle?.tyreSize?.profile) {
			formattedTyreSize += this.vehicle.tyreSize.profile + '/';
		}
		if (this.vehicle?.tyreSize?.diameter) {
			formattedTyreSize += this.vehicle.tyreSize.diameter;
		}
		return formattedTyreSize;
	}

	vrmAvailable() {
		if (this.companyStoreService.company.companyLevelVrmAvailable || this.companyStoreService.branch.branchLevelVrmAvailable) {
			return true;
		} else {
			return false;
		}
	}

	private readonly _vehicleData = new BehaviorSubject<IVehicleData[]>([]);
	readonly vehicleData$ = this._vehicleData.asObservable();

	get vehicleData(): IVehicleData[] {
		return this._vehicleData.getValue();
	}

	set vehicleData(val: IVehicleData[]) {
		this._vehicleData.next(val);
	}

	async getVehicleData() {
		this.vehicleData = await this.vehicleService.getVehicleData().toPromise();
	}

	private readonly _fuelTypes = new BehaviorSubject<IFuelType[]>([]);
	readonly fuelTypes$ = this._vehicleData.asObservable();

	get fuelTypes(): IFuelType[] {
		return this._fuelTypes.getValue();
	}

	set fuelTypes(val: IFuelType[]) {
		this._fuelTypes.next(val);
	}

	async getFuelTypes() {
		this.fuelTypes = await this.vehicleService.getFuelTypes().toPromise();
	}

	async saveVehicleForm(vehicleForm: UntypedFormGroup) {
		let tempVehicle: Partial<IVehicle> = {
			make: vehicleForm.get('vehicleMakesFormControl').value.name || vehicleForm.get('vehicleMakesFormControl').value,
			model: vehicleForm.get('vehicleModelFormControl').value.name || vehicleForm.get('vehicleModelFormControl').value,
			engineCC: +vehicleForm.get('engineSizeFormControl').value,
			registrationNumber: vehicleForm.get('registrationFormControl').value,
			fuelTypeID: +vehicleForm.get('fuelTypeFormControl').value.id | 0,
		};
		this.vehicle = new VehicleModel(tempVehicle);
		this.localStorageStoreService.set(LocalStorageKey.Vehicle, this.authStoreService.clientId, JSON.stringify(this.vehicle));
	}
}
