import { Component, ElementRef, OnInit, Input, Output, EventEmitter, HostBinding, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as IPayPal from './paypal.interface';
import { loadScript } from "@paypal/paypal-js";

let paypal;

@Component({
    selector: 'app-paypal-buttons',
    template: '',
    styleUrls: [],
})
export class PaypalButtonsComponent implements OnInit, OnDestroy {
    /** Paypal Developer App Client ID */
    @Input() clientID: string;
    /** An object of the complete order aka basket/trolly/cart */
    @Input() set orderObject(order: IPayPal.IPayPalOrderRequest) {
        this.orderObject$.next(order);
    };
    get orderObject(): IPayPal.IPayPalOrderRequest { return this.orderObject$.value; }
    private orderObject$ = new BehaviorSubject<IPayPal.IPayPalOrderRequest>(null);

    /** Disables the buttons */
    @Input() set disabled(disabled: boolean) {
        this.disable$.next(disabled);
    }
    /** True when disabled */
    get disabled(): boolean { return this.disable$.value; }
    private disable$ = new BehaviorSubject<boolean>(false);
    // Tweak the component style to reflect the disabled status
    @HostBinding('style.opacity') get opacity() { return this.disabled ? '0.55' : undefined; }
    @HostBinding('style.pointer-events') get cursor() { return this.disabled ? 'none' : undefined; }
    /** Allows the PayPal Smart buttons to handel Capturing
     * payment, should be used if there is no internal API 
     * to handel it.
     */
    @Input() buttonsCapturePayment = false;
    @Input() currency: IPayPal.TCurrency = 'GBP';

    @Output() click: EventEmitter<any> = new EventEmitter<any>();
    @Output() buttonsInit: EventEmitter<any> = new EventEmitter();
    @Output() createdOrder: EventEmitter<any> = new EventEmitter();
    @Output() approvedPayment: EventEmitter<any> = new EventEmitter();
    @Output() capturedPayment: EventEmitter<any> = new EventEmitter();
    @Output() error: EventEmitter<any> = new EventEmitter();


    private disabledSub: Subscription;
    private orderSub: Subscription;

    constructor(
        private el: ElementRef        
    ) { }

    async ngOnInit(): Promise<void> {

        /* Example Order object
        this.orderObject = {
          intent: 'CAPTURE',
          purchase_units: [{
            // Total amount of the order
            amount: {    
              currency_code: 'GBP',
              value: '9.99',     
              breakdown: {
                item_total: {
                  currency_code: 'GBP',
                  value: '9.99'
                }
              }
            },
            // List all the items in the order
            // Basically the slot types/tyres
            items: [{
              name: 'Test Item',
              quantity: '1',
              unit_amount: {
                currency_code: 'GBP',
                value: '9.99'
              }          
            }]
          }]      
        } */

        if (!this.clientID) {
            console.error("PaypalButtonsComponent: Init: Missing Client ID from paypal smart buttons");
            return;
        }

        if (!this.orderObject) {
            console.warn("PaypalButtonsComponent: Init: Missing order from paypal smart buttons");
        }

        // this checks PayPal global variable to see if script it loaded
        // if not add it
        try {
            paypal = await loadScript(
                { 
                    "client-id": this.clientID,
                    currency: "GBP",

                }
            );
        } catch (error) {
            console.error("PaypalButtonsComponent: Failed to load the PayPal JS SDK script", error);
        }

        if (paypal) {
            try {
                this.renderButtons();
            } catch (error) {
                console.error("failed to render the PayPal Buttons", error);
            }
        }
    }

    renderButtons(): void {
        // Rendering PayPal Buttons
        paypal
            .Buttons({
                onInit: (data, actions) => {
                    this.onPayPalInit(data, actions);
                },
                onClick: (data, actions) => {
                    // Emits the onClick data
                    this.click.emit(data);
                    // Prevents the execution when no orders nor subscriptions are defined
                    return !!this.orderObject ? actions.resolve() : actions.reject();
                },
                createOrder: (data, actions) => {
                    return this.onPayPalCreateOrder(data, actions);
                },
                onApprove: (data, actions) => {
                    this.onPayPalApprove(data, actions);
                },
                onError: (err) => {
                    this.error.emit(err);
                },
            })
            .render(this.el.nativeElement);
        // Finished Rendering PayPal Buttons
    }

    onPayPalInit(data, actions) {
        // Unsubscribes previous subscriptions, if any
        if (this.disabledSub) {
            this.disabledSub.unsubscribe();
        }

        // Subscribes to the disable observable
        this.disabledSub = this.disable$.subscribe(disabled => {
            // Disables/Enables the buttons according to the disable observable value 
            if (disabled) { actions.disable(); }
            else { actions.enable(); }
        })

        this.buttonsInit.emit(data);
    }

    onPayPalCreateOrder(data, actions) {
        this.createdOrder.emit(data);
        return actions.order.create(this.orderObject);
    }

    onPayPalApprove(data, actions) {
        this.approvedPayment.emit(data);

        if (this.buttonsCapturePayment) {
            return actions.order.capture().then((details) => {
                this.capturedPayment.emit(details);
            });
        }
    }

    ngOnDestroy() {
        // Unsubscribes the observables
        if (this.disabledSub) {
            this.disabledSub.unsubscribe();
        }
    }
}
