<form [formGroup]="passwordRecoveryForm" (ngSubmit)="recoverPassword()" class="recover-password-form">
    <div class="recover" [hidden]="passwordRecoverySent">
        <div>
            <mat-form-field [appearance]="config.formAppearance">
                <mat-label>Email address</mat-label>
                <input formControlName="emailAddressFormControl" matInput>
                <mat-error *ngIf="passwordRecoveryForm.get('emailAddressFormControl').hasError('serverError')">
                    {{passwordRecoveryErrorResponse}}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="alert alert-success" [hidden]="!passwordRecoverySent">
        Please check your email for instructions to reset your password.
    </div>
 
    <button class="btn btn-primary btn-lg btn-block" [disabled]="!passwordRecoveryForm.valid || btnLoading" [hidden]="passwordRecoverySent">
        <img class="btn-loading-img" src="{{env.assetsUrl}}/images/loader.svg" *ngIf="btnLoading" /> Continue</button>
</form>