import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountEditCustomerComponent } from './account-edit-customer/account-edit-customer.component';
import { AccountManageBookingsComponent } from './account-manage-bookings/account-manage-bookings.component';
import { AccountChangePasswordComponent } from './account-change-password/account-change-password.component';
import { CheckoutModule } from '../checkout/checkout.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
 import { AccountPasswordRecoveryComponent } from './account-password-recovery/account-password-recovery.component';
import { AccountPasswordResetComponent } from './account-password-reset/account-password-reset.component';
import { AppRoutingModule } from 'src/app/app-routing.module';



@NgModule({
  declarations: [AccountEditCustomerComponent, AccountManageBookingsComponent, AccountChangePasswordComponent, AccountPasswordRecoveryComponent, AccountPasswordResetComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    CheckoutModule,
    PipesModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [ AccountPasswordResetComponent, AccountPasswordRecoveryComponent ]
})
export class AccountModule { }
