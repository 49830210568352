import { Injectable } from '@angular/core';
import { IParentPage } from 'src/app/interfaces/parent-page.interface';
import { ClientService } from '../client/client.service';
import { LoggerStoreService } from '../logger/logger-store.service';
declare var parentIFrame;

@Injectable({
	providedIn: 'root'
})
export class IframeResizerStoreService {

	parentPage: IParentPage;
	isInsideIframe = false;

	constructor(
		private loggerStoreService: LoggerStoreService,
		private clientService: ClientService
	) {
		const root = document.getElementsByTagName('html')[0];
		// Checking if we're in an iframe
		if (self == top) {
			// not in an iframe
			root.className += ' no-iframe';
			this.updateMaxModalHeight();
		} else {
			// in iframe
			root.className += ' iframe';
			this.isInsideIframe = true;
			this.getPageInfo();

			window.addEventListener("message", (event) => {
				this.handleIframeParentMessage(event);
			}, false);
		}
	}

	pageInfoCounter = 0;
	getPageInfo() {
		if (this.pageInfoCounter > 20) {
			return;
		}
		if ('parentIFrame' in window) {
			parentIFrame.getPageInfo(
				(pageInfo) => {
					const w = this.parentPage?.windowHeight;
					this.parentPage = pageInfo;
					if (w != this.parentPage?.windowHeight) {
						this.updateMaxModalHeight();
					}					
				}
			);			

			this.loggerStoreService.log("[iFrameSizer] Service: Attempting manual resize from angular");
			parentIFrame.size();
		} else {
			setTimeout(() => {
				this.loggerStoreService.log('[iFrameSizer] Service: Unable to get parentIFrame:' + this.pageInfoCounter);
				this.pageInfoCounter += 1;
				this.getPageInfo();
			}, 500);
		}

	}

	getMaxHeight(fallbackValue: string = '') {
		if (this.isIFrameContent()) {
			return this.parentPage.windowHeight - 60 + 'px';
		} else {
			return fallbackValue;
		}
	}

	getPosition() {
		if (this.isIFrameContent()) {
			return { top: '30px' };
		} else {
			return { };
		}
	}

	updateMaxModalHeight() {
		let root = document.documentElement;
		if (this.isIFrameContent()) {
			// Check height of parent page vs current iframe height.
			if (window.innerHeight < this.parentPage.windowHeight) {
				root.style.setProperty('--max-modal-height', 'calc(100% - 3.5rem)');
			} else {
				root.style.setProperty('--max-modal-height', 'calc(' + this.parentPage.windowHeight + 'px - 3.5rem)');
			}
		} else {
			root.style.setProperty('--max-modal-height', 'calc(100% - 3.5rem)');
		}
	}

	scrollToIFrameTop() {
		if (this.isIFrameContent()) {
			parentIFrame.scrollToOffset(0, 0);
		}
	}

	isIFrameContent() {
		if ('parentIFrame' in window) {
			return true;
		} else {
			return false;
		}
	}

	handleIframeParentMessage(event) {

		// missing a command
		if(!event.data?.command) { 
			// this.loggerStoreService.logErrorToRollBar( new Error(`Iframe message missing command string`)) 
			return;
		}

		// update our client info service so we know where we are embeded
		if(event.data.command.toLowerCase() === "update_top_url") {			
			this.clientService.clientInfo.topWindowURL = event.data.args[0];
			console.log(this.clientService.clientInfo.topWindowURL);
		}
	}

}
