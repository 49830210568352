import { IConfig } from '../interfaces/config.interface';
import { KeyValue } from '@angular/common';

export class ConfigModel implements IConfig {
	public constructor(init?: Partial<IConfig>) {
		if (init?.services) {
			init.services = Object.assign(this.services, init.services);
		}
		if (init?.tyres) {
			init.tyres = Object.assign(this.tyres, init.tyres);
		}
		if (init?.buttonTexts) {
			init.buttonTexts = Object.assign(this.buttonTexts, init.buttonTexts);
		}
		if (init?.templates) {
			init.templates = Object.assign(this.templates, init.templates);
		}
		if (init?.titles) {
			init.titles = Object.assign(this.titles, init.titles);
		}
		if (init?.descriptions) {
			init.descriptions = Object.assign(this.descriptions, init.descriptions);
		}
		// More than one of these needs to be enabled in order for the config to be valid.
		if (init && [init.vehicleTypeCarEnabled, init.vehicleTypeBikeEnabled, init.vehicleTypeVanEnabled].filter(Boolean).length > 1) {
			init.selectVehicleType = true;
		}
		Object.assign(this, init);
		this.formAppearance = 'outline';
	}
	id?: number;

	services = {
		enabled: true,
		showSlotTypeGroups: true,
		showSlotTypeLongDescription: true,
		showSlotTypeIcon: true,
		showExtraDescription: true,
		showExtraIcon: true,
		showSpecialOfferLongDescription: true,
		showSpecialOfferIcon: true,
	};

	tyres = {
		enabled: true,
		featuredTyreBrand: 'landsail',
		featuredTyreBrandFilters: null,
		minimumTyreStockLevel: 2,
		tyrePriceDescription: 'Fully fitted',
		tyreDescription: 'Price include fitting, valves, balancing and casing disposal and VAT.',
		showTyreImage: true,
		showTyreDescription: true,
		tyreCategories: {
			category1: 'Premium',
			category2: 'Midrange',
			category3: 'Budget',
		},
		tyreSizeDescriptionImage: 'https://www.ractyres.co.uk/assets/img/sprites/homepage/enter-size-form-tyre.png',
	};

	buttonTexts = {
		default: 'Continue',
		companySelectBranchComponent: 'Select this branch',
		tyreResultsComponent: 'Add to booking',
		slotTypeListComponent: 'Add',
		specialOfferListComponent: 'Add',
		specialOfferUpgradeListComponent: 'Upgrade',
		tyreLaunchComponent: 'Find tyres',
		dialogVehicleEntryComponent: 'Search',
		checkoutEnterDetailsForm: 'Complete',
		widgetBookingSystemLinkComponent: 'Book online',
		widgetServicesComponent: 'Book Now',
		widgetServicesListComponent: 'Book Now',
		slotTypeServiceChecklistComponent: 'Add',
	};

	templates = {
		default: 'list',
		slotTypeListComponent: 'tile',
		specialOfferListComponent: 'tile',
		basketComponent: 'compact', // compact or large
		stepSelectDateTimeComponent: 'calendar', // calendar or dropdowns
	};

	titles = {
		default: '',
		tyreLaunchComponent: 'Tyres',
		widgetMOTReminderComponent: 'Get FREE MOT reminders',
	};

	descriptions = {
		default: '',
		tyreLaunchComponent: 'Add tyres to your booking by searching through our stock online.',
		widgetBookingSystemLinkComponent: 'You can book directly into our booking diary using our live online booking system. To get started, click the link below.',
		widgetMOTReminderComponent: 'Check when your MOT is due and get FREE MOT reminders.',
	};

	forms = {
		customer: {
			emailAddress: { enabled: true, required: true },
			password: { enabled: true, required: true },
			title: { enabled: true, required: true },
			firstName: { enabled: true, required: true },
			surname: { enabled: true, required: true },
			company: { enabled: true, required: false },
			mobileNumber: { enabled: true, required: true },
			landlineNumber: { enabled: true, required: false },
			notes: { enabled: true, required: false },
			address1: { enabled: true, required: true },
			address2: { enabled: true, required: false },
			address3: { enabled: true, required: false },
			townCity: { enabled: true, required: true },
			county: { enabled: true, required: true },
			postcode: { enabled: true, required: true },
		},
		vehicle: {
			registration: { enabled: true, required: true },
			make: { enabled: true, required: true },
			model: { enabled: true, required: true },
			engineSize: { enabled: true, required: true },
			vehicleType: { enabled: true, required: true },
			fuelType: { enabled: true, required: false },
		},
	};

	startWithVehicle = false;
	brandPrimary = '';
	brandPrimaryHover = '';
	vehicleDealerID = -1;

	// 2 booking modes, 'slot' and 'session. Slot will show the slot start time. Session will show AM / PM. For this to work, all morning slots need to
	// be at the same time in VGM, and all afternoon slots need to be at the same time.
	bookingMode = 'slot';
	skipBasket = false;
	skipUpsell = true;
	loadingPanelDelay = 800;
	formAppearance = 'outline';
	// Change this url if you want to specific a different URL. This is handled automatically so shouldn't be needed.
	passwordRecoveryUrl = '';
	vehicleFeedUrl;
	bookingSystemUrl;
	googleAnalyticsID = '';
	facebookPixelID = '';
	mobileRedirect = true;
	mobileCutoff = 992;
	parentElementCutOff = 1000;
	mobileFallbackComponent = '';
	branches = [];
	selectVehicleType = false;
	vehicleTypeCarEnabled = false;
	vehicleTypeVanEnabled = false;
	vehicleTypeBikeEnabled = false;
	onlinePaymentsPaypal = false;
	vehicleFeedCardImage = 'cover';
	vehicleFeedDetailImage = 'cover';
	confirmationReturnURL = '';

	slotTypeIDs = [];
	slotTypeGroupIDs = [];
	specialOfferIDs = [];

	vehicleTypeCarImage = '';
	vehicleTypeCarTitle = '';
	vehicleTypeCarDescription = '';
	vehicleTypeBikeImage = '';
	vehicleTypeBikeTitle = '';
	vehicleTypeBikeDescription = '';
	vehicleTypeVanImage = '';
	vehicleTypeVanTitle = '';
	vehicleTypeVanDescription = '';

	unpricedItemsHeader = `Items without a price`;
	unpricedItemsSubHeader = `A member of our team will be in touch to confirm the final price of the following items`;

	serviceChecklistTemplate = 'link';
	serviceChecklistButtonText = 'Compare';
	serviceChecklistOpenByDefault = false;
	preferredDateConfiguration = '';
}
