<div class="modal-header mst">
    <h4 class="modal-title">{{data.title}}</h4>
    <button class="btn-close-dialog" (click)="modal.dismiss('Closed modal')">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body mst">
    {{data.content}}
</div>
<div class="modal-footer mst">
    <button type="button" class="btn btn-default" (click)="modal.dismiss('Closed modal')">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="modal.close(true)" ngbAutofocus>{{data.confirm || 'Confirm'}}</button>
</div>