import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SlotTypeStoreService } from 'src/app/services/slot-type/slot-type-store.service';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { ISlotTypePrice, ISlotType } from 'src/app/interfaces/slot-type.interface';
import { ISlotTypeGroup } from 'src/app/interfaces/slot-type-group.interface';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { Subscription } from 'rxjs';
import { SlotTypeType } from 'src/app/global/enums';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogConfirmComponent } from '../../dialogs/dialog-confirm/dialog-confirm.component';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';
import { ModalStoreService } from 'src/app/services/modal/modal-store.service';
import { DialogServiceChecklistComponent } from '../../dialogs/dialog-service-checklist/dialog-service-checklist.component';

@Component({
    selector: 'app-slot-type-list',
    templateUrl: './slot-type-list.component.html',
    styleUrls: ['./slot-type-list.component.scss']
}) 
export class SlotTypeListComponent implements OnInit, OnDestroy {

    config: IComponentConfig;
    columns: string;
    @Input() template: string;

    slotTypeGroups: ISlotTypeGroup[] = [];
    slotTypes: ISlotType[] = [];
    
    public slotTypeType = SlotTypeType; // Exposes Enum to template

    constructor(
        public slotTypeStoreService: SlotTypeStoreService,
        public basketStoreService: BasketStoreService,
        private configStoreService: ConfigStoreService,
        public companyStoreService: CompanyStoreService,
        public vehicleStoreService: VehicleStoreService,
        private modalService: NgbModal) { }

    ngOnInit(): void { 
        this.config = this.configStoreService.getComponentConfig('SlotTypeListComponent');
        // if a template is passed directly into the component, then use that, else, use the default template from config.
        this.template = this.template ? this.template : this.config.template;
    }

    getPricePreamble(slotType: ISlotType): string {
        switch(slotType.fullPrice.type) {
            case 1:
                return 'Price from';
            case 2:
                return 'Price to';
            case 4:
                return slotType.fullPrice.label;
            default:
                return ''
        }
    }

    isPOASlotType(slotType: ISlotType) {
        if (slotType.type == SlotTypeType.POA) {
            return true;
        }
        return false;
    }
    
    addRemoveSlotType(slotType: ISlotType) {
        this.basketStoreService.addRemoveSlotType(slotType);        
    }

    showSlotTypeServiceChecklist(slotTypeGroup: ISlotTypeGroup) {
        const size = slotTypeGroup.slotTypes.length > 3 ? 'xl' : 'lg';
        const modalRef = this.modalService.open(DialogServiceChecklistComponent, { size: size, windowClass: 'mst full-width-modal' });
        modalRef.componentInstance.data = {
            title: slotTypeGroup.name,
            slotTypeGroup: slotTypeGroup,
            readOnly: true
        };

         modalRef.result.then((response) => {

        }, (reason) => { });
    }

    ngOnDestroy() {
        
    }
}
