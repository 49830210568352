import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoggerStoreService } from 'src/app/services/logger/logger-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';
import { CustomerStoreService } from 'src/app/services/customer/customer-store.service';

@Component({
	selector: 'app-account-edit-customer',
	templateUrl: './account-edit-customer.component.html',
	styleUrls: ['./account-edit-customer.component.scss']
})
export class AccountEditCustomerComponent implements OnInit {

	editCustomerForm: UntypedFormGroup;
	constructor(
		public dialog: MatDialog,
		private loggerStoreService: LoggerStoreService,
		private companyStoreService: CompanyStoreService,
		public basketStoreService: BasketStoreService,
		public vehicleStoreService: VehicleStoreService,
		public customerStoreService: CustomerStoreService) { }

	ngOnInit(): void {
		this.loggerStoreService.log(`Account Edit Details: Initialised.`);
		this.editCustomerForm = new UntypedFormGroup({});
	}

}
