import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ISlotTypeGroup } from 'src/app/interfaces/slot-type-group.interface';
import { ITyreSizeWidth, ITyre } from 'src/app/interfaces/tyre.interface';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TyreService {

    constructor(private http: HttpClient) { }

    getTyres(branchGuid: string, manufacturerNames: string,
        tyreWidth: string, tyreProfile: string, tyreDiameter: string, tyreSpeed: string, tyreType: string, tyreId: number) {
        let params: any = {};

        if (branchGuid) { params.branchGuid = branchGuid; }
        if (manufacturerNames) { params.manufacturerNames = manufacturerNames; }
        if (tyreWidth) { params.width = tyreWidth; }
        if (tyreProfile) { params.profile = tyreProfile; }
        if (tyreDiameter) { params.rim = tyreDiameter; }
        if (tyreSpeed) { params.speed = tyreSpeed; }
        if (tyreType) {
            switch (tyreType.toLowerCase()) {
                case 'runflat':
                    params.runFlat = true;
                    break;
                case 'suvtyres':
                    params.suvtyres = true;
                    break;
                case 'vantyres':
                    params.vantyres = true;
                    break;
                case 'extraload':
                    params.extraLoad = true;
                    break;
                case 'wintertyre':
                    params.winterTyre = true;
                    break;
                case 'summertyre':
                    params.summerTyre = true;
                    break;
                case 'allSeasonTyre':
                    params.allSeasonTyre = true;
                    break;
                case 'budget':
                    params.budget = true;
                    break;
                case 'midrange':
                    params.midrange = true;
                    break;
                case 'premium':
                    params.premium = true;
                    break;
                case 'nextday':
                    params.nextDay = true;
                    break;
            }
        }
        
        if (tyreId && tyreId > 0) { params.tyreId = tyreId; };
        const queryString = Object.keys(params).map(function (key) {
            return key + '=' + params[key]
        }).join('&');

        return this.http.get<ITyre[]>(`${environment.apiUrl}/Api/Tyres/Get?${queryString}`).pipe(shareReplay(1));;
    }

    getTyre(branchGuid: string, tyreId: number) {
        let params: any = {};
        if (branchGuid) { params.branchGuid = branchGuid; }
        if (tyreId && tyreId > 0) { params.tyreId = tyreId; };
        const queryString = Object.keys(params).map(function (key) {
            return key + '=' + params[key]
        }).join('&');
        return this.http.get<ITyre[]>(`${environment.apiUrl}/Api/Tyres/Get?${queryString}`).pipe(shareReplay(1));;
    }

    getTyreSizes(branchGuid: string) {
        return this.http.get<ITyreSizeWidth[]>(`${environment.apiUrl}/Api/Tyres/TyreSizes/Get?branchGuid=${branchGuid}`).pipe(shareReplay(1));;
    }

    // Maybe we can move this somewhere better.
    getTyreSlotType(branchGuid: string) {
        return this.http.get<any>(`${environment.apiUrl}/Api/Branch/TyreSlot/Get?branchGuid=${branchGuid}`).pipe(shareReplay(1));;
    }
}
