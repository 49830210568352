import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ExtraService {

    constructor(private http: HttpClient) { }

    get(branchGuid: string, slotTypeIDs) {
        return this.http
            .get<any>(`${environment.apiUrl}/Api/Extras/Get?branchGuid=${branchGuid}&slotTypeIds=${slotTypeIDs}`)
            .pipe(shareReplay(1));

            // The final pipe enables caching, so we can't make the same request (including the query string) multiple times in a row.
            // The result is cached locally and returned instead of doing a http request.
    }
}
