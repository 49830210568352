<div class="extras" *ngIf="extraStoreService.extras.length">
    <div class="header">Add extras to your booking</div>
    <div *ngFor="let extra of extraStoreService.extras" class="extra">
        <img *ngIf="config.services.showExtraIcon" 
            src="https://branchresources.motasoftvgm.co.uk/{{extra.image}}" 
            onError="this.src='https://branchresources.motasoftvgm.co.uk//branchresources/14/Images/SlotTypes/Icons/IconSet1/tacg/tacg/64x64-Servicing.png'"
            class="extra-image" />                     
        <div class="extra-description">
            <p>{{extra.name}}</p>
            <span *ngIf="config.services.showExtraDescription">{{extra.description}}</span>
        </div>
        <div class="extra-price">
            {{"£" + (extra.fullPrice.value | number:"1.2-2")}}
        </div>
        <div>
            <span class="select-extra" (click)="addRemoveExtra(extra)">
                <i class="far" [ngClass]="{'fa-square': !basketStoreService.extraSelected(extra), 'fa-check-square': basketStoreService.extraSelected(extra)}"></i>
            </span>
        </div>
    </div>
</div>
