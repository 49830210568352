export enum PriceType {
	PriceFrom = 1,
	PriceTo = 2,
	FullPrice = 3,
	NoPriceAvailable = 4
}

export enum VehicleType {
	Car = 1,
	Van,
    Bike
}

export enum CommunicationType {
	NoConsent,
	Reminders,
	Marketing,
	LegitimateInterest,
	Transactional
}

// tslint:disable-next-line: no-namespace
export namespace CommunicationType {
	export function toString(dir: CommunicationType): string {
		return CommunicationType[dir];
	}

	export function fromString(dir: string): CommunicationType {
		return (CommunicationType as any)[dir];
	}
}

export enum CommunicationMethod {
	None,
	SMS,
	Email,
	Phone
}

// tslint:disable-next-line: no-namespace
export namespace CommunicationMethod {
	export function toString(dir: CommunicationMethod): string {
		return CommunicationMethod[dir];
	}

	export function fromString(dir: string): CommunicationMethod {
		return (CommunicationMethod as any)[dir];
	}
}


export enum LocalStorageKey {
	Vehicle,
	DvsaVehicle,
	AuthorizationData,
	SlotTypeIDs,
	SpecialOfferIDs,
	TyreIDs,
	ExtraIDs,
	AppVersion,
	BranchGuid,
	ClientID,
}

// tslint:disable-next-line: no-namespace
export namespace LocalStorageKey {
	export function toString(dir: LocalStorageKey): string {
		return LocalStorageKey[dir];
	}

	export function fromString(dir: string): LocalStorageKey {
		return (LocalStorageKey as any)[dir];
	}
}


export enum SlotTypeType
{
	ERROR = -1, 
	MOT,
	Service,
	Custom,
	POA
}
