
<div class="booking-confirmation-wrapper" *ngIf="booking">

    <div class="booking-description">
        <h3 *ngIf="showBookingRequestMessage">Booking request</h3>
        <h3 *ngIf="!showBookingRequestMessage">Booking confirmation</h3>

        <div *ngIf="!showBookingRequestMessage">
            <p>Your booking has been confirmed for {{booking.bookingDate | moment:'dddd, MMMM Do'}} - {{basketStoreService.rangeLabel(booking.slotRange)}}. An email confirmation has been sent to {{booking?.customer?.email}}<span *ngIf="booking?.payerEmail"> and a PayPal receipt will be sent to <strong>{{booking.payerEmail}}</strong></span>.</p>
        </div>

        <div *ngIf="showBookingRequestMessage" class="booking-message">
            <h4>Booking information</h4>
            <p>Your booking request for <strong>{{booking.preferredDate | moment:'dddd, MMMM Do'}} - {{booking.preferredTime}}</strong> has been received. <span *ngIf="!poaSlotTypes.length">Our team will be in touch to confirm the date and time of your booking.</span><span *ngIf="poaSlotTypes.length">Our team will be in touch to confirm the final price and the date and time of your booking.</span> An email confirmation has been sent to <strong>{{booking?.customer?.email}}</strong>.</p>                     
        </div>
         
        <button class="btn btn-light" (click)="startAgain()">Make another booking</button>
        <button class="btn btn-primary btn-print" (click)="print()">Print confirmation</button>
        <button *ngIf="config.confirmationReturnURL" class="btn btn-primary btn-return" (click)="return()">Return</button>
    </div>

    <div class="booking-details row">
        <div class="customer-wrapper col-md-6">
            <div class="customer">
                <h5>Your details</h5>
                <div [hidden]="!booking?.customer?.fullName">
                    <label>Name</label>
                    <p>{{booking?.customer?.fullName}}</p>
                </div>
                <div [hidden]="!booking?.customer?.address1">
                    <label>Address</label>
                    <p>{{customerStoreService.formatedCustomerAddress(booking?.customer)}}</p>
                </div>
                <div [hidden]="!booking?.customer?.mobile">
                    <label>Mobile</label>
                    <p>{{booking?.customer?.mobile}}</p>
                </div>
                <div [hidden]="!booking?.customer?.phone">
                    <label>Phone</label>
                    <p>{{booking?.customer?.phone}}</p>
                </div>
                <div [hidden]="!booking?.customer?.email">
                    <label>Email</label>
                    <p>{{booking?.customer?.email}}</p>
                </div>
                <hr />
                <h5>Vehicle</h5>
                <div [hidden]="!booking?.vehicle?.registrationNumber">
                    <label>Registration</label>
                    <p>{{booking?.vehicle?.registrationNumber | uppercase}}</p>
                </div>
                <div [hidden]="!booking?.vehicle">
                    <label>Make & Model</label>
                    <p>{{vehicleStoreService.getFormattedVehicle(booking?.vehicle)}}</p>
                </div>
            </div>
        </div>
        <div class="branch-wrapper col-md-6">
            <div class="branch">
                <h5>Branch details</h5>
                <div [hidden]="!booking?.branch">
                    <label>Branch name</label>
                    <p>{{companyStoreService.formatBranchName(booking?.branch)}}</p>
                </div>
                <div [hidden]="!booking?.branch">
                    <label>Address</label>
                    <p>{{companyStoreService.formatBranchAddress(booking?.branch, true, true)}}</p>
                </div>
                <div [hidden]="!booking?.branch?.phoneNumber">
                    <label>Phone</label>
                    <p>{{booking?.branch?.phoneNumber}}</p>
                </div>
                <div [hidden]="!booking?.branch?.emailAddress">
                    <label>Email</label>
                    <p>{{booking?.branch?.emailAddress}}</p>
                </div>
                <div [hidden]="!booking?.branch?.website">
                    <label>Website</label>
                    <p>{{booking?.branch?.website}}</p>
                </div>
                <a *ngIf="booking?.branch?.latitude && booking?.branch?.longditute" href="https://www.google.co.uk/maps/place/{{booking?.branch?.latitude}},{{booking?.branch?.longditute}}" target="_blank" class="btn btn-light">Get directions</a>
            </div>
        </div>
    </div>

    <div class="booking-items" *ngIf="booking.onlineShoppingBasket">
        <div class="basket large">
            <div class="header">
                <div>Booking Reference: {{ booking?.confirmedShoppingBasket?.id }}</div>
                <div *ngIf="booking.softDescriptor">
                    Payment confirmed. Charge statment will show as "{{booking.softDescriptor}}".
                </div>
            </div>
            <ng-template *ngFor="let tyre of booking.onlineShoppingBasket.tyres" [ngTemplateOutlet]="BasketItemTyre" [ngTemplateOutletContext]="{tyre: tyre}"></ng-template>
            <ng-template *ngFor="let specialOffer of booking.onlineShoppingBasket.specialOffers" [ngTemplateOutlet]="BasketItemSpecialOffer" [ngTemplateOutletContext]="{specialOffer: specialOffer}"></ng-template>
            <ng-template *ngFor="let slotType of nonPoaSlotTypes" [ngTemplateOutlet]="BasketItemSlotType" [ngTemplateOutletContext]="{slotType: slotType}"></ng-template>
            <ng-template *ngFor="let extra of booking.onlineShoppingBasket.extras" [ngTemplateOutlet]="BasketItemExtra" [ngTemplateOutletContext]="{extra: extra}"></ng-template>
            
            <div *ngIf="poaSlotTypes?.length > 0" class="unpriced">
                <div class="mat-divider"></div>
                <div class="headers">
                    <h4 class="unpriced-header">{{config.unpricedItemsHeader}}</h4>
                    <h5 class="unpriced-subheader">{{config.unpricedItemsSubHeader}}</h5>
                </div>
                <ng-template *ngFor="let slotType of poaSlotTypes" [ngTemplateOutlet]="BasketItemSlotType" [ngTemplateOutletContext]="{slotType: slotType}"></ng-template>
            </div>
            
            <div class="total">
        
                <div class="price">
                    <span>Total:</span>
                    {{"£" + (basketStoreService.getBasketTotal(booking?.confirmedShoppingBasket) | number:"1.2-2")}}
                </div>
                <div *ngIf="poaSlotTypes?.length > 0" class="unpriced-message">
                    Total excludes {{config.unpricedItemsHeader | lowercase}}
                </div>
            </div>

        </div>
    </div>
</div>

<ng-template #BasketItemTyre let-tyre="tyre">
    <div class="basket-item">
        <img *ngIf="config.tyres.showTyreImage" src="https://branchresources.motasoftvgm.co.uk/{{tyre.image}}" onError="this.src='https://bookingsystemapp.motasoftvgm.co.uk//Images/TyreImages/default.jpg'" class="icon" />
        <div class="details">
            <div class="description">
                <h5>{{tyre.manufacturerName | titlecase}} - {{tyre.treadPattern}}</h5>
                <span class="long-description" [hidden]="!config.tyres.showTyreDescription">
                    {{config.tyres.tyreDescription}}
                </span>
            </div>
            <div class="price">
                <div class="value">£{{tyre.fullPrice.value | number:"1.2-2"}}</div>
                <div class="quantity">
                    Quantity: {{tyre.quantity}}
                </div>
            </div>
        </div>
    </div>    
</ng-template>

<ng-template #BasketItemSlotType let-slotType="slotType">
    <div class="basket-item" *ngIf="slotType.id != tyreStoreService.tyreSlot.id">
        <img *ngIf="config.services.showSlotTypeIcon" src="https://branchresources.motasoftvgm.co.uk/{{slotType.image}}" onError="this.src='https://branchresources.motasoftvgm.co.uk/BranchResources/14/Images/SlotTypes/Icons/IconSet1/tacg/tacg/64x64-Servicing.png'" class="icon" />
        <div class="details">
            <div class="description">
                <h5>{{slotType.shortDescription}}</h5>
                <span class="long-description" [hidden]="!config.services.showSlotTypeLongDescription">{{slotType.longDescription}}</span>
            </div>
            <div class="price">
                <div class="value">
                    <span *ngIf="!slotType.priceOverwrite">{{"£" + (slotType.fullPrice.value | number:"1.2-2")}}</span>
                    <span *ngIf="slotType.priceOverwrite">{{ slotType.priceOverwrite }}</span>
                </div>
                <!-- <div class="quantity">
                    Quantity: 1
                </div>  -->
            </div>
        </div>
        <div *ngIf="slotType.completeBookingText" class="slot-type-confirmation">
            {{slotType.completeBookingText}}
        </div>
    </div>    
</ng-template>

<ng-template #BasketItemSpecialOffer let-specialOffer="specialOffer">
    <div class="basket-item">
        <img *ngIf="config.services.showSpecialOfferIcon" src="https://branchresources.motasoftvgm.co.uk/{{specialOffer.image}}" onError="this.src='https://branchresources.motasoftvgm.co.uk/BranchResources/14/Images/SlotTypes/Icons/IconSet1/tacg/tacg/64x64-Servicing.png'" class="icon" />
        <div class="details">
            <div class="description">
                <h5>{{specialOffer.name}}</h5>
                <span class="long-description" [hidden]="!config.services.showSpecialOfferLongDescription">
                    {{specialOffer.generalInformation}}
                </span>
            </div>
            
            <div class="price">
                <div class="value">{{"£" + (specialOffer.discountedPrice.value | number:"1.2-2")}}</div>
                <!-- <div class="quantity">
                    Quantity: 1
                </div> -->
            </div>
        </div>
    </div> 
</ng-template>

<ng-template #BasketItemExtra let-extra="extra">
    <div class="basket-item">
        <img *ngIf="config.services.showExtraIcon" src="https://branchresources.motasoftvgm.co.uk/{{extra.image}}" onError="this.src='https://branchresources.motasoftvgm.co.uk/BranchResources/14/Images/SlotTypes/Icons/IconSet1/tacg/tacg/64x64-Servicing.png'" class="icon" />
        <div class="details">
            <div class="description">
                <h5>{{extra.name}}</h5>
                <span class="long-description" [hidden]="!config.services.showExtraDescription">{{extra.description}}</span>
            </div>
            
            <div class="price">
                <div class="value">{{"£" + (extra.fullPrice.value | number:"1.2-2")}}</div>
                <!-- <div class="quantity">
                    Quantity: 1
                </div> -->
            </div>
        </div>
    </div>    
</ng-template>
