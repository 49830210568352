import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { TyreStoreService } from 'src/app/services/tyre/tyre-store.service';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';
import { LoggerStoreService } from 'src/app/services/logger/logger-store.service';
import { ITyre } from 'src/app/interfaces/tyre.interface';
import { MatDialog } from '@angular/material/dialog';
import { DialogTyreSelectComponent } from '../../dialogs/dialog-tyre-select/dialog-tyre-select.component';
import { Router } from '@angular/router';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { IframeResizerStoreService } from 'src/app/services/iframe-resizer/iframe-resizer-store.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-tyre-results',
    templateUrl: './tyre-results.component.html',
    styleUrls: ['./tyre-results.component.scss']
})
export class TyreResultsComponent implements OnInit {

    showManufacturers = true;
    showSeasons = true;
    showLoadIndex = true;
    showFuelEfficiency = true;
    showNoiseRating = true;
    showWetGrip = true;
    showTyreCategories = true;
    showSpecialistType = true;
    // mobile / tablet only
    showFilters = false;

    config: IComponentConfig;
    @Input() template: string;
    @Output() tyreSelected = new EventEmitter<ITyre>();
    @Output() continue = new EventEmitter();
    loading = false;
    mobileDevice = false;

    constructor(
        public companyStoreService: CompanyStoreService,
        public vehicleStoreService: VehicleStoreService,
        private loggerStoreService: LoggerStoreService,
        public tyreStoreService: TyreStoreService,
        public dialog: MatDialog,
        private iframeResizerStoreService: IframeResizerStoreService,
        private configStoreService: ConfigStoreService,
        private router: Router,
        private modalService: NgbModal,
        private authStoreService: AuthStoreService,
        public basketStoreService: BasketStoreService) {

        this.config = this.configStoreService.getComponentConfig('TyreResultsComponent');
        const windowWidth = window.innerWidth;
        // if a template is passed directly into the component, then use that, else, use the default template from config.

        if (windowWidth > 991) {
            this.template = this.template ? this.template : this.config.template;
        } else {
            this.mobileDevice = true;
            this.template = 'card';
        }
        
        
    }

    ngOnInit(): void {
        this.loggerStoreService.log(`Tyre Results: Initialized`);
        if (this.vehicleStoreService.vehicle.tyreSize) {
            this.tyreStoreService.getTyres(this.companyStoreService.branch.guid, '',this.vehicleStoreService.vehicle.tyreSize.width, this.vehicleStoreService.vehicle.tyreSize.profile, this.vehicleStoreService.vehicle.tyreSize.diameter, '', '', 0).then(() => {
                // this.loading = false;
                this.updateSelectedTyres();
            });
        }
    }

    updateSelectedTyres() {
        // This function will compare the tyres in your basket against the current results of get tyres, and update the 'selected' value when it's able to find the
        // tyre in the basket within the results.
        if (this.basketStoreService.tyres.length) {
            this.basketStoreService.tyres.forEach(tyre => {
                const index = this.tyreStoreService.tyres.indexOf(this.tyreStoreService.tyres.find(t => t.id == tyre.id));
                if (index > -1) {
                    this.tyreStoreService.tyres[index].quantity = tyre.quantity;
                }
            });
        }
    }

    productCountLabel() {
        if (!this.tyreStoreService.sortedTyres) { return ''; }
        switch (this.tyreStoreService.filteredTyres.length) {
            case 0:
                return '0 tyres'
            case 1:
                return '1 tyre'
            default:
                return `${this.tyreStoreService.filteredTyres.length} tyres`;
        }
    }

    selectedTyresLabel() {
        let count = 0;
        this.basketStoreService?.tyres.forEach(tyre => {
            count += tyre.quantity;
        });
        switch (count) {
            case 0:
                return '0 tyres selected'
            case 1:
                return '1 tyre selected'
            default:
                return `${count} tyres selected`;
        }
    }

    changeTemplate(template: string) {
        
        this.tyreStoreService.loading = true;
        switch(template) {
            case 'card':
                this.template = 'card';
                this.tyreStoreService.pageSizeOptions = [18, 27, 36];
                this.tyreStoreService.pageSize = this.tyreStoreService.pageSizeOptions[0];
                this.tyreStoreService.sortTyres();
            break;
            case 'list':
                this.template = 'list';
                this.tyreStoreService.pageSizeOptions = [10, 25, 50];
                this.tyreStoreService.pageSize = this.tyreStoreService.pageSizeOptions[0];
                this.tyreStoreService.sortTyres();
            break;
            case 'table':
                this.template = 'table';
                this.tyreStoreService.pageSizeOptions = [10, 25, 50];
                this.tyreStoreService.pageSize = this.tyreStoreService.pageSizeOptions[0];
                this.tyreStoreService.sortTyres();
            break;
        }
    }

    // This currently isn't in use and can probably be removed....
    //
    // ... Probably
    searchAgain() {
        let data = {
            template: 'TyreSizeSearch',
            title: 'Select your tyre size',
            selectTyres: false
        }

        if (this.vehicleStoreService.vehicle.tyreSetList.length) {
            data.template = 'TyreSizeSelection';
        }

        this.iframeResizerStoreService.scrollToIFrameTop();

        const modalRef = this.modalService.open(DialogTyreSelectComponent, { size: 'lg' });
		modalRef.componentInstance.data = data;

		modalRef.result.then((vehicleConfirmed) => {
			this.tyreStoreService.getTyres(this.companyStoreService.branch.guid, '',this.vehicleStoreService.vehicle.tyreSize.width, this.vehicleStoreService.vehicle.tyreSize.profile, this.vehicleStoreService.vehicle.tyreSize.diameter, '', '', 0).then(() => {
                // this.loading = false;
                this.updateSelectedTyres();
            });
        }, (reason) => {
            
        });
    }
 

    proceed($event) {
        $event.stopPropagation();
        this.continue.emit();
    }

    async addRemoveTyre($event, tyre: ITyre) {
        $event.stopPropagation();
        if(await this.basketStoreService.addRemoveTyre(tyre))
        	this.tyreSelected.emit();
    }
}
 