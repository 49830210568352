import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IPostcodeResult } from 'src/app/interfaces/postcode-result.interface';
import { AddressService } from './address.service';

@Injectable({
	providedIn: 'root'
})
export class AddressStoreService {

	constructor(private addressService: AddressService) { }

	private readonly _postcodeResults = new BehaviorSubject<IPostcodeResult[]>([]);
	readonly postcodeResults$ = this._postcodeResults.asObservable();

	get postcodeResults(): IPostcodeResult[] {
		return this._postcodeResults.getValue();
	}

	set postcodeResults(val: IPostcodeResult[]) {
		this._postcodeResults.next(val);
	}

	async lookupPostcode(branchGuid: string, postcode: string) {
		if (!branchGuid || !postcode) {
			return;
		}
		this.postcodeResults = await this.addressService.get(branchGuid, postcode).toPromise();
	}
}
