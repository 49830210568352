<div class="vehicle-feed-filters" [ngClass]="{'show-filters-mobile' : vehicleFeedStoreService.showFiltersMobile}">
	<button class="btn btn-primary btn-lg btn-filters" [ngClass]="{'d-block' : vehicleFeedStoreService.showFiltersMobile}" 
		(click)="vehicleFeedStoreService.showFiltersMobile =! vehicleFeedStoreService.showFiltersMobile">Hide filters</button>
	<button class="btn btn-light btn-reset btn-block" (click)="resetVehicleFeedFilters()">Clear
		filters</button>
		
	<!-- #region MAKE/MODEL FILTER -->
	<div class="filter-wrapper make-model" [ngClass]="{'open': vehicleFeedStoreService.filterDisplay['showMakeModel']}">
		<div class="filter-title" (click)="tryToggleFilter('showMakeModel')">
			<span>Make/Model</span>
			<i class="fas fa-chevron-up" [hidden]="!vehicleFeedStoreService.filterDisplay['showMakeModel']"></i>
			<i class="fas fa-chevron-down" [hidden]="vehicleFeedStoreService.filterDisplay['showMakeModel']"></i>
		</div>
		<div class="filter-options">
			<mat-form-field [appearance]="config.formAppearance">
				<mat-label>Make</mat-label>
				<mat-select #make
					(selectionChange)="vehicleFeedStoreService.filterVehicleMake($event.value)"
					[value]="vehicleFeedStoreService.selectedVehicleMake">
					<mat-option>Any</mat-option>
					<mat-option *ngFor="let vehicleMake of  vehicleFeedStoreService.vehicleMakes"
						[value]="vehicleMake">
						{{vehicleMake | titlecase}}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field [appearance]="config.formAppearance">
				<mat-label>Model</mat-label>
				<mat-select #model [disabled]="disableModel()"
					(selectionChange)="vehicleFeedStoreService.filterVehicleModel($event.value)"
					[value]="vehicleFeedStoreService.selectedVehicleModel">
					<mat-option>Any</mat-option>
					<mat-option *ngFor="let vehicleModel of  vehicleFeedStoreService.vehicleModels"
						[value]="vehicleModel">
						{{vehicleModel | titlecase}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
	<!-- #endregion -->

	<!-- #region PRICE FILTER -->
	<div class="filter-wrapper price" [ngClass]="{'open': vehicleFeedStoreService.filterDisplay['showPrice']}">
		<div class="filter-title" (click)="tryToggleFilter('showPrice')">
			<span>Price</span>
			<i class="fas fa-chevron-up" [hidden]="!vehicleFeedStoreService.filterDisplay['showPrice']"></i>
			<i class="fas fa-chevron-down" [hidden]="vehicleFeedStoreService.filterDisplay['showPrice']"></i>
		</div>
		<div class="filter-options">
			<div class="form-row">
				<div class="col-sm-6 price-min">
					<mat-form-field [appearance]="config.formAppearance">
						<mat-label>Min</mat-label>
						<mat-select #priceMin
							(selectionChange)="vehicleFeedStoreService.filterVehiclePriceMin($event.value)"
							[value]="vehicleFeedStoreService.selectedPriceMin">
							<mat-option>Any</mat-option>
							<mat-option *ngFor="let vehiclePriceMin of vehicleFeedStoreService.vehiclePriceMins"
								[value]="vehiclePriceMin">
								£{{vehiclePriceMin}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-sm-6 price-max">
					<mat-form-field [appearance]="config.formAppearance">
						<mat-label>Max</mat-label>
						<mat-select #priceMax
							(selectionChange)="vehicleFeedStoreService.filterVehiclePriceMax($event.value)"
							[value]="vehicleFeedStoreService.selectedPriceMax">
							<mat-option>Any</mat-option>
							<mat-option *ngFor="let vehiclePriceMax of vehicleFeedStoreService.vehiclePriceMaxs"
								[value]="vehiclePriceMax">
								£{{vehiclePriceMax}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</div>
	</div>
	<!-- #endregion -->

	<!-- #region MILEAGE FILTER -->
	<div class="filter-wrapper mileage" [ngClass]="{'open': vehicleFeedStoreService.filterDisplay['showMileage']}">
		<div class="filter-title" (click)="tryToggleFilter('showMileage')">
			<span>Mileage</span>
			<i class="fas fa-chevron-up" [hidden]="!vehicleFeedStoreService.filterDisplay['showMileage']"></i>
			<i class="fas fa-chevron-down" [hidden]="vehicleFeedStoreService.filterDisplay['showMileage']"></i>
		</div>
		<div class="filter-options">
			<div class="form-row">
				<div class="col-sm-6 mileage-min">
					<mat-form-field [appearance]="config.formAppearance">
						<mat-label>Min</mat-label>
						<mat-select #mileageMin
							(selectionChange)="vehicleFeedStoreService.filterVehicleMileageMin($event.value)"
							[value]="vehicleFeedStoreService.selectedMileageMin">
							<mat-option>Any</mat-option>
							<mat-option *ngFor="let vehicleMileageMin of vehicleFeedStoreService.vehicleMileageMins"
								[value]="vehicleMileageMin">
								{{vehicleMileageMin}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-sm-6 mileage-max">
					<mat-form-field [appearance]="config.formAppearance">
						<mat-label>Max</mat-label>
						<mat-select #mileageMax
							(selectionChange)="vehicleFeedStoreService.filterVehicleMileageMax($event.value)"
							[value]="vehicleFeedStoreService.selectedMileageMax">
							<mat-option>Any</mat-option>
							<mat-option *ngFor="let vehicleMileageMax of vehicleFeedStoreService.vehicleMileageMaxs"
								[value]="vehicleMileageMax">
								{{vehicleMileageMax}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</div>
	</div>
	<!-- #endregion -->

	<!-- #region GEARBOX FILTER -->

	<div class="filter-wrapper gearbox" [ngClass]="{'open': vehicleFeedStoreService.filterDisplay['showGearbox']}">
		<div class="filter-title" (click)="tryToggleFilter('showGearbox')">
			<span>Gearbox</span>
			<i class="fas fa-chevron-up" [hidden]="!vehicleFeedStoreService.filterDisplay['showGearbox']"></i>
			<i class="fas fa-chevron-down" [hidden]="vehicleFeedStoreService.filterDisplay['showGearbox']"></i>
		</div>
		<div class="filter-options">
					<mat-form-field [appearance]="config.formAppearance">
						<mat-label>Gearbox</mat-label>
						<mat-select #gearbox
							(selectionChange)="vehicleFeedStoreService.filterVehicleGearbox($event.value)"
							[value]="vehicleFeedStoreService.selectedVehicleGearbox">
							<mat-option>Any</mat-option>
							<mat-option *ngFor="let vehicleGearbox of  vehicleFeedStoreService.vehicleGearboxes"
								[value]="vehicleGearbox">
								{{vehicleGearbox | titlecase}}
							</mat-option>
						</mat-select>
					</mat-form-field>
 		</div>
	</div>

	<!-- #endregion -->

	<!-- #region FUEL TYPE FILTER -->

	<div class="filter-wrapper fuel-type" [ngClass]="{'open': vehicleFeedStoreService.filterDisplay['showFuelType']}">
		<div class="filter-title" (click)="tryToggleFilter('showFuelType')">
			<span>Fuel Type</span>
			<i class="fas fa-chevron-up" [hidden]="!vehicleFeedStoreService.filterDisplay['showFuelType']"></i>
			<i class="fas fa-chevron-down" [hidden]="vehicleFeedStoreService.filterDisplay['showFuelType']"></i>
		</div>
		<div class="filter-options">
			<mat-form-field [appearance]="config.formAppearance">
				<mat-label>Fuel Type</mat-label>
				<mat-select #fuelType
					(selectionChange)="vehicleFeedStoreService.filterVehicleFuelType($event.value)"
					[value]="vehicleFeedStoreService.selectedVehicleFuelType">
					<mat-option>Any</mat-option>
					<mat-option *ngFor="let vehicleFueltype of  vehicleFeedStoreService.vehicleFuelTypes"
						[value]="vehicleFueltype">
						{{vehicleFueltype | titlecase}}
					</mat-option>
				</mat-select>
			</mat-form-field>
 		</div>
	</div>

	<!-- #endregion -->

	<!-- #region BODY TYPE FILTER -->

	<div class="filter-wrapper body-type" [ngClass]="{'open': vehicleFeedStoreService.filterDisplay['showBodyType']}">
		<div class="filter-title" (click)="tryToggleFilter('showBodyType')">
			<span>Body Type</span>
			<i class="fas fa-chevron-up" [hidden]="!vehicleFeedStoreService.filterDisplay['showBodyType']"></i>
			<i class="fas fa-chevron-down" [hidden]="vehicleFeedStoreService.filterDisplay['showBodyType']"></i>
		</div>
		<div class="filter-options">
			<mat-form-field [appearance]="config.formAppearance">
				<mat-label>Body Type</mat-label>
				<mat-select #bodyType
					(selectionChange)="vehicleFeedStoreService.filterVehicleBodyType($event.value)"
					[value]="vehicleFeedStoreService.selectedVehicleBodyType">
					<mat-option>Any</mat-option>
					<mat-option *ngFor="let vehicleBodytype of  vehicleFeedStoreService.vehicleBodyTypes"
						[value]="vehicleBodytype">
						{{vehicleBodytype | titlecase}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>

	<!-- #endregion -->

	<!-- #region ENGINE SIZE FILTER -->

	<div class="filter-wrapper engine-size" [ngClass]="{'open': vehicleFeedStoreService.filterDisplay['showEngineSize']}">
		<div class="filter-title" (click)="tryToggleFilter('showEngineSize')">
			<span>Engine Size</span>
			<i class="fas fa-chevron-up" [hidden]="!vehicleFeedStoreService.filterDisplay['showEngineSize']"></i>
			<i class="fas fa-chevron-down" [hidden]="vehicleFeedStoreService.filterDisplay['showEngineSize']"></i>
		</div>
		<div class="filter-options">
			<mat-form-field [appearance]="config.formAppearance">
				<mat-label>Engine Size</mat-label>
				<mat-select #engineSize
					(selectionChange)="vehicleFeedStoreService.filterVehicleEngineSize($event.value)"
					[value]="vehicleFeedStoreService.selectedVehicleEngineSize">
					<mat-option>Any</mat-option>
					<mat-option *ngFor="let vehicleEngineSize of vehicleFeedStoreService.vehicleEngineSizes"
						[value]="vehicleEngineSize">
						{{vehicleEngineSize}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>

	<!-- #endregion -->

	<!-- #region DOORS FILTER -->

	<div class="filter-wrapper doors" [ngClass]="{'open': vehicleFeedStoreService.filterDisplay['showDoors']}">
		<div class="filter-title" (click)="tryToggleFilter('showDoors')">
			<span>Doors</span>
			<i class="fas fa-chevron-up" [hidden]="!vehicleFeedStoreService.filterDisplay['showDoors']"></i>
			<i class="fas fa-chevron-down" [hidden]="vehicleFeedStoreService.filterDisplay['showDoors']"></i>
		</div>
		<div class="filter-options">
			<mat-form-field [appearance]="config.formAppearance">
				<mat-label>Doors</mat-label>
				<mat-select #doors
					(selectionChange)="vehicleFeedStoreService.filterVehicleDoors($event.value)"
					[value]="vehicleFeedStoreService.selectedVehicleDoors">
					<mat-option>Any</mat-option>
					<mat-option *ngFor="let vehicleDoor of vehicleFeedStoreService.vehicleDoors"
						[value]="vehicleDoor">
						{{vehicleDoor}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>

	<!-- #endregion -->

	<!-- #region COLOUR FILTER -->

	<div class="filter-wrapper colour" [ngClass]="{'open': vehicleFeedStoreService.filterDisplay['showColour']}">
		<div class="filter-title" (click)="tryToggleFilter('showColour')">
			<span>Colour</span>
			<i class="fas fa-chevron-up" [hidden]="!vehicleFeedStoreService.filterDisplay['showColour']"></i>
			<i class="fas fa-chevron-down" [hidden]="vehicleFeedStoreService.filterDisplay['showColour']"></i>
		</div>
		<div class="filter-options">
			<mat-form-field [appearance]="config.formAppearance">
				<mat-label>Colour</mat-label>
				<mat-select #colour
					(selectionChange)="vehicleFeedStoreService.filterVehicleColour($event.value)"
					[value]="vehicleFeedStoreService.selectedVehicleColour">
					<mat-option>Any</mat-option>
					<mat-option *ngFor="let vehicleColour of vehicleFeedStoreService.vehicleColours"
						[value]="vehicleColour">
						{{ vehicleColour | titlecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>

	<!-- #endregion -->

</div>
