import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlotTypeListComponent } from './slot-type-list/slot-type-list.component';
import { MaterialModule } from 'src/app/material.module';
import { SlotTypeServiceChecklistComponent } from './slot-type-service-checklist/slot-type-service-checklist.component';
import { DirectivesModule } from 'src/app/directives/directives.module';

@NgModule({
    declarations: [SlotTypeListComponent, SlotTypeServiceChecklistComponent],
    imports: [
        CommonModule,
        MaterialModule,
        DirectivesModule
    ],
    exports: [SlotTypeListComponent, SlotTypeServiceChecklistComponent]
})
export class SlotTypeModule { }
