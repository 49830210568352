import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { AuthStoreService } from '../services/auth/auth-store.service';
import { Injectable } from '@angular/core';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { LoggerStoreService } from '../services/logger/logger-store.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	private refreshTokenInProgress = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(private authStoreService: AuthStoreService, private loggerStoreService: LoggerStoreService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.loggerStoreService.log(`Interceptor: Initialised for ${request.url}`);
		if (request.url.includes('/token')) {
			return next.handle(request).pipe(
				catchError((error: HttpErrorResponse) => {
					this.loggerStoreService.log(`Interceptor token error: ${error}`);
					if (error.status == 0) {
						this.loggerStoreService.log(`Interceptor: User likely closed connection`);
						return throwError(error);
					}
					return throwError(error);
				})
			);
		} else {
			return next.handle(this.addAuthenticationToken(request)).pipe(
				catchError((error: HttpErrorResponse) => {
					this.loggerStoreService.log(`Interceptor: ${error}`);
					this.loggerStoreService.log(`Interceptor: Error after adding token`);
					if (request.url.includes('token')) {
						return throwError(error);
					}

					if (error.status == 400 || error.status == 404 || (error.status == 403 && error.url.includes('branchresources'))) {
						return throwError(error);
					}

					if (error.status == 0) {
						this.loggerStoreService.log(`Interceptor: User likely closed connection`);
						return throwError(error);
					}

					if (this.refreshTokenInProgress) {
						return this.refreshTokenSubject.pipe(
							filter((token) => token != null),
							take(1),
							switchMap((token) => {
								this.loggerStoreService.log(`Interceptor: Refresh token in progress, updating refresh token subject ${token}`);
								return next.handle(this.addAuthenticationToken(request));
							})
						);
					} else {
						this.refreshTokenInProgress = true;
						this.refreshTokenSubject.next(null);
						this.loggerStoreService.log(`Interceptor: Refreshing access token`);

						return this.authStoreService.refreshAccessToken().pipe(
							switchMap((token: any) => {
								this.loggerStoreService.log(`Interceptor: Refreshed access token ${token}`);
								this.refreshTokenInProgress = false;
								this.refreshTokenSubject.next(token);
								this.loggerStoreService.log(`Interceptor: Re-requesting ${request.url} with new token`);
								return next.handle(this.addAuthenticationToken(request));
							})
						);
					}
				})
			);
		}
	}

	addAuthenticationToken(request: HttpRequest<any>) {
		this.loggerStoreService.log(`Interceptor: Adding auth token to ${request.url}`);
		return request.clone({
			headers: request.headers.append('Authorization', 'Bearer ' + this.authStoreService.authorizationData?.access_token),
		});
	}
}
