import { Component, Input, OnInit } from '@angular/core';
import { VehicleFeedStoreService } from 'src/app/services/vehicle-feed/vehicle-feed-store.service';
import { ConfigStoreService } from '../../../services/config/config-store.service';
import { IComponentConfig } from '../../../interfaces/config.interface';

@Component({
	selector: 'app-vehicle-feed-filter',
	templateUrl: './vehicle-feed-filter.component.html',
	styleUrls: ['./vehicle-feed-filter.component.scss']
})
export class VehicleFeedFilterComponent implements OnInit {

	config: IComponentConfig;

	constructor(
		public configStoreService: ConfigStoreService,
		public vehicleFeedStoreService: VehicleFeedStoreService) {
		this.config = this.configStoreService.getComponentConfig("VehicleFeedFilterComponent");
	}

	ngOnInit(): void {

	}

	public resetVehicleFeedFilters() {
		this.vehicleFeedStoreService.resetVehicleFeedFilters();
	}

	tryToggleFilter(filterKey: string) {
		switch (filterKey) {
			default:
				this.vehicleFeedStoreService.filterDisplay[filterKey] = !this.vehicleFeedStoreService.filterDisplay[filterKey];
				break;
		}
	}

	disableModel() {
		if (this.vehicleFeedStoreService.filterDisplay['disableModel']) {
			return '';
		} else {
			return null;
		}
	}

	originalSort() {
		return 0;
	}
}
