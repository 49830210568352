import { Component, OnInit, Output, EventEmitter, ViewChild, Renderer2, AfterViewInit, ElementRef } from '@angular/core';
import { BasketService } from 'src/app/services/basket/basket.service';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { ISlotRange } from 'src/app/interfaces/slot.interface';
import { MatCalendar } from '@angular/material/datepicker';
import { Moment } from 'moment';
import * as moment from 'moment';
import { CheckoutCalendarHeaderComponent } from '../checkout-calendar-header/checkout-calendar-header.component';

@Component({
	selector: 'app-checkout-calendar',
	templateUrl: './checkout-calendar.component.html',
	styleUrls: ['./checkout-calendar.component.scss'],
})
export class CheckoutCalendarComponent implements OnInit {
	checkoutCalendarHeaderComponent = CheckoutCalendarHeaderComponent;
	minDate = new Date();
	@Output() selectDateTime = new EventEmitter();
	@Output() dateSelected: EventEmitter<Moment> = new EventEmitter();
	@Output() selectedDate: Moment;
	@ViewChild('slots', { read: ElementRef, static: false }) slots: ElementRef;
	@ViewChild('calendar', { static: true }) calendar: MatCalendar<Moment>;

	constructor(public basketStoreService: BasketStoreService, public companyStoreService: CompanyStoreService, public basketService: BasketService) {}

	loading = true;
	availableDates: any[];

	ngOnInit(): void {
		this.basketStoreService.getAvailableDates().then(() => {
			this.calendar.updateTodaysDate();
			this.loading = false;
		});
	}

	filterAvailableDates = (date: Date | null) => {
		const input = moment(date).format('YYYY-MM-DD');
		return this.basketStoreService?.availableDatesFormattedForCalendar?.includes(input);
	};

	dateChanged() {
		this.basketStoreService.getAvailableTimes(moment(this.selectedDate).format('YYYY-MM-DD'));
		this.selectedSlotRange = null;
	}

	selectedSlotRange: ISlotRange;
	selectSlot(selectedSlot: ISlotRange) {
		this.selectedSlotRange = selectedSlot;
		this.basketStoreService.selectBookingSlotRange(selectedSlot);
		this.selectDateTime.emit(selectedSlot);
	}

	isSelected(slotRange: ISlotRange) {
		if (slotRange?.startTime == this.selectedSlotRange?.startTime) {
			return true;
		} else {
			return false;
		}
	}
}
