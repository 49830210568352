import { Component, OnInit, AfterViewInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, Event } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { CustomerStoreService } from 'src/app/services/customer/customer-store.service';
import { LocalStorageStoreService } from 'src/app/services/local-storage/local-storage-store.service';
import { LoggerStoreService } from 'src/app/services/logger/logger-store.service';
import { NavigationStoreService } from 'src/app/services/navigation/navigation-store.service';
import { VehicleFeedStoreService } from '../../../services/vehicle-feed/vehicle-feed-store.service';
import { AnalyticsStoreService } from 'src/app/services/analytics/analytics-store.service';
import { LocalStorageKey } from 'src/app/global/enums';
@Component({
	selector: 'app-vehicle-feed',
	templateUrl: './vehicle-feed.component.html',
	styleUrls: ['./vehicle-feed.component.scss']
})
export class VehicleFeedComponent implements OnInit, AfterViewInit {

	showFooterMoreInfo = false;
	maxHeightStyle = { 'max-height': 'initial' };
	maxSidebarHeightStyle = { 'max-height': 'initial' };
	initialInnerHeight: any;
	config: IComponentConfig;

	@ViewChild('header', { read: ElementRef, static: false }) header: ElementRef;
	@ViewChild('nav', { read: ElementRef, static: false }) nav: ElementRef;
	@ViewChild('footer', { read: ElementRef, static: false }) footer: ElementRef;
	@ViewChild('top', { read: ElementRef, static: false }) top: ElementRef;
	@ViewChild('bottom', { read: ElementRef, static: false }) bottom: ElementRef;
	@ViewChild('scrollable', { read: ElementRef, static: false }) scrollable: ElementRef;

	constructor(
		private route: ActivatedRoute,
		public router: Router,
		public vehicleFeedStoreService: VehicleFeedStoreService,
		public authStoreService: AuthStoreService,
		public companyStoreService: CompanyStoreService,
		private loggerStoreService: LoggerStoreService,
		public customerStoreService: CustomerStoreService,
		public navigationStoreService: NavigationStoreService,
		public configStoreService: ConfigStoreService,
		private analyticsStoreService: AnalyticsStoreService,
		private localStorageStoreService: LocalStorageStoreService,
		private titleService: Title,		
	) 
	{
		this.config = this.configStoreService.getComponentConfig('VehicleFeedComponent');

		const root = document.getElementsByTagName('html')[0];
		root.className += ' car-dealer-app';

		// This is how we control the global loading panel.
		// As this is on the main component that hosts every page, we subscribe to the router and this listens to all events.
		// The switch should be self explanatory as to what it's doing, but we essentially show and hide a loading panel before
		// and after navigation.
		this.router.events.subscribe((event: Event) => {
			switch (true) {
				case event instanceof NavigationStart: {
					this.vehicleFeedStoreService.loading = true;
					break;
				}

				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError: {
					// Upon nav end, we don't restrict the height of the scrollable area, so we can recalculate the proper height
					this.recalculateHeight();
					setTimeout(() => {
						// We set a timeout to make sure the view has loaded. Allow it to pop the iframe resize to a max height.
						// Then re-set the max-height of the scrollable area so that only that part scrolls.
						this.scrollable.nativeElement.scrollTop = 0;
						this.loggerStoreService.log('Vehicle Feed: Attempting to scroll to top.');
						this.loggerStoreService.log(this.scrollable.nativeElement);
						this.vehicleFeedStoreService.loading = false;
						if (event instanceof NavigationEnd) {
							// event.url
							const tempUrl = event.urlAfterRedirects.split('/');
							const tempFormattedUrl = tempUrl.splice(3, 2);
							this.analyticsStoreService.trackPageView(tempFormattedUrl.join('/'));
						}

					}, this.config.loadingPanelDelay * 2);
					break;
				}
				default: {
					break;
				}
			}
		});
	}

	ngOnInit(): void {

		if (this.authStoreService.clientId) {
			this.initialiseApp();
		} else {
			this.loggerStoreService.log('Vehicle Feed Component: Error loading app. No client ID')
		}
	}

	// This is rarely used, but this is called after all the child components are loaded. We then use set timeout to ensure the html has finished rendering,
	// then we recalculate the max-height of the scrollable area. Certainly something I'm not too keen on but has seemed ok so far.
	ngAfterViewInit() {
		setTimeout(() => {
			this.recalculateHeight();
		}, 1000);
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.recalculateHeight();
	}

	recalculateHeight() {
		this.maxHeightStyle = { 'max-height': 'initial' };
		this.maxSidebarHeightStyle = { 'max-height': 'initial' };
		setTimeout(() => {
			// We set a timeout to make sure the view has loaded. Allow it to pop the iframe resize to a max height.
			// Then re-set the max-height of the scrollable area so that only that part scrolls.
			if (!this.initialInnerHeight) { this.initialInnerHeight = this.calculateWindowHeight() - 40 };
			// this.initialInnerHeight = this.initialInnerHeight > 0 ? this.initialInnerHeight : window.innerHeight - 50;
			// this.initialInnerHeight = this.calculateWindowHeight() > this.initialInnerHeight ? this.calculateWindowHeight() : this.initialInnerHeight;
			this.initialInnerHeight = this.calculateWindowHeight();
			this.maxHeightStyle = { 'max-height': this.initialInnerHeight - this.calculateOffset() + 'px' };

			this.maxSidebarHeightStyle = { 'max-height': this.initialInnerHeight - 30 - this.calculateOffset() + 'px' };

		}, 1000);
	}

	calculateWindowHeight() {
		const height = window.innerHeight;
		return height;
	}

	calculateOffset() {
		let innerHeightOffset = 0;
		if (this.top) {
			innerHeightOffset += this.top.nativeElement.offsetHeight;
		}
		if (this.bottom) {
			innerHeightOffset += this.bottom.nativeElement.offsetHeight;
		}
		return innerHeightOffset;
	}

	async initialiseApp() {
		const auth = JSON.parse(this.localStorageStoreService.get(LocalStorageKey.AuthorizationData,
			this.authStoreService.clientId));
		this.titleService.setTitle(this.companyStoreService.company.name + ' | Vehicle Feed');

		// Change the CSS variable
		this.configStoreService.updateThemeColours();

		this.analyticsStoreService.init();

		if (auth && this.companyStoreService.branch.guid) {
			this.customerStoreService.getCustomer(this.companyStoreService.branch.guid);
		}
	}

	headerVisible() {
		let visible = true;

		return visible;
	}

	vehicleFeedFooterVisible() {
		let visible = false;

		return visible;
	}
}
