<div class="calendar-loading-panel" [hidden]="!basketStoreService.refreshingDatesAndTimes"></div>
<div class="calendar-wrapper row">
	<div class="calendar col-md-6">
		<mat-calendar
			[dateFilter]="filterAvailableDates"
			[headerComponent]="checkoutCalendarHeaderComponent"
			#calendar
			[(selected)]="selectedDate"
			(selectedChange)="dateChanged()"
		></mat-calendar>
	</div>
	<div class="times-wrapper col-md-6">
		<div class="header">Available times</div>
		<div class="form-row">
			<div class="col-4 col-sm-12 col-md-4" *ngFor="let time of basketStoreService.availableTimes">
				<button class="btn btn-lg btn-light btn-block" [ngClass]="{ 'btn-primary': isSelected(time), 'btn-light': !isSelected(time) }" (click)="selectSlot(time)">
					{{ basketStoreService.rangeLabel(time) }}
				</button>
			</div>
			<div *ngIf="!basketStoreService?.availableTimes" class="no-date-warning">Please select a date to see available times.</div>
		</div>
	</div>
</div>
