import { Component, OnInit } from '@angular/core';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { IBooking } from 'src/app/interfaces/booking.interface';
import { Moment } from 'moment';
import * as moment from 'moment';
import { CustomerStoreService } from 'src/app/services/customer/customer-store.service';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { IConfig, IComponentConfig } from 'src/app/interfaces/config.interface';
import { Router } from '@angular/router';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { TyreStoreService } from 'src/app/services/tyre/tyre-store.service';
import { NavigationStoreService } from 'src/app/services/navigation/navigation-store.service';
import { SlotTypeType } from '../../../global/enums';
import { ISlotType } from '../../../interfaces/slot-type.interface';

@Component({
    selector: 'app-step-booking-confirmation',
    templateUrl: './step-booking-confirmation.component.html',
    styleUrls: ['./step-booking-confirmation.component.scss']
})
export class StepBookingConfirmationComponent implements OnInit {
    booking: any;
    config: IComponentConfig;
    nonPoaSlotTypes: any[] = [];
    poaSlotTypes: any[] = [];

    slotTypeType = SlotTypeType; // Exposes enum to template

    constructor(
        public basketStoreService: BasketStoreService,
        public customerStoreService: CustomerStoreService,
        private configStoreService: ConfigStoreService,
        public tyreStoreService: TyreStoreService,
        private navigationStoreService: NavigationStoreService,
        public companyStoreService: CompanyStoreService,
        public vehicleStoreService: VehicleStoreService,
        private authStoreService: AuthStoreService,
        public router: Router) { }

    ngOnInit(): void {
        this.config = this.configStoreService.getComponentConfig('StepBookingConfirmationComponent');
        this.booking = this.basketStoreService.completedBooking;
        this.poaSlotTypes = this.booking?.confirmedShoppingBasket?.slotTypes.filter((c) =>
            c.type == SlotTypeType.POA
        ) || [];
        this.nonPoaSlotTypes = this.booking?.confirmedShoppingBasket?.slotTypes.filter((c) =>
            c.type != SlotTypeType.POA
        ) || [];
        this.setShowBookingRequestMessage();
    }

    showBookingRequestMessage = false;
    // If we have POA slots OR slots that can't be booked into the diary, this is false.
    setShowBookingRequestMessage() {

        let result = false;

        if (this.poaSlotTypes?.length) {
            result = true;
        }

        const unbookableSlots = [];
        this.booking?.confirmedShoppingBasket?.slotTypes.forEach(slotType => {
            if (!slotType.allowBookingToDiary) {
                unbookableSlots.push(slotType);
            }
        });

        this.booking?.confirmedShoppingBasket?.specialOffers?.forEach(specialOffer => {
            specialOffer.slotTypes.forEach(slotType => {
                if (!slotType.allowBookingToDiary) {
                    unbookableSlots.push(slotType);
                }
            });
        });

        if (unbookableSlots.length) {
            result = true;
        }

        this.showBookingRequestMessage = result;
    }

    print() {
        // window.print();

        const element = document.getElementsByClassName('booking-confirmation-wrapper')[0];
        const headClone = document.head.cloneNode(true) as HTMLHeadElement;
        const bodyClone = element.cloneNode(true) as HTMLElement;

        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        document.body.appendChild(iframe);
        iframe.contentDocument.body.appendChild(bodyClone);
        iframe.contentDocument.head.appendChild(headClone);

        const pri = iframe.contentWindow;
        pri.focus();
        pri.print();
        pri.onafterprint = () => { document.body.removeChild(iframe); }
    }

    startAgain() {
        this.navigationStoreService.goToFirstStep();
    }

    return() {
        window.location.href = this.config.confirmationReturnURL;
    }

}
