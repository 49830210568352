import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogVehicleEntryComponent } from '../../dialogs/dialog-vehicle-entry/dialog-vehicle-entry.component';
import { Router } from '@angular/router';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ITyreSize } from 'src/app/interfaces/tyre.interface';
import { DialogTyreSelectComponent } from '../../dialogs/dialog-tyre-select/dialog-tyre-select.component';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { TyreStoreService } from 'src/app/services/tyre/tyre-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { IVehicle } from 'src/app/interfaces/vehicle.interface';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { IframeResizerStoreService } from 'src/app/services/iframe-resizer/iframe-resizer-store.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-step-select-vehicle',
    templateUrl: './step-select-vehicle.component.html',
    styleUrls: ['./step-select-vehicle.component.scss']
})
export class StepSelectVehicleComponent implements OnInit {

    btnVrmLoading = false;
    vehicleRegistration = '';
    searchType = 'manual';
    vrmError = false;
    vrmForm: UntypedFormGroup;
    vrmType: string;
    config: IComponentConfig;
    selectedTyreSize: ITyreSize;

    constructor(
        public vehicleStoreService: VehicleStoreService,
        private companyStoreService: CompanyStoreService,
        private basketStoreService: BasketStoreService,
        private modalService: NgbModal,
        public tyreStoreService: TyreStoreService,
        public dialog: MatDialog,
        private iframeResizerStoreService: IframeResizerStoreService,
        private authStoreService: AuthStoreService,
        private configStoreService: ConfigStoreService,
        private router: Router) {
            this.config = this.configStoreService.getComponentConfig('StepSelectVehicleComponent');
        }

    ngOnInit(): void {
        // Are we loading the normal VRM lookup or the tyre VRM lookup w/ tyre sizes?
        if (this.config.tyres.enabled) {
            this.vrmType = 'Tyres';
        } else {
            this.vrmType = 'Standard';
        }

        if (this.vehicleStoreService.vrmAvailable()) {
            this.searchType = 'vrm';
        }

        this.vrmForm = new UntypedFormGroup({
			'vrmFormControl': new UntypedFormControl(null, [Validators.required, Validators.minLength(3)])
        });
        if (this.vehicleStoreService.vehicle?.registrationNumber) {
            this.vrmForm.controls.vrmFormControl.setValue(this.vehicleStoreService.vehicle.registrationNumber);
        }
    }

    vrmComplete(checkTyres: boolean) {
        if (checkTyres) {
            this.selectTyreSize();
        } else {
            this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-services']);
        }
    }

    enterVehicleManually($event) {
        this.iframeResizerStoreService.scrollToIFrameTop();

        const modalRef = this.modalService.open(DialogVehicleEntryComponent, {size: 'lg'});
		modalRef.componentInstance.data = {
            template: 'ManualVehicleEntry',
            title: 'Please enter your vehicle',
            basketStoreService: this.basketStoreService
        }

		modalRef.result.then((vehicleConfirmed) => {
			if (vehicleConfirmed) {
                this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-services']);
            }
        }, (reason) => { });
    }

    selectTyreSize() {
        this.iframeResizerStoreService.scrollToIFrameTop();

        const modalRef = this.modalService.open(DialogTyreSelectComponent, { size: 'lg' });
		modalRef.componentInstance.data = {
            template: 'TyreSizeSelection',
            title: 'Confirm your tyre size',
            selectTyres: false
        };

		modalRef.result.then((vehicleConfirmed) => {
			if (vehicleConfirmed) {
                this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-services']);
            }
        }, (reason) => {
            
        });
    }

    selectedTyreSizeConfirmed = false;
    confirmSelectedTyreSize($event) {
        this.selectedTyreSizeConfirmed = $event;
    }

    selectedTyreSizeValueChanged(selectedTyreSize: ITyreSize) {
        this.selectedTyreSize = selectedTyreSize;
    }

    confirmTyreSize() {
        this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-services']);
    }

}
