<!-- <div class="vehicle-feed-detail-back-btn-wrapper btn-wrapper">
	<button class="btn btn-inline btn-lg btn-primary btn-back" > <i
			class="fas fa-chevron-left"></i> Back</button>
</div> -->

<ul class="vehicle-search-breadcrumbs">
	<li>
		<a (click)="navigateToList()" href="javascript:void(0)"> Vehicle search</a>
	</li>
	<li>
		{{ stock?.vehicle?.make | titlecase }} {{ stock?.vehicle?.model | titlecase }}
		{{ stock?.vehicle?.derivative | titlecase }}
	</li>
</ul>

<div class="vehicle-feed-detail-image-card-wrapper row">
	<div class="vehicle-feed-detail-image-wrapper col-8">
		<div *ngIf="!stock" class="loading loading-images-wrapper">
			<div class="shimmer loading-images">Loading</div>
		</div>
		<ngb-carousel [interval]="0" *ngIf="stock?.vehicle.images" (slide)="updateActiveSliderId($event)" [activeId]="activeSliderId">
			<ng-template ngbSlide *ngFor="let image of stock.vehicle.images">
				<!-- {{ image | json }} -->
				<div class="img-wrapper">
					<img
						class="image"
						[src]="image"
						[ngClass]="{
							cover: configStoreService.config.vehicleFeedDetailImage == 'cover',
							contain: configStoreService.config.vehicleFeedDetailImage == 'contain',
							auto: configStoreService.config.vehicleFeedDetailImage == 'auto'
						}"
						style="background-image: url({{ image }});"
						src="{{ image }}"
						onError="this.src='https://m.atcdn.co.uk/a/media/no_image.png'; this.style='background-image: url(https://m.atcdn.co.uk/a/media/no_image.png);'"
					/>
				</div>
			</ng-template>
		</ngb-carousel>
	</div>
	<!-- Thumbnails need another pass to be better
	<ul class="vehicle-feed-detail-carousel-thumbnails">
		<li class="vehicle-feed-detail-carousel-thumbnail-item list-inline-item"
			*ngFor="let image of vehicle?.vehicleMarketing.images" (click)="cycleToSlide(image)">
			<a [ngClass]="{ selected : activeSliderId === image.toString() }">
				<img [src]="image">
			</a>
		</li>
	</ul>
	-->
	<div class="vehicle-feed-detail-card-wrapper col-4">
		<div *ngIf="!stock" class="loading">
			<ng-template [ngTemplateOutlet]="VehicleDetailCardPlaceholder"></ng-template>
		</div>
		<div *ngIf="stock" class="">
			<ng-template [ngTemplateOutlet]="VehicleDetailCard"></ng-template>
		</div>
	</div>
</div>

<div class="row">
	<div *ngIf="!stock" class="vehicle-feed-detail-text-wrapper col-8 loading">
		<ng-template [ngTemplateOutlet]="VehicleDetailPlaceholder"></ng-template>
	</div>
	<div *ngIf="stock" class="vehicle-feed-detail-text-wrapper col-8">
		<ng-template [ngTemplateOutlet]="VehicleDetail"></ng-template>
	</div>
	<div class="col-4"></div>
</div>

<ng-template #VehicleDetailCard>
	<div class="vehicle-feed-detail-card">
		<h3>
			{{ stock.vehicle.make | titlecase }} {{ stock.vehicle.model | titlecase }}
			{{ stock.vehicle.derivative | titlecase }}
		</h3>

		<div class="attention-grabber" *ngIf="stock?.vehicle?.attentionGrabber">{{ stock?.vehicle?.attentionGrabber }}</div>

		<div>
			<span class="year"> <i class="fa-calendar-alt fas"></i> {{ stock.vehicle.manufacturedYearString | titlecase }} </span>
			<span class="mileage" *ngIf="stock?.vehicle?.mileage"> <i class="fas fa-tachometer-alt"></i> {{ stock.vehicle.mileage | number : '' : 'en-GB' }} miles </span>
		</div>

		<div class="price">
			{{ stock.vehicle.price | currency : 'GBP' : 'symbol' : '.0' }}
		</div>
	</div>
</ng-template>

<ng-template #VehicleDetailCardPlaceholder>
	<div class="vehicle-feed-detail-card">
		<div class="vehicle-feed-details">
			<div class="make shimmer">loading</div>
			<div class="model shimmer">loading</div>
			<div class="year shimmer">loading</div>
			<div class="miles shimmer">loading</div>
			<div class="price shimmer">loading</div>
		</div>
	</div>
</ng-template>

<ng-template #VehicleDetail>
	<div class="vehicle-overview">
		<h4>Overview</h4>
		<app-vehicle-feed-detail-icons></app-vehicle-feed-detail-icons>
	</div>

	<div class="vehicle-description" *ngIf="stock?.vehicle?.keyInformation">
		<h4>Description</h4>
		<p>{{ stock?.vehicle?.keyInformation }}</p>
	</div>

	<div class="vehicle-features" *ngIf="stock?.vehicle?.features.length > 0 && stock?.vehicle?.features[0]">
		<h4>Features</h4>

		<div *ngIf="!showFeaturesFull">
			<div class="show-more-less btn btn-primary" *ngIf="stock?.vehicle.features.length > 10" (click)="toggleShowFullFeatureList()">Show more...</div>
			<div *ngFor="let feature of stock?.vehicle.features.slice(0, 10)">
				<app-vehicle-feed-features-icons [value]="feature"></app-vehicle-feed-features-icons>
			</div>
		</div>

		<div *ngIf="showFeaturesFull">
			<div class="show-more-less btn btn-primary" *ngIf="stock?.vehicle.features.length > 10" (click)="toggleShowFullFeatureList()">Show less...</div>
			<div *ngFor="let feature of stock?.vehicle.features">
				<app-vehicle-feed-features-icons [value]="feature"></app-vehicle-feed-features-icons>
			</div>
		</div>
	</div>

	<div class="vehicle-contact">
		<h4>Enquire about this vehicle</h4>

		<ng-container *ngIf="!emailSent">
			<form [formGroup]="form" class="vehicle-feed-enquire-form">
				<div class="form-row">
					<div class="col-sm-5">
						<mat-form-field [appearance]="config.formAppearance">
							<mat-label>Title <span class="text-danger">*</span> </mat-label>
							<mat-select formControlName="titleFormControl">
								<mat-option value="MR"> Mr </mat-option>
								<mat-option value="MRS"> Mrs </mat-option>
								<mat-option value="MISS"> Miss </mat-option>
								<mat-option value="MS"> Ms </mat-option>
							</mat-select>
							<mat-error *ngIf="form.get('titleFormControl').hasError('required')"> Title is <strong>required</strong> </mat-error>
						</mat-form-field>
					</div>
				</div>
				<div class="form-row">
					<div class="col-sm-6">
						<mat-form-field [appearance]="config.formAppearance">
							<mat-label>First Name <span class="text-danger">*</span> </mat-label>
							<input matInput formControlName="firstNameFormControl" />
							<mat-error *ngIf="form.get('firstNameFormControl').hasError('required')"> First Name is <strong>required</strong> </mat-error>
						</mat-form-field>
					</div>
					<div class="col-sm-6">
						<mat-form-field [appearance]="config.formAppearance">
							<mat-label>Surname <span class="text-danger">*</span> </mat-label>
							<input matInput formControlName="surnameFormControl" />
							<mat-error *ngIf="form.get('surnameFormControl').hasError('required')"> Surname is <strong>required</strong> </mat-error>
						</mat-form-field>
					</div>
				</div>
				<div class="form-row">
					<div class="col-sm-6">
						<mat-form-field [appearance]="config.formAppearance">
							<mat-label>Email <span class="text-danger">*</span> </mat-label>
							<input matInput formControlName="emailFormControl" />
							<mat-error *ngIf="form.get('emailFormControl').hasError('required')"> Email is <strong>required</strong> </mat-error>
						</mat-form-field>
					</div>
					<div class="col-sm-6">
						<mat-form-field [appearance]="config.formAppearance">
							<mat-label>Phone number <span class="text-danger">*</span> </mat-label>
							<input matInput formControlName="phoneFormControl" />
							<mat-error *ngIf="form.get('phoneFormControl').hasError('required')"> Phone number is <strong>required</strong> </mat-error>
						</mat-form-field>
					</div>
				</div>
				<div class="form-row">
					<div class="col-sm-12">
						<mat-form-field [appearance]="config.formAppearance">
							<mat-label>Message <span class="text-danger">*</span> </mat-label>
							<textarea matInput rows="6" formControlName="messageFormControl"></textarea>
							<mat-error *ngIf="form.get('messageFormControl').hasError('required')"> Message is <strong>required</strong> </mat-error>
						</mat-form-field>
					</div>
				</div>
				<div class="form-row">
					<div class="col recaptcha-wrapper">
						<re-captcha *ngIf="innerWidth <= 425" formControlName="recaptchaFormControl" size="compact"> </re-captcha>
						<re-captcha *ngIf="innerWidth > 425" formControlName="recaptchaFormControl"></re-captcha>
						<mat-error *ngIf="afterFirstSubmitClick && form.get('recaptchaFormControl').hasError('required')"> Recaptcha is <strong>required</strong> </mat-error>
					</div>
				</div>
				<div class="btn-submit-wrapper">
					<button class="btn btn-lg btn-primary btn-submit" [disabled]="this.form.invalid" (click)="submitContactForm()">Submit</button>
				</div>
			</form>
		</ng-container>
		<ng-container *ngIf="emailSent">
			<h1>{{ emailSubmitResult }}</h1>
		</ng-container>
	</div>
</ng-template>

<ng-template #VehicleDetailPlaceholder>
	<mat-tab-group animationDuration="0ms">
		<mat-tab label="Overview">
			<div class="vehicle-feed-detail-overview-wrapper">
				<div class="make-model-wrapper">
					<h1 class="shimmer">Loading</h1>
					<h2 class="shimmer">Loading</h2>
				</div>

				<div class="key-information-wrapper">
					<p class="shimmer">Loading</p>
				</div>

				<div class="feature-icons-wrapper">
					<div class="shimmer">Loading</div>
				</div>
			</div>
		</mat-tab>
		<mat-tab label="Features">
			<div class="vehicle-feed-detail-features-wrapper">
				<h3 class="shimmer">Loading</h3>
			</div>
		</mat-tab>
		<mat-tab label="Contact">
			<div class="vehicle-feed-detail-enquire-wrapper shimmer">Loading</div>
		</mat-tab>
	</mat-tab-group>
</ng-template>
