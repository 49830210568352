import { Component, OnInit, AfterViewInit, HostListener, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event } from '@angular/router';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { LoggerStoreService } from 'src/app/services/logger/logger-store.service';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { TyreStoreService } from 'src/app/services/tyre/tyre-store.service';
import { SlotTypeStoreService } from 'src/app/services/slot-type/slot-type-store.service';
import { SpecialOfferStoreService } from 'src/app/services/special-offer/special-offer-store.service';
import { CustomerStoreService } from 'src/app/services/customer/customer-store.service';
import { NavigationStoreService } from 'src/app/services/navigation/navigation-store.service';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageStoreService } from 'src/app/services/local-storage/local-storage-store.service';
import { DialogConfirmComponent } from '../../dialogs/dialog-confirm/dialog-confirm.component';
import { DialogLoginComponent } from '../../dialogs/dialog-login/dialog-login.component';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { Location, DOCUMENT } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogTyreSelectComponent } from '../../dialogs/dialog-tyre-select/dialog-tyre-select.component';
import { ITyreSize } from 'src/app/interfaces/tyre.interface';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { IframeResizerStoreService } from 'src/app/services/iframe-resizer/iframe-resizer-store.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsStoreService } from 'src/app/services/analytics/analytics-store.service';
import { LocalStorageKey, VehicleType } from 'src/app/global/enums';

@Component({
	selector: 'app-layout-main',
	templateUrl: './layout-main.component.html',
	styleUrls: ['./layout-main.component.scss'],
})
export class LayoutMainComponent implements OnInit, AfterViewInit {
	@ViewChild('header', { read: ElementRef, static: false }) header: ElementRef;
	@ViewChild('nav', { read: ElementRef, static: false }) nav: ElementRef;
	@ViewChild('footer', { read: ElementRef, static: false }) footer: ElementRef;
	@ViewChild('top', { read: ElementRef, static: false }) top: ElementRef;
	@ViewChild('bottom', { read: ElementRef, static: false }) bottom: ElementRef;
	@ViewChild('scrollable', { read: ElementRef, static: false }) scrollable: ElementRef;

	loading = true;
	config: IComponentConfig;
	showFooterMoreInfo = false;
	// innerHeight: any;
	// innerHeightOffset: any;
	maxHeightStyle = { 'max-height': 'initial' };
	maxSidebarHeightStyle = { 'max-height': 'initial' };
	initialInnerHeight: any;
	subscription: Subscription;

	constructor(
		private route: ActivatedRoute,
		public authStoreService: AuthStoreService,
		public companyStoreService: CompanyStoreService,
		private loggerStoreService: LoggerStoreService,
		public vehicleStoreService: VehicleStoreService,
		public basketStoreService: BasketStoreService,
		public tyreStoreService: TyreStoreService,
		public slotTypeStoreService: SlotTypeStoreService,
		public iframeResizerStoreService: IframeResizerStoreService,
		public specialOfferStoreService: SpecialOfferStoreService,
		public customerStoreService: CustomerStoreService,
		public navigationStoreService: NavigationStoreService,
		public configStoreService: ConfigStoreService,
		private analyticsStoreService: AnalyticsStoreService,
		private modalService: NgbModal,
		private titleService: Title,
		private snackBar: MatSnackBar,
		public router: Router,
		private location: Location,
		public dialog: MatDialog,
		private localStorageStoreService: LocalStorageStoreService
	) {}

	ngOnInit(): void {
		// this.subscription = this.route.params.subscribe(val => {
		// });
		this.config = this.configStoreService.getComponentConfig('LayoutMainComponent');

		const root = document.getElementsByTagName('html')[0];
		if (this.config.mobileFallbackComponent == 'none') {
			root.className += ' no-widget-fallback';
		} else {
			root.className += ' widget-fallback ' + 'widget-fallback-' + this.config.mobileFallbackComponent;
		}

		if (this.authStoreService.clientId) {
			this.initialiseApp();
		} else {
			this.loggerStoreService.log('Layout Main Component: Error loading app. No client ID');
		}

		// This is how we control the global loading panel.
		// As this is on the main component that hosts every page, we subscribe to the router and this listens to all events.
		// The switch should be self explanatory as to what it's doing, but we essentially show and hide a loading panel before
		// and after navigation.
		this.router.events.subscribe((event: Event) => {
			switch (true) {
				case event instanceof NavigationStart: {
					this.loading = true;
					break;
				}

				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError: {
					// Upon nav end, we don't restrict the height of the scrollable area, so we can recalculate the proper height
					this.recalculateHeight();
					setTimeout(() => {
						// We set a timeout to make sure the view has loaded. Allow it to pop the iframe resize to a max height.
						// Then re-set the max-height of the scrollable area so that only that part scrolls.
						this.scrollable.nativeElement.scrollTop = 0;
						this.loggerStoreService.log('Layout Main: Attempting to scroll to top.');
						this.loggerStoreService.log(this.scrollable.nativeElement);
						this.loading = false;
						if (event instanceof NavigationEnd) {
							// event.url
							const tempUrl = event.urlAfterRedirects.split('/');
							const tempFormattedUrl = tempUrl.splice(3, 2);
							this.analyticsStoreService.trackPageView(tempFormattedUrl.join('/'));
						}
					}, this.config.loadingPanelDelay * 2);
					break;
				}
				default: {
					this.loading = false;
					break;
				}
			}
		});
	}

	// This is rarely used, but this is called after all the child components are loaded. We then use set timeout to ensure the html has finished rendering, then we recalculate the max-height of the scrollable
	// area. Certainly something I'm not too keen on but has seemed ok so far.
	ngAfterViewInit() {
		setTimeout(() => {
			this.recalculateHeight();
		}, 1000);
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.recalculateHeight();
	}

	recalculateHeight() {
		this.maxHeightStyle = { 'max-height': 'initial' };
		this.maxSidebarHeightStyle = { 'max-height': 'initial' };
		setTimeout(() => {
			// We set a timeout to make sure the view has loaded. Allow it to pop the iframe resize to a max height.
			// Then re-set the max-height of the scrollable area so that only that part scrolls.
			if (!this.initialInnerHeight) {
				this.initialInnerHeight = this.calculateWindowHeight() - 40;
			}
			// this.initialInnerHeight = this.initialInnerHeight > 0 ? this.initialInnerHeight : window.innerHeight - 50;
			// this.initialInnerHeight = this.calculateWindowHeight() > this.initialInnerHeight ? this.calculateWindowHeight() : this.initialInnerHeight;
			this.initialInnerHeight = this.calculateWindowHeight();
			this.maxHeightStyle = { 'max-height': this.initialInnerHeight - this.calculateOffset() + 'px' };

			this.maxSidebarHeightStyle = { 'max-height': this.initialInnerHeight - 30 - this.calculateOffset() + 'px' };
		}, 1000);
	}

	calculateWindowHeight() {
		const height = window.innerHeight;
		return height;
	}

	calculateOffset() {
		let innerHeightOffset = 0;
		if (this.top) {
			innerHeightOffset += this.top.nativeElement.offsetHeight;
		}
		if (this.bottom) {
			innerHeightOffset += this.bottom.nativeElement.offsetHeight;
		}
		return innerHeightOffset;
	}

	selectTyres(selectTyres) {
		this.iframeResizerStoreService.scrollToIFrameTop();

		const modalRef = this.modalService.open(DialogTyreSelectComponent, { size: 'lg' });
		modalRef.componentInstance.data = {
			template: 'TyreSizeSelection',
			title: 'Confirm your tyre size',
			selectTyres: selectTyres,
		};

		modalRef.result.then(
			(vehicleConfirmed) => {
				if (!selectTyres) {
					if (this.tyreStoreService.tyreSizeValid(this.vehicleStoreService.vehicle.tyreSize)) {
						this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-services']);
					}
				}
			},
			(reason) => {}
		);
	}

	findTyresBySize() {
		this.loading = true;
		this.iframeResizerStoreService.scrollToIFrameTop();

		const modalRef = this.modalService.open(DialogTyreSelectComponent, { size: 'xl' });
		modalRef.componentInstance.data = {
			template: 'TyreResults',
			title: 'Select your tyres',
			selectTyres: true,
		};

		modalRef.result.then(
			(vehicleConfirmed) => {
				if (vehicleConfirmed) {
					setTimeout(() => {
						this.loading = false;
						if (this.tyreStoreService.tyreSizeValid(this.vehicleStoreService.vehicle.tyreSize)) {
							this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-services']);
						}
					}, this.config.loadingPanelDelay * 2);
				} else {
					this.loading = false;
				}
			},
			(reason) => {
				this.loading = false;
			}
		);
	}

	async initialiseApp() {
		const auth = JSON.parse(this.localStorageStoreService.get(LocalStorageKey.AuthorizationData, this.authStoreService.clientId));

		this.titleService.setTitle(this.companyStoreService.company.name + ' | Online Booking System');

		// Change the CSS variable
		this.configStoreService.updateThemeColours();

		this.analyticsStoreService.init();

		if (auth && this.companyStoreService.branch.guid) {
			this.customerStoreService.getCustomer(this.companyStoreService.branch.guid);
		}

		const localStorageVehicle = JSON.parse(this.localStorageStoreService.get(LocalStorageKey.Vehicle, this.authStoreService.clientId));
		if (localStorageVehicle) {
			this.vehicleStoreService.vehicle = localStorageVehicle;
			if (this.vehicleStoreService.vehicle.tyreSetList.length) {
				this.vehicleStoreService.getFrontTyreSizes();
				this.vehicleStoreService.getRearTyreSizes();
			}
		}

		if (this.configStoreService.startingVehicleRegistration) {
			await this.vehicleStoreService.doVrmLookup(this.companyStoreService.branch.guid, this.configStoreService.startingVehicleRegistration).then((result) => {
				this.basketStoreService.refreshPrices();

				this.loggerStoreService.log('Layout Main Component: Did VRM lookup based on query string.');
				this.loggerStoreService.table(result);
				if (!this.vehicleStoreService.isValidVrmResult(result, false)) {
					// Let user know there was an issue with a toast
					this.snackBar.open(
						`We couldn't find a vehicle for ${this.configStoreService.startingVehicleRegistration}. Please try again or continue to add the services you require.`
					);
				}
			});
		}

		// Here we set the starting vehicle type, if that was set by a widget
		if (this.configStoreService.startingVehicleType) {
			this.vehicleStoreService.vehicleType = +this.configStoreService.startingVehicleType;
		}

		// Tyre VRM
		// Here, we will do different things depending if we are just tyres, or both tyres and services.
		if (this.configStoreService.startingTyreVehicleRegistration) {
			await this.vehicleStoreService.doVrmLookup(this.companyStoreService.branch.guid, this.configStoreService.startingTyreVehicleRegistration).then((result) => {
				this.basketStoreService.refreshPrices();

				setTimeout(() => {
					if (this.config.services.enabled) {
						this.selectTyres(true);
					} else {
						this.selectTyres(false);
					}
				}, 2000);
			});
		}

		// Tyre Size
		// Here, we will do different things depending if we are just tyres, or both tyres and services.
		if (this.configStoreService.startingTyreWidth) {
			const tyreSize: ITyreSize = {
				width: this.configStoreService?.startingTyreWidth || null,
				profile: this.configStoreService?.startingTyreProfile || null,
				diameter: this.configStoreService?.startingTyreDiameter || null,
				rating: this.configStoreService?.startingTyreSpeed || null,
			};
			// Save selected tyre size
			// Emit
			this.vehicleStoreService.vehicle.tyreSize = tyreSize;
			if (this.configStoreService.config.tyres.enabled && this.configStoreService.config.services.enabled) {
				this.findTyresBySize();
			}
		}

		if (this.companyStoreService.branch.guid) {
			// We've only going to get slot types etc... if we have a branch guid.
			await this.basketStoreService.refreshPrices();

			// Starting slot types and special offers
			// If a user has selected either on a widget on a previous page, we'll
			// addRemove the items here.
			if (this.configStoreService.startingSlotType) {
				this.basketStoreService.addRemoveSlotType(this.slotTypeStoreService.getSlotTypeByID(+this.configStoreService.startingSlotType), true);
			}

			const selectedSlotTypeIDs = this.localStorageStoreService.get(LocalStorageKey.SlotTypeIDs, this.authStoreService.clientId);
			if (selectedSlotTypeIDs) {
				const ids = JSON.parse(selectedSlotTypeIDs);
				let startingSlotType = +this.configStoreService?.startingSlotType || 0;
				ids.forEach((id) => {
					if (id != startingSlotType) {
						this.basketStoreService.addRemoveSlotType(this.slotTypeStoreService.getSlotTypeByID(id), false);
					}
				});
			}

			if (this.configStoreService.startingSpecialOffer) {
				this.basketStoreService.addRemoveSpecialOffer(this.specialOfferStoreService.getSpecialOfferByID(+this.configStoreService.startingSpecialOffer));
			}

			const specialOfferIDs = this.localStorageStoreService.get(LocalStorageKey.SpecialOfferIDs, this.authStoreService.clientId);
			if (specialOfferIDs) {
				const ids = JSON.parse(specialOfferIDs);
				let startingSpecialOffer = +this.configStoreService?.startingSpecialOffer || 0;
				ids.forEach((id) => {
					if (id != startingSpecialOffer) {
						this.basketStoreService.addRemoveSpecialOffer(this.specialOfferStoreService.getSpecialOfferByID(id));
					}
				});
			}

			// Lets do tyre api calls here.
			const tyreIDs = this.localStorageStoreService.get(LocalStorageKey.TyreIDs, this.authStoreService.clientId);
			if (tyreIDs) {
				const tyres = JSON.parse(tyreIDs);

				for (const id in tyres) {
					this.tyreStoreService.getTyre(this.companyStoreService.branch.guid, +id).then((t) => {
						const tyre = this.tyreStoreService.tyre[0];
						tyre.quantity = tyres[id];
						if (tyre && tyre.nationalQuantity >= tyres[id]) {
							this.basketStoreService.addRemoveTyre(tyre);
							tyre.quantity = tyres[id];
						}
					});
				}
			}
		} else {
			// If the user doesn't have a branch selected, lets clear these down just to make sure.
			this.localStorageStoreService.remove(LocalStorageKey.SlotTypeIDs, this.authStoreService.clientId);
			this.localStorageStoreService.remove(LocalStorageKey.SpecialOfferIDs, this.authStoreService.clientId);
			this.localStorageStoreService.remove(LocalStorageKey.TyreIDs, this.authStoreService.clientId);
			this.localStorageStoreService.remove(LocalStorageKey.ExtraIDs, this.authStoreService.clientId);
		}

		// Extras!!!
		// Extras respond to the slots that are selected so the refreshExtras() function in the basket store service
		// deals with ensuring the extras are reselected.
		if (this.authStoreService.passwordResetKey) {
			this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'account', 'password-reset', this.authStoreService.passwordResetKey]);
			this.loading = false;
		} else {
			this.navigationStoreService.goToFirstStep(true);
			this.loading = false;
		}
	}

	async startAgain() {
		this.iframeResizerStoreService.scrollToIFrameTop();
		let content = 'Please confirm that you wish to start again and empty your basket.';
		if (this.companyStoreService?.company?.branches?.length > 1 && this.companyStoreService.branch.guid && this.authStoreService.isLoggedIn()) {
			content = 'Please confirm that you wish to start again and empty your basket. Please note, this process will log you out.';
		}

		const modalRef = this.modalService.open(DialogConfirmComponent);
		modalRef.componentInstance.data = {
			title: 'Start your booking again',
			content: content,
		};

		modalRef.result.then(
			(result) => {
				if (result == true) {
					this.confirmStartAgain();
				}
			},
			(reason) => {}
		);
	}

	async confirmStartAgain() {
		this.loading = true;
		let logout = false;
		this.localStorageStoreService.remove(LocalStorageKey.Vehicle, this.authStoreService.clientId);
		this.basketStoreService.clearBasket();
		this.basketStoreService.clearDateTime();
		this.vehicleStoreService.clearVehicle();

		if (this.companyStoreService?.company?.branches?.length > 1) {
			this.localStorageStoreService.remove(LocalStorageKey.BranchGuid, this.authStoreService.clientId);
			this.companyStoreService.clearBranch();
			this.customerStoreService.resetCustomer();
			logout = true;
		}

		if (logout) {
			this.authStoreService.logout();
		}

		this.navigationStoreService.goToFirstStep();
		this.loading = false;
	}

	login() {
		this.iframeResizerStoreService.scrollToIFrameTop();

		const modalRef = this.modalService.open(DialogLoginComponent);
		modalRef.componentInstance.data = {
			template: 'Login',
			title: 'Login to your account',
		};

		modalRef.result.then(
			(response) => {
				if (response == true) {
					this.basketStoreService.refreshPrices();
				}
			},
			(reason) => {}
		);
	}

	async logout() {
		this.basketStoreService.clearBasket();
		await this.navigationStoreService.goToFirstStep();
		await this.authStoreService.logout();
	}

	navigateBackButtonActive() {
		let active = true;

		if (this.navigationStoreService.currentStepNumber(this.router.url) == 1) {
			active = false;
		}

		return active;
	}

	navigateBack() {
		this.location.back();
	}

	accountsNavigate() {
		if (this.basketStoreService.getBasketNumberOfItems() > 0 && this.basketStoreService.bookingDateTime) {
			// If basket is good, go to enter details.
			this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'enter-details']);
		} else if (this.basketStoreService.getBasketNumberOfItems() > 0 && !this.basketStoreService.bookingDateTime) {
			// If basket is good, but no date time, go to take time.
			this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-date-time']);
		} else {
			// Else, go to start.
			this.navigationStoreService.goToFirstStep();
		}
	}

	accountsButtonLabel() {
		let label = 'Continue';

		if (this.basketStoreService.getBasketNumberOfItems() == 0) {
			label = 'Make a booking';
		}

		return label;
	}

	accountsButtonVisible() {
		let visible = false;

		if (this.router.url.includes('account/')) {
			visible = true;
		}

		return visible;
	}

	loginButtonVisible() {
		let visible = true;
		if (this.router.url.includes('/booking/enter-details')) {
			visible = false;
		}

		if (this.router.url.includes('/booking/select-location')) {
			visible = false;
		}

		return visible;
	}

	sidebarVisible() {
		let visible = true;
		if (this.router.url.includes('/booking/select-location')) {
			visible = false;
		}

		if (this.router.url.includes('/booking/select-vehicle-type')) {
			visible = false;
		}

		if (this.router.url.includes('/booking/basket')) {
			visible = false;
		}

		if (this.router.url.includes('/booking/confirmation')) {
			visible = false;
		}

		if (this.router.url.includes('/booking/select-services') && this.config.tyres.enabled && !this.config.services.enabled) {
			// this hides the sidebar on the select services page when we are tyres only.
			visible = false;
		}

		return visible;
	}

	bookingSystemFooterVisible() {
		let visible = true;

		if (this.router.url.includes('/booking/select-vehicle') && this.basketStoreService.getBasketNumberOfItems() == 0) {
			visible = false;
		}

		if (this.router.url.includes('/booking/enter-details')) {
			visible = false;
		}

		if (this.router.url.includes('booking/select-location')) {
			visible = false;
		}

		if (this.router.url.includes('booking/confirmation')) {
			visible = false;
		}

		return visible;
	}

	headerVisible() {
		let visible = true;
		// if (this.router.url.includes('booking/select-location')) {
		// 	visible = false;
		// }
		return visible;
	}

	// Breadcrumbss
	selectServicesBreadcrumbActive() {
		let active = true;
		// This will always be active unless we haven't got a branch selected.
		if (!this.companyStoreService.branch?.guid) {
			active = false;
		}

		if (this.router.url.includes('/booking/select-vehicle')) {
			active = false;
		}

		return active;
	}

	extrasBreadcrumbActive() {
		let active = true;

		// Check previous step is ok.
		if (!this.selectServicesBreadcrumbActive()) {
			active = false;
		}

		// Check we have items in basket
		if (this.basketStoreService.getBasketNumberOfItems() == 0) {
			active = false;
		}

		// Only enable it for steps backwards
		if (this.router.url.includes('/booking/select-services') || this.router.url.includes('/booking/select-location')) {
			active = false;
		}

		return active;
	}

	extrasBreadcrumbVisible() {
		let visible = true;

		// If we have configured to skip basket, remove the link.
		if (this.config.skipBasket && this.config.skipUpsell) {
			visible = false;
		}

		return visible;
	}

	dateTimeBreadcrumbVisible() {
		let visible = true;

		if (this.router.url.includes('/booking/enter-details') && this.basketStoreService.unbookableSlotTypes.length > 0) {
			visible = false;
		}

		if (this.router.url.includes('/booking/add-extras') && this.basketStoreService.unbookableSlotTypes.length > 0) {
			visible = false;
		}

		if (this.router.url.includes('/booking/basket') && this.basketStoreService.unbookableSlotTypes.length > 0) {
			visible = false;
		}

		return visible;
	}

	dateTimeBreadcrumbActive() {
		let active = true;

		// Check previous step(s) to make sure we pass the validation.
		if (!this.extrasBreadcrumbActive()) {
			active = false;
		}

		// Check the URL. extrasBreadcrumbActive will be checking select-services and select-location
		if (this.router.url.includes('/booking/basket')) {
			active = false;
		}

		if (this.router.url.includes('/booking/enter-details') && this.basketStoreService.unbookableSlotTypes.length > 0) {
			active = false;
		}

		return active;
	}

	enterDetailsBreadcrumbActive(allowForwardsNav: boolean = true) {
		let active = true;

		if (!this.dateTimeBreadcrumbActive()) {
			active = false;
		}

		if (this.router.url.includes('/booking/select-date-time')) {
			active = false;
		}

		if (this.router.url.includes('/booking/enter-details') && this.basketStoreService.unbookableSlotTypes.length > 0) {
			active = true;
		}

		return active;
	}

	bookingStepsVisible() {
		// if (this.router.url == "/booking/select-location") {
		//     return false;
		// } else {
		//     return true;
		// }

		return true;
	}

	// Buttons

	extrasButtonVisible() {
		let visible = false;

		if (!this.config.skipBasket || !this.config.skipUpsell) {
			visible = true;
		}

		if (!this.router.url.includes('/booking/select-services')) {
			visible = false;
		}

		if (this.router.url.includes('/booking/select-vehicle') && this.basketStoreService.getBasketNumberOfItems() > 0) {
			visible = true;
		}

		if (this.router.url.includes('/booking/add-extras') && this.basketStoreService.getBasketNumberOfItems() > 0 && !this.config.skipBasket) {
			// We're on the upsell step, but want to go through the basket next.
			visible = true;
		}

		return visible;
	}

	extrasButtonActive() {
		let active = true;

		if (!this.companyStoreService.branch?.guid) {
			active = false;
		}

		if (this.basketStoreService.getBasketNumberOfItems() == 0) {
			active = false;
		}

		return active;
	}

	extrasNavigate() {
		if (!this.extrasButtonActive()) {
			return;
		}
		// End with date / time or enter details, depending on POA.

		if (!this.config.skipUpsell && this.basketStoreService.getAvailableUpsellCount() > 0 && !this.router.url.includes('/booking/add-extras')) {
			// Config wants us to upsell, and there are upsells available.
			this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'add-extras']);
		} else if (!this.config.skipBasket) {
			// If we're here, we can't upsell. Does config want us to go to the basket?
			this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'basket']);
		} else if (this.basketStoreService.unbookableSlotTypes.length) {
			// We have a slot type that can't be booked, so lets go to enter details.
			this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'enter-details']);
		} else {
			this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-date-time']);
		}
	}

	dateTimeButtonVisible() {
		let visible = false;

		if (this.config.skipBasket && this.config.skipUpsell && this.router.url.includes('/booking/select-services')) {
			visible = true;
		}

		if (this.config.skipBasket && !this.config.skipUpsell && this.router.url.includes('/booking/add-extras')) {
			visible = true;
		}

		if (this.basketStoreService.unbookableSlotTypes.length) {
			visible = false;
		}

		return visible;
	}

	dateTimeButtonActive() {
		let active = true;

		if (!this.companyStoreService.branch?.guid) {
			active = false;
		}

		if (this.basketStoreService.getBasketNumberOfItems() == 0) {
			active = false;
		}

		// if (!this.basketStoreService.bookingSlotRange) {
		// // Commented out as I believe this was left in as a mistake. This would stop the user being able to get to the date time step were we get that value.
		//    active = false;
		// }

		return active;
	}

	enterDetailsButtonVisible() {
		let visible = false;
		if (this.router.url.includes('/booking/select-date-time')) {
			visible = true;
		}

		if (this.router.url.includes('/booking/basket') && this.basketStoreService.unbookableSlotTypes.length > 0) {
			visible = true;
		}

		if (
			this.router.url.includes('/booking/select-services') &&
			this.config.skipBasket &&
			this.config.skipUpsell &&
			this.basketStoreService.unbookableSlotTypes.length > 0
		) {
			visible = true;
		}

		if (this.router.url.includes('/booking/add-extras') && this.config.skipBasket && this.basketStoreService.unbookableSlotTypes.length > 0) {
			visible = true;
		}

		return visible;
	}

	enterDetailsButtonActive() {
		let active = false;

		if (this.dateTimeButtonActive() && this.basketStoreService.bookingSlotRange) {
			active = true;
		}

		if (this.basketStoreService.bookingDateTime) {
			active = true;
		}

		if (this.router.url.includes('/booking/basket') && this.basketStoreService.unbookableSlotTypes.length > 0) {
			active = true;
		}

		if (this.router.url.includes('/booking/select-services') && this.basketStoreService.unbookableSlotTypes.length > 0) {
			active = true;
		}

		if (this.router.url.includes('/booking/add-extras') && this.basketStoreService.unbookableSlotTypes.length > 0) {
			active = true;
		}

		return active;
	}

	addExtrasButtonVisible() {
		let visible = false;

		if (this.router.url.includes('/booking/basket')) {
			visible = true;

			if (this.basketStoreService.unbookableSlotTypes.length > 0) {
				visible = false;
			}
		}

		return visible;
	}

	addExtrasButtonActive() {
		let active = true;

		if (!this.companyStoreService.branch?.guid) {
			active = false;
		}

		if (this.basketStoreService.getBasketNumberOfItems() == 0) {
			active = false;
		}

		return active;
	}
}
