import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { LoggerStoreService } from 'src/app/services/logger/logger-store.service';
import { CustomerStoreService } from 'src/app/services/customer/customer-store.service';
import { PasswordValidator } from '../../checkout/checkout-enter-details-form/checkout-enter-details-form.component';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-account-change-password',
	templateUrl: './account-change-password.component.html',
	styleUrls: ['./account-change-password.component.scss']
})
export class AccountChangePasswordComponent implements OnInit {

	env: any;
	config: IComponentConfig;
	form: UntypedFormGroup;
	btnUpdatePasswordLoading = false;
	incorrectPassword = false;
	passwordChanged = false;

	constructor(
		private loggerStoreService: LoggerStoreService,
		public customerStoreService: CustomerStoreService,
		private configStoreService: ConfigStoreService,
		public companyStoreService: CompanyStoreService) {
			this.env = environment;
		 }

		

	ngOnInit(): void {
		this.config = this.configStoreService.getComponentConfig('AccountChangePasswordComponent');
		this.loggerStoreService.log(`Account Edit Details: Initialised.`);
		this.form = new UntypedFormGroup({ });
        this.form.addControl('customerOldPasswordFormControl', new UntypedFormControl(null,  [Validators.required]));
		this.form.addControl('customerPasswordFormControl', new UntypedFormControl(null,  [PasswordValidator.strong, Validators.minLength(6), Validators.required]));
        this.form.addControl('customerRepeatPasswordFormControl', new UntypedFormControl(null, [this.validatePasswords()]));
	}

	validatePasswords(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const match = control.value == this.form.controls.customerPasswordFormControl.value;
            return match ? null : { 'passwordMatch': { value: control.value } };
        };
	}
	
	async updatePassword() {
		if (!this.form.valid || this.btnUpdatePasswordLoading) { return; }
		this.btnUpdatePasswordLoading = true;
		await this.customerStoreService.updateCustomerPassword(this.form.controls.customerOldPasswordFormControl.value, this.form.controls.customerPasswordFormControl.value).then(response => {
			setTimeout(() => {
				this.btnUpdatePasswordLoading = false;
				if (!response) {
					this.incorrectPassword = true;
				} else {
					this.incorrectPassword = false;
					this.passwordChanged = true;
				} 
			}, this.config.loadingPanelDelay);
		});
	}

}
