import { Component, OnInit, Input } from '@angular/core';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { TyreStoreService } from 'src/app/services/tyre/tyre-store.service';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogTyreSelectComponent } from '../../dialogs/dialog-tyre-select/dialog-tyre-select.component';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ITyreSize } from 'src/app/interfaces/tyre.interface';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { Router } from '@angular/router';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { IVehicle } from 'src/app/interfaces/vehicle.interface';
import { IframeResizerStoreService } from 'src/app/services/iframe-resizer/iframe-resizer-store.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-tyre-launch',
    templateUrl: './tyre-launch.component.html',
    styleUrls: ['./tyre-launch.component.scss']
})
export class TyreLaunchComponent implements OnInit {

    config: IComponentConfig;
    searchType = 'manual';
    @Input() template: string;

    loading = false;
    vehicleRegistration = '';
    vrmError = false;
    vrmForm: UntypedFormGroup;
    vrmType: string;
    selectedTyreSize: ITyreSize;
    hideTyres = false;
    btnVrmLoading = false;

    constructor(
        public companyStoreService: CompanyStoreService,
        public tyreStoreService: TyreStoreService,
        public basketStoreService: BasketStoreService,
        public vehicleStoreService: VehicleStoreService,
        private authStoreService: AuthStoreService,
        private configStoreService: ConfigStoreService,
        private iframeResizerStoreService: IframeResizerStoreService,
        private modalService: NgbModal,
        private router: Router, 
        public dialog: MatDialog) {

        this.config = this.configStoreService.getComponentConfig('TyreLaunchComponent');
        // if a template is passed directly into the component, then use that, else, use the default template from config.
        this.template = this.template ? this.template : this.config.template;
    }

    ngOnInit(): void {
        // Are we loading the normal VRM lookup or the tyre VRM lookup w/ tyre sizes?
        if (this.config.tyres.enabled) {
            this.vrmType = 'Tyres';
        } else {
            this.vrmType = 'Standard';
        }

        if (this.vehicleStoreService.vrmAvailable()) {
            this.searchType = 'vrm';
        }
        this.vrmForm = new UntypedFormGroup({
			'vrmFormControl': new UntypedFormControl(null, [Validators.required, Validators.minLength(3)])
        });
        if (this.vehicleStoreService.vehicle?.registrationNumber) {
            this.vrmForm.controls.vrmFormControl.setValue(this.vehicleStoreService.vehicle.registrationNumber);
        }
    }

    vrmComplete(checkTyres: boolean) { 
        if (checkTyres) {
            this.selectTyreSize();
        }
    }

    selectTyreSize() {
        if (this.loading) { return };
        this.loading = true;

        this.iframeResizerStoreService.scrollToIFrameTop();

        const modalRef = this.modalService.open(DialogTyreSelectComponent, { size: 'lg' });
		modalRef.componentInstance.data = {
            template: 'TyreSizeSelection',
            title: 'Confirm your tyre size',
            selectTyres: true
        };

		modalRef.result.then((vehicleConfirmed) => {
			if (vehicleConfirmed) {
                setTimeout(() => {
                    this.loading = false;
                    this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-services']);
                }, this.config.loadingPanelDelay*2);
			} else {
                this.loading = false;
            }
        }, (reason) => {
            this.loading = false;
        });
        
    }

    // Saves the tyre size selected in the manual tyre selection form
    findTyresBySize() {
        if (this.loading) {return;}
        this.loading = true;
        this.iframeResizerStoreService.scrollToIFrameTop();

        const modalRef = this.modalService.open(DialogTyreSelectComponent, { size: 'xl' });
		modalRef.componentInstance.data = {
            template: 'TyreResults',
            title: 'Select your tyres',
            selectTyres: true
        };

		modalRef.result.then((vehicleConfirmed) => {
			if (vehicleConfirmed) {
                setTimeout(() => {
                    this.loading = false;
                    this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-services']);
                }, this.config.loadingPanelDelay*2);
            } else {
                this.loading = false;
            }
        }, (reason) => {
            this.loading = false;
        });

    }
}
