<div class="loading-panel" [hidden]="!loading"></div>
<div class="modal-header mst">
    <h4 class="modal-title">{{data.title}}</h4>
    <button class="btn-close-dialog" (click)="modal.dismiss('Closed modal')">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body mst">
    <ng-container *ngIf="data.template == 'Login'">
        <ng-template [ngTemplateOutlet]="Login"></ng-template>
    </ng-container>
    <ng-container *ngIf="data.template == 'ForgottenPassword'">
        <ng-template [ngTemplateOutlet]="ForgottenPassword"></ng-template>
    </ng-container>
</div>

<ng-template #Login>
    <form [formGroup]="loginForm" (ngSubmit)="doLogin()" class="login-form">
        <div>
            <mat-form-field [appearance]="config.formAppearance">
                <mat-label>Email address</mat-label>
                <input formControlName="emailAddressFormControl" matInput>
                
                <mat-error *ngIf="email?.errors?.email && !email?.errors?.required">
                    Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="email?.errors?.required">
                    Email is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field [appearance]="config.formAppearance">
                <mat-label>Password</mat-label>
                <input matInput formControlName="passwordFormControl" type="password">
                <mat-error *ngIf="password?.errors?.required">
                    Password is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="alert alert-danger" *ngIf="incorrectLogin">
            Incorrect username or password. Please try again.
        </div>
        <button class="btn btn-primary btn-lg btn-block" [disabled]="!loginForm.valid || btnLoginLoading"><img class="btn-loading-img" src="{{env.assetsUrl}}/images/loader.svg" *ngIf="btnLoginLoading" /> Login</button>
        <a href="javascript:void(0)" class="small" (click)="navigate('ForgottenPassword')">Forgotten password?</a>
    </form>
</ng-template>

<ng-template #ForgottenPassword>
     <app-account-password-recovery></app-account-password-recovery>
</ng-template>