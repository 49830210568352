import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ModalStoreService {

	private renderer: Renderer2;
	currentModal: any;

	constructor(rendererFactory: RendererFactory2) {
		this.renderer = rendererFactory.createRenderer(null, null);
	}

	openModal(title: string, content: any) {
		if (!this.currentModal) {
			const modal = this.renderer.createElement("div");
			modal.classList.add("widget_modal-wrapper");
			modal.innerHTML = `
			<div class='widget_info-modal'>
				<div class='widget_info-modal-header'>
					<h1 class='widget_info-modal-header-title'>
						${title}
					</h1>
					<button type="button" id='widget_close-modal-button' class='btn btn-primary widget_info-modal-header-close'>
						<i class="fas fa-times"></i>
					</button>
				</div>
				<div class='widget_info-modal-content'>
					${content}
				</div>
			</div>`;
			document.body.appendChild(modal);
			this.currentModal = modal;
			this.renderer.listen(document.getElementById('widget_close-modal-button'), 'click', (event) => { this.closeModal() });
		} else {
			this.closeModal();
		}
	}

	closeModal() {
		if (this.currentModal) {
			document.body.removeChild(this.currentModal);
			this.currentModal = undefined;
		}
	}
}
