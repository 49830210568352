import { Injectable } from '@angular/core';
import {
	TCurrency,
	IPayPalOrderRequest,
	IPayPalOrderItem,
} from 'src/app/components/checkout/paypal-buttons/paypal.interface';
import { IOnlineShoppingBasketItem, IOnlineShoppingBasketValid } from 'src/app/interfaces/shopping-basket.interface';
import { LoggerStoreService } from '../logger/logger-store.service';

@Injectable({
	providedIn: 'root',
})
export class PaypalStoreService {
	constructor(
		private loggerService: LoggerStoreService
	) {}

	// Transforms our model into PayPals OrderRequest object
	public createPayPalOrder(shoppingBasket: IOnlineShoppingBasketValid, currencyCode: TCurrency): IPayPalOrderRequest {
		try {
			// If we have no items then something went wrong
			// and the request shouldn't be created
			if (!currencyCode) {
				this.loggerService.logCritialToRollBar(new Error('createPayPalOrder: currencyCode not defined'));
			}

			if (shoppingBasket.onlineShoppingBasketItems?.length <= 0) {
				this.loggerService.logCritialToRollBar(new Error('createPayPalOrder: No validated shopping basket items'));
			}

			// initialise request
			const orderRequest: IPayPalOrderRequest = {
				intent: 'CAPTURE',
				purchase_units: [
					{
						amount: {
							value: '0.00',
							currency_code: currencyCode,
						},
						items: [],
					},
				],
				application_context: {
					shipping_preference: 'NO_SHIPPING',
				},
			};

			// some variables we want for totals
			let itemTotal = 0;
			let vatTotal = 0;
			
			// start converting	
			shoppingBasket.onlineShoppingBasketItems.forEach((item: IOnlineShoppingBasketItem) => {
				const payPalItem: IPayPalOrderItem = {
					name: this.paypalSafeStringLength(item.partNumber),
					description: this.paypalSafeStringLength(item.description),
					quantity: item.quantity.toFixed(0),					
					unit_amount: {
						value: item.discountNetPrice > 0 ?						
							item.discountNetPrice.toFixed(2) :
							item.fullNetPrice.toFixed(2), 
						currency_code: currencyCode,
					}, 
					tax: {						
						value: item.discountVat > 0 ?						
							item.discountVat.toFixed(2) :
							item.fullVat.toFixed(2), 
						currency_code: currencyCode
					}
				};

				itemTotal += (item.discountNetPrice > 0 ?						
					item.discountNetPrice :
					item.fullNetPrice) * item.quantity;
				vatTotal += (item.discountVat > 0 ?						
					item.discountVat :
					item.fullVat) * item.quantity;
				
				orderRequest.purchase_units[0].items.push(payPalItem);
			})
			
			// If we have no items then something went wrong
			// and the request shouldn't be created
			if (orderRequest.purchase_units[0].items.length <= 0) {
				this.loggerService.logCritialToRollBar(new Error('createPayPalOrder: Order request not created, no items created'));
			}

			// assign totals
			orderRequest.purchase_units[0].amount.value = (itemTotal + vatTotal).toFixed(2);
			orderRequest.purchase_units[0].amount.currency_code = currencyCode;
			orderRequest.purchase_units[0].amount.breakdown = {
				item_total: {
					value: itemTotal.toFixed(2),
					currency_code: currencyCode,
				},
				tax_total: {
					value: vatTotal.toFixed(2),
					currency_code: currencyCode
				}
			};

			this.loggerService.log("PaypalStoreService: Created Order");
			
			return orderRequest;
		} catch (err) {		
			this.loggerService.logCritialToRollBar(new Error(`createPayPalOrder Error: ${err}`));
		}
	}

	private paypalSafeStringLength(str: string): string {
		return str.length > 127 ? '' : str;
	}
}
