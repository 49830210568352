import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoggerStoreService } from '../logger/logger-store.service';
import { LocalStorageStoreService } from '../local-storage/local-storage-store.service';
import { ISlotTypeGroup } from 'src/app/interfaces/slot-type-group.interface';
import { SlotTypeService } from './slot-type.service';
import { CompanyStoreService } from '../company/company-store.service';
import { BasketStoreService } from '../basket/basket-store.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VehicleStoreService } from '../vehicle/vehicle-store.service';
import { ISlotType } from '../../interfaces/slot-type.interface';
import { ConfigStoreService } from '../config/config-store.service';
import { SlotTypeType } from 'src/app/global/enums';

@Injectable({
    providedIn: 'root'
})
export class SlotTypeStoreService {

    constructor(
        private slotTypeService: SlotTypeService,
        private companyStoreService: CompanyStoreService,
        private configStoreService: ConfigStoreService,
        private loggerStoreService: LoggerStoreService,
        private injector: Injector,
        private snackBar: MatSnackBar,
        private vehicleStoreService: VehicleStoreService,
        private localStorageStoreService: LocalStorageStoreService) {
            this.loggerStoreService.log(`Slot Type Store Service: Initialized`);
            // Once we have a vehicle service, amend this to pass in correct params if we have them.
            this.getSlotTypeGroups(this.companyStoreService.branch?.guid);
        }

    private readonly _slotTypeGroups = new BehaviorSubject<Array<ISlotTypeGroup>>([] as Array<ISlotTypeGroup>);
    readonly slotTypeGroups$ = this._slotTypeGroups.asObservable();

    get slotTypeGroups(): Array<ISlotTypeGroup> {
        return this._slotTypeGroups.getValue();
    }

    set slotTypeGroups(val: Array<ISlotTypeGroup>) {
        this._slotTypeGroups.next(val);
    }

    // Additional helper functions for which slot type / slot type groups should
    // be visible based on user interaction that doesn't provoke a fresh API call.
    // For example: select a vehicle type.
    showSlotTypeGroup(slotTypeGroup: ISlotTypeGroup) {
        let visible = true;
        // Check if we have a vehicle type, and if we do, that there is at least 1 slot type with that vehicle type.
        for (let i = 0; i < slotTypeGroup.slotTypes.length; i++) {
            visible = false;
            if (this.showSlotType(slotTypeGroup.slotTypes[i])) {
                visible = true;
                break;
            }
        }

        return visible;
    }

    showSlotType(slotType: ISlotType) {
        let visible = true;
        // Does the slot type vehicle category match?
        if (this.configStoreService.config.selectVehicleType && this.vehicleStoreService.vehicleType && !slotType.vehicleCategories.includes(this.vehicleStoreService.vehicleType)) {
            visible = false;
        }

        return visible;
    }

    slotTypeGroupHasChecklist(slotTypeGroup: ISlotTypeGroup) {
        if (slotTypeGroup.checklistTemplate) {
            return true;
        }
        return false;
    }

    get slotTypeGroupsWithoutChecklists(): Array<ISlotTypeGroup> {
        return this.slotTypeGroups.filter(c => c.checklistTemplate == null);
    }

    async getSlotTypeGroups(branchGuid: string, vehicleMake: string = '', fuelTypeID: number = 0, engineCC: number = 0, getNextAvailableSlotDates:boolean = false) {
        if (!branchGuid) {
            this.loggerStoreService.log(`Slot Type Store Service: No Branch Guid.`);
            return;
        }
        let slotTypeGroups = await this.slotTypeService.getSlotTypeGroups(branchGuid, vehicleMake, fuelTypeID, engineCC, getNextAvailableSlotDates).toPromise();
        
        slotTypeGroups = this.applyFilters(slotTypeGroups);        

        this.loggerStoreService.log(`Slot Type Store Service: Received ${slotTypeGroups.length} slot type groups.`);
        this.loggerStoreService.table(slotTypeGroups);

        this.slotTypeGroups = slotTypeGroups;
    }

    private applyFilters(slotTypeGroups: ISlotTypeGroup[]): ISlotTypeGroup[] {
        let result: ISlotTypeGroup[] = slotTypeGroups;
        if (this.configStoreService.isFilteredItemConfig) {
            result = []
            if (this.configStoreService.config.slotTypeGroupIDs[0] != '') {
                console.log(this.configStoreService.config.slotTypeGroupIDs);

                console.log(
                    ...slotTypeGroups.filter(c => 
                        this.configStoreService.config.slotTypeGroupIDs.includes(c.id.toString())
                    )
                );

                result.push(
                    ...slotTypeGroups.filter(c => 
                        this.configStoreService.config.slotTypeGroupIDs.includes(c.id.toString())
                    )
                );
            }

            if (this.configStoreService.config.slotTypeIDs[0] != '') {

                slotTypeGroups.forEach(group => {
                    // if the group has been filtered then we want all slot types in that group,
                    // therefore skip it.
                    if(this.configStoreService.config.slotTypeGroupIDs.includes(group.id.toString())) { return; }

                    const tempGroup = group;
                    tempGroup.slotTypes = group.slotTypes.filter(c => this.configStoreService.config.slotTypeIDs.includes(c.id.toString()));
                    if(tempGroup.slotTypes.length > 0) {
                        result.push(tempGroup);
                    }
                });
            }

            result = result
                .filter(c => c.slotTypes.length > 0);
        }

          return result;
    }

    async getWidgetSlotTypes(branchGuid: string, slotTypeGroupIDs: string[], slotTypeIDs: string[]): Promise<ISlotType[]> {
        if(
            (!slotTypeGroupIDs || slotTypeGroupIDs.length <= 0) &&
            (!slotTypeIDs || slotTypeIDs.length <= 0)
        ) return [];

        await this.getSlotTypeGroups(branchGuid);

        let slotTypes: ISlotType[] = [];
        
        this.slotTypeGroups.forEach(group => {
            if(slotTypeGroupIDs?.length && slotTypeGroupIDs.includes(group.id.toString())){
                slotTypes.push(...group.slotTypes);
            }
        });

        this.slotTypeGroups.forEach(group => {
            slotTypes.push(...group.slotTypes.filter(c => slotTypeIDs.includes(c.id.toString())))
        });

        // Distinct 
        const key = 'id';
        slotTypes = [...new Map(slotTypes.map(item =>
            [item[key], item])).values()];

         
        slotTypeIDs.forEach(slotTypeID => {
            if(!slotTypes.map(c => c.id.toString()).includes(slotTypeID)) { console.error(`Slot Type ID ${slotTypeID} not found`);}
        });
    
        this.loggerStoreService.log(`Slot Type Store Service: Filtered to ${slotTypes.length} slot types.`);
        this.loggerStoreService.table(slotTypes);
        return slotTypes;
    }

    getSlotTypeGroupFromSlotType(slotTypeID: number) {
        if (!this.slotTypeGroups.length) { return []}
        const slotTypeGroups: ISlotTypeGroup[] = [];

        this.slotTypeGroups.forEach(slotTypeGroup => {
            const matches = slotTypeGroup.slotTypes.filter(s => s.id == slotTypeID);
            if (matches.length) {
                slotTypeGroups.push(slotTypeGroup)
            }
        });

        return slotTypeGroups;
    }

    async updateSlotTypePrices(branchGuid: string, vehicleMake: string = '', fuelTypeID: number = 0, engineCC: number = 0, getNextAvailableSlotDates:boolean = false) {
        // This function exists to simply update the prices of slot types when vehicles change.
        // We could easily just replace slotTypeGroups, but replacing something that large will
        // cause a full re-render of the slots. This is purely for a smoother visual experience.
        if (!branchGuid) {
            this.loggerStoreService.log(`Slot Type Store Service: No Branch Guid.`);
            return;
        }
        
        let newSlotTypeGroups = await this.slotTypeService.getSlotTypeGroups(branchGuid, vehicleMake, fuelTypeID, engineCC, getNextAvailableSlotDates).toPromise();
        this.loggerStoreService.log(`Slot Type Store Service: Received ${newSlotTypeGroups.length} slot type groups.`);
        this.loggerStoreService.table(newSlotTypeGroups);

        newSlotTypeGroups = this.applyFilters(newSlotTypeGroups);

        if (!this.slotTypeGroups.length) {
            this.slotTypeGroups = newSlotTypeGroups;
        } else {
            this.slotTypeGroups = newSlotTypeGroups;
        }
    }

    async refreshSlotTypeGroups() {
        await this.updateSlotTypePrices(
            this.companyStoreService.branch.guid, 
            this.vehicleStoreService.vehicle.make,
            this.vehicleStoreService.vehicle.fuelTypeID,
            this.vehicleStoreService.vehicle.engineCC,
            false);
    }

    get slotTypes() {
        if (!this.slotTypeGroups.length) return;
        let slotTypes = [];
        this.slotTypeGroups.forEach(slotTypeGroup => {
            slotTypes = slotTypes.concat(slotTypeGroup.slotTypes);
        });
        return slotTypes;
    }

    getSlotTypeByID(id: number) {
        if (!this.slotTypeGroups.length) return;
        for (let slotTypeGroup of this.slotTypeGroups) {
            if (slotTypeGroup.slotTypes.find(slotType => slotType.id == id)) {
                return slotTypeGroup.slotTypes.find(slotType => slotType.id == id);
            }
        }
    }

    get serviceChecklists(): Array<ISlotTypeGroup> {
        return this.slotTypeGroups.filter(c => c.checklistTemplate != null);
    }
}
