<div class="loading-panel" [hidden]="!loading"></div>
<div class="footer-overlay" *ngIf="showFooterMoreInfo"></div>

<div class="widget-fallbacks" *ngIf="iframeResizerStoreService.isInsideIframe && config.mobileFallbackComponent != 'none'">
	<app-widget-vrm-lookup [fallback]="true" *ngIf="config.mobileFallbackComponent == 'vrm'"></app-widget-vrm-lookup>
	<app-widget-booking-system-link [fallback]="true" *ngIf="config.mobileFallbackComponent == 'link'"></app-widget-booking-system-link>
	<app-widget-tyre-size [fallback]="true" *ngIf="config.mobileFallbackComponent == 'tyre'"></app-widget-tyre-size>
</div>

<div class="layout-wrapper">
	<div class="top-wrapper" #top>
		<div class="layout-header" #header *ngIf="headerVisible()">
			<div class="booking-steps">
				<button class="btn btn-light btn-back" (click)="navigateBack()" [disabled]="!navigateBackButtonActive()">
					<i class="fas fa-arrow-left"></i>
				</button>
				<button class="btn btn-light" (click)="startAgain()">Start again</button>
				<div class="step-numbers">
					{{ navigationStoreService.currentStepNumber(router.url) }}&nbsp;<span> / {{ navigationStoreService.totalSteps() }}</span>
				</div>
			</div>
			<div class="booking-info">
				<div class="branch-vehicle-info">
					<div class="location" [hidden]="companyStoreService.formatBranchName(companyStoreService.branch) == ''">
						<i class="fas fa-home"></i> {{ companyStoreService.formatBranchName(companyStoreService.branch) }}
					</div>
					<div class="vehicle" [hidden]="vehicleStoreService.getFormattedVehicle() == ''">
						<i class="fas fa-car"></i> {{ vehicleStoreService.getFormattedVehicle() }}
					</div>
				</div>
			</div>
			<button class="user" [matMenuTriggerFor]="belowMenu" *ngIf="authStoreService.isLoggedIn()">
				<i class="fas fa-user"></i> <span>{{ customerStoreService.customer.fullName }}</span
				><i class="fas fa-chevron-down"></i>
			</button>
			<mat-menu #belowMenu="matMenu" xPosition="before">
				<button [routerLink]="['/' + authStoreService.clientId, configStoreService.configId, 'account', 'edit-customer']" mat-menu-item>Edit details</button>
				<button [routerLink]="['/' + authStoreService.clientId, configStoreService.configId, 'account', 'change-password']" mat-menu-item>Change password</button>
				<button [routerLink]="['/' + authStoreService.clientId, configStoreService.configId, 'account', 'manage-bookings']" mat-menu-item>View bookings</button>
				<button mat-menu-item (click)="logout()">Log out</button>
			</mat-menu>
			<button type="button" *ngIf="!authStoreService.isLoggedIn() && loginButtonVisible()" (click)="login()" class="btn btn-outline-primary">Login</button>
		</div>
		<div class="layout-nav" #nav *ngIf="bookingStepsVisible()">
			<ul class="booking-breadcrumbs">
				<li *ngIf="companyStoreService.company?.branches?.length > 1" [ngClass]="{ current: router.url.includes('select-location') }">
					<a [routerLink]="['/' + authStoreService.clientId, configStoreService.configId, 'booking', 'select-location']"> Select location</a>
				</li>
				<li [ngClass]="{ current: router.url.includes('select-vehicle-type') }" *ngIf="config.selectVehicleType && config.services.enabled">
					<a [routerLink]="['/' + authStoreService.clientId, configStoreService.configId, 'booking', 'select-vehicle-type']"> Select vehicle type </a>
				</li>
				<li
					[ngClass]="{ current: router.url.includes('select-vehicle') && !router.url.includes('type') }"
					*ngIf="(config.startWithVehicle && !config.tyres.enabled) || (!config.services.enabled && config.tyres.enabled)"
				>
					<a [routerLink]="['/' + authStoreService.clientId, configStoreService.configId, 'booking', 'select-vehicle']">
						<span *ngIf="config.tyres.enabled">Select tyre size</span>
						<span *ngIf="!config.tyres.enabled">Enter vehicle</span>
					</a>
				</li>
				<li [ngClass]="{ disabled: !selectServicesBreadcrumbActive(), current: router.url.includes('select-services') }">
					<a [routerLink]="selectServicesBreadcrumbActive() ? ['/' + authStoreService.clientId, configStoreService.configId, 'booking', 'select-services'] : null">
						<span *ngIf="config.tyres.enabled && !config.services.enabled">Select tyres</span>
						<span *ngIf="config.services.enabled">Select services</span>
					</a>
				</li>
				<li
					[ngClass]="{ disabled: !extrasBreadcrumbActive(), current: router.url.includes('basket') || router.url.includes('add-extras') }"
					*ngIf="extrasBreadcrumbVisible()"
				>
					<a
						[routerLink]="extrasBreadcrumbActive() ? ['/' + authStoreService.clientId, configStoreService.configId, 'booking', 'basket'] : null"
						*ngIf="!config.skipBasket && config.skipUpsell"
					>
						Add extras
					</a>
					<a
						[routerLink]="extrasBreadcrumbActive() ? ['/' + authStoreService.clientId, configStoreService.configId, 'booking', 'add-extras'] : null"
						*ngIf="config.skipBasket && !config.skipUpsell"
					>
						Add extras
					</a>
					<a
						[routerLink]="extrasBreadcrumbActive() ? ['/' + authStoreService.clientId, configStoreService.configId, 'booking', 'add-extras'] : null"
						*ngIf="!config.skipBasket && !config.skipUpsell"
					>
						Add extras
					</a>
				</li>
				<li [ngClass]="{ disabled: !dateTimeBreadcrumbActive(), current: router.url.includes('select-date-time') }" *ngIf="dateTimeBreadcrumbVisible()">
					<a [routerLink]="dateTimeBreadcrumbActive() ? ['/' + authStoreService.clientId, configStoreService.configId, 'booking', 'select-date-time'] : null">
						Select date & time
					</a>
				</li>
				<li [ngClass]="{ disabled: !enterDetailsBreadcrumbActive(), current: router.url.includes('enter-details') }">
					<a [routerLink]="enterDetailsBreadcrumbActive() ? ['/' + authStoreService.clientId, configStoreService.configId, 'booking', 'enter-details'] : null">
						Confirm booking
					</a>
				</li>
			</ul>
		</div>
	</div>

	<div class="layout-body">
		<div class="layout-main" [ngStyle]="maxHeightStyle">
			<div class="scrollable custom-scrollbar" #scrollable>
				<div class="content">
					<router-outlet></router-outlet>
				</div>
			</div>
		</div>
		<div class="layout-sidebar" *ngIf="sidebarVisible()">
			<div class="sidebar-wrapper custom-scrollbar" [ngStyle]="maxSidebarHeightStyle">
				<div class="sidebar-header">
					<div class="sidebar-total">
						<span class="spacer"> Total </span>
						<span class="price">
							{{ '£' + (basketStoreService.getBasketTotal() | number : '1.2-2') }}
						</span>
					</div>
					<div *ngIf="basketStoreService.poaSlotTypes.length > 0" class="unpriced-message">Total excludes {{ config.unpricedItemsHeader | lowercase }}</div>
					<div *ngIf="basketStoreService.bookingDateTime" class="selected-date-time">
						<i class="far fa-calendar-alt"></i> <span>{{ basketStoreService.bookingDateTime | moment : 'dddd, MMM Do' }}</span> -
						<span>{{ basketStoreService.rangeLabel() }}</span>
					</div>

					<button class="btn btn-primary btn-lg btn-block btn-loading" *ngIf="extrasButtonVisible()" (click)="extrasNavigate()" [disabled]="!extrasButtonActive()">
						Continue
					</button>
					<button
						class="btn btn-primary btn-lg btn-block btn-loading"
						*ngIf="dateTimeButtonVisible()"
						[routerLink]="['/' + authStoreService.clientId, configStoreService.configId, 'booking', 'select-date-time']"
						[disabled]="!dateTimeButtonActive()"
					>
						Continue
					</button>
					<button
						class="btn btn-primary btn-lg btn-block btn-loading"
						*ngIf="enterDetailsButtonVisible()"
						[routerLink]="['/' + authStoreService.clientId, configStoreService.configId, 'booking', 'enter-details']"
						[disabled]="!enterDetailsButtonActive()"
					>
						Continue
					</button>
					<button class="btn btn-primary btn-lg btn-block btn-loading" *ngIf="accountsButtonVisible()" (click)="accountsNavigate()">
						{{ accountsButtonLabel() }}
					</button>
				</div>

				<div class="sidebar-body">
					<app-basket template="compact"></app-basket>
				</div>
			</div>
		</div>
	</div>
	<div class="bottom-wrapper" #bottom>
		<div class="layout-footer" #footer *ngIf="bookingSystemFooterVisible()">
			<div class="more-info" *ngIf="showFooterMoreInfo">
				<div class="branch-vehicle-info">
					<div
						class="location"
						*ngIf="companyStoreService.company?.branches?.length > 1"
						[hidden]="companyStoreService.formatBranchName(companyStoreService.branch) == ''"
					>
						<i class="fas fa-home"></i> <span>{{ companyStoreService.formatBranchName(companyStoreService.branch) }} </span>
					</div>
					<div class="vehicle" [hidden]="vehicleStoreService.getFormattedVehicle() == ''">
						<i class="fas fa-car"></i> <span>{{ vehicleStoreService.getFormattedVehicle() }} </span>
					</div>
				</div>
				<div class="basket-wrapper">
					<app-basket template="mobile-footer"></app-basket>
				</div>
			</div>
			<div class="footer-actions">
				<button class="btn btn-light btn-lg" (click)="showFooterMoreInfo = !showFooterMoreInfo">
					<i class="fas" [ngClass]="{ 'fa-chevron-up': !showFooterMoreInfo, 'fa-chevron-down': showFooterMoreInfo }"></i>
				</button>
				<div class="total">
					<div class="price">{{ '£' + (basketStoreService.getBasketTotal() | number : '1.2-2') }}</div>
					<div *ngIf="basketStoreService.poaSlotTypes.length > 0" class="unpriced-message">Total excludes {{ config.unpricedItemsHeader | lowercase }}</div>
				</div>
				<button class="btn btn-primary btn-lg" *ngIf="extrasButtonVisible()" (click)="extrasNavigate()" [disabled]="!extrasButtonActive()">
					Continue <i class="fas fa-chevron-right"></i>
				</button>
				<button
					class="btn btn-primary btn-lg"
					*ngIf="dateTimeButtonVisible()"
					[routerLink]="['/' + authStoreService.clientId, configStoreService.configId, 'booking', 'select-date-time']"
					[disabled]="!dateTimeButtonActive()"
				>
					Continue <i class="fas fa-chevron-right"></i>
				</button>
				<button
					class="btn btn-primary btn-lg"
					*ngIf="enterDetailsButtonVisible()"
					[routerLink]="['/' + authStoreService.clientId, configStoreService.configId, 'booking', 'enter-details']"
					[disabled]="!enterDetailsButtonActive()"
				>
					Continue <i class="fas fa-chevron-right"></i>
				</button>
				<button
					class="btn btn-primary btn-lg"
					*ngIf="addExtrasButtonVisible()"
					[routerLink]="['/' + authStoreService.clientId, configStoreService.configId, 'booking', 'select-date-time']"
					[disabled]="!addExtrasButtonActive()"
				>
					Continue <i class="fas fa-chevron-right"></i>
				</button>
				<button class="btn btn-primary btn-lg" *ngIf="accountsButtonVisible()" (click)="accountsNavigate()">
					{{ accountsButtonLabel() }} <i class="fas fa-chevron-right"></i>
				</button>
			</div>
		</div>
	</div>
</div>
