<div class="form-row">
    <div class="col-md-3 col-sm-6">
        <mat-form-field [appearance]="config.formAppearance">
            <mat-label>Year</mat-label>
            <mat-select [disabled]="!basketStoreService.availableYears?.length" [(value)]="basketStoreService.selectedYear" (selectionChange)="basketStoreService.selectYear($event.value)">
                <mat-option *ngFor="let year of basketStoreService.availableYears" [value]="year">
                    {{year}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-3 col-sm-6">

        <mat-form-field [appearance]="config.formAppearance">
            <mat-label>Month</mat-label>
            <mat-select [disabled]="!basketStoreService.availableMonths?.length" [(value)]="basketStoreService.selectedMonth" (selectionChange)="basketStoreService.selectMonth($event.value)">
                <mat-option *ngFor="let month of basketStoreService.availableMonths" [value]="month">
                    {{getMonthName(month)}}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </div>
    <div class="col-md-3 col-sm-6">

        <mat-form-field [appearance]="config.formAppearance">
            <mat-label>Day</mat-label>
            <mat-select [disabled]="!basketStoreService.availableDates?.length" (selectionChange)="basketStoreService.getAvailableTimes($event.value)">
                <mat-option *ngFor="let date of basketStoreService.availableDates" [value]="date.date">
                    {{date.date | moment:'dddd Do'}}
                </mat-option>
            </mat-select>
        </mat-form-field>
 
    </div>
    <div class="col-md-3 col-sm-6">

        <mat-form-field [appearance]="config.formAppearance">
            <mat-label>Time</mat-label>
            <mat-select [disabled]="!basketStoreService.availableTimes?.length" (selectionChange)="selectSlot($event)">
                <mat-option *ngFor="let time of basketStoreService.availableTimes" [value]="time">
                    {{basketStoreService.rangeLabel(time)}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>