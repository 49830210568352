import { Component, OnInit, OnDestroy, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';

@Component({
	selector: 'app-checkout-calendar-header',
	templateUrl: './checkout-calendar-header.component.html',
	styleUrls: ['./checkout-calendar-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutCalendarHeaderComponent<D> implements OnDestroy {
	private _destroyed = new Subject<void>();
	constructor(
		private _calendar: MatCalendar<D>,
		private _dateAdapter: DateAdapter<D>,
		private basketStoreService: BasketStoreService,
		@Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, 
		cdr: ChangeDetectorRef) {
		_calendar.stateChanges
			.pipe(takeUntil(this._destroyed))
			.subscribe(() => cdr.markForCheck());
	}

	ngOnInit(): void {
		this.backDisabled();	
	}

	ngOnDestroy() {
		this._destroyed.next();
		this._destroyed.complete();
	}

	get periodLabel() {
		return this._dateAdapter
			.format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
			.toLocaleUpperCase();
	}

	currentDate = new Date();
	backDisabled() {
		if (new Date(this._calendar.activeDate as any) < this.currentDate) {
			return true;
		} else {
			return false;
		}
	}

	previousClicked() {
		const activeDate = new Date(this._calendar.activeDate as any);
		let year = activeDate.getFullYear();
		let month = activeDate.getMonth(); 
		this.basketStoreService.availableTimes = null;
		this.basketStoreService.getAvailableDates(year, month).then(() => {
			this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
		});
	}

	nextClicked() {
		const activeDate = new Date(this._calendar.activeDate as any);
		let year = activeDate.getFullYear();
		let month = activeDate.getMonth() + 2; 
		this.basketStoreService.availableTimes = null;
		this.basketStoreService.getAvailableDates(year, month).then(() => {
			this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
		});
	}

}
