import { Component, OnInit } from '@angular/core';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { VehicleFeedStoreService } from 'src/app/services/vehicle-feed/vehicle-feed-store.service';
import { IComponentConfig, IConfig } from '../../../interfaces/config.interface';

@Component({
  selector: 'app-vehicle-feed-list',
  templateUrl: './vehicle-feed-list.component.html',
  styleUrls: ['./vehicle-feed-list.component.scss']
})
export class VehicleFeedListComponent implements OnInit {

  // mobile show or hide filters
  showFilters = false;

  config: IComponentConfig;

  constructor(
    private configStoreService: ConfigStoreService,
    public vehicleFeedStoreService: VehicleFeedStoreService
  ) { 
    this.config = this.configStoreService.getComponentConfig('VehicleFeedListComponent');
  }

  ngOnInit(): void {
  }

  sortResults() {
    this.vehicleFeedStoreService.sortVehicles();
  }

}
