import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogVehicleEntryComponent } from './dialog-vehicle-entry/dialog-vehicle-entry.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VehicleModule } from '../vehicle/vehicle.module';
import { DialogTyreSelectComponent } from './dialog-tyre-select/dialog-tyre-select.component';
import { TyresModule } from '../tyres/tyres.module';
import { DialogLoginComponent } from './dialog-login/dialog-login.component';
import { DialogInfoComponent } from './dialog-info/dialog-info.component';
import { DialogSelectAddressComponent } from './dialog-select-address/dialog-select-address.component';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { AccountModule } from '../account/account.module';
import { DialogServiceChecklistComponent } from './dialog-service-checklist/dialog-service-checklist.component';
import { SlotTypeModule } from '../slot-type/slot-type.module';


@NgModule({
    declarations: [DialogVehicleEntryComponent, DialogTyreSelectComponent, DialogLoginComponent, DialogInfoComponent, DialogSelectAddressComponent, DialogConfirmComponent, DialogServiceChecklistComponent],
    imports: [
        CommonModule,
        FormsModule,
        VehicleModule,
        TyresModule,
        SlotTypeModule,
        ReactiveFormsModule,
        MaterialModule,
        AccountModule
    ],
    exports: [ ]
})
export class DialogsModule { }
