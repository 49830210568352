import { Injectable, Injector, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ISlotType } from 'src/app/interfaces/slot-type.interface';
import { LoggerStoreService } from '../logger/logger-store.service';
import { PriceType, SlotTypeType } from 'src/app/global/enums';
import { MatDialog } from '@angular/material/dialog';
import { DialogVehicleEntryComponent } from 'src/app/components/dialogs/dialog-vehicle-entry/dialog-vehicle-entry.component';
import { SlotTypeStoreService } from '../slot-type/slot-type-store.service';
import { ISpecialOffer } from 'src/app/interfaces/special-offer.interface';
import { SpecialOfferStoreService } from '../special-offer/special-offer-store.service';
import { ITyre } from 'src/app/interfaces/tyre.interface';
import { TyreStoreService } from '../tyre/tyre-store.service';
import { IExtra } from 'src/app/interfaces/extra.interface';
import * as moment from 'moment';
import { BasketService } from './basket.service';
import { CompanyStoreService } from '../company/company-store.service';
import { ISlotRange } from 'src/app/interfaces/slot.interface';
import { IBooking, IOnlineShoppingBasket } from 'src/app/interfaces/booking.interface';

import { ExtraStoreService } from '../extra/extra-store.service';
import { LocalStorageStoreService } from '../local-storage/local-storage-store.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ConfigStoreService } from '../config/config-store.service';
import { IframeResizerStoreService } from '../iframe-resizer/iframe-resizer-store.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaypalStoreService } from '../online-payment/paypal-store.service';
import { IPayPalOrderRequest } from '../../components/checkout/paypal-buttons/paypal.interface';
import { LocalStorageKey } from '../../global/enums';
import { AuthStoreService } from '../auth/auth-store.service';
import { DialogConfirmComponent } from 'src/app/components/dialogs/dialog-confirm/dialog-confirm.component';
import { EOnlineShoppingBasketIssueType } from 'src/app/interfaces/shopping-basket.interface';
import { VehicleStoreService } from '../vehicle/vehicle-store.service';

declare var parentIFrame;

@Injectable({
	providedIn: 'root',
})
export class BasketStoreService {
	config: IComponentConfig;

	constructor(
		private loggerStoreService: LoggerStoreService,
		private slotTypeStoreService: SlotTypeStoreService,
		private authStoreService: AuthStoreService,
		private specialOfferStoreService: SpecialOfferStoreService,
		private tyreStoreService: TyreStoreService,
		private modalService: NgbModal,
		private extraStoreService: ExtraStoreService,
		private paypalStoreService: PaypalStoreService,
		private localStorageStoreService: LocalStorageStoreService,
		private companyStoreService: CompanyStoreService,
		private configStoreService: ConfigStoreService,
		private basketService: BasketService,
		private snackBar: MatSnackBar,
		private injector: Injector,
		private iframeResizerStoreService: IframeResizerStoreService,
		public dialog: MatDialog
	) {
		this.config = this.configStoreService.getComponentConfig('BasketStoreService');
		this.configureYears();
		this.selectedYear = this.availableYears[0];
		this.getAvailableMonths();
		this.selectedMonth = this.availableMonths[0];
	}

	public basketPricesRefreshed$: Subject<boolean> = new Subject();

	//#region Basket
	clearBasket() {
		this.slotTypes = [];
		this.specialOffers = [];
		this.extras = [];
		this.tyres = [];

		this.localStorageStoreService.set(LocalStorageKey.SlotTypeIDs, this.authStoreService.clientId, JSON.stringify(this.getSlotTypeIDs()));
		this.localStorageStoreService.set(LocalStorageKey.SpecialOfferIDs, this.authStoreService.clientId, JSON.stringify(this.getSpecialOfferIDs()));
		this.localStorageStoreService.set(LocalStorageKey.TyreIDs, this.authStoreService.clientId, JSON.stringify(this.getTyreIDs()));
		this.localStorageStoreService.set(LocalStorageKey.ExtraIDs, this.authStoreService.clientId, JSON.stringify(this.getExtraIDs()));
	}

	getBasketTotal(booking = this) {
		let total = 0;

		booking.slotTypes.forEach((slotType) => {
			total += slotType.fullPrice.value;
		});

		booking.specialOffers.forEach((specialOffer) => {
			total += specialOffer.discountedPrice.value;
		});

		booking.tyres.forEach((tyre) => {
			total += tyre.fullPrice.value * tyre.quantity;
		});

		booking.extras.forEach((extra) => {
			total += extra.fullPrice.value;
		});

		return total;
	}

	getSlotTypesTotal(booking = this) {
		let total = 0;

		booking.slotTypes.forEach((slotType) => {
			total += slotType.fullPrice.value;
		});

		return total;
	}

	getSpecialOffersTotal(booking = this) {
		let total = 0;

		booking.specialOffers.forEach((specialOffer) => {
			total += specialOffer.discountedPrice.value;
		});

		return total;
	}

	getBasketNumberOfItems() {
		let total = 0;

		this.poaSlotTypes.forEach(() => {
			total += 1;
		});

		this.slotTypes.forEach(() => {
			total += 1;
		});

		this.specialOffers.forEach(() => {
			total += 1;
		});

		this.tyres.forEach(() => {
			total += 1;
		});

		this.extras.forEach(() => {
			total += 1;
		});

		return total;
	}

	getAvailableUpsellCount() {
		let count = 0;
		this.extraStoreService.extras.forEach((e) => (count += 1));
		this.specialOfferStoreService.specialOfferUpgrades.forEach((so) => (count += 1));
		return count;
	}

	refreshingPrices = false;
	async refreshPrices() {
		this.refreshingPrices = true;
		// As part of this, we need to first update the slot types and special offers, then loop through the basket,
		// and if the prices are different, update them, then inform the user.
		await this.slotTypeStoreService.refreshSlotTypeGroups();
		await this.specialOfferStoreService.refreshSpecialOffers();
		let basketPriceChanged = false;
		this.slotTypes.forEach((slotType) => {
			const tempSlotType = this.slotTypeStoreService.getSlotTypeByID(slotType.id);
			if (tempSlotType && tempSlotType?.fullPrice.value != slotType.fullPrice.value) {
				basketPriceChanged = true;
				slotType.fullPrice = tempSlotType.fullPrice;
			}
		});

		this.specialOffers.forEach((specialOffer) => {
			const tempSpecialOffer = this.specialOfferStoreService.getSpecialOfferByID(specialOffer.id);
			if (tempSpecialOffer && tempSpecialOffer?.discountedPrice.value != specialOffer.discountedPrice.value) {
				basketPriceChanged = true;
				specialOffer.fullPrice = tempSpecialOffer.fullPrice;
			}
		});

		if (basketPriceChanged) {
			this.snackBar.open('The prices of some of your selected services have been updated.');
		}

		this.basketPricesRefreshed$.next(true);
		this.refreshingPrices = false;
	}
	//#endregion

	//#region Booking Dates & Times
	private currentYear = moment().year();
	private currentMonth = moment().month() + 1;
	refreshingDatesAndTimes = false;

	selectedYear: any;
	selectedMonth: any;
	selectedDay: any;
	selectedHour: any;
	selectedMinute: any;

	availableYears: number[];
	availableMonths: number[];
	availableDates: any;
	availableDatesFormattedForCalendar: any;
	availableTimes: any;

	bookingSlotRange: ISlotRange;
	bookingDateTime;

	private configureYears() {
		this.availableYears = [];
		for (let i: number = this.currentYear; i < this.currentYear + 4; i++) {
			if (this.availableYears) {
				this.availableYears.push(i);
			} else {
				this.availableYears = [i];
			}
		}
	}

	public selectYear(year: number) {
		this.selectedYear = year;
		this.clearDateTime();
		this.availableDates = [];
		this.availableDatesFormattedForCalendar = [];
		this.availableTimes = [];
		this.availableMonths = [];
		this.getAvailableMonths();
	}

	public getAvailableMonths() {
		let startMonth: number = this.selectedYear === this.currentYear ? this.currentMonth : 1;
		this.availableMonths = [];
		for (let i: number = startMonth; i < 13; i++) {
			if (this.availableMonths) {
				this.availableMonths.push(i);
			} else {
				this.availableMonths = [i];
			}
		}
	}

	async selectMonth(month: number) {
		this.clearDateTime();
		this.selectedMonth = month;
		this.availableDates = [];
		this.availableDatesFormattedForCalendar = [];
		this.availableTimes = [];
		this.loggerStoreService.log(`Basket Store Service: Selecting month (${month})`);
		await this.getAvailableDates();
	}

	async getAvailableDates(year: number = this.selectedYear, month: number = this.selectedMonth) {
		this.clearDateTime();
		if (!year || !month) {
			this.loggerStoreService.log(`Basket Store Service: Get Available Dates: Error - Either no year (${year}) or month (${month}).`);
			return;
		}
		this.refreshingDatesAndTimes = true;
		// First. Create a date using the year number and month number passed in. Set to UTC to ignore timezones etc...
		const date = moment()
			.year(year)
			.month(month - 1);
		// Get first and last day of the month, formatted as a string ready for the API.
		const dateFrom = date.startOf('month').format('YYYY-MM-DD');
		const dateTo = date.endOf('month').format('YYYY-MM-DD');
		this.loggerStoreService.log(`Basket Store Service: Getting Available Dates: Date (${date}), Date from (${dateFrom}), Date to (${dateTo})`);
		if (!this.getSelectedSlotTypeIDs().length) {
			return;
		}
		this.availableDates = await this.basketService
			.getAvailableSlotDates(this.companyStoreService.branch.guid, dateFrom, dateTo, this.getSelectedSlotTypeIDs().join(','), this.tyres)
			.toPromise();
		this.loggerStoreService.log(`Basket Store Service: Got ${this.availableDates.length} available dates`);
		this.availableDatesFormattedForCalendar = this.availableDates.map((d) => moment(d.date).format('YYYY-MM-DD'));
		this.loggerStoreService.log(`Basket Store Service: Formatted ${this.availableDatesFormattedForCalendar.length} available dates for calendar`);
		this.refreshingDatesAndTimes = false;
	}

	async getAvailableTimes(date: string) {
		this.clearDateTime();
		if (!date) {
			this.loggerStoreService.log('Basket Store Service: Get Available Times: Error - No date passed in.');
			this.loggerStoreService.table(date);
			return;
		}
		this.availableTimes = null;
		this.refreshingDatesAndTimes = true;
		this.clearDateTime();
		const selectedDate = date.substring(0, 10);
		this.selectedDay = +date.substring(8, 10);
		this.selectedMonth = +date.substring(5, 7);
		this.selectedYear = +date.substring(0, 4);
		this.availableTimes = await this.basketService
			.getAvailableSlotTimes(this.companyStoreService.branch.guid, selectedDate, this.getSelectedSlotTypeIDs().join(','), this.tyres)
			.toPromise();
		this.loggerStoreService.log(`Basket Store Service: Got ${this.availableTimes.length} Available Times`);
		this.refreshingDatesAndTimes = false;
	}

	selectBookingSlotRange(slotRange: ISlotRange) {
		if (!slotRange || !slotRange.startTime) {
			this.loggerStoreService.log(`Basket Store Service: Select Booking Slot Range: Error - No slot range or start time (${slotRange}).`);
			this.loggerStoreService.table(slotRange);
			return;
		}

		this.selectedHour = +slotRange.startTime.substring(11, 13);
		this.selectedMinute = +slotRange.startTime.substring(14, 16);
		this.selectBookingDateTime();
		this.bookingSlotRange = slotRange;
	}

	selectBookingDateTime() {
		this.bookingDateTime = moment().utc();
		this.bookingDateTime.year(this.selectedYear);
		this.bookingDateTime.month(this.selectedMonth - 1);
		this.bookingDateTime.date(+this.selectedDay);
		this.bookingDateTime.hour(+this.selectedHour);
		this.bookingDateTime.minute(+this.selectedMinute);
		this.bookingDateTime.second(0);
		this.loggerStoreService.log(`Basket Store Service: Selecting booking date time ${this.bookingDateTime}`);
	}

	public rangeLabel(range: ISlotRange = this.bookingSlotRange): any {
		if (!range?.startTime) {
			this.loggerStoreService.log(`Basket Store Service: Range Label: Error - no range or start time (${range}).`);

			return '';
		}
		const bookingMode = this.config.bookingMode;
		if (bookingMode == 'slot') {
			return moment(range.startTime).format('HH:mma');
		} else {
			const start = moment(range.startTime).format('a');
			if (start == 'am') {
				return 'Morning (From ' + moment(range.startTime).format('HH:mm') + ')';
			} else {
				return 'Afternoon (From ' + moment(range.startTime).format('HH:mm') + ')';
			}
		}
	}

	clearDateTime() {
		this.loggerStoreService.log(`Basket Store Service: Clearing booking date time`);
		this.bookingDateTime = null;
		this.bookingSlotRange = null;
	}

	clearAvailableDatesAndTimes() {
		this.availableDates = null;
		this.availableTimes = null;
	}
	//#endregion

	//#region Slot Types
	private readonly _slotTypes = new BehaviorSubject<ISlotType[]>([]);
	readonly slotTypes$ = this._slotTypes.asObservable();

	get slotTypes(): ISlotType[] {
		return this._slotTypes.getValue();
	}

	set slotTypes(val: ISlotType[]) {
		this._slotTypes.next(val);
	}

	get bookableSlotTypes(): ISlotType[] {
		return this.slotTypes.filter((c) => c.allowBookingToDiary === true);
	}

	get unbookableSlotTypes(): ISlotType[] {
		const slotTypes: ISlotType[] = [];

		this.slotTypes.forEach((slotType) => {
			if (!slotType.allowBookingToDiary) {
				slotTypes.push(slotType);
			}
		});

		this.specialOffers.forEach((specialOffer) => {
			specialOffer.slotTypes.forEach((slotType) => {
				if (!slotType.allowBookingToDiary) {
					slotTypes.push(slotType);
				}
			});
		});

		return slotTypes;
	}

	get poaSlotTypes(): ISlotType[] {
		return this.slotTypes.filter((c) => c.type == SlotTypeType.POA);
	}

	get nonPoaSlotTypes(): ISlotType[] {
		return this.slotTypes.filter((c) => c.type != SlotTypeType.POA);
	}

	get onlinePaymentEnabled(): boolean {
		const slotTypes = this.slotTypes.filter((c) => c.onlinePaymentEnabled === true);

		this.specialOffers.filter((c) => {
			c.slotTypes.filter((d) => {
				if (d.onlinePaymentEnabled == true) {
					slotTypes.push(d);
				}
			});
		});

		return slotTypes.length > 0;
	}

	slotTypeSelected(slotType: ISlotType) {
		if (!slotType || !this.slotTypes.length) {
			return false;
		}
		const match = this.slotTypes.find((s) => s.id == slotType.id);
		if (match) {
			return true;
		} else {
			return false;
		}
	}

	getSlotTypeIDs() {
		const ids = [];
		this.slotTypes.forEach((slotType) => {
			ids.push(slotType.id);
		});
		return ids;
	}

	async addRemoveSlotType(slotType: ISlotType, showMoreInfo = true) {
		this.clearDateTime();
		// Only add / remove if we have a correct price
		if (!slotType || !slotType.fullPrice) {
			this.loggerStoreService.log('addRemoveSlotType(): Missing slot type');
			return;
		}

		if (slotType.fullPrice.type == PriceType.FullPrice) {
			const index = this.slotTypes.indexOf(this.slotTypes.find((st) => st.id === slotType.id));
			if (index > -1) {
				// Already selected. So remove selection.
				this.slotTypes.splice(index, 1);
				this.localStorageStoreService.set(LocalStorageKey.SlotTypeIDs, this.authStoreService.clientId, JSON.stringify(this.getSlotTypeIDs()));
				this.loggerStoreService.log(`Basket Store Service: Removed ${slotType.shortDescription} from basket.`);
			} else {
				// Not selected, so add.

				if (slotType.id != this.tyreStoreService.tyreSlot.id && slotType.addToBasketMessage && slotType.addToBasketMessage.trim() != '' && showMoreInfo) {
					this.iframeResizerStoreService.scrollToIFrameTop();

					const modalRef = this.modalService.open(DialogConfirmComponent);
					modalRef.componentInstance.data = {
						title: slotType.shortDescription,
						content: slotType.addToBasketMessage,
						confirm: 'Add to booking',
					};

					try {
						const modalResult = await modalRef.result;
						this.modalService.dismissAll();
						if (!modalResult) {
							// user clicked cancel stop here
							return;
						}
					} catch {
						// user clicked cancel stop here
						return;
					}
				}

				this.slotTypes.push(slotType);
				this.removeConflictingSlotTypes(slotType, null);
				this.localStorageStoreService.set(LocalStorageKey.SlotTypeIDs, this.authStoreService.clientId, JSON.stringify(this.getSlotTypeIDs()));
				this.loggerStoreService.log(`Basket Store Service: Added ${slotType.shortDescription} to basket.`);
			}
			this.specialOfferStoreService.getSpecialOfferUpgrades(this.slotTypes);
			this.refreshExtras();
		} else {
			this.iframeResizerStoreService.scrollToIFrameTop();

			const template =
				this.companyStoreService.company.companyLevelVrmAvailable || this.companyStoreService.branch.branchLevelVrmAvailable ? 'VrmLookup' : 'ManualVehicleEntry';
			const title =
				this.companyStoreService.company.companyLevelVrmAvailable || this.companyStoreService.branch.branchLevelVrmAvailable
					? 'Find your vehicle'
					: 'Enter your vehicle';

			const modalRef = this.modalService.open(DialogVehicleEntryComponent, { size: 'lg' });
			modalRef.componentInstance.data = {
				template: template,
				title: title,
				selectedSlotType: slotType,
				basketStoreService: this,
			};

			modalRef.result.then(
				(addToBasket) => {
					this.modalService.dismissAll();
					if (addToBasket) {
						// We now have a full price so calling the same function should result in adding correctly.
						this.addRemoveSlotType(this.slotTypeStoreService.getSlotTypeByID(slotType.id));
					}
				},
				(reason) => {
					return false;
				}
			);
		}
	}

	removeSlotType(slotType: ISlotType) {
		const index = this.slotTypes.indexOf(this.slotTypes.find((st) => st.id === slotType.id));
		if (index > -1) {
			// Already selected. So remove selection.
			this.slotTypes.splice(index, 1);
			this.localStorageStoreService.set(LocalStorageKey.SlotTypeIDs, this.authStoreService.clientId, JSON.stringify(this.getSlotTypeIDs()));
			this.loggerStoreService.log(`Basket Store Service: Removed ${slotType.shortDescription} from basket.`);
		}
	}

	getSelectedSlotTypeIDs(): number[] {
		const slotTypeIDs = [];
		this.slotTypes.forEach((slotType) => {
			slotTypeIDs.push(slotType.id);
		});
		this.specialOffers.forEach((specialOffer) => {
			specialOffer.slotTypes.forEach((slotType) => {
				slotTypeIDs.push(slotType.id);
			});
		});
		if (this.tyres.length) {
			slotTypeIDs.push(this.tyreStoreService.tyreSlot.id);
		}
		return slotTypeIDs;
	}

	//#endregion

	//#region Special Offers
	private readonly _specialOffers = new BehaviorSubject<ISpecialOffer[]>([]);
	readonly specialOffers$ = this._specialOffers.asObservable();

	get specialOffers(): ISpecialOffer[] {
		return this._specialOffers.getValue();
	}

	set specialOffers(val: ISpecialOffer[]) {
		this._specialOffers.next(val);
	}

	specialOfferSelected(specialOffer: ISpecialOffer) {
		if (!specialOffer || !this.specialOffers.length) {
			return false;
		}
		const match = this.specialOffers.find((s) => s.id == specialOffer.id);
		if (match) {
			return true;
		} else {
			return false;
		}
	}

	getSpecialOfferIDs() {
		const ids = [];
		this.specialOffers.forEach((specialOffer) => {
			ids.push(specialOffer.id);
		});
		return ids;
	}

	async upgradeSlotTypeToSpecialOffer(slotType: ISlotType, specialOffer: ISpecialOffer) {
		this.clearDateTime();
		// We need a vehicle before we can make the upgrade.
		if (specialOffer.discountedPrice.type == PriceType.FullPrice) {
			this.removeConflictingSlotTypes(null, specialOffer);
			this.confirmSpecialOfferUpgrade(slotType, specialOffer);
		} else {
			this.iframeResizerStoreService.scrollToIFrameTop();
			// No vehicle, so lets deal with that.

			const modalRef = this.modalService.open(DialogVehicleEntryComponent, { size: 'lg' });
			const template =
				this.companyStoreService.company.companyLevelVrmAvailable || this.companyStoreService.branch.branchLevelVrmAvailable ? 'VrmLookup' : 'ManualVehicleEntry';

			modalRef.componentInstance.data = {
				template: template,
				title: 'Please enter your vehicle for an accurate price',
				selectedSpecialOffer: specialOffer,
				basketStoreService: this,
			};

			modalRef.result.then(
				(addToBasket) => {
					if (addToBasket) {
						this.removeConflictingSlotTypes(null, specialOffer);
						this.confirmSpecialOfferUpgrade(slotType, specialOffer);
					}
				},
				(reason) => {}
			);
		}
	}

	removeConflictingSlotTypes(selectedSlotType: ISlotType, selectedSpecialOffer: ISpecialOffer) {
		// Extract slot type IDs from special offer.
		const specialOfferSlotTypeIDs = [];
		if (selectedSpecialOffer) {
			selectedSpecialOffer.slotTypes.forEach((slotType) => {
				specialOfferSlotTypeIDs.push(slotType.id);
			});
		}

		// loop through all slot types and remove conflicts. Make sure we ignore the selected slot type.
		for (let i = this.slotTypes.length - 1; i >= 0; i--) {
			const slotType = this.slotTypes[i];
			if (selectedSpecialOffer) {
				if (specialOfferSlotTypeIDs.includes(slotType.id)) {
					// We have found a slot type that is part of the incoming special offer,
					// so remove it.
					this.removeSlotType(slotType);
					this.snackBar.open(`Swapped ${slotType.shortDescription} for ${selectedSpecialOffer.name}`);
				}
			}
		}

		// loop through all special offers and remove conflicts
		for (let i = this.specialOffers.length - 1; i >= 0; i--) {
			const specialOffer = this.specialOffers[i];
			// Create an array of slot type IDs for this specific special offer.
			const tempSpecialOfferSlotTypeIDs = [];
			specialOffer.slotTypes.forEach((slotType) => {
				tempSpecialOfferSlotTypeIDs.push(slotType.id);
			});

			if (selectedSpecialOffer) {
				// Here, we look though the slot type IDs in each special offer,
				// if there is a match AT ALL remove the old special offer.
				// First, create an array of slot types in the loop, then loop through each slot type ID and compare to
				// top special offer slot type array.
				let remove = false;
				tempSpecialOfferSlotTypeIDs.forEach((id) => {
					if (specialOfferSlotTypeIDs.includes(id)) {
						remove = true;
					}
				});
				if (remove && specialOffer.id != selectedSpecialOffer.id) {
					// Just double check that we're not removing the special offer we just added
					this.removeSpecialOffer(specialOffer);
					this.snackBar.open(`Swapped ${specialOffer.name} for ${selectedSpecialOffer.name}`);
				}
			}
			if (selectedSlotType) {
				// Here, we are passing a rogue slot type. If it exists already inside of a special offer,
				// remove the special offer. In the future, this could be smoother with a dialog or something.
				if (tempSpecialOfferSlotTypeIDs.includes(selectedSlotType.id)) {
					this.removeSpecialOffer(specialOffer);
					this.snackBar.open(`Swapped ${specialOffer.name} for ${selectedSlotType.shortDescription}`);
				}
			}
		}
	}

	confirmSpecialOfferUpgrade(slotType: ISlotType, specialOffer: ISpecialOffer) {
		// Add special offer.
		const refreshedSpecialOffer = this.specialOfferStoreService.specialOffers.find((so) => so.id == specialOffer.id);
		if (refreshedSpecialOffer) {
			this.specialOffers.push(refreshedSpecialOffer);
		}
		// Remove old slot type.
		const index = this.slotTypes.indexOf(this.slotTypes.find((st) => st.id === slotType.id));
		if (index > -1) {
			this.slotTypes.splice(index, 1);
		}
		this.loggerStoreService.log(`Basket Store Service: Upgraded ${slotType.shortDescription} to ${specialOffer.name}.`);
		this.localStorageStoreService.set(LocalStorageKey.SpecialOfferIDs, this.authStoreService.clientId, JSON.stringify(this.getSpecialOfferIDs()));
		this.localStorageStoreService.set(LocalStorageKey.SlotTypeIDs, this.authStoreService.clientId, JSON.stringify(this.getSlotTypeIDs()));
		this.specialOfferStoreService.getSpecialOfferUpgrades(this.slotTypes);
		this.refreshExtras();
	}

	async addRemoveSpecialOffer(specialOffer: ISpecialOffer) {
		this.clearDateTime();
		// Only add / remove if we have a correct price
		if (specialOffer.discountedPrice.type == PriceType.FullPrice) {
			const index = this.specialOffers.indexOf(this.specialOffers.find((so) => so.id === specialOffer.id));
			if (index > -1) {
				// Already selected. So remove selection.
				this.specialOffers.splice(index, 1);
				this.localStorageStoreService.set(LocalStorageKey.SpecialOfferIDs, this.authStoreService.clientId, JSON.stringify(this.getSpecialOfferIDs()));
				this.loggerStoreService.log(`Basket Store Service: Removed ${specialOffer.name} special offer from basket.`);
			} else {
				// Not selected, so add.
				this.specialOffers.push(specialOffer);
				this.removeConflictingSlotTypes(null, specialOffer);
				this.localStorageStoreService.set(LocalStorageKey.SpecialOfferIDs, this.authStoreService.clientId, JSON.stringify(this.getSpecialOfferIDs()));
				this.loggerStoreService.log(`Basket Store Service: Added ${specialOffer.name} special offer to basket.`);
			}
			this.specialOfferStoreService.getSpecialOfferUpgrades(this.slotTypes);
			this.refreshExtras();
		} else {
			this.iframeResizerStoreService.scrollToIFrameTop();

			const modalRef = this.modalService.open(DialogVehicleEntryComponent, { size: 'lg' });
			const template =
				this.companyStoreService.company.companyLevelVrmAvailable || this.companyStoreService.branch.branchLevelVrmAvailable ? 'VrmLookup' : 'ManualVehicleEntry';
			const title =
				this.companyStoreService.company.companyLevelVrmAvailable || this.companyStoreService.branch.branchLevelVrmAvailable
					? 'Find your vehicle'
					: 'Enter your vehicle';

			modalRef.componentInstance.data = {
				template: template,
				title: title,
				selectedSpecialOffer: specialOffer,
				basketStoreService: this,
			};

			modalRef.result.then(
				(addToBasket) => {
					if (addToBasket) {
						// We now have a full price so calling the same function should result in adding correctly.
						this.addRemoveSpecialOffer(this.specialOfferStoreService.getSpecialOfferByID(specialOffer.id));
					}
				},
				(reason) => {}
			);
		}
	}

	removeSpecialOffer(specialOffer: ISpecialOffer) {
		const index = this.specialOffers.indexOf(this.specialOffers.find((so) => so.id === specialOffer.id));
		if (index > -1) {
			// Already selected. So remove selection.
			this.specialOffers.splice(index, 1);
		}
	}

	refreshSpecialOfferUpgrades() {
		this.specialOfferStoreService.getSpecialOfferUpgrades(this.slotTypes);
	}
	//#endregion

	//#region Tyres
	private readonly _tyres = new BehaviorSubject<ITyre[]>([]);
	readonly tyres$ = this._tyres.asObservable();

	get tyres(): ITyre[] {
		return this._tyres.getValue();
	}

	set tyres(val: ITyre[]) {
		this._tyres.next(val);
	}

	clearTyres() {
		//
		for (let i = this.tyres.length - 1; i >= 0; i--) {
			this.addRemoveTyre(this.tyres[i]);
		}
	}

	tyreSelected(tyre: ITyre) {
		if (!tyre || !this.tyres.length) {
			return false;
		}
		const match = this.tyres.find((s) => s.id == tyre.id);
		if (match) {
			return true;
		} else {
			return false;
		}
	}

	async addRemoveTyre(tyre: ITyre): Promise<boolean> {
		this.clearDateTime();
		const index = this.tyres.indexOf(this.tyres.find((t) => t.id === tyre.id));
		if (index > -1) {
			this.tyres.splice(index, 1);
			this.removeTyreSlot();
			this.localStorageStoreService.set(LocalStorageKey.TyreIDs, this.authStoreService.clientId, JSON.stringify(this.getTyreIDs()));
			this.loggerStoreService.log(`Basket Store Service: Removed ${tyre.treadPattern} tyre from basket.`);
		} else {
			// We do this check first to make sure we only add one tyre slot.
			const tyreSlot = this.slotTypes.find((s) => s.id == this.tyreStoreService.tyreSlot.id);
			if (!tyreSlot) {
				// Then check if we need to show a message before adding the tyres
				if (this.tyreStoreService.tyreSlot.addToBasketMessage && this.tyreStoreService.tyreSlot.addToBasketMessage.trim() != '') {
					this.iframeResizerStoreService.scrollToIFrameTop();

					// If this doesn't have open modals, we are the top modal
					const hasOpenModals = this.modalService.hasOpenModals();
					const modalRef = this.modalService.open(DialogConfirmComponent, {
						backdropClass: hasOpenModals ? 'mst tyre-add-message-backdrop' : 'mst',
						windowClass: hasOpenModals ? 'mst tyre-add-message' : 'mst',
					});

					modalRef.componentInstance.data = {
						title: tyre.treadPattern,
						content: this.tyreStoreService.tyreSlot.addToBasketMessage,
						confirm: 'Add to booking',
					};

					try {
						const modalResult = await modalRef.result;
						this.modalService.dismissAll();
						if (!modalResult) {
							// user clicked cancel stop here
							return false;
						}
					} catch {
						// user clicked cancel stop here
						return false;
					}
				}

				await this.addRemoveSlotType(this.tyreStoreService.tyreSlot);
			}

			this.tyres.push(tyre);
			this.localStorageStoreService.set(LocalStorageKey.TyreIDs, this.authStoreService.clientId, JSON.stringify(this.getTyreIDs()));
			this.loggerStoreService.log(`Basket Store Service: Added ${tyre.treadPattern} tyre to basket.`);
		}
		await this.refreshExtras();
		return true;
	}

	async removeTyreSlot() {
		if (this.tyres.length == 0) {
			await this.addRemoveSlotType(this.tyreStoreService.tyreSlot);
		}
	}

	hasTyres() {
		if (this.tyres.length) {
			return true;
		}
		return false;
	}

	getTyreIDs() {
		const ids = {};
		this.tyres.forEach((tyre) => {
			ids[tyre.id] = tyre.quantity;
		});
		return ids;
	}
	//#endregion

	//#region Extras
	private readonly _extras = new BehaviorSubject<IExtra[]>([]);
	readonly extras$ = this._extras.asObservable();

	get extras(): IExtra[] {
		return this._extras.getValue();
	}

	set extras(val: IExtra[]) {
		this._extras.next(val);
	}

	extraSelected(extra: IExtra) {
		if (!extra || !this.extras.length) {
			return false;
		}
		const match = this.extras.find((s) => s.id == extra.id);
		if (match) {
			return true;
		} else {
			return false;
		}
	}

	getExtraIDs() {
		const ids = [];
		this.extras.forEach((extra) => {
			ids.push(extra.id);
		});
		return ids;
	}

	async addRemoveExtra(extra: IExtra) {
		if (!extra) {
			return;
		}
		const index = this.extras.indexOf(this.extras.find((e) => e.id === extra.id));
		if (index > -1) {
			this.extras.splice(index, 1);
			this.localStorageStoreService.set(LocalStorageKey.ExtraIDs, this.authStoreService.clientId, JSON.stringify(this.getExtraIDs()));
			this.loggerStoreService.log(`Extra Store Service: Removed ${extra.name} extra from basket.`);
		} else {
			this.extras.push(extra);
			this.localStorageStoreService.set(LocalStorageKey.ExtraIDs, this.authStoreService.clientId, JSON.stringify(this.getExtraIDs()));
			this.loggerStoreService.log(`Extra Store Service: Added ${extra.name} extra to basket.`);
		}
	}

	async addRemoveExtraByID(id: number) {
		const extra = this.extras.find((extra) => extra.id == id);
		this.addRemoveExtra(extra);
	}

	async refreshExtras() {
		// First, get an array of selected extra IDs.
		// then, refresh the extras.
		// Finally, loop through the selected extra IDs and remove any that can no longer
		// be found within the new list of extras.
		const selectedExtraIDs = [];
		this.extras.forEach((extra) => {
			selectedExtraIDs.push(extra.id);
		});
		await this.extraStoreService.getExtras(this.companyStoreService.branch.guid, this.getSelectedSlotTypeIDs().join(','));
		// Here, get extra IDs from local storage, and make sure that
		// whatever extra IDS are in that array are now selected.
		const localStorageExtraIDs = this.localStorageStoreService.get(LocalStorageKey.ExtraIDs, this.authStoreService.clientId);
		if (localStorageExtraIDs) {
			const ids = JSON.parse(localStorageExtraIDs);
			ids.forEach((id) => {
				const extraSelected = this.extras.indexOf(this.extras.find((e) => e.id == id));
				if (extraSelected == -1) {
					this.addRemoveExtra(this.extraStoreService.extras.find((e) => e.id == id));
				}
			});
		}

		selectedExtraIDs.forEach((id) => {
			const extra = this.extraStoreService.extras.find((e) => e.id == id);
			if (!extra) {
				this.addRemoveExtra(this.extras.find((e) => e.id == id));
			}
		});
	}
	//#endregion

	//#region Terms
	private readonly _termsAndPrivacy = new BehaviorSubject<any>({});
	readonly termsAndPrivacy$ = this._termsAndPrivacy.asObservable();

	get termsAndPrivacy(): any {
		return this._termsAndPrivacy.getValue();
	}

	set termsAndPrivacy(val: any) {
		this._termsAndPrivacy.next(val);
	}

	async getTermsAndPrivacy(branchGuid: string) {
		this.termsAndPrivacy = await this.basketService.getTermsAndPrivacy(branchGuid).toPromise();
	}
	//#endregion

	//#region Send Booking
	private readonly _booking = new BehaviorSubject<IBooking>({} as IBooking);
	readonly booking$ = this._booking.asObservable();

	get booking(): IBooking {
		return this._booking.getValue();
	}

	set booking(val: IBooking) {
		this._booking.next(val);
	}

	async submitBooking(branchGuid: string, booking: IBooking) {
		const response = await this.basketService.confirmBooking(branchGuid, booking).toPromise();
		return response;
	}

	async submitPOABooking(branchGuid: string, booking: IBooking) {
		const response = await this.basketService.confirmPOABooking(branchGuid, booking).toPromise();
		return response;
	}

	private readonly _completedBooking = new BehaviorSubject<IBooking>({} as IBooking);
	readonly completedBooking$ = this._completedBooking.asObservable();

	get completedBooking(): IBooking {
		return this._completedBooking.getValue();
	}

	set completedBooking(val: IBooking) {
		this._completedBooking.next(val);
	}

	updateCompletedBooking(booking: IBooking, onlineShoppingBasket: IOnlineShoppingBasket) {
		this.completedBooking = booking;
		this.completedBooking.confirmedShoppingBasket = onlineShoppingBasket;

		this.clearDateTime();
		this.clearBasket();

		const vehicleStoreService = this.injector.get(VehicleStoreService);
		vehicleStoreService.clearVehicle();
	}
	//#endregion

	//#region Validate Basket

	/** Validates the current basket.
	 * @param complete adds extra checks for if you are wanting to submit a booking.
	 * @returns true if valid, false if not.
	 */
	public validateBasket(complete?: boolean): boolean {
		const basket = this.getSelectedSlotTypeIDs();

		if (basket == [] || basket.length <= 0) {
			this.loggerStoreService.log('Basket Store: Invalid basket is empty');
			return false;
		}

		// if (this.slotTypes.filter((c) => c.type != SlotTypeType.POA && c.allowBookingToDiary == false).length > 0) {
		//     this.loggerStoreService.log("Basket Store: Invalid basket, nonPOA slot types should not be unbookable");
		//     return false;
		// }

		return true;
	}

	//#endregion

	get enabledOnlinePayment(): boolean {
		// get online payment enabled slot types
		const slotTypesEnabled = this.slotTypes.filter((c) => c.onlinePaymentEnabled);
		// get online payment enabled slot types in side special offers
		const specialOffersEnabled = this.specialOffers.filter((c) => c.slotTypes.some((t) => t.onlinePaymentEnabled));

		// does this config have onlinePaymentEnabled?
		if (this.companyStoreService.branch.onlinePaymentEnabled == false) {
			return false;
		}

		// do we have and booking request slot types?
		if (this.poaSlotTypes.length > 0 || this.unbookableSlotTypes.length > 0) {
			return false;
		}

		// are there any online payment enabled slot types in the basket (inc special offers)
		if (!slotTypesEnabled.length && !specialOffersEnabled.length) {
			return false;
		}

		return true;
	}

	async getPayPalOrder(branchGuid: string, booking: IBooking): Promise<IPayPalOrderRequest> {
		// call api to sort out prices for us
		const validBasket = await this.basketService.getShoppingBasket(branchGuid, booking).toPromise();

		if (validBasket.response?.length) {
			validBasket.response.forEach((issue) => {
				if (issue.issueType != EOnlineShoppingBasketIssueType.Blacklist) {
					this.loggerStoreService.logCritialToRollBar(new Error(`Could not validate basket for PayPal Order: [${issue.issueType}] ${issue.issueDescription}`));
				}
			});
			return Promise.reject(validBasket.response);
		}

		return this.paypalStoreService.createPayPalOrder(validBasket, 'GBP');
	}

	async completePayPalOrder(branchGuid: string, booking: IBooking, payPalOrderID: string) {
		const response = await this.basketService.confirmBookingPayPal(branchGuid, booking, payPalOrderID).toPromise();
		return response;
	}
}
