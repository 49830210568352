
<!-- Template: List - SlotTypeGroup: True -->
<div *ngIf="config.services.showSlotTypeGroups && config.template == 'list' && slotTypeStoreService.slotTypeGroups.length" equalHeight="equal-slot-price">
    <ng-container *ngFor="let slotTypeGroup of slotTypeStoreService.slotTypeGroups">

        <!-- Default (If we have a checklist and it's turned off OR we don't have a checklist)  -->
        <div class="slot-type-group list" *ngIf="slotTypeStoreService.showSlotTypeGroup(slotTypeGroup)  && (!slotTypeStoreService.slotTypeGroupHasChecklist(slotTypeGroup) || config.serviceChecklistTemplate == 'none') ">
            <h4>
                <span>{{slotTypeGroup.name}}</span>
                <i class="fas fa-chevron-up" [hidden]="slotTypeGroup.hidden" (click)="slotTypeGroup.hidden = !slotTypeGroup.hidden"></i>
                <i class="fas fa-chevron-down" [hidden]="!slotTypeGroup.hidden" (click)="slotTypeGroup.hidden = !slotTypeGroup.hidden"></i>
            </h4>

            <div *ngFor="let slotType of slotTypeGroup.slotTypes" [hidden]="slotTypeGroup.hidden">
                <ng-container *ngIf="slotTypeStoreService.showSlotType(slotType)">
                    <ng-template  [ngTemplateOutlet]="SlotTypeListItem" [ngTemplateOutletContext]="{slotType: slotType}"></ng-template>
                </ng-container>
            </div>
        </div>

        <!-- If checklist available - Display inline (template set to inline) -->
        <div class="slot-type-group tiles checklist" *ngIf="slotTypeStoreService.showSlotTypeGroup(slotTypeGroup) && slotTypeStoreService.slotTypeGroupHasChecklist(slotTypeGroup) && config.serviceChecklistTemplate == 'inline'">
            <h4>
                <span>{{slotTypeGroup.name}}</span>
                <i class="fas fa-chevron-up ml-3" [hidden]="slotTypeGroup.hidden" (click)="slotTypeGroup.hidden = !slotTypeGroup.hidden"></i>
                <i class="fas fa-chevron-down ml-3" [hidden]="!slotTypeGroup.hidden" (click)="slotTypeGroup.hidden = !slotTypeGroup.hidden"></i>
            </h4>
            
            <div [hidden]="slotTypeGroup.hidden">
                <app-slot-type-service-checklist [slotTypeGroup]="slotTypeGroup"></app-slot-type-service-checklist>
            </div>         
        </div>

        <!-- If checklist available - Display link (template set to link) -->
        <div class="slot-type-group list" *ngIf="slotTypeStoreService.showSlotTypeGroup(slotTypeGroup) && slotTypeStoreService.slotTypeGroupHasChecklist(slotTypeGroup) && config.serviceChecklistTemplate == 'link'">
            <h4>
                <span>{{slotTypeGroup.name}}</span>
                <a href="javascript:void(0)" (click)="showSlotTypeServiceChecklist(slotTypeGroup)" class="btn btn-outline-primary btn-more btn-sm" *ngIf="config.serviceChecklistTemplate == 'link'">
                    {{config.serviceChecklistButtonText}}
                </a>
                <i class="fas fa-chevron-up" [hidden]="slotTypeGroup.hidden" (click)="slotTypeGroup.hidden = !slotTypeGroup.hidden"></i>
                <i class="fas fa-chevron-down" [hidden]="!slotTypeGroup.hidden" (click)="slotTypeGroup.hidden = !slotTypeGroup.hidden"></i>
            </h4>

            <div *ngFor="let slotType of slotTypeGroup.slotTypes" [hidden]="slotTypeGroup.hidden">
                <ng-container *ngIf="slotTypeStoreService.showSlotType(slotType)">
                    <ng-template  [ngTemplateOutlet]="SlotTypeListItem" [ngTemplateOutletContext]="{slotType: slotType}"></ng-template>
                </ng-container>
            </div>
        </div>


    </ng-container>
</div>


<!-- Template: Tile - SlotTypeGroup: True -->
<div *ngIf="config.services.showSlotTypeGroups && config.template == 'tile'" equalHeight="equal-slot-price">
    <ng-container *ngFor="let slotTypeGroup of slotTypeStoreService.slotTypeGroups">

        <!-- Default (If we have a checklist and it's turned off OR we don't have a checklist)  -->
        <div class="slot-type-group tiles" *ngIf="slotTypeStoreService.showSlotTypeGroup(slotTypeGroup) && (!slotTypeStoreService.slotTypeGroupHasChecklist(slotTypeGroup) || config.serviceChecklistTemplate == 'none') ">
            <h4>
                <span>{{slotTypeGroup.name}}</span>
                <i class="fas fa-chevron-up" [hidden]="slotTypeGroup.hidden" (click)="slotTypeGroup.hidden = !slotTypeGroup.hidden"></i>
                <i class="fas fa-chevron-down" [hidden]="!slotTypeGroup.hidden" (click)="slotTypeGroup.hidden = !slotTypeGroup.hidden"></i>
            </h4>
            
            <div class="form-row" [hidden]="slotTypeGroup.hidden">
                <ng-container *ngFor="let slotType of slotTypeGroup.slotTypes">
                    <ng-container *ngIf="slotTypeStoreService.showSlotType(slotType)">
                        <ng-template [ngTemplateOutlet]="SlotTypeTile" [ngTemplateOutletContext]="{slotType: slotType}"></ng-template>
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <!-- If checklist available - Display inline (template set to inline) -->
        <div class="slot-type-group tiles checklist" *ngIf="slotTypeStoreService.showSlotTypeGroup(slotTypeGroup) && slotTypeStoreService.slotTypeGroupHasChecklist(slotTypeGroup) && config.serviceChecklistTemplate == 'inline'">
            <h4>
                <span>{{slotTypeGroup.name}}</span>
                <i class="fas fa-chevron-up ml-3" [hidden]="slotTypeGroup.hidden" (click)="slotTypeGroup.hidden = !slotTypeGroup.hidden"></i>
                <i class="fas fa-chevron-down ml-3" [hidden]="!slotTypeGroup.hidden" (click)="slotTypeGroup.hidden = !slotTypeGroup.hidden"></i>
            </h4>
            
            <div [hidden]="slotTypeGroup.hidden">
                <app-slot-type-service-checklist [slotTypeGroup]="slotTypeGroup"></app-slot-type-service-checklist>
            </div>         
        </div>

        <!-- If checklist available - Display link (template set to link) -->
        <div class="slot-type-group tiles checklist" *ngIf="slotTypeStoreService.showSlotTypeGroup(slotTypeGroup) && slotTypeStoreService.slotTypeGroupHasChecklist(slotTypeGroup) && config.serviceChecklistTemplate == 'link'">
            <h4>
                <span>{{slotTypeGroup.name}}</span>
                <a href="javascript:void(0)" (click)="showSlotTypeServiceChecklist(slotTypeGroup)" class="btn btn-outline-primary btn-more btn-sm" *ngIf="config.serviceChecklistTemplate == 'link'">
                    {{config.serviceChecklistButtonText}}
                </a>
                <i class="fas fa-chevron-up ml-3" [hidden]="slotTypeGroup.hidden" (click)="slotTypeGroup.hidden = !slotTypeGroup.hidden"></i>
                <i class="fas fa-chevron-down ml-3" [hidden]="!slotTypeGroup.hidden" (click)="slotTypeGroup.hidden = !slotTypeGroup.hidden"></i>
            </h4>
            
            <div class="form-row" [hidden]="slotTypeGroup.hidden">
                <ng-container *ngFor="let slotType of slotTypeGroup.slotTypes">
                    <ng-container *ngIf="slotTypeStoreService.showSlotType(slotType)">
                        <ng-template [ngTemplateOutlet]="SlotTypeTile" [ngTemplateOutletContext]="{slotType: slotType}"></ng-template>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>


<!-- Service checklists aren't available when we ungroup everything, as there's no logical way to show a checklist against ungrouped items -->
<div *ngIf="!config.services.showSlotTypeGroups && config.template == 'list'" equalHeight="equal-slot-price">
    <ng-container *ngFor="let slotType of slotTypeStoreService.slotTypes">
        <ng-template *ngIf="slotTypeStoreService.showSlotType(slotType)" [ngTemplateOutlet]="SlotTypeListItem" [ngTemplateOutletContext]="{slotType: slotType}"></ng-template>
    </ng-container>
</div>

<div equalHeight="equal-slot-price"*ngIf="!config.services.showSlotTypeGroups && config.template == 'tile'">
    <div class="form-row">
        <ng-container *ngFor="let slotType of slotTypeStoreService.slotTypes">
            <ng-template *ngIf="slotTypeStoreService.showSlotType(slotType)" [ngTemplateOutlet]="SlotTypeTile" [ngTemplateOutletContext]="{slotType: slotType}"></ng-template>
        </ng-container>
    </div>
</div>



<!-- Individual templates -->
<!-- Tile template -->
<ng-template #SlotTypeTile let-slotType="slotType">
    <div class="col-md-6 col-lg-4 d-flex">
        <div class="slot-type-tile">
            <div class="description">
                <img *ngIf="config.services.showSlotTypeIcon" src="https://branchresources.motasoftvgm.co.uk/{{slotType.image}}" onError="this.src='https://branchresources.motasoftvgm.co.uk//branchresources/global/images/slottypes/icons/iconset1/blue/repairs.png'" class="icon" />     
                <div>
                    <p>{{slotType.shortDescription}}</p>
                    <span [hidden]="!config.services.showSlotTypeLongDescription">
                        {{slotType.longDescription}}
                    </span>
                </div>
            </div>
            <div class="price equal-slot-price">
                <span class="value">
                    <span [ngClass]="{'price-preamble': slotType.fullPrice.type != 4, 'no-price':slotType.fullPrice.type == 4}">{{getPricePreamble(slotType)}}</span>


                    <!-- Normal slot pricing -->
                    <span *ngIf="slotType.fullPrice.type != 4 && !isPOASlotType(slotType)">
                        {{"£" + (slotType.fullPrice.value | number:"1.2-2")}}<span class="price-suffix small">{{slotType.priceSuffix}}</span>
                    </span>
                    <span class="please-call" *ngIf="slotType.fullPrice.type == 4 && !isPOASlotType(slotType)">
                        Please call {{companyStoreService.branch.phoneNumber}}
                    </span>


                    <!-- POA slot pricing -->
                    <span *ngIf="isPOASlotType(slotType)" class="price-overwrite">
                        {{ slotType.priceOverwrite }}
                        <span class="price-suffix small">{{slotType.priceSuffix}}</span>
                    </span>



                </span>
                <a *ngIf="slotType.fullPrice.type != 4 || isPOASlotType(slotType)" href="javascript:void(0)" [ngClass]="{'btn-primary': !basketStoreService.slotTypeSelected(slotType), 'btn-success': basketStoreService.slotTypeSelected(slotType)}" class="btn btn-sm btn-primary" (click)="addRemoveSlotType(slotType)">
                    <i class="fas fa-plus" [hidden]="basketStoreService.slotTypeSelected(slotType)"></i>
                    <i class="fas fa-check" [hidden]="!basketStoreService.slotTypeSelected(slotType)"></i>
                    <span [hidden]="basketStoreService.slotTypeSelected(slotType)">{{config.buttonText}}</span>
                </a>

            </div>
        </div>
     </div>
</ng-template>

<!-- List template -->
<ng-template #SlotTypeListItem let-slotType="slotType">
    <div class="slot-type-list-item">
        <div class="description">
            <img *ngIf="config.services.showSlotTypeIcon" src="https://branchresources.motasoftvgm.co.uk/{{slotType.image}}" onError="this.src='https://branchresources.motasoftvgm.co.uk//branchresources/global/images/slottypes/icons/iconset1/blue/repairs.png'" class="icon" />     
            <div>
                <p>{{slotType.shortDescription}}</p>
                <span [hidden]="!config.services.showSlotTypeLongDescription">
                    {{slotType.longDescription}}
                </span>
                <span class="price-suffix small">{{slotType.priceSuffix}}</span>
            </div>
            
        </div>
        <div class="price equal-slot-price">
            <span class="value">
                <span [ngClass]="{'price-preamble': slotType.fullPrice.type != 4, 'no-price': slotType.fullPrice.type == 4}">{{getPricePreamble(slotType)}}</span>

                <!-- Normal slot pricing -->
                <span *ngIf="slotType.fullPrice.type != 4 && !isPOASlotType(slotType)">
                    {{"£" + (slotType.fullPrice.value | number:"1.2-2")}}
                    <span class="price-suffix small">{{slotType.priceSuffix}}</span>
                </span>
                <span class="please-call" *ngIf="slotType.fullPrice.type == 4 && !isPOASlotType(slotType)">
                    Please call {{companyStoreService.branch.phoneNumber}}
                </span>
                
                <!-- POA slot pricing -->
                <span *ngIf="isPOASlotType(slotType)" class="price-overwrite">
                    {{ slotType.priceOverwrite }}
                    <span class="price-suffix small">{{slotType.priceSuffix}}</span>
                </span>
                
            </span>

            <a *ngIf="slotType.fullPrice.type != 4 || isPOASlotType(slotType)" href="javascript:void(0)" [ngClass]="{'btn-primary': !basketStoreService.slotTypeSelected(slotType), 'btn-success': basketStoreService.slotTypeSelected(slotType)}" class="btn btn-sm btn-primary" (click)="addRemoveSlotType(slotType)">
                <i class="fas fa-plus" [hidden]="basketStoreService.slotTypeSelected(slotType)"></i>
                <i class="fas fa-check" [hidden]="!basketStoreService.slotTypeSelected(slotType)"></i>
                <span [hidden]="basketStoreService.slotTypeSelected(slotType)">{{config.buttonText}}</span>
            </a>
        </div>
    </div>
</ng-template>
