import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';
import { Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { IVehicleData, IVehicleModel, IVehicleMake } from 'src/app/interfaces/vehicle-data.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { VehicleType } from '../../../global/enums';

@Component({
	selector: 'app-vehicle-form',
	templateUrl: './vehicle-form.component.html',
	styleUrls: ['./vehicle-form.component.scss'],
})
export class VehicleFormComponent implements OnInit, OnDestroy {
	config: IComponentConfig;
	filteredMakes: Observable<IVehicleMake[]>;
	filteredModels: Observable<IVehicleModel[]>;
	selectedMake: IVehicleMake;
	selectedVehicleType: VehicleType;
	vehicleDataSubscription: Subscription;
	fuelTypeSubscription: Subscription;
	@Input() form: UntypedFormGroup;

	constructor(public companyStoreService: CompanyStoreService, private configStoreService: ConfigStoreService, public vehicleStoreService: VehicleStoreService) {}

	ngOnInit(): void {
		this.config = this.configStoreService.getComponentConfig('VehicleFormComponent');

		if (this.vehicleStoreService.vehicleType) {
			this.selectedVehicleType = this.vehicleStoreService.vehicleType;
		} else {
			this.selectedVehicleType = VehicleType.Car;
		}

		this.vehicleStoreService.getFuelTypes();
		this.vehicleStoreService.getVehicleData();
		// Add registration form field
		if (this.config.forms.vehicle.registration.enabled) {
			const registrationValidators = [Validators.maxLength(50)];
			if (this.config.forms.vehicle.registration.required) {
				registrationValidators.push(Validators.required);
			}
			this.form.addControl('registrationFormControl', new UntypedFormControl(null, registrationValidators));
		}
		// Add engine size form field
		if (this.config.forms.vehicle.engineSize.enabled) {
			const engineSizeValidators = [Validators.min(0)];
			if (this.config.forms.vehicle.engineSize.required) {
				engineSizeValidators.push(Validators.required);
			}
			this.form.addControl('engineSizeFormControl', new UntypedFormControl(null, engineSizeValidators));
		}
		// Add fuel type form field
		if (this.config.forms.vehicle.fuelType.enabled) {
			const fuelTypeValidators = [Validators.min(0)];
			if (this.config.forms.vehicle.fuelType.required) {
				fuelTypeValidators.push(Validators.required);
			}
			this.form.addControl('fuelTypeFormControl', new UntypedFormControl(null, fuelTypeValidators));
			this.fuelTypeSubscription = this.form.get('fuelTypeFormControl').valueChanges.subscribe((val) => {
				if (!val) return;
				if (val.id === 8) {
					this.form.get('engineSizeFormControl').setValue(null);
					this.form.get('engineSizeFormControl').disable();
				} else {
					this.form.get('engineSizeFormControl').enable();
				}
			});
		}

		// Add makes and models fields
		this.form.addControl('vehicleMakesFormControl', new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]));
		this.form.addControl('vehicleModelFormControl', new UntypedFormControl(null, [Validators.required, Validators.maxLength(100)]));

		// Upon the vehicle data observable being populate, subscribe to the result and update dropdowns.
		this.vehicleDataSubscription = this.vehicleStoreService.vehicleData$.subscribe(() => {
			this.updateFilteredMakes();
		});
	}

	selectVehicleType(type: number) {
		this.selectedVehicleType = type;
		this.updateFilteredMakes();
	}

	displayMake(make: IVehicleMake) {
		return make && make.name ? make.name : '';
	}

	onMakeSelected($event: any) {
		this.selectedMake = $event.option.value;
		this.form.get('vehicleModelFormControl').setValue(null);
		this.updateFilteredModels();
	}

	displayModel(model: IVehicleModel) {
		return model && model.name ? model.name : '';
	}

	updateFilteredMakes() {
		this.filteredMakes = this.form.get('vehicleMakesFormControl').valueChanges.pipe(
			startWith(''),
			map((value) => this.filterMakes(value))
		);
	}

	updateFilteredModels() {
		this.filteredModels = this.form.get('vehicleModelFormControl').valueChanges.pipe(
			startWith(''),
			map((value) => this.filterModels(value))
		);
	}

	private filterMakes(value: string) {
		const filterValue = value ? value.toString().toLowerCase() : '';
		if (!this.vehicleStoreService.vehicleData.length) return [] as Array<IVehicleMake>;
		// -1 because enum is 1 based
		return this.vehicleStoreService.vehicleData[this.selectedVehicleType - 1].makes.filter((option) => option.name.toLowerCase().indexOf(filterValue) === 0);
	}

	private filterModels(value: string) {
		const filterValue = value ? value.toString().toLowerCase() : '';
		if (!this.selectedMake.models.length) return [] as Array<IVehicleModel>;
		return this.selectedMake.models.filter((option) => option.name.toLowerCase().indexOf(filterValue) === 0);
	}

	ngOnDestroy() {
		if (this.vehicleDataSubscription) {
			this.vehicleDataSubscription.unsubscribe();
		}
	}

	engineSizeChange() {
		let value = this.form.controls.engineSizeFormControl.value;
		if (value > 0 && value < 10) {
			this.form.controls.engineSizeFormControl.setValue(value * 1000);
		}
	}
}
