import { ICustomer } from '../interfaces/customer.interface';

export class CustomerModel implements ICustomer {
    address1: string = '';
    address2: string = '';; 
    address3: string = '';;
    company: string = '';
    consentLogItems: any = [];
    county: string = '';;
    email: string = '';;
    emailReminder: number = 0;
    firstName: string = '';
    fullAddress: string = '';
    fullName: string = '';
    id: number = 0;
    mobile: string =  '';
    password: string = '';
    passwordResetGuid: string = '';
    phone: string = '';
    postcode: string = '';
    smsReminder: number = 0;
    surname: string = '';
    title: string = '';
    townCity: string = '';

    public constructor(init?: Partial<ICustomer>) {
        Object.assign(this, init);
	}
    
}