<ng-container [ngSwitch]="template">
  
    <ng-container *ngSwitchCase="'dropdown'">
        <mat-form-field [appearance]="config.formAppearance">
            <mat-label>Select a branch</mat-label>
            <mat-select (selectionChange)="selectBranch($event, $event.value)" [(value)]="selectedBranch">
                <mat-option *ngFor="let branch of companyStoreService.company.branches" [value]="branch">
                    {{companyStoreService.formatBranchName(branch)}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'list'">
        <div class="form-row">
            <div class="{{getColumnClass()}}" *ngFor="let branch of companyStoreService.company.branches">
                <div class="branch-list-item text-center">
                    <h5 class="text-truncate font-weight-bold">{{branch.branchName}}</h5>
                    <p class="text-truncate text-muted small">{{companyStoreService.formatBranchAddress(branch, true, false)}}</p>
                    <p class="small text-muted">{{branch.postcode}}</p>
                    <h4 class="font-weight-light">{{branch.phoneNumber}}</h4>
                    <button class="btn btn-primary" (click)="selectBranch($event, branch)">{{config.buttonText}}</button>
                </div>
            </div>
        </div>
    </ng-container>

</ng-container>
