import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecialOfferListComponent } from './special-offer-list/special-offer-list.component';
import { SpecialOfferUpgradeListComponent } from './special-offer-upgrade-list/special-offer-upgrade-list.component';



@NgModule({
  declarations: [SpecialOfferListComponent, SpecialOfferUpgradeListComponent],
  imports: [
    CommonModule
  ],
  exports: [SpecialOfferListComponent, SpecialOfferUpgradeListComponent]
})
export class SpecialOfferModule { }
