import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ISlotTypeGroup } from 'src/app/interfaces/slot-type-group.interface';
import { CompanyStoreService } from '../company/company-store.service';
import { LoggerStoreService } from '../logger/logger-store.service';
import { TyreService } from './tyre.service';
import { ITyreSizeWidth, ITyre, ITyreManufacturer, ITyreSize } from 'src/app/interfaces/tyre.interface';
import { KeyValue } from '@angular/common';
import { ISlotType } from 'src/app/interfaces/slot-type.interface';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ConfigStoreService } from '../config/config-store.service';
import { AuthStoreService } from '../auth/auth-store.service';

@Injectable({
    providedIn: 'root'
})
export class TyreStoreService {

    public loading = true;
    public sortSelection = 'lowToHigh';
    public pageLength: number;
    public pageSize = 18;
    public pageSizeOptions = [18, 27, 36];
    config: IComponentConfig;

    // There are 3 steps to this.
    // 1. getTyres near the top of the page gets a full list of tyres by tyre size. The result of this is this.tyres and this must be
    // kept clean so that if we change filters etc... the full list is still there.
    // 2. filterTyres() near the bottom is a set of objects that stores the users filter selections. When the user has made some selections we pass this.tyres
    // through filter tyres and that removes any tyres that doesn't meet the criteria selected by the user.
    // 3. sortTyres(), sortedTyres and splicedTyres. sortTyres() will generate another new list of tyres that has been re-ordered by the user
    // i.e. changing the price order. splicedTyres is then a subset of sortedTyres and takes into account pagination.
    // Doing it this way means we can change the order of a selection without having to first refilter the list etc... and we have much
    // closer control of what's going on.

    constructor(
        private companyStoreService: CompanyStoreService,
        private loggerStoreService: LoggerStoreService,
        private authStoreService: AuthStoreService,
        private configStoreService: ConfigStoreService,
        private tyreService: TyreService) {
        this.loggerStoreService.log(`Tyre Store Service: Initialized`);
        if (this.authStoreService?.authorizationData?.access_token) { 
            if (this.companyStoreService.company.branches?.length > 1) {
                this.getTyreSizes('');
            } else {
                this.getTyreSizes(this.companyStoreService.branch?.guid);
            }
            this.getTyreSlot(this.companyStoreService.branch?.guid);
        }
        
        this.config = this.configStoreService.getComponentConfig('');
    }   

    //#region Tyre Slot
    private readonly _tyreSlot = new BehaviorSubject<ISlotType>({} as ISlotType);
    readonly tyreSlot$ = this._tyreSlot.asObservable();

    get tyreSlot(): ISlotType {
        return this._tyreSlot.getValue();
    }

    set tyreSlot(val: ISlotType) {
        this._tyreSlot.next(val);
    }

    async getTyreSlot(branchGuid: string) {
        if (!branchGuid) {
            this.loggerStoreService.log(`Tyre Store Service: No Branch Guid.`);
            this.tyreSlot = null;
            return;
        }
        this.tyreSlot = await this.tyreService.getTyreSlotType(branchGuid).toPromise();
        this.loggerStoreService.log(`Tyre Store Service: Received slot type ID ${this.tyreSlot.id} as tyre slot ID.`);
    }
    //#endregion

    //#region Tyre sizes
    private readonly _tyreSizes = new BehaviorSubject<ITyreSizeWidth[]>([]);
    readonly tyreSizes$ = this._tyreSizes.asObservable();

    get tyreSizes(): ITyreSizeWidth[] {
        return this._tyreSizes.getValue();
    }

    set tyreSizes(val: ITyreSizeWidth[]) {
        this._tyreSizes.next(val);
    }

    async getTyreSizes(branchGuid: string) {
        this.tyreSizes = await this.tyreService.getTyreSizes(branchGuid).toPromise();
        this.tyreSizes = this.tyreSizes.sort((c1, c2) => +c1.value - +c2.value);
        this.loggerStoreService.log(`Tyre Store Service: Received ${this.tyreSizes.length} tyre sizes.`);
        this.loggerStoreService.table(this.tyreSizes);
    }

    tyreSizeValid(tyreSize: ITyreSize) {
        if (!tyreSize) { return false;}
        if (tyreSize?.width && tyreSize?.profile && tyreSize?.diameter && tyreSize?.rating) {
            return true;
        }
        return false;
    }

    //#endregion

    //#region Tyre
    private readonly _tyre = new BehaviorSubject<ITyre[]>([] as ITyre[]);
    readonly tyre$ = this._tyre.asObservable();

    get tyre(): ITyre[] {
        return this._tyre.getValue();
    }

    set tyre(val: ITyre[]) {
        this._tyre.next(val);
    }

    async getTyre(branchGuid: string, tyreId: number) {
        this.tyre = await this.tyreService.getTyre(branchGuid, tyreId).toPromise();
    }

    //#endregion

    //#region Tyres
    private readonly _tyres = new BehaviorSubject<ITyre[]>([]);
    readonly tyres$ = this._tyres.asObservable();

    get tyres(): ITyre[] {
        return this._tyres.getValue();
    }

    set tyres(val: ITyre[]) {
        this._tyres.next(val);
    }

    async getTyres(branchGuid: string, manufacturerNames: string,
        tyreWidth: string, tyreProfile: string, tyreDiameter: string, tyreSpeed: string, tyreType: string, tyreId: number) {
        if (!branchGuid) {
            this.loggerStoreService.log(`Tyre Store Service: No Branch Guid.`);
            return;
        }
        this.tyres = await this.tyreService.getTyres(branchGuid, manufacturerNames, tyreWidth, tyreProfile, tyreDiameter, tyreSpeed, tyreType, tyreId).toPromise();
        this.tyres = this.tyres.filter(tyre => tyre.totalQuantity > this.config.tyres.minimumTyreStockLevel);
        this.tyres.forEach(t => {
            t.quantity = t.totalQuantity > 1 ? 2 : 1;
        });
        
        this.configureTyreFilters();
        this.filterTyres();
        setTimeout(() => {
            this.loading = false;
        }, this.config.loadingPanelDelay);

        this.loggerStoreService.log(`Tyre Store Service: Received ${this.tyres.length} tyres.`);
        this.loggerStoreService.table(this.tyres);
    }

    public tyreManufacturers: {};
    public tyreLoadIndexSpeedRatings: {};
    public tyreFuelEfficiencies: {};
    public tyreWetGripGrades: {};
    public tyreNoiseRatings: {};
    public tyreCatgories = {
        1: false,
        2: false,
        3: false
    };

    configureTyreFilters() {
        this.tyreLoadIndexSpeedRatings = {};
        this.tyreFuelEfficiencies = {};
        this.tyreWetGripGrades = {};
        this.tyreNoiseRatings = {};
        this.tyreManufacturers = {};
        this.tyres.forEach(tyre => {
            this.configureTyreManufacturers(tyre);
            this.configureTyreLoadIndexSpeedRatings(tyre);
            this.configureTyreFuelEfficiencies(tyre);
            this.configureTyreWetGripGrades(tyre);
            this.configureTyreNoiseRatings(tyre);
        });
    }

    resetTyreFilters() {        
        this.tyreCatgories = {
            1: false,
            2: false,
            3: false
        };
        this.tyreSpecialistType = {
            runFlat: false,
            extraLoad: false,
            suvTyres: false,
            vanTyres: false
        };  
        this.tyreSeasons = {
            allSeasons: false,
            summer: false,
            winter: false
        };  
        this.configureTyreFilters();
        setTimeout(() => {
            this.filterTyres();
        }, 0);
    }
    //#endregion

    //#region Featured Tyre
    private readonly _featuredTyres = new BehaviorSubject<ITyre[]>([] as ITyre[]);
    readonly featuredTyres$ = this._featuredTyres.asObservable();

    get featuredTyres(): ITyre[] {
        return this._featuredTyres.getValue();
    }

    set featuredTyres(val: ITyre[]) {
        this._featuredTyres.next(val);
    }

    setFeaturedTyres() {
        if (this.config.tyres.featuredTyreBrand) {            
            this.featuredTyres = this.sortedTyres.filter(tyre => tyre.manufacturerName.toLowerCase() == this.config.tyres.featuredTyreBrand.toLowerCase())

            if (!this.featuredTyres) { return; }

            //check feature tyre config values
            for (var i = this.featuredTyres.length - 1; i >= 0; i--) 
            {
                let remove: boolean = false;
                let removeLst: [boolean, boolean][] = [];

                removeLst[0] = [this.config.tyres.featuredTyreBrandFilters?.allSeason, this.featuredTyres[i].summerTyre && this.featuredTyres[i].winterTyre];
                removeLst[1] = [this.config.tyres.featuredTyreBrandFilters?.winter, this.featuredTyres[i].winterTyre];
                removeLst[2] = [this.config.tyres.featuredTyreBrandFilters?.summer, this.featuredTyres[i].summerTyre];
                removeLst[3] = [this.config.tyres.featuredTyreBrandFilters?.tyreDescriptionMatch != null, 
                    this.featuredTyres[i].description.toLowerCase().includes(this.config.tyres.featuredTyreBrandFilters?.tyreDescriptionMatch?.toLowerCase())];
                removeLst[4] = [this.config.tyres.featuredTyreBrandFilters?.tyreTreadPatternMatch != null, 
                    this.featuredTyres[i].treadPattern.toLowerCase().includes(this.config.tyres.featuredTyreBrandFilters?.tyreTreadPatternMatch?.toLowerCase())];

                for (let j = 0; j < removeLst.length; j++) {
                    const e = removeLst[j];
                    if (e[0] === true && e[1] === false)
                    {
                        remove = true;
                    }
                }

                if (remove) {
                    this.featuredTyres.splice(i, 1);
                }
            }            
        }
    }
    //#endregion

    //#region Tyre Filtering - Manufacturers

    configureTyreManufacturers(tyre: ITyre) {
        this.tyreManufacturers[tyre.manufacturerName.toLowerCase()] = false;
    }

    toggleTyreManufacturer(manufacturer: any) {
        this.tyreManufacturers[manufacturer.key] = !this.tyreManufacturers[manufacturer.key];
        setTimeout(() => {
            this.filterTyres();
        }, 0);
    }

    tyreManufacturerFilterActive(): boolean {
        let active = false;
        for (var manufacturer in this.tyreManufacturers) {
            if (this.tyreManufacturers[manufacturer]) {
                active = true;
                break;
            }
        }
        return active;
    }

    //#endregion

    //#region Tyre Filtering - Seasons

    public tyreSeasons = {
        allSeasons: false,
        summer: false,
        winter: false
    };

    seasonFilterActive() {
        if (this.tyreSeasons.allSeasons || this.tyreSeasons.summer || this.tyreSeasons.winter) {
            return true;
        } else {
            return false;
        }
    }

    toggleTyreSeason() {        
        setTimeout(() => {
            this.filterTyres();
        }, 0);
    }

    //#endregion

    //#region Tyre Filtering - Categories
    toggleTyreCategory() {
        // this.tyreCatgories[category] = !this.tyreCatgories[category];
        setTimeout(() => {
            this.filterTyres();
        }, 50);
    }

    categoryFilterActive() {
        let active = false;

        for (var category in this.tyreCatgories) {
            if (this.tyreCatgories[+category]) {
                active = true;
                break;
            }
        }
        return active;
    }

    //#endregion

    //#region Tyre Filtering - Fuel Efficiencies
    configureTyreFuelEfficiencies(tyre: ITyre) {
        this.tyreFuelEfficiencies[tyre.fuelEfficiency.toLowerCase()] = false;
    }

    toggleTyreFuelEfficiency(fuelEfficiency: any) {
        this.tyreFuelEfficiencies[fuelEfficiency.key] = !this.tyreFuelEfficiencies[fuelEfficiency.key];
        setTimeout(() => {
            this.filterTyres();
        }, 0);
    }

    fuelEffiencyFilterActive() {
        let active = false;
        for (var fuelEfficiency in this.tyreFuelEfficiencies) {
            if (this.tyreFuelEfficiencies[fuelEfficiency]) {
                active = true;
                break;
            }
        }
        return active;
    }

    //#endregion

    //#region Tyre Filtering - Wet Grip Grades
    configureTyreWetGripGrades(tyre: ITyre) {
        this.tyreWetGripGrades[tyre.wetGripGrade.toLowerCase()] = false;
    }

    toggleTyreWetGripGrade(wetGripGrade: any) {
        this.tyreWetGripGrades[wetGripGrade.key] = !this.tyreWetGripGrades[wetGripGrade.key];
        setTimeout(() => {
            this.filterTyres();
        }, 0);
    }

    wetGripGradeFilterActive() {
        let active = false;
        for (var wetGripGrade in this.tyreWetGripGrades) {
            if (this.tyreWetGripGrades[wetGripGrade]) {
                active = true;
                break;
            }
        }
        return active;
    }

    //#endregion

    //#region Tyre Filtering - Noise
    configureTyreNoiseRatings(tyre: ITyre) {
        this.tyreNoiseRatings[tyre.noiseDb] = false;
    }

    toggleTyreNoiseRating(noiseRating: any) {
        this.tyreNoiseRatings[noiseRating.key] = !this.tyreNoiseRatings[noiseRating.key];
        setTimeout(() => {
            this.filterTyres();
        }, 0);
    }

    noiseRatingFilterActive() {
        let active = false;
        for (var noiseRating in this.tyreNoiseRatings) {
            if (this.tyreNoiseRatings[noiseRating]) {
                active = true;
                break;
            }
        }
        return active;
    }

    //#endregion

    //#region Tyre Filtering - Load Index Speed Rating

    configureTyreLoadIndexSpeedRatings(tyre: ITyre) {
        this.tyreLoadIndexSpeedRatings[tyre.loadIndexSpeed.toLowerCase()] = false;
    }

    toggleTyreLoadIndexSpeedRating(loadIndexSpeedRating: any) {
        this.tyreLoadIndexSpeedRatings[loadIndexSpeedRating.key] = !this.tyreLoadIndexSpeedRatings[loadIndexSpeedRating.key];
        setTimeout(() => {
            this.filterTyres();
        }, 0);
    }

    loadIndexSpeedRatingFilterActive() {
        let active = false;
        for (var loadIndexSpeedRating in this.tyreLoadIndexSpeedRatings) {
            if (this.tyreLoadIndexSpeedRatings[loadIndexSpeedRating]) {
                active = true;
                break;
            }
        }
        return active;
    }
    //#endregion

    //#region Tyre Filtering - Specialist Type

    public tyreSpecialistType = {
        runFlat: false,
        extraLoad: false,
        suvTyres: false,
        vanTyres: false
    };

    specialistTypeFilterActive() {
        if (this.tyreSpecialistType.runFlat || this.tyreSpecialistType.extraLoad || 
            this.tyreSpecialistType.vanTyres  || this.tyreSpecialistType.suvTyres) 
        {
            return true;
        } else {
            return false;
        }
    }

    toggleTyreSpecialistType() {                
        setTimeout(() => {
            this.filterTyres();
        }, 0);
    }

    //#endregion

    //#region Tyre Filtering - Main Filter    

    filteredTyres: ITyre[] = [];
    filterTyres() {
        const filteredTyres = [...this.tyres];
        const filterByManufacturer = this.tyreManufacturerFilterActive();
        const filterBySeasons = this.seasonFilterActive();
        const filterByLoadIndexSpeed = this.loadIndexSpeedRatingFilterActive();
        const filterByFuelEfficiency = this.fuelEffiencyFilterActive();
        const filterByWetGripGrade = this.wetGripGradeFilterActive();
        const filterByNoiseRating = this.noiseRatingFilterActive();
        const filterByCategory = this.categoryFilterActive();
        const filterBySpecialistType = this.specialistTypeFilterActive();

        for (var i = filteredTyres.length - 1; i >= 0; i--) {

            // Manufacturers
            const m = filteredTyres[i].manufacturerName.toLowerCase();
            if (filterByManufacturer && !this.tyreManufacturers[m]) {
                filteredTyres.splice(i, 1);
                continue;
            }

            // Seasons
            if (filterBySeasons) {
                let remove = true;
                if (this.tyreSeasons.summer && filteredTyres[i].summerTyre) {
                    remove = false;
                    // keep
                }
                if (this.tyreSeasons.winter && filteredTyres[i].winterTyre) {
                    remove = false;
                    // keep
                }
                if (this.tyreSeasons.allSeasons && filteredTyres[i].summerTyre && filteredTyres[i].winterTyre) {
                    remove = false;
                }
                if (remove) {
                    filteredTyres.splice(i, 1);
                    continue;
                }
            }

            // Load Index Speed
            const l = filteredTyres[i].loadIndexSpeed.toLowerCase();
            if (filterByLoadIndexSpeed && !this.tyreLoadIndexSpeedRatings[l]) {
                filteredTyres.splice(i, 1);
                continue;
            }

            // Fuel efficiency
            const f = filteredTyres[i].fuelEfficiency.toLowerCase();
            if (filterByFuelEfficiency && !this.tyreFuelEfficiencies[f]) {
                filteredTyres.splice(i, 1);
                continue;
            }

            // Wet Grip Grades
            const w = filteredTyres[i].wetGripGrade.toLowerCase();
            if (filterByWetGripGrade && !this.tyreWetGripGrades[w]) {
                filteredTyres.splice(i, 1);
                continue;
            }

            // Noise Ratings
            const n = filteredTyres[i].noiseDb;
            if (filterByNoiseRating && !this.tyreNoiseRatings[n]) {
                filteredTyres.splice(i, 1);
                continue;
            }

            // Category
            const c = filteredTyres[i].tyreCategory;
            if (filterByCategory && !this.tyreCatgories[c]) {
                filteredTyres.splice(i, 1);
                continue;
            }

            // Specialist Type
            if (filterBySpecialistType) {
                let remove = true;
                if (this.tyreSpecialistType.runFlat && filteredTyres[i].runFlat) {
                    remove = false;
                    // keep
                }
                if (this.tyreSpecialistType.extraLoad && filteredTyres[i].extraLoad) {
                    remove = false;
                    // keep
                }
                if (this.tyreSpecialistType.suvTyres && filteredTyres[i].suvTyre) {
                    remove = false;
                    // keep
                }
                if (this.tyreSpecialistType.vanTyres && filteredTyres[i].vanTyre) {
                    remove = false;
                    // keep
                }
                if (remove) {
                    filteredTyres.splice(i, 1);
                    continue;
                }
            }
        }
        this.filteredTyres = filteredTyres;
        
        this.sortTyres();
    }

    sortedTyres: ITyre[] = [];
    splicedTyres: ITyre[] = [];
    sortTyres() {
        let sortedTyres: ITyre[] = [];
        switch (this.sortSelection) {
            case 'lowToHigh':
                this.loading = true;
                sortedTyres = this.filteredTyres.sort((a: ITyre, b: ITyre) => {
                    if (a.fullPrice.value < b.fullPrice.value) {
                        return -1;
                    } else if (a.fullPrice.value > b.fullPrice.value) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                break;
            case 'highToLow':
                this.loading = true;
                sortedTyres = this.filteredTyres.sort((a: ITyre, b: ITyre) => {
                    if (a.fullPrice.value > b.fullPrice.value) {
                        return -1;
                    } else if (a.fullPrice.value < b.fullPrice.value) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                break;
            case 'fuelEfficiency':
                this.loading = true;
                sortedTyres = this.filteredTyres.sort((a: ITyre, b: ITyre) => {
                    if (a.fuelEfficiency < b.fuelEfficiency) {
                        return -1;
                    } else if (a.fuelEfficiency > b.fuelEfficiency) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                break;
            case 'wetGrip':
                this.loading = true;
                sortedTyres = this.filteredTyres.sort((a: ITyre, b: ITyre) => {
                    if (a.wetGripGrade < b.wetGripGrade) {
                        return -1;
                    } else if (a.wetGripGrade > b.wetGripGrade) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                break;
            case 'noiseDb':
                this.loading = true;
                sortedTyres = this.filteredTyres.sort((a: ITyre, b: ITyre) => {
                    if (a.noiseDb < b.noiseDb) {
                        return -1;
                    } else if (a.noiseDb > b.noiseDb) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                break;
            default:
                sortedTyres = this.filteredTyres;
                break;
        }
        this.pageLength = sortedTyres.length;
        this.sortedTyres = sortedTyres;
        this.setFeaturedTyres();
        this.splicedTyres = this.sortedTyres.slice(((0 + 1) - 1) * this.pageSize).slice(0, this.pageSize);
        setTimeout(() => {
            this.loading = false;
        }, this.config.loadingPanelDelay);

    }
    //#endregion

    pageChangeEvent(event) {
        this.loading = true;
        const offset = ((event.pageIndex + 1) - 1) * event.pageSize;
        this.splicedTyres = this.sortedTyres.slice(offset).slice(0, event.pageSize);
        setTimeout(() => {
            this.loading = false;
        }, this.config.loadingPanelDelay);
    }

}
