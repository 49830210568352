import { Component, OnInit, EventEmitter, Input, Output  } from '@angular/core';
import { TyreStoreService } from 'src/app/services/tyre/tyre-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { ITyreSizeWidth, ITyreSizeProfile, ITyreSizeRim, ITyreSizeSpeed } from 'src/app/interfaces/tyre.interface';
import { ITyreSize } from 'src/app/interfaces/tyre.interface';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';

@Component({
    selector: 'app-tyre-size-form',
    templateUrl: './tyre-size-form.component.html',
    styleUrls: ['./tyre-size-form.component.scss']
})
export class TyreSizeFormComponent implements OnInit {

    config: IComponentConfig;
    @Output() valueChange = new EventEmitter<ITyreSize>();
    @Output() confirmTyreSize = new EventEmitter<any>();
    selectedWidth: ITyreSizeWidth;
    selectedProfile: ITyreSizeProfile;
    selectedDiameter: ITyreSizeRim;
    selectedSpeed: ITyreSizeSpeed;
    
    constructor(
        public tyreStoreService: TyreStoreService,
        public companyStoreService: CompanyStoreService,
        private vehicleStoreService: VehicleStoreService,
        private configStoreService: ConfigStoreService) { }

    ngOnInit() { 
        this.config = this.configStoreService.getComponentConfig('TyreSizeFormComponent');
    }

    selectWidth(selectedWidth: ITyreSizeWidth) {
        this.selectedProfile = null;
        this.selectedDiameter = null;
        this.selectedSpeed = null;
        selectedWidth.profiles.sort((c1, c2) => +c1.value - +c2.value);
    }

    selectProfile(selectedProfile: ITyreSizeProfile) {
        this.selectedDiameter = null;
        this.selectedSpeed = null;        
        selectedProfile.rims.sort((c1, c2) => +c1.value - +c2.value);
    }

    selectRim(selectedRim: ITyreSizeRim) {
        this.selectedSpeed = this.selectedDiameter.speeds[0];        
        selectedRim.speeds.sort((c1, c2) => {
            const speed1 = c1.value.toUpperCase(); // ignore case
            const speed2 = c2.value.toUpperCase(); 
            if (speed1 < speed2) {
                return -1;
            }
            if (speed1 > speed2) {
                return 1;
            }

            // value must be equal
            return 0;
        });
    }

    selectSpeed(selectedSpeed: ITyreSizeSpeed) { }

    saveTyreSize($event) {
        $event.stopPropagation();
        const tyreSize: ITyreSize = {
            width: this.selectedWidth?.value || null,
            profile: this.selectedProfile?.value || null,
            diameter: this.selectedDiameter?.value || null,
            rating: this.selectedSpeed?.value || null
        };
        // Set select values to null            
        this.selectedWidth = null;
        this.selectedProfile = null;
        this.selectedDiameter = null;
        this.selectedSpeed = null;

        // Save selected tyre size
        // Emit
        this.vehicleStoreService.vehicle.tyreSize = tyreSize;
        this.confirmTyreSize.emit();
    }

}
