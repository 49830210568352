import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { BasketStoreService } from '../services/basket/basket-store.service';
import { NavigationStoreService } from '../services/navigation/navigation-store.service';
import { AuthStoreService } from '../services/auth/auth-store.service';
import { ConfigStoreService } from '../services/config/config-store.service';
import { CompanyStoreService } from '../services/company/company-store.service';

@Injectable({
	providedIn: 'root',
})
export class ValidBasketGuard implements CanActivate, CanLoad {

  constructor(
    private basketStoreService: BasketStoreService,
    private authStoreService: AuthStoreService,
    private configStoreService: ConfigStoreService,
    private navigationStoreService: NavigationStoreService,
    private companyStoreService: CompanyStoreService
  ) {}


	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.basketStoreService.validateBasket()) {

      if (!this.authStoreService.clientId) {
        this.authStoreService.clientId = route.pathFromRoot[1].url[0].path;
        
        this.configStoreService.configId = +route.pathFromRoot[1].url[1].path;
        
        this.authStoreService.init();
        await this.companyStoreService.getCompany();

      }

		  this.navigationStoreService.goToFirstStep();      
      return false;
    }

		return true;
	}

  canLoad(): boolean {
    if (!this.basketStoreService.validateBasket()) {
		  this.navigationStoreService.goToFirstStep();      
      return false;
    }

		return true;
  }
}
