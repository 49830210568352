import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IVehicleData } from 'src/app/interfaces/vehicle-data.interface';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class VehicleService {

    constructor(
		private http: HttpClient) { }
    
    // VRM Lookup
    get(vrm: string, branchGuid: string) {
        return this.http.get<any>(`${environment.apiUrl}/Api/vrm/Get?vehicleRegistration=${vrm}&branchGuid=${branchGuid}`).pipe(shareReplay(1));
    }

    // VRM MOT Lookup
    getMOT(vrm: string) {
        return this.http.get<any>(`${environment.apiUrl}/Api/vrm/mot/Get?vehicleRegistration=${vrm}`).pipe(shareReplay(1));
    }

    // Get remaining VRM credits for current branch
    hasVrmCredits(branchGuid: string) {
        return this.http.get<any>(`${environment.apiUrl}/Api/Vrm/Credits/Get?branchGuid=${branchGuid}`);
    }

    // Lists of makes and models fro DB
    getVehicleData() {
        return this.http.get<IVehicleData[]>(`${environment.apiUrl}/Api/VehicleData/Get`).pipe(shareReplay(1));
    }

    // Get fuel types from DB
    getFuelTypes() {
        return this.http.get<any>(`${environment.apiUrl}/Api/FuelTypes/Get`).pipe(shareReplay(1));
    }
}
