import { Component, OnInit } from '@angular/core';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { Router } from '@angular/router';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';

@Component({
    selector: 'app-step-select-services',
    templateUrl: './step-select-services.component.html',
    styleUrls: ['./step-select-services.component.scss']
})
export class  StepSelectServicesComponent implements OnInit {

    tyresOnly = false;
    config: IComponentConfig;

    constructor(
        public companyStoreService: CompanyStoreService,
        private router: Router,
        private authStoreService: AuthStoreService,
        private basketStoreService: BasketStoreService,
        private configStoreService: ConfigStoreService) { }

    ngOnInit(): void {
        // backup
        this.config = this.configStoreService.getComponentConfig('StepSelectServicesComponent');

        if ((this.config.tyres.enabled && this.companyStoreService.branch.tyreSlotID > 0) && !this.config.services.enabled) {
            this.tyresOnly = true;
        }
    }

    tyreSelected() {
        // Navigate to add extras etc..
        // We only want to automatically navigate when we have both tyres and services, as the user will be inside
        // of a pop up, so we want to loop back around via the generic services page with has the tyre launch and
        // slot type groups.

        // Since then, this has been changed to automatically redirect after selecting a tyre, as Alan believes that users will typically only
        // want one type of tyre, which I think is probably correct for most bookings.
        // if (this.config.tyres.enabled && this.config.services.enabled) {
        //     // Determining where we are going.
        //     this.continue();
        // }
        this.continue();
    }

    continue() {

        // This is only used after adding tyres, to determine what the next step should be as the continue function is called automatically.

        if (this.config.skipBasket && this.config.skipUpsell) {

            if (this.basketStoreService.unbookableSlotTypes.length > 0) {
                this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'enter-details']);
            } else {
                this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-date-time']);
            }

        } else if (!this.config.skipUpsell && this.basketStoreService.getAvailableUpsellCount() > 0) {
            this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'add-extras']);

        } else if (!this.config.skipBasket) {
            this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'basket']);

        } else {
            
            if (this.basketStoreService.unbookableSlotTypes.length > 0) {
                this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'enter-details']);
            } else {
                this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-date-time']);
            }

        }

    }

}
