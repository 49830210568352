import { Component, OnInit } from '@angular/core';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { NavigationStoreService } from 'src/app/services/navigation/navigation-store.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-step-select-date-time',
	templateUrl: './step-select-date-time.component.html',
	styleUrls: ['./step-select-date-time.component.scss']
})
export class StepSelectDateTimeComponent implements OnInit {

	config: IComponentConfig;

	constructor(
		private router: Router,
		public basketStoreService: BasketStoreService,
		private configStoreService: ConfigStoreService,
		public companyStoreService: CompanyStoreService,
		private navigationStoreService: NavigationStoreService) { }

	ngOnInit(): void {		
		this.config = this.configStoreService.getComponentConfig('StepSelectDateTimeComponent');
		this.basketStoreService.clearAvailableDatesAndTimes();
		this.basketStoreService.clearDateTime();
		this.basketStoreService.getAvailableDates();		
	}
	
}


