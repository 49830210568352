import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TyreStoreService } from 'src/app/services/tyre/tyre-store.service';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';
import { ITyreSize, ITyre } from 'src/app/interfaces/tyre.interface';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-dialog-tyre-select',
    templateUrl: './dialog-tyre-select.component.html',
    styleUrls: ['./dialog-tyre-select.component.scss']
})
export class DialogTyreSelectComponent implements OnInit {

    @Input() data;
    selectedTyreSize: ITyreSize;
    confirmTyreSizeCheck = false;
    loading = false;
    btnVrmLoading = false;
    config: IComponentConfig;
    vrmError = false;
    vrmForm: UntypedFormGroup;
    searchType = 'manual';
    
    constructor(
        public companyStoreService: CompanyStoreService,
        private basketStoreService: BasketStoreService,
        public vehicleStoreService: VehicleStoreService,
        private elRef: ElementRef,
        public modal: NgbActiveModal,
        private configStoreService: ConfigStoreService,
        public tyreStoreService: TyreStoreService) { }

    ngOnInit(): void {
        this.config = this.configStoreService.getComponentConfig('DialogTyreSelectComponent');
        this.vrmForm = new UntypedFormGroup({
			'vrmFormControl': new UntypedFormControl(null, [Validators.required, Validators.minLength(3)])
        });
        if (this.vehicleStoreService.vrmAvailable()) {
            this.searchType = 'vrm';
        }
        this.tyreStoreService.getTyreSizes(this.companyStoreService.branch.guid);
    }

    vrmComplete() {
        this.navigate('TyreSizeSelection'); 
    }

    // Saves the tyre size selected after a vrm lookup
    saveTyreSize() {
        // When we select a tyre from the results, we check if we're expected to go to the tyre results within the modal
        // or save the result, close the modal, and let the step-select-vehicle deal with the navigation.
        this.vehicleStoreService.vehicle.tyreSize = this.selectedTyreSize;
        if (this.data.selectTyres) {
            this.navigate('TyreResults');
        } else {
            this.modal.close(true);
        }
        
    }

    // Confirms the tyre size in the the manual tyre size component.
    confirmTyreSize() {
        if (this.data.selectTyres) {
            this.navigate('TyreResults');
        } else {
            this.modal.close(true);
        }
    }

    selectedTyreSizeConfirmed = false;
    confirmSelectedTyreSize($event) {
        this.selectedTyreSizeConfirmed = $event;
    }

    navigate(step: string, title?: string) {
        const parentElement = this.elRef.nativeElement.closest('.modal-dialog');
        this.loading = true;
        switch(step) {
            case 'TyreSizeSearch':
                setTimeout(() => {
                    if (parentElement?.classList) {
                        parentElement.classList.remove('modal-xl');
                        parentElement.classList.add('modal-lg');
                    }
                    this.data.template = 'TyreSizeSearch';
                    this.data.title = title ? title : 'Select your tyre size';
                    this.loading = false;
                }, this.config.loadingPanelDelay);
            break;
            case 'TyreSizeSelection':
                setTimeout(() => {
                    if (parentElement?.classList) {
                        parentElement.classList.remove('modal-xl');
                        parentElement.classList.add('modal-lg');
                    }
                    this.data.template = 'TyreSizeSelection';
                    this.data.title = title ? title : 'Confirm your tyre size';
                    this.loading = false;
                }, this.config.loadingPanelDelay);
            break;
            case 'TyreResults':
                setTimeout(() => {
                    if (parentElement?.classList) {
                        parentElement.classList.add('modal-xl');
                        parentElement.classList.remove('modal-lg');
                    }
                    this.data.template = 'TyreResults';
                    this.data.title = title ? title : 'Select your tyres';
                    this.loading = false;
                }, this.config.loadingPanelDelay);
            break;
            case 'TyresAddMore':
                setTimeout(() => {
                    if (parentElement?.classList) {
                        parentElement.classList.remove('modal-xl');
                        parentElement.classList.add('modal-lg');
                    }
                    this.data.template = 'TyresAddMore';
                    this.data.title = title ? title : 'Add more tyres?';
                    this.loading = false;
                }, this.config.loadingPanelDelay);
            break;
        }
    }

    // Callback function for the manual tyre size form
    selectedTyreSizeValueChanged(selectedTyreSize: ITyreSize) {
        this.selectedTyreSize = selectedTyreSize;
    }

    tyreSizeValid() {
        if (this.selectedTyreSize?.width && this.selectedTyreSize?.profile && this.selectedTyreSize?.diameter && this.selectedTyreSize?.rating) {
            return true;
        }
        return false;
    }

    tyresSelected = false;
    tyreSelected() {

        // First we check to see if there are tyres in the basket.
        // Depending on the configuration, we can either take this as the user finishing their selction
        if (this.basketStoreService.tyres.length) {
            this.tyresSelected = true;
        } else {
            this.tyresSelected = false;
        }
        if (this.tyresSelected) {
            this.loading = true;
            setTimeout(() => {
                this.modal.dismiss();
                setTimeout(() => {
                    this.loading = false;
                }, this.config.loadingPanelDelay);
            }, this.config.loadingPanelDelay);
        }
        
    }
}
