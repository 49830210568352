<div class="loading-panel" [hidden]="!loading"></div>
<div class="modal-header mst">
    <h4 class="modal-title">{{data.title}}</h4>
    <button class="btn-close-dialog" (click)="modal.close()">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body mst">
    <ng-container *ngIf="data.template == 'VrmLookup'">
        <ng-template [ngTemplateOutlet]="VrmLookup"></ng-template>
    </ng-container>
    <ng-container *ngIf="data.template == 'ManualVehicleEntry'">
        <ng-template [ngTemplateOutlet]="ManualVehicleEntry"></ng-template>
    </ng-container>
    <ng-container *ngIf="data.template == 'VehicleResult'">
        <ng-template [ngTemplateOutlet]="VehicleResult"></ng-template>
    </ng-container>
    <ng-container *ngIf="data.template == 'AddToBasket'">
        <ng-template [ngTemplateOutlet]="AddToBasket"></ng-template>
    </ng-container>
    <ng-container *ngIf="data.template == 'NoPriceAvailable'">
        <ng-template [ngTemplateOutlet]="NoPriceAvailable"></ng-template>
    </ng-container>
</div>
<div class="modal-footer mst">
    <ng-container *ngIf="data.template == 'VehicleResult'">
        <ng-template [ngTemplateOutlet]="VehicleResultActions"></ng-template>
    </ng-container>
    <ng-container *ngIf="data.template == 'ManualVehicleEntry'">
        <ng-template [ngTemplateOutlet]="ManualVehicleEntryActions"></ng-template>
    </ng-container>
    <ng-container *ngIf="data.template == 'AddToBasket'">
        <ng-template [ngTemplateOutlet]="AddToBasketActions"></ng-template>
    </ng-container>
    <ng-container *ngIf="data.template == 'NoPriceAvailable'">
        <ng-template [ngTemplateOutlet]="NoPriceAvailableActions"></ng-template>
    </ng-container>
</div>

<ng-template #VrmLookup>
    <app-vehicle-vrm-lookup [checkTyres]="false" (vrmComplete)="vrmComplete()" (enterVehicleManually)="enterVehicleManually($event)"></app-vehicle-vrm-lookup>
</ng-template>

<ng-template #VehicleResult>
    <div class="vehicle-result">
        <label>Your vehicle</label>
        <p>{{vehicleStoreService.getFormattedVehicle()}}</p>
    </div>
</ng-template>
<ng-template #VehicleResultActions>
    <button class="btn btn-link" (click)="navigate('VrmLookup')">Search again or enter manually</button>
    <button class="btn btn-primary" (click)="navigate('AddToBasket')" cdkFocusInitial>Confirm vehicle</button>
</ng-template>

<ng-template #ManualVehicleEntry>
    <form [formGroup]="vehicleForm">
        <app-vehicle-form [form]="vehicleForm"></app-vehicle-form>
    </form>
</ng-template>
<ng-template #ManualVehicleEntryActions>
    <button class="btn btn-primary" [disabled]="!vehicleForm.valid" (click)="saveVehicle()">Continue</button>
</ng-template>

<ng-template #AddToBasket>
    <div class="add-to-basket">
        <p>The price for the following services has been updated to reflect the vehicle that you have entered.</p>
        <div class="slot-type" *ngIf="updatedSlotType">
            <div class="description">
                <img *ngIf="config.services.showSlotTypeIcon" src="https://branchresources.motasoftvgm.co.uk/{{updatedSlotType.image}}" onError="this.src='https://branchresources.motasoftvgm.co.uk/BranchResources/14/Images/SlotTypes/Icons/IconSet1/tacg/tacg/64x64-Servicing.png'" class="icon" />     
                <div>
                    <p>{{updatedSlotType.shortDescription}}</p>
                    <span [hidden]="!config.services.showSlotTypeLongDescription">
                        {{updatedSlotType.longDescription}}
                    </span>
                </div>
                
            </div>
            <div class="price">
                <span class="value">
                    {{"£" + (updatedSlotType.fullPrice.value | number:"1.2-2")}}
                </span>
            </div>
        </div>

        <div class="slot-type" *ngIf="updatedSpecialOffer">
            <div class="description">
                <img *ngIf="config.services.showSpecialOfferIcon" src="https://branchresources.motasoftvgm.co.uk/{{updatedSpecialOffer.bookingSystemIcon}}" onError="this.src='https://branchresources.motasoftvgm.co.uk/BranchResources/14/Images/SlotTypes/Icons/IconSet1/tacg/tacg/64x64-Servicing.png'" class="icon" />     
                <div>
                    <p>{{updatedSpecialOffer.name}}</p>
                    <span [hidden]="!config.services.showSpecialOfferLongDescription">
                        {{updatedSpecialOffer.generalInformation}}
                    </span>
                </div>
                
            </div>
            <div class="price">
                <span class="value">
                    {{"£" + (updatedSpecialOffer.discountedPrice.value | number:"1.2-2")}}
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #NoPriceAvailable>
<p *ngIf="originalSlotType">There is no online price available for <strong>{{originalSlotType.shortDescription}}</strong> for your {{vehicleStoreService.getFormattedVehicle()}}.</p>
<p *ngIf="originalSpecialOffer">There is no online price available for <strong>{{originalSpecialOffer.name}}</strong> for your {{vehicleStoreService.getFormattedVehicle()}}.</p>

<p>Please call us on {{companyStoreService.branch.phoneNumber}} or email {{companyStoreService.branch.emailAddress}} as we may be able to accommodate you. Alternatively, take a look at our other services.</p>
</ng-template>

<ng-template #NoPriceAvailableActions>
    <button class="btn btn-link" (click)="modal.dismiss('Closed modal')">Close</button>
</ng-template>

<ng-template #AddToBasketActions>
    <button class="btn btn-link" (click)="modal.dismiss('Closed modal')">Cancel</button>
    <button class="btn btn-primary" (click)="modal.close(true)">Add to booking</button>
</ng-template>