import { Inject, Injectable } from '@angular/core';
import { WINDOW } from 'src/app/app.module';
import { environment } from 'src/environments/environment';
import { IClientInfo } from '../../interfaces/client-info.interface';
import { IOS, IBrowser } from '../../interfaces/client.interface';
import { LoggerStoreService } from '../logger/logger-store.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

	clientInfo: IClientInfo = {
		topWindowURL: '',
		startingURL: '',
		resolution: '',
		device: '',
		browser: '',
		browserVersion: '',
		bookingSystemConfigurationID: 0,
		bookingSystemEnviroment: '',
		bookingSystemVersionNumber: ''
	};

  private allOS = [
    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'iPhone', value: 'iPhone', version: 'OS' },
    { name: 'iPad', value: 'iPad', version: 'OS' },
    { name: 'Kindle', value: 'Silk', version: 'Silk' },
    { name: 'Android', value: 'Android', version: 'Android' },
    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'Linux', value: 'Linux', version: 'rv' },
    { name: 'Palm', value: 'Palm', version: 'PalmOS' }
  ];

  private allBrowsers = [
      { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
      { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
      { name: 'Safari', value: 'Safari', version: 'Version' },
      { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
      { name: 'Opera', value: 'Opera', version: 'Opera' },
      { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
      { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
  ];

  constructor(
    private loggerStoreService: LoggerStoreService,
    @Inject(WINDOW) private window: Window
  ) { 
    this.setClientInfo();
  }
  
  setClientInfo(configId?: number) {
    const os = this.getOS();
    const browser = this.getBrowser();

    if(!this.clientInfo.topWindowURL) {
      // if the iframeResizer didn't pass+set the top window URL
      // fall back to this, it will either get the "pure" URL
      // or at least show us the domain the iframe is on

      const topWindowUrl = (window.location != window.parent.location)
        ? document.referrer : document.location.href;
    
      this.clientInfo.topWindowURL = topWindowUrl;
    }

    this.clientInfo.bookingSystemConfigurationID = configId ?? 0;
    this.clientInfo.resolution = `${this.window.outerWidth}x${this.window.outerHeight}`;
    this.clientInfo.device = `${os.name} : ${os.version}`;
    this.clientInfo.browser = browser.name,
    this.clientInfo.browserVersion = browser.version,
    this.clientInfo.bookingSystemEnviroment = environment.name;
    this.clientInfo.bookingSystemVersionNumber = environment.versionNumber;
    
    this.loggerStoreService.log(`ClientService: ${JSON.stringify(this.clientInfo)}`);
  }

  getBrowser(): IBrowser {
    let agent = [
      navigator.platform, 
      navigator.userAgent, 
      navigator.appVersion, 
      navigator.vendor
    ].join(' ');

    return this.matchItem(agent, this.allBrowsers) as IBrowser;
  }

  getOS(): IOS {
    let agent = [
      navigator.platform, 
      navigator.userAgent, 
      navigator.appVersion, 
      navigator.vendor
    ].join(' ');

    return this.matchItem(agent, this.allOS) as IOS;
  }

  private matchItem(agentStr, matchData) {
    for (let i = 0; i < matchData.length; i += 1) {
      let regex = new RegExp(matchData[i].value, 'i');
      let match = regex.test(agentStr);
      if (match) {
          let regexv = new RegExp(matchData[i].version + '[- /:;]([\\d._]+)', 'i');
          let matches = agentStr.match(regexv);
          let versionStr = '';
          if (matches) { 
            if (matches[1]) { 
              matches = matches[1]; 
            } 
          }

          if (matches) {
              let matchesArr = matches.split(/[._]+/);
              for (let j = 0; j < matchesArr.length; j += 1) {
                  if (j === 0) {
                    versionStr += matchesArr[j] + '.';
                  } else {
                    versionStr += matchesArr[j];
                  }
              }
          } else {
            versionStr = '0';
          }

          return  {
            name: matchData[i].name,
            value: matchData[i].value,
            version: versionStr
          };
      }
    }
    return { name: 'unknown', version: 0 };
  }
}
