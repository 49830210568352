<form
	[formGroup]="vrmForm"
	(ngSubmit)="doVrmLookup($event)"
	class="vrm-lookup"
	[ngClass]="{ tyres: checkTyres, standard: !checkTyres }"
>
	<div class="vrm-input-wrapper" *ngIf="checkTyres">
		<label *ngIf="checkTyres"
			>Enter your <strong>vehicle registration</strong>.</label
		>
		<input
			class="vrm-input"
			placeholder="Enter your registration"
			formControlName="vrmFormControl"
		/>
	</div>

	<label *ngIf="!checkTyres">Vehicle registration</label>
	<div class="vrm-input-wrapper" *ngIf="!checkTyres">
		<input
			class="vrm-input"
			placeholder="Enter your registration"
			formControlName="vrmFormControl"
		/>
		<button
			class="btn btn-lg btn-primary"
			[disabled]="!vrmForm.valid || btnVrmLoading"
		>
			<img
				class="btn-loading-img"
				src="{{ env.assetsUrl }}/images/loader.svg"
				*ngIf="btnVrmLoading"
			/>
			{{ config.buttonText }}
		</button>
	</div>

	<button
		*ngIf="checkTyres"
		class="btn btn-lg btn-primary"
		[disabled]="!vrmForm.valid || btnVrmLoading"
	>
		<img
			class="btn-loading-img"
			src="{{ env.assetsUrl }}/images/loader.svg"
			*ngIf="btnVrmLoading"
		/>
		{{
			config.buttonText === "Continue" ? "Find tyres" : config.buttonText
		}}
	</button>

	<div class="vrm-error" [hidden]="!vrmError" *ngIf="!checkTyres">
		<strong>Your vehicle registration returned no result.</strong><br />
		Please search again or
		<a
			href="javascript:void(0)"
			(click)="doEnterVehicleManually($event)"
			*ngIf="showManualEntryLink"
			>enter your vehicle manually.</a
		><span *ngIf="!showManualEntryLink">enter your vehicle manually.</span>
	</div>
	<div class="vrm-error" [hidden]="!vrmError" *ngIf="checkTyres">
		<strong>Your vehicle registration returned no tyre sizes.</strong>
		Please search again or enter your tyre size manually.
	</div>
	<a
		href="javascript:void(0)"
		[hidden]="vrmError"
		(click)="doEnterVehicleManually($event)"
		class="small"
		*ngIf="!checkTyres && showManualEntryLink"
		>Alternatively, enter your vehicle manually.</a
	>
</form>
