<!-- <div style="max-width: 100%; min-width: 0; display: flex; overflow: hidden;">
    <mat-tab-group>
        <mat-tab label="First First First"> Content 1 </mat-tab>
        <mat-tab label="Second Second Second"> Content 2 </mat-tab>
        <mat-tab label="Third Third Third"> Content 3 </mat-tab>
      </mat-tab-group>
</div> -->

<div class="service-checklist mst"
    [ngClass]="{'read-only': readOnly, 'wide-checklist': wide, 'narrow-checklist': !wide }" #serviceChecklist style="width: 100%; max-width: 100%; overflow: hidden;">

    <ng-container *ngIf="wide">
        <div equalHeight="checklist-slot-type-header-price">
            <ng-template [ngTemplateOutlet]="SlotTypes"
                [ngTemplateOutletContext]="{position: 'checklist-slot-type-header'}">
            </ng-template>
        </div>
        

        <div class="checklist-group" *ngFor="let group of slotTypeGroup?.checklistTemplate.itemGroups">

            <div class="checklist-header d-flex" (click)="showHideGroup(group)">
                <span>{{group.name}}</span>
                <span class="group-item-count">{{group.items.length}} items</span>
                <i class="fas fa-chevron-up ml-3" [hidden]="group.hidden" *ngIf="!readOnly"></i>
                <i class="fas fa-chevron-down ml-3" [hidden]="!group.hidden" *ngIf="!readOnly"></i>
            </div>

            <div class="checklist-item row no-gutters" *ngFor="let item of group.items; let last = last;"
                [ngClass]="{'last-child': last}" [hidden]="group.hidden && !readOnly">

                <div class="checklist-item-spacer d-flex align-items-center {{spacerColCssClass}}">
                    <div class="item-name">
                        {{item.name}}
                    </div>
                </div>

                <div class="checklist-item-wrapper {{itemsWrapperCssClass}}">

                    <div class="d-flex align-items-center row flex-row">
                        <div class="col text-center" *ngFor="let slotType of slotTypeGroup?.slotTypes">
                            <i class="fas fa-check text-success" [hidden]="!checkSlotTypesCompatibility(slotType.id, item.slotTypesCompatibility)"></i>
                            <i class="fas fa-horizontal-rule text-muted" [hidden]="checkSlotTypesCompatibility(slotType.id, item.slotTypesCompatibility)"></i>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <div equalHeight="checklist-slot-type-footer-price">
            <ng-template [ngTemplateOutlet]="SlotTypes"
                [ngTemplateOutletContext]="{position: 'checklist-slot-type-footer'}">
        </ng-template>
        </div>
        
    </ng-container>

    <ng-container *ngIf="!wide">
        <div equalHeight="checklist-equal-short">
            <div class="checklist-group" equalHeight="checklist-equal-long">
                <mat-tab-group mat-align-tabs="start">

                    <mat-tab *ngFor="let slotType of slotTypeGroup?.slotTypes; let i = index" [label]="slotType.shortDescription">
                        <div class="slot-types-wrapper tab-wrapper">
                            <ng-template [ngTemplateOutlet]="SlotType" [ngTemplateOutletContext]="{slotType: slotType}">
                            </ng-template>
                           
                            <div class="checklist-wrapper" *ngFor="let group of slotTypeGroup?.checklistTemplate.itemGroups">
                                <div class="checklist-header" (click)="showHideGroup(group)">
                                    <span>{{group.name}}</span>
                                    <span class="group-item-count">{{group.items.length}} items</span>
                                    <i class="fas fa-chevron-up ml-3" [hidden]="group.hidden" *ngIf="!readOnly"></i>
                                    <i class="fas fa-chevron-down ml-3" [hidden]="!group.hidden" *ngIf="!readOnly"></i>
                                </div>
                                <div class="checklist-item row no-gutters" *ngFor="let item of group.items; let last = last;" [ngClass]="{'last-child': last}" [hidden]="group.hidden && !readOnly">
                                    <div class="col-9">
                                        <div class="item-name">
                                            {{item.name}}
                                        </div>
                                    </div>
                                    <div class="col-3 text-right">
                                        <i class="fas fa-check text-success"
                                        [hidden]="!checkSlotTypesCompatibility(slotType.id, item.slotTypesCompatibility)"></i>
                                    <i class="fas fa-horizontal-rule text-muted"
                                        [hidden]="checkSlotTypesCompatibility(slotType.id, item.slotTypesCompatibility)"></i>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                    </mat-tab>

                </mat-tab-group>
            </div>
        </div>
    </ng-container>
</div>


<ng-template #SlotTypes let-position="position">

    <div class="checklist-slot-types row no-gutters {{position}}">
        <div class="{{spacerColCssClass}} slot-types-spacer d-flex align-items-center">

        </div>
        <div class="{{itemsWrapperCssClass}} slot-types-wrapper" equalHeight="checklist-equal-short">
            <div class="d-flex align-items-center flex-row row" equalHeight="checklist-equal-long">
                <div class="col" *ngFor="let slotType of slotTypeGroup?.slotTypes">

                    <ng-template [ngTemplateOutlet]="SlotType" [ngTemplateOutletContext]="{slotType: slotType, position: position}">
                    </ng-template>

                </div>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #SlotType let-slotType="slotType" let-position="position">
    <div class="slot-type">
        <h5 class="long-description checklist-equal-long">
            <span>{{slotType.shortDescription}}</span>
            <span (click)="openInfoModal(slotType.shortDescription, slotType.longDescription)" *ngIf="!readOnly"
                [hidden]="!slotType.longDescription || !showLongDescriptionLink">
                <i class="fas fa-question-circle"></i>
            </span>
        </h5>

        <div class="short-description checklist-equal-short" [hidden]="!showLongDescription">
            {{slotType.longDescription}}
        </div>
        <div class="items-checked">{{getSlotTypeCount(slotType.id)}}<span>/{{totalItems}} items</span></div>
        <div class="price {{position}}-price">
            <span class="value">
                <span *ngIf="slotType.fullPrice.type != 4"
                    [ngClass]="{'price-preamble': slotType.fullPrice.type != 4}">{{getPricePreamble(slotType)}}</span>
                <span *ngIf="slotType.fullPrice.type != 4">
                    {{"£" + (slotType.fullPrice.value | number:"1.2-2")}}<span
                        class="price-suffix">{{slotType.priceSuffix}}</span>
                </span>
                <span class="please-call" *ngIf="slotType.fullPrice.type == 4">
                    Please call {{companyStoreService.branch.phoneNumber}}
                </span>
            </span>
            <a *ngIf="slotType.fullPrice.type != 4 && !readOnly" href="javascript:void(0)"
                [ngClass]="{'btn-primary': !basketStoreService.slotTypeSelected(slotType), 'btn-success': basketStoreService.slotTypeSelected(slotType)}"
                class="btn btn-sm btn-primary" (click)="addRemoveSlotType(slotType)">
                <i class="fas fa-plus" [hidden]="basketStoreService.slotTypeSelected(slotType)"></i>
                <i class="fas fa-check" [hidden]="!basketStoreService.slotTypeSelected(slotType)"></i>
                <span [hidden]="basketStoreService.slotTypeSelected(slotType)">{{config.buttonText}}</span>
            </a>
        </div>
    </div>
</ng-template>  
