import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { AuthStoreService } from 'src/app/services/auth/auth-store.service';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { VehicleFeedStoreService } from 'src/app/services/vehicle-feed/vehicle-feed-store.service';
import { IAutoTraderStock } from '../../../interfaces/vehicle-feed.interface';
import { LoggerStoreService } from '../../../services/logger/logger-store.service';
import { CompanyStoreService } from '../../../services/company/company-store.service';
import { AbstractControl } from '@angular/forms';

@Component({
	selector: 'app-vehicle-feed-detail',
	templateUrl: './vehicle-feed-detail.component.html',
	styleUrls: ['./vehicle-feed-detail.component.scss'],
})
export class VehicleFeedDetailComponent implements OnInit {
	stock: IAutoTraderStock;
	activeSliderId: string;

	afterFirstSubmitClick = false;
	emailSending = false;
	emailSent = false;
	emailSubmitResult: string;
	showFeaturesFull = false;

	form: UntypedFormGroup;
	recaptchaResult: boolean;
	config: IComponentConfig;
	innerWidth: any;

	constructor(
		public configStoreService: ConfigStoreService,
		private authStoreService: AuthStoreService,
		private router: Router,
		private route: ActivatedRoute,
		private loggerStoreService: LoggerStoreService,
		public vehicleFeedStoreService: VehicleFeedStoreService
	) {}

	ngOnInit(): void {
		this.config = this.configStoreService.getComponentConfig('VehicleFeedDetailComponent');

		this.form = new UntypedFormGroup({
			titleFormControl: new UntypedFormControl(null, [Validators.required]),
			firstNameFormControl: new UntypedFormControl(null, [Validators.required]),
			surnameFormControl: new UntypedFormControl(null, [Validators.required]),
			emailFormControl: new UntypedFormControl(null, [Validators.required, Validators.email]),
			phoneFormControl: new UntypedFormControl(null, [Validators.required, Validators.pattern('[0-9 ]{11}')]),
			messageFormControl: new UntypedFormControl(null, [Validators.required]),
			recaptchaFormControl: new UntypedFormControl(null, [Validators.required]),
		});
		if (this.vehicleFeedStoreService.vehicleFeedFull && this.vehicleFeedStoreService.vehicleFeedFull.stock) {
			this.stock = this.vehicleFeedStoreService.vehicleFeedFull.stock.filter((c) => c.stockItemID == this.route.snapshot.paramMap.get('stockItemID'))[0];
			this.activeSliderId = this.stock?.vehicle?.images?.[0]?.toString() ?? '';
		} else {
			this.vehicleFeedStoreService.initialiseService().then(() => {
				this.stock = this.vehicleFeedStoreService.vehicleFeedFull.stock.filter((c) => c.stockItemID == this.route.snapshot.paramMap.get('stockItemID'))[0];
				this.activeSliderId = this.stock?.vehicle?.images?.[0]?.toString() ?? '';
			});
		}

		this.innerWidth = window.innerWidth;
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
	}

	navigateToList(): void {
		this.router.navigate([this.authStoreService.clientId, this.configStoreService.configId, 'vehicle', 'list']);
	}

	//#region Thumbnail logic
	cycleToSlide(image: string): void {
		this.activeSliderId = image;
	}

	updateActiveSliderId(e: any): void {
		this.activeSliderId = e.current;
	}
	//#endregion

	submitContactForm() {
		this.afterFirstSubmitClick = true;

		if (this.form.valid) {
			this.emailSending = true;
			this.vehicleFeedStoreService.submitEnquiry(this.stock, this.form).then((c) => {
				this.emailSending = false;
				this.emailSubmitResult = c;
				this.emailSent = true;
			});
		}
	}

	toggleShowFullFeatureList() {
		this.vehicleFeedStoreService.loading = true;
		setTimeout(() => {
			this.showFeaturesFull = !this.showFeaturesFull;
			this.vehicleFeedStoreService.loading = false;
		}, 600);
	}
}
