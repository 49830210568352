<div class="widget_services-list" [ngClass]="{'widget_narrow': widgetSize == 'narrow'}" #servicesList>
    <div class="widget_inner-wrapper">
        <div class="widget_loading-panel" *ngIf="loading"></div>
        <div *ngIf="!widgetAttributesErrors.length && !loading">
            <div class="widget_search-wrapper" *ngIf="!hasBranchOnInit">    
                <div class="widget_title">
                    Book Online with <span>{{ companyStoreService?.company?.name }}</span> <span class="small" *ngIf="pricesShownBranchName != null && !staleData"> - {{ pricesShownBranchName }}</span>
                </div>
                <div class="widget_search">
                    <app-company-select-branch [template]="'dropdown'" (branchSelected)="branchSelected($event)"></app-company-select-branch>
                </div>      
            </div>
            <div class="widget_title" *ngIf="hasBranchOnInit">
                Book Online with <span>{{getCompanyBranchName()}}</span>
            </div>
            <div class="widget_results-wrapper" *ngIf="specialOffers.length || slotTypes.length">
                <ng-template *ngFor="let specialOffer of specialOffers" 
                [ngTemplateOutlet]="SpecialOfferRow" [ngTemplateOutletContext]="{specialOffer: specialOffer}"></ng-template>
                <ng-template *ngFor="let slotType of slotTypes" 
                    [ngTemplateOutlet]="SlotTypeRow" [ngTemplateOutletContext]="{slotType: slotType}"></ng-template>
                <!-- <ng-template *ngFor="let slotType of unpricedSlotTypes" 
                    [ngTemplateOutlet]="POASlotTypeRow" [ngTemplateOutletContext]="{slotType: slotType}"></ng-template> -->
            
            </div>
        </div>
        <div class="widget_error" *ngIf="widgetAttributesErrors.length">
            <div><strong>Error loading widget.</strong></div>
        </div>
    </div>
</div>


<ng-template #SlotTypeRow let-slotType="slotType">
    <div class="widget_row widget_slot-type">
        <div class="widget_information">
            <img *ngIf="config.services.showSlotTypeIcon" 
                src="https://branchresources.motasoftvgm.co.uk/{{slotType.image}}" 
                onError="this.src='https://branchresources.motasoftvgm.co.uk//branchresources/global/images/slottypes/icons/iconset1/blue/repairs.png'" 
                class="widget_icon" />     
            <div class="widget_description">
                <div class="widget_short-description widget_has-more-info" *ngIf="slotTypeHasMoreInfo(slotType)" (click)="showSlotTypeInfoModal(slotType)">{{slotType.shortDescription}} <i class="fas fa-info-circle"></i></div>
                <div class="widget_short-description" *ngIf="!slotTypeHasMoreInfo(slotType)">{{slotType.shortDescription}}</div>
                <div class="widget_long-description" *ngIf="config.services.showSlotTypeLongDescription">
                    {{slotType.longDescription}}
                </div>
                <!-- <span class="widget_price-suffix">{{slotType.priceSuffix}}</span> -->
            </div>
        </div>
        <div class="widget_price">

            <div class="widget_value" *ngIf="!isPOASlotType(slotType)">
                <div [ngClass]="{'widget_price-preamble': slotType.fullPrice.type != 4, 'widget_no-price':slotType.fullPrice.type == 4}">{{getPricePreambleSlotType(slotType)}} </div>
                <div *ngIf="slotType.fullPrice.type != 4" class="widget_price-number"> 
                    {{"£" + (slotType.fullPrice.value | number:"1.2-2")}} 
                    <span class="widget_price-suffix">{{slotType.priceSuffix}}</span>
                </div>
                <div class="widget_please-call" *ngIf="slotType.fullPrice.type == 4">Please call {{companyStoreService.branch.phoneNumber}}</div>
                
            </div>

            <div class="widget_value" *ngIf="isPOASlotType(slotType)">
                {{ slotType.priceOverwrite }}
                <span class="widget_price-suffix">{{slotType.priceSuffix}}</span>
            </div>

            <button *ngIf="slotType.fullPrice.type != 4" href="javascript:void(0)"
                class="widget_button" (click)="addToBasketAndNavigateSlotType(slotType)">
                <span>{{config.buttonText}}</span>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #SpecialOfferRow let-specialOffer="specialOffer">
    <div class="widget_row widget_special-offer">
        <div class="widget_information">
            <img *ngIf="config.services.showSpecialOfferIcon" 
                src="https://branchresources.motasoftvgm.co.uk/{{specialOffer.bookingSystemIcon}}" 
                onError="this.src='https://branchresources.motasoftvgm.co.uk//branchresources/global/images/slottypes/icons/iconset1/blue/repairs.png'" 
                class="widget_icon" />     
                <div class="widget_description">
                    
                    <div class="widget_short-description widget_has-more-info" (click)="showSpecialOfferInfoModal(specialOffer)">
                        {{specialOffer.name}} <i class="fas fa-info-circle"></i>
                    </div>

                    <div class="widget_save" *ngIf="specialOffer.discountedPrice.type == 3">
                        Save £{{(specialOffer.fullPrice.value - specialOffer.discountedPrice.value) | number:"1.2-2"}}
                    </div>
                    <div class="widget_save" *ngIf="specialOffer.discountedPrice.type != 3">
                        Save at least £{{(specialOffer.fullPrice.value - specialOffer.discountedPrice.value) | number:"1.2-2"}}
                    </div>
                    <div *ngIf="config.services.showSpecialOfferLongDescription" class="widget_special-offer-items">
                        <!-- {{specialOffer.generalInformation}} -->
                        <ul>
                            <li *ngFor="let slotType of specialOffer.slotTypes"><i class="fas fa-check"></i> {{slotType.shortDescription}}</li>
                        </ul>
                    </div>
                </div>

        </div>
        <div class="widget_price" *ngIf="specialOffer.discountedPrice.type != 4">
            <div class="widget_value">
                <div class="widget_price-preamble" *ngIf="getPricePreambleSpecialOffer(specialOffer)">{{getPricePreambleSpecialOffer(specialOffer)}}</div>
                <div class="widget_price-value widget_strikethrough" *ngIf="specialOffer.discountedPrice.type == 3">£{{specialOffer.fullPrice.value | number:"1.2-2"}}</div>
                <div class="widget_price-value">{{"£" + (specialOffer.discountedPrice.value | number:"1.2-2")}}</div>
            </div>
            <a href="javascript:void(0)" 
                class="widget_button" 
                (click)="addToBasketAndNavigateSpecialOffer(specialOffer)">
                <span>{{config.buttonText}}</span>
            </a>
        </div>
        <div class="widget_price no-price" *ngIf="specialOffer.discountedPrice.type == 4">
            {{specialOffer.discountedPrice.label}}
            <span class="widget_please-call">Please call {{companyStoreService?.branch?.phoneNumber}}</span>
        </div>
    </div> 
</ng-template>
