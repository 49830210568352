import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISpecialOffer, ISpecialOfferUpgrade } from 'src/app/interfaces/special-offer.interface';
import { SpecialOfferService } from './special-offer.service';
import { CompanyStoreService } from '../company/company-store.service';
import { LoggerStoreService } from '../logger/logger-store.service';
import { BasketStoreService } from '../basket/basket-store.service';
import { ISlotType } from 'src/app/interfaces/slot-type.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VehicleStoreService } from '../vehicle/vehicle-store.service';
import { ConfigStoreService } from '../config/config-store.service';

@Injectable({
    providedIn: 'root'
})
export class SpecialOfferStoreService {

    constructor(
        private specialOfferService: SpecialOfferService,
        private companyStoreService: CompanyStoreService,
        private configStoreService: ConfigStoreService,
        private vehicleStoreService: VehicleStoreService,
        private snackBar: MatSnackBar,
        private injector: Injector,
        private loggerStoreService: LoggerStoreService) {
            this.loggerStoreService.log(`Special Offer Store Service: Initialized`);
            // Once we have a vehicle service, amend this to pass in correct params if we have them.
            this.getSpecialOffers(this.companyStoreService.branch?.guid);
        }

    private readonly _specialOffers = new BehaviorSubject<ISpecialOffer[]>([] as ISpecialOffer[]);
    readonly specialOffers$ = this._specialOffers.asObservable();

    get specialOffers(): ISpecialOffer[] {
        return this._specialOffers.getValue();
    }

    set specialOffers(val: ISpecialOffer[]) {
        this._specialOffers.next(val);
    }

    // Additional helper function for which special offers should
    // be visible based on user interaction that doesn't provoke a fresh API call.
    // For example: select a vehicle type.
    showSpecialOffers() {
        if (!this.specialOffers.length) {
            return false;
        }
        let visible = false;
        for (let i = 0; i < this.specialOffers.length; i++) {
            if (this.showSpecialOffer(this.specialOffers[i])) {
                visible = true;
                break;
            }
        }
        return visible;
    }

    showSpecialOffer(specialOffer: ISpecialOffer) {
        let visible = true;

        if (this.configStoreService.config.selectVehicleType && this.vehicleStoreService.vehicleType) {
            const matchedSlotTypes = specialOffer.slotTypes.filter(slotType => slotType.vehicleCategories.includes(this.vehicleStoreService.vehicleType));
            if (specialOffer.slotTypes.length != matchedSlotTypes.length) { 
                visible = false;
            }
        }

        return visible;
    }

    async getSpecialOffers(branchGuid: string, vehicleMake: string = '', fuelTypeID: number = 0, engineCC: number = 0, getNextAvailableSlotDates:boolean = false) {
        if (!branchGuid) {
            this.loggerStoreService.log(`Special Offer Store Service: No Branch Guid.`);
            return;
        }
        let specialOffers = await this.specialOfferService.get(branchGuid, vehicleMake, fuelTypeID, engineCC, getNextAvailableSlotDates).toPromise();

        specialOffers = this.filterSpecialOfferList(specialOffers);   
        this.loggerStoreService.log(`Special Offer Store Service: Received ${specialOffers.length} special offers.`);
        this.loggerStoreService.table(specialOffers);
        this.specialOffers = specialOffers;
    }

    async getWidgetSpecialOffers(branchGuid: string, specialOffersIDs: string[]): Promise<ISpecialOffer[]> {
        if(!specialOffersIDs || specialOffersIDs.length <= 0) return [];

        await this.getSpecialOffers(branchGuid);

        let specialOffers: ISpecialOffer[] = [];
        
        specialOffers.push(...this.specialOffers.filter(c => specialOffersIDs.includes(c.id.toString())))        

        // Distinct 
        const key = 'id';
        specialOffers = [...new Map(specialOffers.map(item =>
            [item[key], item])).values()];
            
        specialOffersIDs.forEach(offerID => {
            if(!specialOffers.map(c => c.id.toString()).includes(offerID)) { console.error(`Special Offer ID ${offerID} not found`);}
        });

        this.loggerStoreService.log(`Special Offer Store Service: Filtered to  ${specialOffers.length} special offers.`);
        this.loggerStoreService.table(specialOffers);
        return specialOffers;
    }

    async updateSpecialOfferPrices(branchGuid: string, vehicleMake: string = '', fuelTypeID: number = 0, engineCC: number = 0, getNextAvailableSlotDates:boolean = false) {
        // This function exists to simply update the prices of slot types when vehicles change.
        // We could easily just replace slotTypeGroups, but replacing something that large will
        // cause a full re-render of the slots. This is purely for a smoother visual experience.
        if (!branchGuid) {
            this.loggerStoreService.log(`Special Offer Store Service: No Branch Guid.`);
            return;
        }
        // const basketStoreService = this.injector.get(BasketStoreService);
        // let basketTotal = basketStoreService.getSpecialOffersTotal();

        let newSpecialOffers = await this.specialOfferService.get(branchGuid, vehicleMake, fuelTypeID, engineCC, getNextAvailableSlotDates).toPromise();
        
        this.loggerStoreService.log(`Special Offer Store Service: Received ${this.specialOffers.length} special offers.`);
        this.loggerStoreService.table(this.specialOffers);

        newSpecialOffers = this.filterSpecialOfferList(newSpecialOffers);

        if (!this.specialOffers.length) {
            this.specialOffers = newSpecialOffers;
        } else {
            // newSpecialOffers.forEach(newSpecialOffer => {
            //     const oldSpecialOfferIndex = this.specialOffers.indexOf(this.specialOffers.find(so => so.id == newSpecialOffer.id));
            //     if (oldSpecialOfferIndex > -1) {
            //         this.specialOffers[oldSpecialOfferIndex].fullPrice = newSpecialOffer.fullPrice;
            //         this.specialOffers[oldSpecialOfferIndex].discountedPrice = newSpecialOffer.discountedPrice;
            //     }
            // });
            this.specialOffers = newSpecialOffers;
        }


        // let newBasketTotal = basketStoreService.getSpecialOffersTotal();
        // if (newBasketTotal != basketTotal) {
        //     this.snackBar.open(`The prices of some of your selected special offers has changed.`, '', {duration: 5000});
        // }
    }

    async refreshSpecialOffers() {
        await this.updateSpecialOfferPrices(
            this.companyStoreService.branch.guid,
            this.vehicleStoreService.vehicle.make,
            this.vehicleStoreService.vehicle.fuelTypeID,
            this.vehicleStoreService.vehicle.engineCC, false);
    }

    getSpecialOfferByID(id: number) {
        if (!this.specialOffers.length) return;
        return this.specialOffers.find(specialOffer => specialOffer.id == id);
    }

    private readonly _specialOfferUpgrades = new BehaviorSubject<ISpecialOfferUpgrade[]>([] as ISpecialOfferUpgrade[]);
    readonly specialOfferUpgrades$ = this._specialOfferUpgrades.asObservable();

    get specialOfferUpgrades(): ISpecialOfferUpgrade[] {
        return this._specialOfferUpgrades.getValue();
    }

    set specialOfferUpgrades(val: ISpecialOfferUpgrade[]) {
        this._specialOfferUpgrades.next(val);
    }

    getSpecialOfferUpgrades(slotTypes: ISlotType[]) {
        const availableSpecialOfferUpgrades: ISpecialOfferUpgrade[] = [];
        slotTypes.forEach(basketSlotType => {
            this.specialOffers.forEach(specialOffer => {
                let upgradeAvailable = false;
                let pricingCorrect = true;
                specialOffer.slotTypes.forEach(slotType => {
                    if (slotType.id == basketSlotType.id) {
                        upgradeAvailable = true;
                    }
                    // Uncomment below, if you ONLY want special offer upgrades to show that have
                    // proper prices.
                    // if (slotType.fullPrice.type != 3) {
                    //     pricingCorrect = false;
                    // }
                });

                if (upgradeAvailable && pricingCorrect) {
                    const upgrade: ISpecialOfferUpgrade = {
                        specialOffer: specialOffer,
                        slotType: basketSlotType
                    };
                    availableSpecialOfferUpgrades.push(upgrade);
                }
            });
        });
        this.specialOfferUpgrades = availableSpecialOfferUpgrades;
    }

    getPricePreamble(specialOffer: ISpecialOffer): string {
        switch(specialOffer.discountedPrice.type) {
            case 1:
                return 'Price from';
            case 2:
                return 'Price to';
            default:
                return ''
        }
    }

    filterSpecialOfferList(specialOffers: ISpecialOffer[]): ISpecialOffer[]{
        let result = specialOffers;
        
        if (this.configStoreService.isFilteredItemConfig) {
            result = result.filter(c => this.configStoreService.config.specialOfferIDs.includes(c.id.toString()));
        }

        return result;
    }

    filterSpecialOfferUpgradeList(specialOfferUpgrades: ISpecialOfferUpgrade[]): ISpecialOfferUpgrade[] {
        let result = specialOfferUpgrades;
        
        if(this.configStoreService.config.specialOfferIDs[0] != '') {
            result = result.filter(c => this.configStoreService.config.specialOfferIDs.includes(c.specialOffer.id.toString()));
        }

        // Check if special offer slot types have selected vehicle types (if this is turned on)
        if (this.configStoreService.config.selectVehicleType) {
            result = result.filter(upgrade => this.showSpecialOffer(upgrade.specialOffer))
        }

        return result;
    }

}


