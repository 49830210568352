import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehicleFormComponent } from './vehicle-form/vehicle-form.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VehicleVrmLookupComponent } from './vehicle-vrm-lookup/vehicle-vrm-lookup.component';
import { VehicleTypeComponent } from './vehicle-type/vehicle-type.component';
import { DirectivesModule } from 'src/app/directives/directives.module';
 

@NgModule({
    declarations: [VehicleFormComponent, VehicleVrmLookupComponent, VehicleTypeComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        DirectivesModule
    ],
    exports: [VehicleFormComponent, VehicleVrmLookupComponent, VehicleTypeComponent]
})
export class VehicleModule { }
