<div class="tyre-size-results">
    <div class="vehicle">
        <label>Your vehicle</label>
        <h5>{{vehicleStoreService.getFormattedVehicle()}}</h5>
    </div>

    <div class="confirm-tyre-check">
        <mat-checkbox [(ngModel)]="confirmTyreSizeCheck" (change)="confirmTyreSizeCheckChange($event)">I can confirm that I have checked my vehicles tyre size, and the following selection is suitable for my vehicle.</mat-checkbox>
    </div>

    <div class="switch">
        <button class="btn btn-sm" [ngClass]="{'btn-primary': visibleTyreSizes == 'Front'}" (click)="visibleTyreSizes = 'Front'">Front</button>
        <button class="btn btn-sm" [ngClass]="{'btn-primary': visibleTyreSizes == 'Rear'}" (click)="visibleTyreSizes = 'Rear'">Rear</button>
    </div>

    <div class="form-row tyre-size-results" [hidden]="visibleTyreSizes != 'Front'">
        <ng-template *ngFor="let tyreSize of vehicleStoreService.availableFrontTyreSizes" [ngTemplateOutlet]="TyreSize" [ngTemplateOutletContext]="{tyreSize: tyreSize}"></ng-template>
    </div>

    <div class="form-row tyre-size-results" [hidden]="visibleTyreSizes != 'Rear'">
        <ng-template *ngFor="let tyreSize of vehicleStoreService.availableRearTyreSizes" [ngTemplateOutlet]="TyreSize" [ngTemplateOutletContext]="{tyreSize: tyreSize}"></ng-template>
    </div>

    <div class="tyre-image">
        <img src="{{config.tyres.tyreSizeDescriptionImage}}" />
    </div>

     
</div>

<ng-template #TyreSize let-tyreSize="tyreSize">
    <div class="col-sm-6 col-md-4">
        <div class="tyre-result" (click)="selectTyreSize($event, tyreSize)" [ngClass]="{'selected': selectedTyreSize?.width == tyreSize.width && selectedTyreSize?.profile == tyreSize.profile && selectedTyreSize?.diameter == tyreSize.diameter && selectedTyreSize?.position == tyreSize.position}">
            <div>
                <i class="far fa-circle"></i>
                
            </div>
            <div>
                <label>Width</label>
                <span>{{tyreSize.width}}</span>
            </div>
            <div>
                <label>Profile</label>
                <span>{{tyreSize.profile}}</span>
            </div>
            <div>
                <label>Rim</label>
                <span>{{tyreSize.diameter}}</span>
            </div>
            
        </div>
    </div>
</ng-template>