<form [formGroup]="passwordResetForm" class="password-reset-form" (ngSubmit)="resetPassword()">
    <h4>Reset your password</h4>
    <mat-form-field [appearance]="config.formAppearance" [hidden]="passwordChanged">
        <mat-label>New password <span class="text-danger">*</span>
        </mat-label>
        <input matInput formControlName="customerPasswordFormControl" type="password">
        <mat-error *ngIf="passwordResetForm.get('customerPasswordFormControl').hasError('required')">
            Password is <strong>required</strong>.
        </mat-error>
        <mat-error
            *ngIf="!passwordResetForm.get('customerPasswordFormControl').hasError('required') && passwordResetForm.get('customerPasswordFormControl').hasError('strong')">
            Password must contain a combination of <strong>letters and numbers</strong> and be at least 6 characters.
        </mat-error>
    </mat-form-field>

    <mat-form-field [appearance]="config.formAppearance" [hidden]="passwordChanged">
        <mat-label>Repeat new password <span class="text-danger">*</span>
        </mat-label>
        <input matInput formControlName="customerRepeatPasswordFormControl" type="password">
        <mat-error *ngIf="passwordResetForm.get('customerRepeatPasswordFormControl').hasError('passwordMatch')">
            Passwords <strong>don't match</strong>
        </mat-error>
    </mat-form-field>

    <div class="confirmation" [hidden]="!passwordChanged">
        <div>Your password has been updated!</div>
        <!-- If there are items in the basket, go to extras screen to checkout -->
        <a href="javascript:void(0)" class="btn btn-light" [routerLink]="['/' + authStoreService.clientId, configStoreService.configId + '', 'booking', 'select-date-time']" *ngIf="hasValidBooking()">Complete your booking</a>
        <!-- Otherwise, go to first step -->
        <a href="javascript:void(0)" class="btn btn-light" (click)="navigationStoreService.goToFirstStep()"  *ngIf="!hasValidBooking()">Make a booking</a>
    </div>

    <div class="error" [hidden]="!passwordResetError">
        {{passwordResetError}}
    </div>

    <button class="btn btn-lg btn-primary btn-block" [hidden]="passwordChanged"><img class="btn-loading-img" src="{{env.assetsUrl}}/images/loader.svg" *ngIf="btnLoading" /> Update password</button>
</form>