import { Injectable, OnInit } from '@angular/core';
import { CompanyStoreService } from '../company/company-store.service';
import { CustomerStoreService } from '../customer/customer-store.service';
import { AuthStoreService } from '../auth/auth-store.service';
import { Router } from '@angular/router';
import { LoggerStoreService } from '../logger/logger-store.service';
import { ConfigStoreService } from '../config/config-store.service';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { VehicleStoreService } from '../vehicle/vehicle-store.service';
import { AnalyticsStoreService } from '../analytics/analytics-store.service';
import { BasketStoreService } from '../basket/basket-store.service';

@Injectable({
	providedIn: 'root',
})
export class NavigationStoreService implements OnInit {
	startingPath = [];
	config: IComponentConfig;

	constructor(
		public companyStoreService: CompanyStoreService,
		public customerStoreService: CustomerStoreService,
		private analyticsStoreService: AnalyticsStoreService,
		private basketStoreService: BasketStoreService,
		public authStoreService: AuthStoreService,
		private configStoreService: ConfigStoreService,
		private vehicleStoreService: VehicleStoreService,
		private router: Router,
		private loggerStoreService: LoggerStoreService
	) {
		this.config = this.configStoreService.getComponentConfig('NavigationStoreService');
	}

	ngOnInit() {}

	// get first step
	async goToFirstStep(replace = false) {
		this.startingPath = [this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-location'];

		// We have a branch selected, so no need to start with branch select.
		// If the company has only 1 branch, this is set inside the companyStoreService which is initialised before the app.
		if (this.companyStoreService.branch.guid) {
			this.loggerStoreService.log('Account Password Reset: Branch guid selected.');
			this.startingPath = [this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-services'];

			// if they want to start with VRM lookup. Must have VRM lookup enabled.
			// we check that there are either company level credits available or branch level credits available.
			// However, if we have both services and tyres set to true we must start at the services step.
			if (
				this.config.startWithVehicle &&
				(this.companyStoreService.company.companyLevelVrmAvailable || this.companyStoreService.branch.branchLevelVrmAvailable) &&
				this.configStoreService.config.tyres.enabled != this.configStoreService.config.services.enabled &&
				!this.vehicleStoreService.hasValidVehicle()
			) {
				this.loggerStoreService.log('Account Password Reset: Start with vehicle true. VRM enabled. First step - select vehicle.');
				this.startingPath = [this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-vehicle'];
			}

			// if we have select vehicle type enabled and we don't want to start with a vehicle,
			// then we go to 'select vehicle type' otherwise the 'select vehicle' step will handle
			// the vehicle type should it be enabled. However, if the config has already selected a
			// vehicle type via the widget, we can ignore this.
			if (this.config.services.enabled && this.config.selectVehicleType && !this.vehicleStoreService.vehicleType) {
				this.loggerStoreService.log('Account Password Reset: Start with vehicle false. Select Vehicle Type true: First step - select vehicle type.');
				this.startingPath = [this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-vehicle-type'];
			}

			// if the booking system is purely tyres, we MUST start with vrm/tyre size search
			if (this.config.tyres.enabled && !this.config.services.enabled) {
				this.loggerStoreService.log('Account Password Reset: Tyres only. Must start with vrm/tyre size search.');
				this.startingPath = [this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-vehicle'];

				if (this.configStoreService.startingTyreWidth) {
					// But wait a sec! We have a starting width and tyre size, so we don't need to start with the vrm lookup.
					// The layout component has already commited the tyre size to the service, so we delete it afterwards so subsequent calls to
					// go to first step are correct.
					this.configStoreService.startingTyreWidth = null;
					this.startingPath = [this.authStoreService.clientId, this.configStoreService.configId, 'booking', 'select-services'];
				}
			}

			// If we have a password reset key... we're going somewhere else.
		}
		this.analyticsStoreService.trackPageView(`/${this.startingPath[2]}/${this.startingPath[3]}`);
		this.router.navigate(this.startingPath, { replaceUrl: replace });
		await this.basketStoreService.refreshPrices();
	}

	totalSteps() {
		let count = 3;

		if (this.companyStoreService?.company?.branches?.length > 1) {
			count += 1;
		}

		if (this.config.selectVehicleType && !this.config.startWithVehicle) {
			count += 1;
		}

		if (this.config.startWithVehicle) {
			count += 1;
		}

		if (!this.config.skipBasket) {
			count += 1;
		}

		return count;
	}

	currentStepNumber(route: string) {
		let step = 1;

		if (route?.includes('booking/select-location')) {
			step = 1;
		}

		if (route?.includes('booking/select-vehicle-type') && this.companyStoreService?.company?.branches?.length > 1) {
			step = 2;
		}

		if (route?.includes('booking/select-vehicle') && !route?.includes('type') && this.companyStoreService?.company?.branches?.length > 1) {
			step = 2;
		}

		if (route?.includes('booking/select-services') && (this.config.startWithVehicle || this.config.selectVehicleType)) {
			step = this.companyStoreService?.company?.branches?.length > 1 ? 3 : 2;
		}

		if (route?.includes('booking/select-services') && this.companyStoreService?.company?.branches?.length > 1) {
			step = 2;
		}

		if (route?.includes('booking/basket')) {
			step = this.totalSteps() - 2;
		}

		if (route?.includes('booking/select-date-time')) {
			step = this.totalSteps() - 1;
		}

		if (route?.includes('booking/enter-details')) {
			step = this.totalSteps();
		}

		return step;
	}

	// current state

	// navigate
}
