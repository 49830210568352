<div class="loading-panel" [hidden]="!loading"></div>
<div class="modal-header mst">
    <h4 class="modal-title">{{data.title}}</h4>
    <button class="btn-close-dialog" (click)="modal.dismiss('Closed modal')">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body mst">
    <app-slot-type-service-checklist [slotTypeGroup]="data.slotTypeGroup" [readOnly]="data.readOnly" [widget]="data.widget" *ngIf="!loading"></app-slot-type-service-checklist>
</div>
<div class="modal-footer mst">
    <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
</div>



 