import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutMainComponent } from './layout-main/layout-main.component';
import { CompanyModule } from '../company/company.module';
import { SlotTypeModule } from '../slot-type/slot-type.module';
import { SpecialOfferModule } from '../special-offer/special-offer.module';
import { ExtraModule } from '../extra/extra.module';
import { CheckoutModule } from '../checkout/checkout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { DialogsModule } from '../dialogs/dialogs.module';
import { VehicleModule } from '../vehicle/vehicle.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { TyresModule } from '../tyres/tyres.module';
import { BasketModule } from '../basket/basket.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { WidgetsModule } from '../widgets/widgets.module';
 
@NgModule({
	declarations: [LayoutMainComponent],
	imports: [
		CommonModule,
		AppRoutingModule,
		CompanyModule, 
		SlotTypeModule,
		SpecialOfferModule,
		ExtraModule,
		CheckoutModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		DialogsModule,
		VehicleModule,
        PipesModule,
        TyresModule,
		BasketModule,
		SpecialOfferModule,
		WidgetsModule
	],
	exports: [
		LayoutMainComponent
	]
})
export class LayoutModule { }
