import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VehicleFeedResultsComponent } from './vehicle-feed-results/vehicle-feed-results.component';
import { VehicleFeedDetailComponent } from './vehicle-feed-detail/vehicle-feed-detail.component';
import { VehicleFeedFilterComponent } from './vehicle-feed-filter/vehicle-feed-filter.component';
import { VehicleFeedDetailIconsComponent } from './vehicle-feed-detail-icons/vehicle-feed-detail-icons.component';
import { VehicleFeedListComponent } from './vehicle-feed-list/vehicle-feed-list.component';
import { VehicleFeedComponent } from './vehicle-feed/vehicle-feed.component'; 
import { VehicleFeedFeaturesIconsComponent } from './vehicle-feed-features-icons/vehicle-feed-features-icons.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
    RECAPTCHA_SETTINGS,
    RecaptchaModule,
    RecaptchaSettings,
    RecaptchaFormsModule,
} from 'ng-recaptcha';

const globalSettings: RecaptchaSettings = { siteKey: '6Ldp8uMUAAAAAL1V9oP3JrFABy1oqK7aic4j_mQk' };


import { MaterialModule } from 'src/app/material.module';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
    declarations: [
        VehicleFeedResultsComponent, 
        VehicleFeedDetailComponent, 
        VehicleFeedFilterComponent, 
        VehicleFeedComponent, 
        VehicleFeedListComponent, 
        VehicleFeedDetailIconsComponent, 
        VehicleFeedFeaturesIconsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        PipesModule,
        NgbModule,
        AppRoutingModule,
        RecaptchaModule,
        RecaptchaFormsModule
    ],
    exports: [
        VehicleFeedResultsComponent, 
        VehicleFeedDetailComponent, 
        VehicleFeedFilterComponent, 
        VehicleFeedComponent,
        VehicleFeedListComponent, 
        VehicleFeedDetailIconsComponent,
        VehicleFeedFeaturesIconsComponent
    ],
    providers: [
      { provide: RECAPTCHA_SETTINGS, useValue: globalSettings },
    ]
})
export class VehicleFeedModule { }
