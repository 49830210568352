import { Injectable } from '@angular/core';
import { LoggerStoreService } from '../logger/logger-store.service';
import { LocalStorageKey } from 'src/app/global/enums';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageStoreService {
    localStorage: Storage;

    constructor(
        private loggerStoreService: LoggerStoreService
    ) {
         
    }

    get(key: LocalStorageKey, clientID: string): any {
        if (this.isLocalStorageSupported()) {
            const value = JSON.parse(this.localStorage.getItem(this.formatKey(key, clientID)))
            this.loggerStoreService.log('Local Storage Service: Got value for ' + this.formatKey(key, clientID));
            this.loggerStoreService.table(value);
            return value;
        }
        return null;
    }

    set(key: LocalStorageKey, clientID: string, value: any): boolean {
        if (this.isLocalStorageSupported()) {
            this.localStorage.setItem(this.formatKey(key, clientID), JSON.stringify(value)); 
            this.loggerStoreService.log('Local Storage Service: Set value for ' + this.formatKey(key, clientID));
            this.loggerStoreService.table(value);
            return true;
        }

        return false;
    }

    remove(key: LocalStorageKey, clientID: string): boolean {
        if (this.isLocalStorageSupported()) {
            this.localStorage.removeItem(this.formatKey(key, clientID));
            this.loggerStoreService.log('Local Storage Service: Removed value for ' + this.formatKey(key, clientID));
            return true;
        }

        return false;
    }

    private formatKey(key: LocalStorageKey, clientID: string) {
        return `motasoft${LocalStorageKey.toString(key)}_${clientID}`
    }

    clearLocalStorage(clientID: string, clearAuth = false) {
        
        if (clearAuth) {
            this.remove(LocalStorageKey.AuthorizationData, clientID);
        }
        
        this.remove(LocalStorageKey.ExtraIDs, clientID);
        this.remove(LocalStorageKey.TyreIDs, clientID);
        this.remove(LocalStorageKey.SlotTypeIDs, clientID);
        this.remove(LocalStorageKey.SpecialOfferIDs, clientID);
        this.remove(LocalStorageKey.BranchGuid, clientID);
        this.remove(LocalStorageKey.Vehicle, clientID);
        this.remove(LocalStorageKey.DvsaVehicle, clientID);
        this.remove(LocalStorageKey.ClientID, clientID);
        
    }

    isLocalStorageSupported(): boolean {
        if (this.localStorage) { return true; }
        try {
            this.localStorage = window.localStorage; 
            // Test localStorage works, even if it returns a value
            this.localStorage.setItem('localStorage', "1");
            this.localStorage.removeItem('localStorage');
            return true;
        } catch(e) {
            return false;
        }
    }
}
