
<div class="widget_tyre-size-lookup" [ngClass]="{'widget_narrow': !wide}">

    <div class="widget_loading-panel" *ngIf="loading || showLoadingPanel"></div>
   
    <div class="widget_tabbed-nav" *ngIf="vehicleStoreService.vrmAvailable() && !wide">
        <a href="javascript:void(0)" (click)="searchType = 'vrm'" [ngClass]="{'active': searchType == 'vrm'}">
            Search by reg
        </a>
        <a href="javascript:void(0)" (click)="searchType = 'manual'" [ngClass]="{'active': searchType == 'manual'}">
            Search by size
        </a>
    </div>
    
    <div class="widget_inner-wrapper {{searchType}}-search" #tyreSizeLookup>
        

        <div *ngIf="companyStoreService?.company?.branches?.length > 1" class="widget_select-location">
            <div class="widget_description">Select where you'd like to book</div>
            <app-company-select-branch [template]="'dropdown'" (branchSelected)="branchSelected($event)"></app-company-select-branch>
        </div>
        
        <div *ngIf="configStoreService?.config?.selectVehicleType">
            <div class="widget_description">Select your vehicle type</div>
            <app-vehicle-type [template]="'dropdown'" (vehicleTypeSelected)="vehicleTypeSelected($event)"></app-vehicle-type>
        </div>

        <div class="widget_tyre-search" *ngIf="!loading">

            <div class="widget_tyre-vrm-lookup" *ngIf="vehicleStoreService.vrmAvailable()">
                <div class="widget_description">
                    Enter your vehicle <strong>registration</strong>.
                </div>
                <div class="widget_form-wrapper">
                    <input class="widget_vrm-input" placeholder="Enter your registration" [(ngModel)]="vehicleRegistration" />
                </div>
                
                <a href="javascript:void(0)" class="widget_button" (click)="searchByRegistration()" [ngClass]="{'disabled': searchByRegistrationDisabled()}" [attr.disabled]="searchByRegistrationDisabled()">{{config.buttonText}}</a>

                <div class="widget_error" *ngIf="searchByRegistrationError">
                    {{searchByRegistrationError}}
                </div>

            </div>
            <div class="widget_or" *ngIf="vehicleStoreService.vrmAvailable()">
                <span>Or</span>
            </div>
            
            <div class="widget_manual-entry">
                
                <div class="widget_description">Select your <strong>tyre size</strong>.</div>

                <div class="widget_form-wrapper">
                    <div class="widget_form-row">
                        <div class="widget_form-row-col">
                            <mat-form-field [appearance]="config?.formAppearance">
                                <mat-label>Width</mat-label>
                                <mat-select (selectionChange)="selectWidth($event.value)" [(ngModel)]="selectedWidth" name="selectedWidth">
                                <mat-option *ngFor="let tyreWidth of tyreStoreService.tyreSizes" [value]="tyreWidth">
                                    {{tyreWidth.value}}
                                </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="widget_form-row-col">
                            <mat-form-field [appearance]="config.formAppearance">
                                <mat-label>Profile</mat-label>
                                <mat-select (selectionChange)="selectProfile($event.value)" [disabled]="!selectedWidth"
                                [(ngModel)]="selectedProfile" name="selectedProfile">
                                <mat-option *ngFor="let tyreProfile of selectedWidth?.profiles" [value]="tyreProfile">
                                    {{tyreProfile.value}}
                                </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="widget_form-row-col">
                            <mat-form-field [appearance]="config.formAppearance">
                                <mat-label>Rim</mat-label>
                                <mat-select (selectionChange)="selectRim($event.value)" [disabled]="!selectedProfile"
                                [(ngModel)]="selectedDiameter" name="selectedDiameter">
                                <mat-option *ngFor="let tyreDiameter of selectedProfile?.rims" [value]="tyreDiameter">
                                    {{tyreDiameter.value}}
                                </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="widget_form-row-col">
                            <mat-form-field [appearance]="config.formAppearance">
                                <mat-label>Speed</mat-label>
                                <mat-select (selectionChange)="selectSpeed($event.value)" [disabled]="!selectedDiameter"
                                [(ngModel)]="selectedSpeed" name="selectedSpeed">
                                <mat-option *ngFor="let tyreSpeed of selectedDiameter?.speeds" [value]="tyreSpeed">
                                    {{tyreSpeed.value}}
                                </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <a href="javascript:void(0)" class="widget_button" (click)="searchByTyreSize()" [ngClass]="{'disabled': searchByTyreSizeDisabled()}" [attr.disabled]="searchByTyreSizeDisabled()">{{config.buttonText}}</a>
            </div>
        </div>
    </div>
</div>
