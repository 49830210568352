import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IComponentConfig } from 'src/app/interfaces/config.interface';
import { VehicleStoreService } from 'src/app/services/vehicle/vehicle-store.service';
import { CompanyStoreService } from 'src/app/services/company/company-store.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfigStoreService } from 'src/app/services/config/config-store.service';
import { Router } from '@angular/router';
import { BasketStoreService } from 'src/app/services/basket/basket-store.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-vehicle-vrm-lookup',
	templateUrl: './vehicle-vrm-lookup.component.html',
	styleUrls: ['./vehicle-vrm-lookup.component.scss']
})
export class VehicleVrmLookupComponent implements OnInit {
    env: any;
	btnVrmLoading = false;
    vehicleRegistration = '';
    vrmError = false;
    vrmForm: UntypedFormGroup;
    vrmType: string;
    config: IComponentConfig;
    
	@Output() vrmComplete: EventEmitter<any> = new EventEmitter();
	@Output() enterVehicleManually: EventEmitter<any> = new EventEmitter();
    @Input() checkTyres: boolean;
    @Input() showManualEntryLink: boolean;
    @Input() buttonText: string;
	
	constructor(
        public vehicleStoreService: VehicleStoreService,
        private basketStoreService: BasketStoreService,
		private companyStoreService: CompanyStoreService,
		public dialog: MatDialog,
		private configStoreService: ConfigStoreService,
		private router: Router) {
            this.config = this.configStoreService.getComponentConfig('VehicleVrmLookupComponent');
            this.env = environment;
        }

	ngOnInit(): void {
        this.showManualEntryLink = this.showManualEntryLink == undefined ? true : this.showManualEntryLink;
        if (this.buttonText) {
            this.config.buttonText = this.buttonText;
        }
		this.vrmForm = new UntypedFormGroup({
			'vrmFormControl': new UntypedFormControl(null, [Validators.required, Validators.minLength(3)])
        });
        if (this.vehicleStoreService.vehicle?.registrationNumber) {
            this.vrmForm.controls.vrmFormControl.setValue(this.vehicleStoreService.vehicle.registrationNumber);
		}
	}

	doVrmLookup($event) {
        $event.stopPropagation();
        if (!this.vrmForm.valid || this.btnVrmLoading) return;
        this.btnVrmLoading = true;
        this.vehicleStoreService.doVrmLookup(this.companyStoreService.branch.guid, this.vrmForm.get('vrmFormControl').value)
        .then((result: any) => {
            
			// Pass an input in to see if we check the tyres result
            if (this.vehicleStoreService.isValidVrmResult(result, this.checkTyres)) {
                this.basketStoreService.refreshPrices();

				// We have a result, so navigate forwards.
				// We need to just return the result back to the parent component to handle.
				// This should be an Output
				this.btnVrmLoading = false;
				this.vrmComplete.emit();
                
            } else {
                setTimeout(() => {
                    this.vrmError = true;
                    this.btnVrmLoading = false;   
                }, this.config.loadingPanelDelay);
            }
        }).catch((error) => {
            setTimeout(() => {
                this.vrmError = true;
                this.btnVrmLoading = false;   
            }, this.config.loadingPanelDelay);
        });
	}

	doEnterVehicleManually($event) {
        $event.stopPropagation();
		this.enterVehicleManually.emit();
	}


}
