import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { IAutoTraderDealer, IAutoTraderVehicleFeedEnquire } from 'src/app/interfaces/vehicle-feed.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VehicleFeedService {

  constructor(private http: HttpClient) { }

  getAutoTraderVehicleFeed(vehicleDealerID: number) {

    return this.http.get<IAutoTraderDealer>(`${environment.apiUrl}/Api/VehicleFeed/AutoTrader/Get?vehicleDealerID=${vehicleDealerID}`).pipe(shareReplay(1));    
  }

  sendEnquiry(vehicleDealerID: number, emailPayload: IAutoTraderVehicleFeedEnquire) {
		return this.http.post<any>(`${environment.apiUrl}/Api/Email/SendAutoTraderVehicleEnquiry/Post?vehicleDealerID=${vehicleDealerID}`, emailPayload);
	}
}
