import { Injectable, OnInit, Injector, Inject } from '@angular/core';
import { CompanyService } from './company.service';
import { BehaviorSubject } from 'rxjs';
import { ICompany } from '../../interfaces/company.interface';
import { IBranch } from '../../interfaces/branch.interface';
import { LoggerStoreService } from '../logger/logger-store.service';
import { ConfigModel } from '../../models/config.model';
import { LocalStorageStoreService } from '../local-storage/local-storage-store.service';
import { SpecialOfferStoreService } from '../special-offer/special-offer-store.service';
import { SlotTypeStoreService } from '../slot-type/slot-type-store.service';
import { BasketStoreService } from '../basket/basket-store.service';
import { ConfigStoreService } from '../config/config-store.service';
import { ActivatedRoute } from '@angular/router';
import { IVehicleDealer } from '../../interfaces/vehicle-dealer.interface';
import { TyreStoreService } from '../tyre/tyre-store.service';
import { AuthStoreService } from '../auth/auth-store.service';
import { LocalStorageKey } from 'src/app/global/enums';
import * as moment from 'moment';

declare var paypal; // PayPal script declares this at a window level;

@Injectable({
	providedIn: 'root',
})
export class CompanyStoreService {
	constructor(
		private companyService: CompanyService,
		private loggerStoreService: LoggerStoreService,
		private authStoreService: AuthStoreService,
		private configStoreService: ConfigStoreService,
		private injector: Injector,
		private route: ActivatedRoute,
		private localStorageStoreService: LocalStorageStoreService
	) {}

	// Company
	private readonly _company = new BehaviorSubject<ICompany>({} as ICompany);
	readonly company$ = this._company.asObservable();

	get company(): ICompany {
		return this._company.getValue();
	}

	set company(val: ICompany) {
		this._company.next(val);
	}

	getCompany(): Promise<any> {
		const promise = this.companyService
			.get()
			.toPromise()
			.then((response) => {
				this.loggerStoreService.log(`Company Store Service: Received company with name '${response.name}' `);
				this.company = response;
				let config = this.company.configurations.find((c) => c.id == this.configStoreService.configId);
				if (!config) {
					config = this.company.configurations[0];
				}
				this.loggerStoreService.log(`Company Store Service: Loading config ID '${config.id}' `);

				this.configStoreService.updateConfig(config);
				// If there is only 1 branch select it. If there are multiple, check if we have a previous selection and if we do

				// Remove localstorage tyres if tyre is false
				if (!this.configStoreService.config.tyres.enabled) {
					this.localStorageStoreService.remove(LocalStorageKey.TyreIDs, this.authStoreService.clientId);
				}

				// Remove localstorage services and special offers if services is false
				if (!this.configStoreService.config.services.enabled) {
					this.localStorageStoreService.remove(LocalStorageKey.SlotTypeIDs, this.authStoreService.clientId);
					this.localStorageStoreService.remove(LocalStorageKey.SpecialOfferIDs, this.authStoreService.clientId);
				}

				// Dump branches with GUID's that don't match our config
				if (this.configStoreService.config.branches[0]) {
					this.company.branches = this.company.branches.filter((c) => this.configStoreService.config.branches.includes(c.guid));
				}

				// Change the CSS variable
				if (this.configStoreService.config.brandPrimary) {
				}

				// re-select branch.
				if (this.company.branches.length == 1) {
					this.selectBranch(this.company.branches[0]);
				} else if (this.configStoreService.startingBranchGuid) {
					const selectedBranch: IBranch = this.company.branches.find((branch) => branch.guid === this.configStoreService.startingBranchGuid);
					if (selectedBranch) {
						this.selectBranch(selectedBranch);
					}
				} else {
					const branchGuid = this.localStorageStoreService.get(LocalStorageKey.BranchGuid, this.authStoreService.clientId);
					if (branchGuid) {
						const selectedBranch: IBranch = this.company.branches.find((branch) => branch.guid === branchGuid);
						if (selectedBranch) {
							this.selectBranch(selectedBranch);
						}
					}
				}

				// re-select vehicle dealer
				if (this.configStoreService.config.vehicleDealerID && this.configStoreService.config.vehicleDealerID > -1) {
					const selectedVehicleDealer: IVehicleDealer = this.company.vehicleDealers.find((c) => c.id === this.configStoreService.config.vehicleDealerID);
					if (selectedVehicleDealer) {
						this.selectVehicleDealer(selectedVehicleDealer);
					}
				} else {
					if (this.company.vehicleDealers.length > 0) {
						this.selectVehicleDealer(this.company.vehicleDealers[0]);
					} else {
						this.selectVehicleDealer(undefined);
					}
				}
				return response;
			})
			.catch((error) => {
				this.loggerStoreService.logErrorToRollBar(error);
			});
		return promise;
	}

	//#region  Branch

	private readonly _branch = new BehaviorSubject<IBranch>({} as IBranch);
	readonly branch$ = this._branch.asObservable();

	get branch(): IBranch {
		return this._branch.getValue();
	}

	set branch(val: IBranch) {
		this._branch.next(val);
	}

	formatBranchName(branch: IBranch) {
		if (!branch || !branch?.branchName) return '';
		return branch.branchName.indexOf(branch.companyName) > -1 ? branch.branchName : `${branch.companyName} - ${branch.branchName}`;
	}

	formatBranchAddress(branch: IBranch, includeCity = false, includePostcode = false) {
		if (!branch) return '';
		let formattedAddress = '';
		formattedAddress += branch.address1 ? branch.address1 + ', ' : '';
		formattedAddress += branch.address2 ? branch.address2 + ', ' : '';
		formattedAddress += branch.address3 ? branch.address3 + ', ' : '';
		if (includeCity) formattedAddress += branch.city ? branch.city + ' ' : '';
		if (includePostcode) formattedAddress += branch.postcode ? branch.postcode + ' ' : '';
		return formattedAddress;
	}

	isSelectedBranch(branch: IBranch): boolean {
		if (branch.guid == this.branch.guid) {
			return true;
		} else {
			return false;
		}
	}

	isBranchSelected(): boolean {
		if (this.branch) {
			return true;
		} else {
			return false;
		}
	}

	isMultiBranch(): boolean {
		if (this.company.branches.length > 1) {
			return true;
		} else {
			return false;
		}
	}

	selectBranch(branch: IBranch) {
		this.localStorageStoreService.set(LocalStorageKey.BranchGuid, this.authStoreService.clientId, String(branch.guid));
		this.branch = branch;
		this.loggerStoreService.log('CompanyStoreService: Got branch');
		this.loggerStoreService.log(branch);
		const tyreStoreService = this.injector.get(TyreStoreService);
		tyreStoreService.getTyreSlot(this.branch.guid);
		tyreStoreService.getTyreSizes(this.branch.guid);
	}

	clearBranch() {
		if (this.company.branches.length > 1) {
			this.branch = {} as IBranch;
		}
	}

	//#endregion

	//#region Vehicle Dealer
	private readonly _vehicleDealer = new BehaviorSubject<IVehicleDealer>({} as IVehicleDealer);
	readonly vehicleDealer$ = this._vehicleDealer.asObservable();

	get vehicleDealer(): IVehicleDealer {
		return this._vehicleDealer.getValue();
	}

	set vehicleDealer(val: IVehicleDealer) {
		this._vehicleDealer.next(val);
	}

	formatVehicleDealerName(vehicleDealer: IVehicleDealer) {
		if (!vehicleDealer || !vehicleDealer?.name) return '';
		return vehicleDealer.name.indexOf(vehicleDealer.companyName) > -1 ? vehicleDealer.name : `${vehicleDealer.companyName} - ${vehicleDealer.name}`;
	}

	formatVehicleDealerAddress(vehicleDealer: IVehicleDealer, includeCity = false, includePostcode = false) {
		if (!vehicleDealer) return '';
		let formattedAddress = '';
		formattedAddress += vehicleDealer.address1 ? vehicleDealer.address1 + ', ' : '';
		formattedAddress += vehicleDealer.address2 ? vehicleDealer.address2 + ', ' : '';
		formattedAddress += vehicleDealer.address3 ? vehicleDealer.address3 + ', ' : '';
		if (includeCity) formattedAddress += vehicleDealer.city ? vehicleDealer.city + ' ' : '';
		if (includePostcode) formattedAddress += vehicleDealer.postcode ? vehicleDealer.postcode + ' ' : '';
		return formattedAddress;
	}

	isSelectedVehicleDealer(vehicleDealer: IVehicleDealer): boolean {
		if (vehicleDealer.id == this.vehicleDealer.id) {
			return true;
		} else {
			return false;
		}
	}

	isVehicleDealerSelected(): boolean {
		if (this.vehicleDealer) {
			return true;
		} else {
			return false;
		}
	}

	isMultiVehicleDealer(): boolean {
		if (this.company.vehicleDealers.length > 1) {
			return true;
		} else {
			return false;
		}
	}

	selectVehicleDealer(vehicleDealer: IVehicleDealer) {
		this.vehicleDealer = vehicleDealer;
	}

	clearVehicleDealer() {
		if (this.company.vehicleDealers.length > 1) {
			this.vehicleDealer = {} as IVehicleDealer;
		}
	}

	//#endregion
}
